import { Box, Button, ButtonGroup, Chip, Grid, IconButton, Typography } from "@mui/material"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { IconsSource, IconsSourceSvg } from "../../../../../images/Icons"
import { useCallback, useEffect, useState } from "react";
import { useStyles } from "./style";
import { ImageComponent } from "../../../../../components/ImageComponent";
import { AdicionarDespesaComponent } from "./AdicionarDespesa";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FeedbackIcon from '@mui/icons-material/Feedback';
import DialogComponent from "../../sections/dialogs-desktop";
import { EditarDespesaComponent } from "./EditarDespesa";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { EnumTipo, formatarParaMoedaString, formatarParaNomeStatusAdiantamento, formatarParaNomeStatusViagem, formatarValorComMoeda, hasApprovedAdiantamento } from "../../../../../enums/enumPrestacaoDeContas";
import { HodometroComponent } from "./Hodometro";
import { ViewHodometerDComponent } from "./HodometroInfo";
import { ExtrasDespesasComponent } from "./Extras";
import prestacaoDeContasRepositorio from "../../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import ULoaderVoo from "../../../../../components/uloadervoo";
import TransferComponent from "../../../../../components/autalii_expanses/card_transfer";
import { CreationDate } from "../../sections";
import { getTipoHodometroForNumber } from "../../../../../mock/tiposdespesas";
import { TourComponent } from "../../../../../components/tutorial/index2";

import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';

const startTour = () => {
    const tour = new Shepherd.Tour({
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
            classes: 'shepherd-theme-default',
            scrollTo: { behavior: 'smooth', block: 'center' },
        },
        useModalOverlay: true,
    });

    // Passo 1
    tour.addStep({
        id: 'custom-step',
        text: `
        <div class="custom-step-text" style="text-align: center;">
            <h4 class="custom-step-text-typography1">Olá! Eu sou o guia do sistema e estou aqui para ajudar você.</h4>
            <p class="custom-step-text-body1">Essa é uma experiência simples e ágil. Você poderá criar sua viagem e adicionar despesas para controlar seus gastos.</p>
            <p class="custom-step-text-body1" style="margin-top: 15px">Vamos começar?</p>
        </div>`,
        attachTo: {
            element: '.my-element',
            on: 'right'
        },
        classes: 'custom-tooltip-class',
        buttons: [
            {
                text: 'Iniciar Tutorial',
                action: tour.next,
                classes: 'custom-next-button'
            }
        ],
        cancelIcon: {
            enabled: false
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        highlightClass: 'highlighted-element'
    });

    // Passo 2
    tour.addStep({
        id: 'second-step',
        text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Edite o titulo da sua viagem</h4>
                <p class="custom-step-text-body1">Aqui neste campo, você pode alterar o titulo da sua viagem tranquilamente.</p>
            </div>
        `,
        attachTo: {
            element: '.titulo',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: () => {
                    tour.next();
                },
                classes: 'custom-next-button'
            }
        ],
    });

    tour.addStep({
        id: 'second-step',
        text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Exclua sua viagem</h4>
                <p class="custom-step-text-body1">Aqui neste botão você pode excluir a sua viagem, caso você tenha criado apenas para ver como funciona.</p>
            </div>
        `,
        attachTo: {
            element: '.excluir',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: () => {
                    tour.next();
                },
                classes: 'custom-next-button'
            }
        ],
    });

    tour.addStep({
        id: 'second-step',
        text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Adicione sua despesa</h4>
                <p class="custom-step-text-body1">Aqui neste botão você pode adicionar sua despesa.</p>
            </div>
        `,
        attachTo: {
            element: '.adicionardespesa',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: () => {
                    tour.next();
                },
                classes: 'custom-next-button'
            }
        ],
    });

    tour.addStep({
        id: 'second-step',
        text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Adicione seu hodometro</h4>
                <p class="custom-step-text-body1">Aqui neste botão você pode adicionar seu hodometro, com fotos ou utilizando o celular com o GPS.</p>
            </div>
        `,
        attachTo: {
            element: '.adicionarhodometro',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: () => {
                    tour.next();
                },
                classes: 'custom-next-button'
            }
        ],
    });

    tour.addStep({
        id: 'second-step',
        text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Saldo Atual e Situação de Adiantamento</h4>
                <p class="custom-step-text-body1">Exibe o saldo disponível e o status do adiantamento para a viagem atual. Caso solicitado, o adiantamento aparecerá em destaque azul; se não solicitado, exibirá "Adiantamento não solicitado.</p>
            </div>
        `,
        attachTo: {
            element: '.total',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: () => {
                    tour.next();
                },
                classes: 'custom-next-button'
            }
        ],
    });


    // Iniciar o tour
    tour.start();
};




export const ViagemComponent = ({ viagem, handleCreate, handleEdit, handleDelete, setTemDespesa, handleIniciarViagem, setTypeGlobal, handleFinalizar, handleClickCreateHodometro, handleClickEditHodometro }) => {
    const classes = useStyles();
    //Type 0
    const [type, setType] = useState(0);
    const [openDeleteDespesa, setOpenDeleteDespesa] = useState(false);
    const [openFinalizarDespesa, setOpenFinalizarDespesa] = useState(false);
    const [openMessageDespesa, setOpenMessageDespesa] = useState(false);
    const [typehodometer, setTypeHodometer] = useState(0);
    const [despesas, setDespesas] = useState([]);
    const [despesa, setDespesa] = useState([]);
    const [extras, setExtras] = useState([]);
    const [currentTab, setCurrentTab] = useState(1);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pendente, setPendente] = useState(false);

    const { enqueueSnackbar } = useCustomSnackbar();

    const getDespesas = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(viagem.id);
            setDespesas(response.despesas);
            if (response.despesas.length === 0) {
                setTemDespesa(false)
            } else {
                setTemDespesa(true)
            }
            response.despesas.forEach(item => {
                if (item.mensagem !== "" && item.mensagem !== null) {
                    setPendente(true);
                }
            });
            setExtras(response.extras);
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, setDespesas, setExtras, viagem.id, enqueueSnackbar]);


    //Type 1
    const handleClickDeleteDespesa = () => {
        setOpenDeleteDespesa(false)
        handleDelete({
            idviagem: viagem.id,
            id: despesa.iddespesa
        })
    }

    const handleClickInfoDespesa = (despesa) => {
        setDespesa(despesa)
        setOpenMessageDespesa(true)
    }

    const handleClickFinalizarViagem = () => {
        setOpenFinalizarDespesa(false)
        handleFinalizar(viagem.id)
    }

    const handleChangeTab = useCallback((newValue) => {
        setCurrentTab(newValue);
    }, []);

    useEffect(() => {
        getDespesas()
    }, [viagem, getDespesas])

    useEffect(() => {
        let c = 0;
        extras?.forEach(extra => {
            if (!extra.add) {
                c += 1;
            }
        })
        setCount(c)
    }, [extras])

    useEffect(() => {
        setTypeGlobal(type)
        if (type === 0) {
            getDespesas()
        }
    }, [type, getDespesas, setTypeGlobal])

    return (
        <Grid>

            <ULoaderVoo isLoading={loading} />
            <DialogComponent
                title={viagem?.titulo}
                open={openDeleteDespesa}
                handleClose={() => setOpenDeleteDespesa(false)}
                body={
                    <Grid container item xs={12}>
                        <Typography>Deseja realmente deletar essa despesa?</Typography>
                    </Grid>
                }
                action={
                    <Grid>
                        <Button onClick={() => setOpenDeleteDespesa(false)}>Cancelar</Button>
                        <Button onClick={() => handleClickDeleteDespesa()}>Confimar</Button>
                    </Grid>
                }
                width={"sm"}
            />

            {type === 0 ? (
                <Grid style={{ minWidth: "500px" }}>
                    <Grid container item xs={12} style={{ display: "grid", justifyContent: "center" }}>
                        {viagem.status === 1 || viagem.status === 0 || viagem.status === EnumTipo.viagemstatus.pendenteacorrecao ? (
                            <Grid style={{ minWidth: "200px", marginTop: "-10px", justifyContent: "center", textAlign: "center" }}>
                                {viagem !== null && despesas.length !== 0 && viagem.status !== EnumTipo.viagemstatus.pendenteacorrecao ? (
                                    <Button variant="contained" color="primary" onClick={() => setOpenFinalizarDespesa(true)}>Finalizar viagem</Button>
                                ) : (null)}
                                {viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && pendente && !loading && (
                                    <Typography variant="body2" style={{ color: "#313131" }}>Há despesa(s) pendente a correção, retornado pelo auditor, corrija oque foi pedido para avançar sua viagem.</Typography>
                                )}
                                {viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && !pendente && !loading && despesas.length !== 0 ? (
                                    <Button variant="contained" size="large" color="primary" fullWidth onClick={() => setOpenFinalizarDespesa(true)}>Aplicar Correção</Button>
                                ) : (null)}
                                {(viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && despesas === 0) && (
                                    <Button variant="contained" color="error" onClick={() => setOpenDeleteDespesa(true)}>Deletar viagem</Button>
                                )}
                                <DialogComponent
                                    title={viagem?.titulo}
                                    open={openFinalizarDespesa}
                                    handleClose={() => setOpenFinalizarDespesa(false)}
                                    body={
                                        <Grid container item xs={12}>
                                            <Typography>Ao avançar sua viagem não poderá retorna-la, apenas ver sua viagem finalizada</Typography>
                                        </Grid>
                                    }
                                    action={
                                        <Grid>
                                            <Button onClick={() => setOpenFinalizarDespesa(false)}>Cancelar</Button>
                                            <Button onClick={() => handleClickFinalizarViagem()}>Confimar</Button>
                                        </Grid>
                                    }
                                    width={"sm"}
                                />

                                {viagem.status === EnumTipo.viagemstatus.aguardandoinicio || viagem.status === EnumTipo.viagemstatus.emviagem || viagem.status !== EnumTipo.viagemstatus.correcaorealizada && (viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && !hasApprovedAdiantamento(viagem.adiantamento)) ? (
                                    <>
                                        <Button fullWidth className="adicionardespesa" variant="contained" color="primary" onClick={() => setType(1)}>Adicionar despesa</Button>
                                        <Button
                                            className="adicionarhodometro"
                                            fullWidth
                                            variant="contained"
                                            startIcon={<img alt="Google Maps" src={IconsSource.googleMapIcon} width={20} />}
                                            color="secondary"
                                            onClick={() => setType(2)}
                                        >
                                            Hodometro
                                        </Button>
                                    </>
                                ) : (null)}
                            </Grid>
                        ) : (null)}
                    </Grid>
                    {viagem !== null && count !== 0 ? (
                        <Grid item xs={12} style={{ justifyContent: "center", alignContent: "center", textAlign: "center", marginBottom: "0px" }}>
                            <ButtonGroup variant="outlined" color="inherit" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                                {[{ value: 1, label: "Despesas" }, { value: 2, label: "Extras" }].map((tab) => (
                                    <Button
                                        key={tab.value}
                                        onClick={() => handleChangeTab(tab.value)}
                                        style={{
                                            textTransform: "none", // Mantém a capitalização original do texto
                                            fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                            borderColor: "#D6D6D6"
                                        }}
                                    >
                                        <Typography variant="h6" style={{ fontSize: "15px", fontWeight: currentTab === tab.value ? 600 : 400 }}>
                                            {tab.label}
                                        </Typography>
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Grid>
                    ) : (null)}
                    {currentTab === 1 && (
                        <>
                            {viagem !== null && despesas.length !== 0 ? (
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "20px" }}>
                                    {(viagem.status !== 1 && viagem.status !== 0) && (
                                        <Grid item xs={12} style={{ marginTop: "8px", fontFamily: "sans-serif", textAlign: "center" }}>
                                            {formatarParaNomeStatusViagem(viagem.status)}
                                        </Grid>
                                    )}
                                    {despesas
                                        .sort((a, b) => {
                                            // Itens com descrição "Pendente" ficam no topo
                                            const isAPendente = a.despesa_desc.includes("Pendente") ? 1 : 0;
                                            const isBPendente = b.despesa_desc.includes("Pendente") ? 1 : 0;

                                            return isBPendente - isAPendente; // Inverte a ordem para "Pendente" ficar no topo
                                        })
                                        .map((value) => (
                                            <>
                                                {!value.aprovado || (viagem.status !== EnumTipo.viagemstatus.finalizado) ? (
                                                    <Grid
                                                        item xs={12}
                                                        className={classes.card}
                                                        style={{ marginTop: "20px" }}
                                                        container
                                                    >
                                                        {value.hodometro ? (
                                                            <>
                                                                <Grid container item xs={12} style={{ padding: "20px" }}>
                                                                    <Grid container item xs={7} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                                        <Typography style={{ fontWeight: "600" }}>{value.hodometro ? ("Hodometro") : ("Despesa")}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={5} style={{ display: "flex", justifyContent: "right", textAlign: "right" }}>
                                                                        <Grid style={{ marginLeft: "auto" }}>
                                                                            <CreationDate data={value.datacriacao} />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={12} style={{ textAlign: "left" }}>
                                                                        <Typography style={{ fontWeight: "500", color: "#595959" }}>{value.descricao}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-start",
                                                                        alignContent: "flex-start",
                                                                        alignItems: "flex-start",
                                                                        flexDirection: "row",
                                                                        marginTop: "auto"
                                                                    }}>
                                                                        <Typography style={{ fontSize: "small" }}>
                                                                            {value.despesa_desc}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3} style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                        alignContent: "flex-end",
                                                                        alignItems: "flex-end",
                                                                        flexDirection: "row",
                                                                        marginTop: "auto"
                                                                    }}>
                                                                        {
                                                                            viagem.status === 0 ||
                                                                                viagem.status === 1 ||
                                                                                viagem.status === 9 ||
                                                                                viagem.status === EnumTipo.viagemstatus.controladoria ? (
                                                                                <>
                                                                                    {value.controladoria ? (
                                                                                        <>
                                                                                            {value.mensagemcontrolador !== "" && value.mensagemcontrolador !== null ? (
                                                                                                <>
                                                                                                    <IconButton color="warning" onClick={() => handleClickInfoDespesa(value)}>
                                                                                                        <FeedbackIcon />
                                                                                                    </IconButton>
                                                                                                    <DialogComponent
                                                                                                        title={
                                                                                                            <>
                                                                                                                Mensagem
                                                                                                            </>
                                                                                                        }
                                                                                                        open={openMessageDespesa}
                                                                                                        handleClose={() => setOpenMessageDespesa(false)}
                                                                                                        body={
                                                                                                            <Grid container item xs={12} width={280}>
                                                                                                                <Typography>{despesa.mensagemcontrolador}</Typography>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                        action={
                                                                                                            <Grid>
                                                                                                                <Button onClick={() => setOpenMessageDespesa(false)}>Fechar</Button>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                        width={"sm"}
                                                                                                    />
                                                                                                    <IconButton onClick={() => {
                                                                                                        setDespesa(value);
                                                                                                        if (value.produtos.value === 1) {
                                                                                                            setTypeHodometer(1)
                                                                                                        } else {
                                                                                                            setTypeHodometer(1)
                                                                                                        }
                                                                                                        setType(value.hodometro !== null ? (4) : (3));
                                                                                                    }}>
                                                                                                        <EditRoundedIcon />
                                                                                                    </IconButton>
                                                                                                </>
                                                                                            ) : (
                                                                                                <Button color="warning" style={{ marginTop: "0px", marginLeft: "5px" }} startIcon={<AdminPanelSettingsIcon />}>
                                                                                                    Controladoria
                                                                                                </Button>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {value.mensagem !== "" ? (
                                                                                                <>
                                                                                                    <IconButton onClick={() => handleClickInfoDespesa(value)}>
                                                                                                        <FeedbackIcon />
                                                                                                    </IconButton>
                                                                                                    <DialogComponent
                                                                                                        title={
                                                                                                            <>
                                                                                                                Mensagem
                                                                                                            </>
                                                                                                        }
                                                                                                        open={openMessageDespesa}
                                                                                                        handleClose={() => setOpenMessageDespesa(false)}
                                                                                                        body={
                                                                                                            <Grid container item xs={12} width={280}>
                                                                                                                <Typography>{despesa.mensagem}</Typography>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                        action={
                                                                                                            <Grid>
                                                                                                                <Button onClick={() => setOpenMessageDespesa(false)}>Fechar</Button>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                        width={"sm"}
                                                                                                    />
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {!value.aprovado && value.status === EnumTipo.viagemstatus.pendenteacorrecao ? (
                                                                                                        <Chip label="Pendente" color="secondary" />
                                                                                                    ) : (null)}
                                                                                                </>
                                                                                            )}

                                                                                            {!value.aprovado ? (
                                                                                                <>
                                                                                                    {value.mensagem !== "" ? (
                                                                                                        <>
                                                                                                            <IconButton onClick={() => {
                                                                                                                setDespesa(value);
                                                                                                                if (value.produtos.value === 1) {
                                                                                                                    setTypeHodometer(1)
                                                                                                                } else {
                                                                                                                    setTypeHodometer(2)
                                                                                                                }
                                                                                                                setType(value.hodometro !== null ? (4) : (3));
                                                                                                            }}>

                                                                                                                <EditRoundedIcon />
                                                                                                            </IconButton>
                                                                                                            {/* {hasApprovedAdiantamento(viagem.adiantamento) && (
                                                                                                            <IconButton onClick={() => { setDespesa(value); setOpenDeleteDespesa(true); }}>
                                                                                                                <DeleteOutlineOutlinedIcon />
                                                                                                            </IconButton>
                                                                                                        )} */}
                                                                                                        </>
                                                                                                    ) : (null)}
                                                                                                    {viagem.status === EnumTipo.viagemstatus.emviagem || viagem.status === 0 ? (
                                                                                                        <>
                                                                                                            <IconButton onClick={() => { setDespesa(value); setOpenDeleteDespesa(true); }}>
                                                                                                                <DeleteOutlineOutlinedIcon />
                                                                                                            </IconButton>
                                                                                                            <IconButton onClick={() => {
                                                                                                                setDespesa(value);
                                                                                                                if (value.produtos.value === 1) {
                                                                                                                    setTypeHodometer(1)
                                                                                                                } else {
                                                                                                                    setTypeHodometer(2)
                                                                                                                }
                                                                                                                setType(value.hodometro !== null ? (4) : (3));
                                                                                                            }}>
                                                                                                                <EditRoundedIcon />
                                                                                                            </IconButton>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {!hasApprovedAdiantamento(viagem.adiantamento) && (
                                                                                                                <IconButton onClick={() => { setDespesa(value); setOpenDeleteDespesa(true); }}>
                                                                                                                    <DeleteOutlineOutlinedIcon />
                                                                                                                </IconButton>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (null)}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                null
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={3} style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                        alignContent: "flex-end",
                                                                        alignItems: "flex-end",
                                                                        flexDirection: "row",
                                                                    }}>
                                                                        {
                                                                            viagem.status === EnumTipo.viagemstatus.viagemfinalizada ||
                                                                                viagem.status === EnumTipo.viagemstatus.pendenteacorrecao ?
                                                                                (
                                                                                    <Grid style={{ marginRight: "10px" }}>
                                                                                        {value.aprovado ? (
                                                                                            <Chip label="Aprovado" color="primary" />
                                                                                        ) : (
                                                                                            <Chip label="Pendente" color="secondary" />
                                                                                        )}
                                                                                    </Grid>
                                                                                ) : (null)
                                                                        }
                                                                        <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>
                                                                            {value.hodometro.kmtotal} Km
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Grid container item xs={10} style={{ padding: "20px" }}>
                                                                    <Grid container item xs={7} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                                        <Typography style={{ fontWeight: "600" }}>{value.hodometro ? ("Hodometro") : ("Despesa")}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={5} style={{ display: "flex", justifyContent: "right", textAlign: "right" }}>
                                                                        <CreationDate data={value.datacriacao} />
                                                                    </Grid>
                                                                    <Grid item xs={12} style={{ textAlign: "left" }}>
                                                                        <Typography style={{ fontWeight: "500", color: "#595959" }}>{value.descricao}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-start",
                                                                        alignContent: "flex-start",
                                                                        alignItems: "flex-start",
                                                                        flexDirection: "row",
                                                                        marginTop: "auto"
                                                                    }}>
                                                                        <Typography style={{ fontSize: "small" }}>
                                                                            {value.despesa_desc}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3} style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                        alignContent: "flex-end",
                                                                        alignItems: "flex-end",
                                                                        flexDirection: "row",
                                                                        marginTop: "auto"
                                                                    }}>
                                                                        {
                                                                            viagem.status === 0 ||
                                                                                viagem.status === 1 ||
                                                                                viagem.status === 9 ||
                                                                                viagem.status === EnumTipo.viagemstatus.controladoria ? (
                                                                                <>
                                                                                    {value.controladoria ? (
                                                                                        <>
                                                                                            {value.mensagemcontrolador !== "" && value.mensagemcontrolador != null ? (
                                                                                                <>
                                                                                                    <IconButton color="warning" onClick={() => handleClickInfoDespesa(value)}>
                                                                                                        <FeedbackIcon />
                                                                                                    </IconButton>
                                                                                                    <DialogComponent
                                                                                                        title={
                                                                                                            <>
                                                                                                                Mensagem
                                                                                                            </>
                                                                                                        }
                                                                                                        open={openMessageDespesa}
                                                                                                        handleClose={() => setOpenMessageDespesa(false)}
                                                                                                        body={
                                                                                                            <Grid container item xs={12} width={280}>
                                                                                                                <Typography>{despesa.mensagemcontrolador}</Typography>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                        action={
                                                                                                            <Grid>
                                                                                                                <Button onClick={() => setOpenMessageDespesa(false)}>Fechar</Button>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                        width={"sm"}
                                                                                                    />
                                                                                                    <IconButton onClick={() => {
                                                                                                        setDespesa(value);
                                                                                                        if (value.produtos.value === 1) {
                                                                                                            setTypeHodometer(1)
                                                                                                        } else {
                                                                                                            setTypeHodometer(2)
                                                                                                        }
                                                                                                        setType(value.hodometro !== null ? (4) : (3));
                                                                                                    }}>
                                                                                                        <EditRoundedIcon />
                                                                                                    </IconButton>
                                                                                                </>
                                                                                            ) : (
                                                                                                <Button color="warning" style={{ marginTop: "0px", marginLeft: "5px" }} startIcon={<AdminPanelSettingsIcon />}>
                                                                                                    Controladoria
                                                                                                </Button>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {value.mensagem !== "" ? (
                                                                                                <>
                                                                                                    <IconButton onClick={() => handleClickInfoDespesa(value)}>
                                                                                                        <FeedbackIcon />
                                                                                                    </IconButton>
                                                                                                    <DialogComponent
                                                                                                        title={
                                                                                                            <>
                                                                                                                Mensagem
                                                                                                            </>
                                                                                                        }
                                                                                                        open={openMessageDespesa}
                                                                                                        handleClose={() => setOpenMessageDespesa(false)}
                                                                                                        body={
                                                                                                            <Grid container item xs={12} width={280}>
                                                                                                                <Typography>{despesa.mensagem}</Typography>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                        action={
                                                                                                            <Grid>
                                                                                                                <Button onClick={() => setOpenMessageDespesa(false)}>Fechar</Button>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                        width={"sm"}
                                                                                                    />
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {!value.aprovado && value.status === EnumTipo.viagemstatus.pendenteacorrecao ? (
                                                                                                        <Chip label="Pendente" color="secondary" />
                                                                                                    ) : (null)}
                                                                                                </>
                                                                                            )}

                                                                                            {!value.aprovado ? (
                                                                                                <>
                                                                                                    {value.mensagem !== "" ? (
                                                                                                        <>
                                                                                                            <IconButton onClick={() => {
                                                                                                                setDespesa(value);
                                                                                                                if (value.produtos.value === 1) {
                                                                                                                    setTypeHodometer(1)
                                                                                                                } else {
                                                                                                                    setTypeHodometer(2)
                                                                                                                }
                                                                                                                setType(value.hodometro !== null ? (4) : (3));
                                                                                                            }}>

                                                                                                                <EditRoundedIcon />
                                                                                                            </IconButton>
                                                                                                            {/* <IconButton onClick={() => { setDespesa(value); setOpenDeleteDespesa(true); }}>
                                                                                                            <DeleteOutlineOutlinedIcon />
                                                                                                        </IconButton> */}
                                                                                                        </>
                                                                                                    ) : (null)}
                                                                                                    {viagem.status === EnumTipo.viagemstatus.emviagem || viagem.status === 0 ? (
                                                                                                        <>
                                                                                                            <IconButton onClick={() => { setDespesa(value); setOpenDeleteDespesa(true); }}>
                                                                                                                <DeleteOutlineOutlinedIcon />
                                                                                                            </IconButton>
                                                                                                            <IconButton onClick={() => {
                                                                                                                setDespesa(value);
                                                                                                                if (value.produtos.value === 1) {
                                                                                                                    setTypeHodometer(1)
                                                                                                                } else {
                                                                                                                    setTypeHodometer(2)
                                                                                                                }
                                                                                                                setType(value.hodometro !== null ? (4) : (3));
                                                                                                            }}>
                                                                                                                <EditRoundedIcon />
                                                                                                            </IconButton>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {!hasApprovedAdiantamento(viagem.adiantamento) && (
                                                                                                                <IconButton onClick={() => { setDespesa(value); setOpenDeleteDespesa(true); }}>
                                                                                                                    <DeleteOutlineOutlinedIcon />
                                                                                                                </IconButton>
                                                                                                            )}
                                                                                                            {/* <IconButton onClick={() => { setDespesa(value); setOpenDeleteDespesa(true); }}>
                                                                                                            <DeleteOutlineOutlinedIcon />
                                                                                                        </IconButton> */}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (null)}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ) : (null)}
                                                                    </Grid>
                                                                    <Grid item xs={3} style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                        alignContent: "flex-end",
                                                                        alignItems: "flex-end",
                                                                        flexDirection: "row",
                                                                    }}>
                                                                        {
                                                                            viagem.status === EnumTipo.viagemstatus.viagemfinalizada ||
                                                                                viagem.status === EnumTipo.viagemstatus.pendenteacorrecao ? (
                                                                                <Grid style={{ marginRight: "10px" }}>
                                                                                    {value.aprovado ? (
                                                                                        <Chip label="Aprovado" color="primary" />
                                                                                    ) : (
                                                                                        <>
                                                                                            {viagem.status === EnumTipo.viagemstatus.controladoria && !value.controladoria ? (
                                                                                                <Chip label="Pendente" color="secondary" />
                                                                                            ) : (
                                                                                                null
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </Grid>
                                                                            ) : (null)}
                                                                        <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>
                                                                            {value.hodometro !== null ? (<>
                                                                                {value.hodometro.kmtotal} Km
                                                                            </>) : (<>
                                                                                {formatarValorComMoeda(value.tipo_moeda, value.total)}
                                                                            </>)}
                                                                        </Typography>
                                                                    </Grid>

                                                                </Grid>
                                                                <Grid container item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <ImageComponent width={100} height={148} src={value.comprovante} alt={"Imagem não encontrada"} />
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                ) : (
                                                    <Grid container item xs={12}>
                                                        <Grid item xs={12} style={{ textAlign: "center", padding: "10px" }}>
                                                            {formatarParaNomeStatusAdiantamento(viagem.adiantamento.status, viagem.totalinicial)}
                                                        </Grid>
                                                        {viagem?.despesas?.map((item) => (
                                                            <>
                                                                <Grid
                                                                    item xs={12}
                                                                    className={classes.card}
                                                                    style={{ marginTop: "20px" }}
                                                                    container
                                                                >
                                                                    {item.hodometro ? (
                                                                        <>
                                                                            <Grid container item xs={12} style={{ padding: "20px" }}>
                                                                                <Grid container item xs={6}>
                                                                                    <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                                                        <Typography style={{ fontWeight: "600" }}>Hodometro</Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                    <Grid style={{ marginLeft: "auto" }}>
                                                                                        <CreationDate data={item.datadecriacao} />
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={8} style={{ textAlign: "left" }}>
                                                                                    <Typography style={{ fontWeight: "500", color: "#595959" }}>{item.descricao}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4} style={{ textAlign: "right" }}>
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "flex-start",
                                                                                    alignContent: "flex-start",
                                                                                    alignItems: "flex-end",
                                                                                    flexDirection: "row",
                                                                                }}>
                                                                                    <Grid style={{ marginLeft: "2px" }}>
                                                                                        {getTipoHodometroForNumber(item.hodometro.veiculo)}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "flex-end",
                                                                                    alignContent: "flex-end",
                                                                                    alignItems: "center",
                                                                                    flexDirection: "row",
                                                                                }}>

                                                                                    <Grid style={{}}>
                                                                                        {item.controladoria ? (
                                                                                            <>
                                                                                                <Chip label="Controladoria" color="warning" style={{ marginTop: "-18px", marginLeft: "5px", color: "white" }} icon={<AdminPanelSettingsIcon />}>
                                                                                                </Chip>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {item.aprovado ? (
                                                                                                    <Chip label="Aprovado" color="primary" />
                                                                                                ) : (
                                                                                                    <Chip label="Pendente" color="secondary" />
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </Grid>

                                                                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{item.hodometro.kmtotal} Km - {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.hodometro.valor || 0)}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    )
                                                                        :
                                                                        (
                                                                            <>
                                                                                <Grid container item xs={10} style={{ padding: "20px" }}>
                                                                                    <Grid container item xs={6} >
                                                                                        <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                                                            <Typography style={{ fontWeight: "600" }}>Despesa</Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} style={{ display: "flex", flexDirection: "column", justifyContent: "right" }}>
                                                                                        <CreationDate data={item.datadecriacao} />
                                                                                    </Grid>
                                                                                    <Grid item xs={12} style={{ textAlign: "left" }}>
                                                                                        <Typography style={{ fontWeight: "500", color: "#595959" }}>{item.descricao}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "flex-start",
                                                                                        alignContent: "flex-start",
                                                                                        alignItems: "flex-start",
                                                                                        flexDirection: "row",
                                                                                        marginTop: "auto"
                                                                                    }}>
                                                                                        <Typography style={{ fontSize: "small" }}>
                                                                                            {item.despesa_desc}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "flex-end",
                                                                                        alignContent: "flex-end",
                                                                                        alignItems: "center",
                                                                                        flexDirection: "row",
                                                                                        marginTop: "auto"
                                                                                    }}>
                                                                                        <Grid style={{ marginRight: "10px" }}>
                                                                                            {item.controladoria ? (
                                                                                                <>
                                                                                                    <Chip label="Controladoria" color="warning" style={{ marginTop: "-18px", marginLeft: "5px", color: "white" }} icon={<AdminPanelSettingsIcon />}>
                                                                                                    </Chip>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {item.aprovado ? (
                                                                                                        <Chip label="Aprovado" color="primary" />
                                                                                                    ) : (
                                                                                                        <Chip label="Pendente" color="secondary" />
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </Grid>
                                                                                        <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{formatarValorComMoeda(item.tipo_moeda, item.total)}</Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                    <ImageComponent width={100} height={148} src={item.comprovante} alt={"Imagem não encontrada"} />
                                                                                </Grid>
                                                                            </>
                                                                        )}
                                                                </Grid>
                                                            </>
                                                        ))}
                                                    </Grid>
                                                )}
                                            </>
                                        ))}
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "20px" }}>
                                        <img alt="Imagem de pastas" src={IconsSourceSvg.pastas}></img>
                                        <Typography>Nenhuma despesa encontrada</Typography>
                                        <TourComponent startTour={startTour} />
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                    {currentTab === 2 && (
                        <>
                            <ExtrasDespesasComponent viagem={viagem} extras={extras} />
                        </>
                    )}
                </Grid>
            ) : (
                null
            )}
            {type === 1 ? (
                <>
                    <AdicionarDespesaComponent viagem={viagem} handleCreate={handleCreate} setType={setType} />
                </>
            ) : (
                null
            )}
            {type === 3 ? (
                <>
                    <EditarDespesaComponent viagem={viagem} despesa={despesa} handleEdit={handleEdit} setType={setType} />
                </>
            ) : (
                null
            )}
            {type === 2 ? (
                <>
                    <HodometroComponent idviagem={viagem.id} setType={setType} handleClickCreateHodometro={handleClickCreateHodometro} />
                </>
            ) : (
                null
            )}
            {type === 4 ? (
                <>
                    <ViewHodometerDComponent viagem={viagem} hodometro={despesa} setType={setType} editHodometro={handleClickEditHodometro} typehodometro={typehodometer} />
                </>
            ) : (
                null
            )}
        </Grid>
    )
}

export const ConfirmButtonViagem = ({ viagem }) => {
    const [showBalance, setShowBalance] = useState(true);
    const despesas = [];

    const handleToggleBalance = () => {
        setShowBalance(!showBalance);
    };

    return (
        <div style={{ width: "-webkit-fill-available" }} className="total">
            {!viagem.tem_multimonetario ? (
                <>
                    {viagem.totalinicial === 0 ? (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939', display: "flex" }}>
                                    Total
                                </Typography>
                            </Box>
                            <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "18px", textAlign: "left" }}>
                                {showBalance ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.total) : '***'}
                                <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                    {showBalance ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </Typography>
                        </>
                    ) : (
                        <Box style={{ width: "100%", display: "flex" }}>
                            <Box style={{ width: "-webkit-fill-available", paddingLeft: "10px" }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                    <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "20px", textAlign: "left", display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                            {showBalance ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial - viagem.total) : '***'}
                                        </div>
                                        <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                            {showBalance ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2px" }}>
                                    <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                        Saldo Atual
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={{ width: "100%", textAlign: "end" }}>
                                <Typography variant="h6">
                                    {formatarParaNomeStatusAdiantamento(viagem.adiantamento[0]?.status)}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </>
            ) : (
                <>
                    {viagem.adiantamento.length === 1 ? (
                        <Box style={{ width: "100%", display: "flex" }}>
                            <Box style={{ width: "-webkit-fill-available", paddingLeft: "10px" }}>
                                <Typography variant="caption" color="textSecondary">{formatarParaMoedaString(viagem.adiantamento[0].tipo_moeda, viagem.adiantamento[0].total)}</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                    <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "20px", textAlign: "left", display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                            {showBalance ? formatarValorComMoeda(viagem.adiantamento[0].tipo_moeda, viagem.adiantamento[0].total) : '***'}
                                        </div>
                                        <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                            {showBalance ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2px" }}>
                                    <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                        Saldo Atual
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={{ width: "100%", textAlign: "end" }}>
                                <Typography variant="h6">
                                    {formatarParaNomeStatusAdiantamento(viagem.adiantamento[0]?.status)}
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <Grid item xs={12} sx={{
                            position: 'fixed', left: 0, top: 0, zIndex: -1,
                            height: "-webkit-fill-available",
                            flexDirection: "row",
                            paddingLeft: "2%",
                            width: "inherit",
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                            padding: "20px"
                        }}>
                            <Grid item xs={12} style={{ backgroundColor: "#FFFFFF", borderRadius: "15px", maxWidth: "350px", minWidth: "330px", padding: "20px" }}>
                                {viagem.adiantamento?.map((item) => (
                                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                                        <TransferComponent viagem={viagem} despesas={despesas} totalex={item.total} totalbrl={item.total_brl} moeda={item.tipo_moeda} status={item.status} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </div>
    )
}