import { Settings } from "@mui/icons-material";
import { Avatar, Button, Card, Grow, IconButton, ListItemText, Typography } from "@mui/material";
import { stringAvatar } from "../../../theme/palette";
import { useState } from "react";

export const MiniPersonCardItem = ({ pessoa, permissoes, selected, onSelected, index }) => {
    const { nome, centrodecusto_codigo, centrodecusto_nome, img, id } = pessoa;
    const [showFullPermissions, setShowFullPermissions] = useState(false);

    // Filtra as permissões que estão ativas (true) e formata o texto
    const permissoesAtivas = Object.keys(permissoes)
        .filter(key => permissoes[key] === true)
        .map(key => key.replace('permissao_', '').replace(/([A-Z])/g, ' $1').toUpperCase());

    // Limita o número de permissões exibidas quando `showFullPermissions` é falso
    const displayedPermissions = showFullPermissions ? permissoesAtivas : permissoesAtivas.slice(0, 2);

    return (
        <Grow
            in={true}
            style={{ transformOrigin: '0 0 0' }}
            timeout={(index + 1) * 300}
            key={id}
        >
            <Card
                style={{
                    display: 'flex',
                    alignItems: "flex-start",
                    padding: "8px",
                    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
                    borderRadius: "10px"
                }}
            >
                <Avatar src={img} {...stringAvatar(nome)} style={{ width: 48, height: 48 }} />

                <ListItemText
                    style={{ alignItems: "flex-start" }}
                    primary={nome}
                    secondary={
                        <>
                            <Typography
                                component="span"
                                variant="body2"
                                sx={{ color: 'text.primary', display: 'inline' }}
                            >
                                {centrodecusto_nome + " - " + centrodecusto_codigo}
                            </Typography>
                            {permissoesAtivas.length > 0 && (
                                <Typography
                                    component="div"
                                    variant="caption"
                                    sx={{ color: 'text.secondary', marginTop: '4px' }}
                                >
                                    Permissões: {displayedPermissions.join(', ')}
                                    {permissoesAtivas.length > 1 && !showFullPermissions && (
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowFullPermissions(true);
                                            }}
                                            style={{ marginLeft: '4px', cursor: 'pointer', color: '#2A2A2AFF', textDecoration: 'underline' }}
                                        >
                                            ver mais
                                        </a>
                                    )}
                                    {showFullPermissions && (
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowFullPermissions(false);
                                            }}
                                            style={{ marginLeft: '4px', cursor: 'pointer', color: '#2A2A2AFF', textDecoration: 'underline' }}
                                        >
                                            ver menos
                                        </a>
                                    )}
                                </Typography>
                            )}
                        </>
                    }
                    primaryTypographyProps={{
                        noWrap: true,
                        typography: 'subtitle2',
                    }}
                />

                <IconButton onClick={() => onSelected()}>
                    <Settings />
                </IconButton>
            </Card>
        </Grow>
    );
};
