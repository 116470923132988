import { Box, Button, ButtonGroup, Container, Grid, Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import React, { useState } from "react";
import CustomizedSnackbars from "../../../components/snackbar";
import UDatePicker from "../../../components/udatepicker";
import ULoaderVoo from "../../../components/uloadervoo";
import Dashboard from "../../dashboard";
import { useStyles } from "./styles";
import { useEffect } from "react";
import EconomiaTotalMobile from "./Mobile";
import EconomiaTotalDesktop from "./Desktop";
import economiaTotalRepositorio from "../../../repositorios/relatorio/ecomomiaTotal";

ChartJS.register(ArcElement, Tooltip, Legend)

export const EconomiaTotal = () => {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState();
    const [snackVariant, setSnackVariant] = useState('error');
    const [snackMessage, setSnackMessage] = useState('');
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [data, setData] = useState({
        reduction: {
            original: 0,
            reductioned: 0,
            issued: 0
        },
        economyIssued: {
            original: 0,
            final: 0,
            economy: 0,
        }
    })
    const [typeOfPeriod, setTypeOfPeriod] = useState(4);
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [options, setOptions] = useState([
        {
            datasets: [
                {
                    label: 'R$',
                    data: [data.reduction.original],
                    backgroundColor: ['#FF3300',]
                },
                {
                    label: 'R$',
                    data: [data.reduction.issued, data.reduction.reductioned],
                    backgroundColor: ['#70AD47', '#FFC000']
                },
            ],
        },
        {
            datasets: [
                {
                    label: 'R$',
                    data: [data.economyIssued.final],
                    backgroundColor: ['#FF3300',],
                },
                {
                    label: 'R$',
                    data: [data.economyIssued.original, data.economyIssued.economy],
                    backgroundColor: ['#70AD47', '#FFC000'],
                },
            ],
        },
    ]);

    const handleClickTypeOfPeriod = (period) => {
        setTypeOfPeriod(period)
        let inicio = new Date();
        let fim = new Date();
        switch (period) {
            case 1:
                inicio.setDate(inicio.getDate() - 7);
                break;
            case 2:
                inicio = new Date(inicio.getFullYear(), inicio.getMonth(), 1);
                fim = new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0);
                break;
            case 3:
                inicio = new Date(inicio.getFullYear(), 0, 1);
                fim = new Date(inicio.getFullYear(), 11, 31);
                break;
            default:
                console.warn(`Esperado um periodo`)
                break;
        }
        setDateStart(inicio);
        setDateEnd(fim);
    }

    const handleClickSearch = async () => {
        setIsLoading(true)
        try {
            let dto = {
                dataInicio: dateStart,
                dataFim: dateEnd,
                // idCentroCusto: centroCusto ? centroCusto.value : 0,
                // idViajante: viajante ? viajante.value : 0,
                // status: tipoValue.value,
            };
            let response = await economiaTotalRepositorio.getRelatorioEconomiaTotal(dto)
            setData(response.data)
        } catch (error) {
            setSnackVariant("error");
            setSnackMessage("Falha ao buscar as informações.");
            setSnackVisible({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setOptions([
            {
                datasets: [
                    {
                        label: 'R$',
                        data: [data.reduction.original],
                        backgroundColor: ['#FF3300',]
                    },
                    {
                        label: 'R$',
                        data: [data.reduction.issued, data.reduction.reductioned],
                        backgroundColor: ['#FFC000', '#70AD47',]
                    },
                ],
            },
            {
                datasets: [
                    {
                        label: 'R$',
                        data: [data.economyIssued.final],
                        backgroundColor: ['#FF3300',],
                    },
                    {
                        label: 'R$',
                        data: [data.economyIssued.original, data.economyIssued.economy],
                        backgroundColor: ['#FFC000', '#70AD47'],
                    },
                ],
            },
        ])
    }, [data])

    return (
        <>
            <ULoaderVoo isLoading={isLoading} />
            <CustomizedSnackbars {...{ variant: snackVariant, message: snackMessage, visible: snackVisible.visible, setSnackbar: setSnackVisible }} />
            <Container maxWidth="xl">
                <Box mt={2} mb={responsive ? 10 : 0}>
                    <Grid container className={classes.mainGrid}>
                        <Grid item container xs={12} styles={{ marginBottom: 1 }}>
                            <Grid item xs={responsive ? 12 : 2}>
                                <Typography variant="h6" component="h1">Economia Total IA</Typography>
                            </Grid>
                            {responsive ? null : (
                                <Grid item container xs={8}>
                                    <Grid item xs={12} className={classes.groupButton}>
                                        <ButtonGroup>
                                            <Button className={typeOfPeriod === 1 ? classes.buttonSelected : classes.buttonUnselected} onClick={() => handleClickTypeOfPeriod(1)}>
                                                <Typography className={classes.buttonTypo}>Semanal</Typography>
                                            </Button>
                                            <Button className={typeOfPeriod === 2 ? classes.buttonSelected : classes.buttonUnselected} onClick={() => handleClickTypeOfPeriod(2)}>
                                                <Typography className={classes.buttonTypo}>Mensal</Typography>
                                            </Button>
                                            <Button className={typeOfPeriod === 3 ? classes.buttonSelected : classes.buttonUnselected} onClick={() => handleClickTypeOfPeriod(3)}>
                                                <Typography className={classes.buttonTypo}>Anual</Typography>
                                            </Button>
                                            <Button className={typeOfPeriod === 4 ? classes.buttonSelected : classes.buttonUnselected} onClick={() => handleClickTypeOfPeriod(4)}>
                                                <Typography className={classes.buttonTypo}>Personalizado</Typography>
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <Box p={3}>
                                    <form autoComplete="off">
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={2}>
                                                <UDatePicker date={dateStart} setDate={setDateStart} label="Data Início" />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <UDatePicker date={dateEnd} setDate={setDateEnd} label="Data Fim" />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                {/* {permissao.relatorioCentroCusto ? (
                                                <USearchField disabled={!politicaViagem.utilizarcentrocusto} label="Centro de custo" itens={centrosCusto} value={centroCusto} setValue={setCentroCusto} />
                                            ) : null} */}
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                {/* {permissao.relatorioPessoal ? (
                                                <USearchField label="Viajante" itens={viajantes} value={viajante} setValue={setViajante} />
                                            ) : null} */}
                                            </Grid>
                                            <Grid item xs={12} md={1} style={{ alignSelf: 'center' }}>
                                                <Button onClick={() => handleClickSearch()} className={classes.button} variant="contained" fullWidth >
                                                    <SearchOutlined />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>

                        </Grid>
                        <Grid item xs={12}>
                            {options.length <= 0 ? null :
                                responsive ? (
                                    <EconomiaTotalMobile {...{ options, data }} />
                                ) : (
                                    <EconomiaTotalDesktop {...{ options, data }} />
                                )
                            }
                        </Grid>
                    </Grid >


                </Box>
            </Container >
        </>
    );
};

export const ListaEconomiaTotal = (props) => {
    return (
        <div>
            <Dashboard {...props} component={EconomiaTotal} />
        </div>
    );
};
export default ListaEconomiaTotal;
