import React from 'react';
import { TextField, useMediaQuery, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';

export const UtaliiDatePickerNormal = ({
  label = "Data Inicial", // Label padrão, mas pode ser alterado
  value,
  onChange,
  minDate, // Adicionado a capacidade de definir uma data mínima
  maxDate,
  shouldDisableDate, // Função para desabilitar datas específicas
  renderInputProps = {}, // Propriedades adicionais para o TextField
}) => {
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      {fullscreen ? (
        <MobileDatePicker
          sx={{ width: "100%", fontFamily: "sans-serif" }}
          onChange={onChange}
          label={label}
          value={value}
          renderInput={(params) => <TextField {...params} {...renderInputProps} />}
          minDate={minDate}
          maxDate={maxDate}
          shouldDisableDate={shouldDisableDate}
          fullWidth
        />
      ) : (
        <DatePicker
          label={label}
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} {...renderInputProps} />}
          minDate={minDate}
          maxDate={maxDate}
          shouldDisableDate={shouldDisableDate} // Permite desabilitar datas
          fullWidth
        />
      )}
    </LocalizationProvider>
  );
};
