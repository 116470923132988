import React from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';

const DrawerMenu = ({ currentTab, handleChangeTab, TABS_GROUPED }) => {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                // bgcolor: 'background.paper',
                borderRadius: "16px",
                position: "sticky",
                top: 80,
                boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
            }}
        >
            {/* aaaaaa */}
            <div>
                <Tabs
                    value={currentTab}
                    onChange={handleChangeTab}
                    orientation="vertical"
                    sx={{
                        mt: 'auto',
                        zIndex: 9,
                        borderRadius: "12px",
                        // bgcolor: 'background.paper',
                        [`& .${tabsClasses.flexContainer}`]: {
                            alignItems: 'flex-start',
                            color: '#585858',
                        },
                        [`& .Mui-selected`]: {
                            bgcolor: 'rgba(0, 0, 0, 0.08)',
                            color: 'primary.main',
                        },
                    }}
                >
                    {TABS_GROUPED.map((group) => (
                        <Box key={group.groupTitle} sx={{ width: '100%' }}>
                            <Typography variant="body2" sx={{ padding: '10px' }}>
                                {group.groupTitle}
                            </Typography>
                            <Tabs
                                value={currentTab}
                                onChange={handleChangeTab}
                                orientation="vertical"
                                sx={{
                                    mt: 'auto',
                                    zIndex: 9,
                                    // bgcolor: 'background.paper',
                                    [`& .${tabsClasses.flexContainer}`]: {
                                        alignItems: 'flex-start',
                                    },
                                    [`& .Mui-selected`]: {
                                        bgcolor: 'rgba(0, 0, 0, 0.08)',
                                        color: 'primary.main',
                                    },
                                }}
                            >
                                {group.tabs.map((tab) => (
                                    <Tab
                                        key={tab.value}
                                        value={tab.value}
                                        icon={tab.icon}
                                        label={tab.label}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            flexDirection: 'row',
                                            width: "100%",
                                            bgcolor: currentTab === tab.value ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
                                            color: currentTab === tab.value ? 'primary.main' : 'inherit',
                                        }}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                    ))}
                </Tabs>
            </div>
        </div>
    );
};

export default DrawerMenu;
