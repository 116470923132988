import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CloseOutlined, OpenInBrowserOutlined } from "@material-ui/icons";
import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router";
import Cookies from "universal-cookie";
import Snackbar from "../../components/snackbar";
import ULoaderVoo from "../../components/uloadervoo";
import viagemPoliticaDocRepositorio from "../../repositorios/politicaViagemDoc/index.js";
import Dashboard from "../dashboard";
import { useStyles } from "../politicaViagemDoc/style.js";
import "./style.css";

export const PoliticaViagemDoc = () => {
  const classes = useStyles();
  const cookie = new Cookies();
  const history = useHistory();
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [checkTermos, setCheckTermos] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(true);
  const [confirmed, setConfirmed] = useState(true);
  const [responseLinkDoc, setResponseLinkDoc] = useState("");
  const [backDrop, setBackDrop] = useState(false);
  const [snackState, setSnackState] = useState({ visible: false });
  const [snackVariant, setSnackVariant] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");

  const handleChangeCheckTermos = (event) => {
    setCheckTermos(event.target.checked);
  };

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleClickOpenModalLogout = () => {
    setOpenModal(true);
    cookie.remove("token");
    cookie.remove("sessionId");
    cookie.remove("usession");
    history.push("/");
  };

  const handleClickCloseModal = () => {
    setOpenModal(false);
  };

  const handleClickCloseDialog = () => {
    setOpenDialog(false);
    history.push("/dashboard");
  };

  const handleClickConcordo = async () => {
    setBackDrop(true);
    try {
      await viagemPoliticaDocRepositorio.putConfirmPoliticaViagem();
      setOpenDialog(false);
      history.push("/dashboard");
    } catch {
      setSnackVariant("error");
      setSnackMessage("Erro ao salvar confirmação de leitura.");
      setSnackState({
        visible: true,
      });
    } finally {
      setBackDrop(false);
    }
  };

  const getData = async () => {
    setBackDrop(true);
    try {
      const teste = await viagemPoliticaDocRepositorio.getLinkDocTenant();
      setResponseLinkDoc(teste.data);
    } finally {
      setBackDrop(false);
    }
  };

  useLayoutEffect(() => {
    (async () => {
      setConfirmed(await viagemPoliticaDocRepositorio.getStatusConfirmed());
      await getData();
    })();
  }, []);

  return (
    <>
      <ULoaderVoo isLoading={backDrop} />
      <Snackbar
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackState.visible,
          setSnackbar: setSnackState,
        }}
      />
      <Dialog maxWidth={"sm"} open={openModal} onClose={handleClickCloseModal}>
        <AppBar position="relative">
          <Toolbar className={classes.toolbarCustom}>
            <Typography className={classes.typographyTitle}>Aviso</Typography>
            <IconButton onClick={handleClickCloseModal}>
              <CloseOutlined className={classes.buttonExit} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            <Typography className={classes.typographyTitle}>
              Para continuar usando o sistema é necessário que concorde com os
              termos da política de viagem, para isso confirme a leitura e em
              seguida clique em 'CONCORDO'.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} className={classes.gridContainerLogout}>
              <Button
                onClick={handleClickOpenModalLogout}
                variant="outlined"
                className={classes.button}
              >
                Não Concordo
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog fullScreen open={openDialog}>
        <AppBar position="relative" className={classes.appBarCuston}>
          <Toolbar className={classes.toolbarCustom}>
            <Typography className={classes.typographyTitle}>
              Política de Viagens
            </Typography>
            {!confirmed ? null : (
              <IconButton onClick={handleClickCloseDialog}>
                <CloseOutlined className={classes.buttonExit} />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          <Container
            id={confirmed ? "BoxConfDesk" : "BoxNotConfDesk"}
            className={classes.container}
          >
            {responsive ? (
              <Box
                id={confirmed ? "BoxConfDesk" : "BoxNotConfDesk"}
                className={classes.boxPDF}
              >
                <IconButton className={classes.iconBackground}>
                  <a
                    href={responseLinkDoc}
                    className={classes.aCustom}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <OpenInBrowserOutlined className={classes.iconPDF} />
                  </a>
                </IconButton>
                <Typography className={classes.typographyInfo}>
                  Clique aqui para vizualizar o documento referente a política
                  de viagens.
                </Typography>
              </Box>
            ) : (
              <iframe
                className={classes.embed}
                title="Documento Externo" // Adicionando o atributo title
                src={responseLinkDoc}
                alt="Link Documento"
              ></iframe>
            )}
          </Container>
        </DialogContent>
        {confirmed ? null : (
          <>
            <DialogActions className={classes.dialogActions}>
              <Grid container className={classes.gridContainer}>
                <Grid item xs={responsive ? 12 : 8}>
                  <Box className={classes.boxCheck}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkTermos}
                            onChange={handleChangeCheckTermos}
                            className={classes.check}
                          />
                        }
                        label={
                          <Typography className={classes.typographyTitle}>
                            Eu li e concordo com os termos da Política de
                            Viagem.
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Box>
                </Grid>
                <Grid item xs={responsive ? 12 : 4}>
                  <Box className={classes.boxButtonsConcordarDesk}>
                    <Button
                      onClick={handleClickConcordo}
                      disabled={!checkTermos}
                      variant="outlined"
                      className={classes.button}
                    >
                      Concordo
                    </Button>
                    <Button
                      onClick={handleClickOpenModal}
                      variant="outlined"
                      className={classes.button}
                    >
                      Não Concordo
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export const Lista = (props) => {
  return (
    <div>
      <Dashboard {...props} component={PoliticaViagemDoc} />
    </div>
  );
};
export default Lista;
