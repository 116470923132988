import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch, withStyles } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import readXlsxFile from 'read-excel-file';
import Cookies from 'universal-cookie';
import Dropzone from '../../../../../components/dropzone';
import LinesTextField from '../../../../../components/LinesTextField';
import ULoaderVoo from '../../../../../components/uloadervoo';
import compraConsolidadaRepositorio from '../../../../../repositorios/relatorio/compraConsolidada';
import CheckListExcel from '../../checkListExcel/index';
import { useStyles } from './styles';

const CustomSwitch = withStyles({
    switchBase: {
        color: '#cb3d3d',
        '&$checked': {
            color: '#c62828',
        },
        '&$checked + $track': {
            backgroundColor: '#c62828',
        },
    },
    checked: {},
    track: {},
})(Switch);

export const DialogImportar = (props) => {
    const cookies = new Cookies();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [nFatura, setNFatura] = useState('');
    const [dadosImportacao, setDadosImportacao] = useState([]);
    const [existFile, setExistFile] = useState(false);
    const [openCheckListExcel, setOpenCheckListExcel] = useState(false);
    const [isNewSwitch, setIsNewSwitch] = useState(true);
    const permissaoDashboard = useSelector(state => state.permissao.permissaoDashboard);
    const [itensFromFile, setItensFromFile] = useState([]);

    const handleClickClose = () => {
        props.setModalPlanilha(false);
        setExistFile(false)
    }

    const handleChangeSwitch = (event) => {
        setIsNewSwitch(event.target.checked)
    }

    const setValueNFatura = (props) => {
        setNFatura(props)
    }

    const handleClickImportar = async () => {
        if (!nFatura && !isNewSwitch) {
            alert('Nº da Fatura não pode ser vazio!')
            return;
        }

        if (!existFile) {
            alert('Sem arquivo para importar!')
            return;
        }

        setIsLoading(true);
        try {
            if (isNewSwitch) {
                let result = await compraConsolidadaRepositorio.OrganizarExcelConsolidado(itensFromFile);
                setItensFromFile(result.data)
                setOpenCheckListExcel(true)
                return;
            } else {
                let lista = dadosImportacao.map(item => {
                    return {
                        ...item,
                        nfatura: nFatura
                    }
                })
                await compraConsolidadaRepositorio.InserirExcelConsolidado(lista);
            }
            alert('Dados importados com sucesso!');
            handleClickClose()
        } catch (e) {
            setIsLoading(false);
            handleClickClose()
        } finally {
            setIsLoading(false);
        }
    }

    const onDrop = (acceptedFiles) => {
        try {
            let listaLinhas = [];
            let sessionId = cookies.get('sessionId');

            if (isNewSwitch) {
                readXlsxFile(acceptedFiles[0]).then((rows) => {
                    listaLinhas = rows.map(item => {
                        return {
                            itemlista: item[0]
                        }
                    })
                    setItensFromFile(listaLinhas)
                    setExistFile(true)
                })
                return
            }

            readXlsxFile(acceptedFiles[0]).then((rows) => {
                try {
                    rows.splice(0, 1);
                    listaLinhas = rows.map(item => {
                        return {
                            faturado: (item[0]),
                            nome: item[1],
                            fornecedor: item[2],
                            cid: item[3],
                            uf: item[4],
                            dataida: (item[5]),
                            datavolta: (item[6]),
                            valor: String(item[7]).replace('.', ','),
                            diarias: String(item[8]),
                            origem: item[9],
                            destino: item[10],
                            retorno: item[11],
                            descricao: item[12],
                            centrocusto: item[13],
                            tipo: item[14],
                            sessionId
                        }
                    })
                    setDadosImportacao(listaLinhas);
                    setExistFile(true)
                } catch (e) {
                    alert('Formato ou Arquivo incorretos');
                }
            })
        } catch (e) {
            return;
        }
    }

    useLayoutEffect(() => {
        setValueNFatura('');
        setExistFile(false);
    }, [])

    return (
        <>
            <Dialog
                open={props.modalPlanilha}
                fullWidth={true}
                maxWidth="sm"
                onClose={() => handleClickClose()}
            >
                <DialogTitle className={classes.dialogTittle}>
                    Importar Planilha
                </DialogTitle>
                <DialogContent style={{ height: 400 }}>
                    <ULoaderVoo isLoading={isLoading} />
                    <Grid container item xs={12}>
                        <Grid item xs={12} className={classes.gridItem}>
                            <LinesTextField disabled={isNewSwitch} margin="dense" variant="outlined" label="Nº Fatura" value={nFatura} onChange={e => setValueNFatura(e.target.value)} />
                            {!existFile === false ? (
                                <>
                                    <Done fontSize='large' style={{ color: '#c62828' }} />
                                </>
                            ) : (null)}
                            {permissaoDashboard.usuarioLinestur ? (
                                <Box ml={'15px'} >
                                    <FormControlLabel
                                        control={<CustomSwitch checked={isNewSwitch} onChange={(e) => handleChangeSwitch(e)} name="isNewSwitch" />}
                                        label="Modelo Novo"
                                    />
                                </Box>
                            ) : null}
                        </Grid>
                        <Grid item xs={12}>
                            <Dropzone onDrop={onDrop} accept={".xlx, .xlsx, .csv"} />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckListExcel
                                {...{ open: openCheckListExcel, setOpen: setOpenCheckListExcel, itensFromFile }} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions id="action6" style={{ background: '#d1d1d1' }}>
                    <Button className={classes.buttonImportar} onClick={handleClickImportar}>Importar</Button>
                    <Button className={classes.buttonCancelar} onClick={() => handleClickClose()}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DialogImportar;