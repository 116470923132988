import React from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const NewExportExcel = async ({ name, columns, data }) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(name);

  // Definir colunas com largura
  worksheet.columns = columns.map((col) => ({
    header: col.title,
    key: col.title,
    width: col.width || 20, // Define largura padrão de 20 caracteres se não for especificado
  }));

  // Adicionar dados
  data.forEach((row) => {
    worksheet.addRow(row);
  });

  // Gerar arquivo Excel
  const buffer = await workbook.xlsx.writeBuffer();

  // Salvar o arquivo usando file-saver
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `Relatório - ${new Date().toLocaleString()}.xlsx`);
};
