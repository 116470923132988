import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Badge, Grid, Typography, makeStyles } from '@material-ui/core';
import "../../../../../fonts/fonts.css"
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    customBadge: {
        backgroundColor: "#c62828",
        color: "white"
    },
    badgeContent: {
        marginLeft: '10px', // Adiciona margem à esquerda ao conteúdo do badge
    }
}));

export default function ListaDeDespesasPendentes(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const permissaoAutoDespesa = useSelector(state => state.permissao.politicaViagem.permiteautodespesas);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);  
    };

    return (
        <Grid item xs={12} container>
            {props.responsive ? (
                <Grid item xs={12} style={{ marginTop: "10px" }}>
                    {permissaoAutoDespesa && props.count > 0 ? (
                        <Button variant="outlined" color="#c62828" fullWidth onClick={handleClickOpen} style={{ fontSize: "9px" }}>
                            <Badge badgeContent={props.count} classes={{ badge: classes.customBadge }}>
                                <ReceiptIcon style={{ color: "#c62828", marginRight: "0px" }} />
                            </Badge>
                            <Typography style={{ fontFamily: "HindBold", color: "#c62828", marginLeft: "10px" }}>
                                Despesas pendentes
                            </Typography>
                        </Button>
                    ) : (
                        null
                    )}
                </Grid>
            ) : (
                <Grid item xs={12}>
                    {permissaoAutoDespesa && props.count > 0 ? (
                        <Button variant="outlined" color="#c62828" onClick={handleClickOpen} style={{ fontSize: "9px" }}>
                            <Badge badgeContent={props.count} classes={{ badge: classes.customBadge }}>
                                <ReceiptIcon style={{ color: "#c62828", marginRight: "0px" }} />
                            </Badge>
                            <Typography style={{ fontFamily: "HindBold", color: "#c62828", marginLeft: "10px" }}>
                                Despesas pendentes
                            </Typography>
                        </Button>
                    ) : (
                        null
                    )}
                </Grid>
            )}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-slide-title">Despesas ativas</DialogTitle>
                <DialogContent>
                    <Grid container item xs={12} style={{ width: "500px" }}>
                        {props.componentRenderizado}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="#c62828">
                        <Typography style={{ color: "#c62828" }}>Concluir</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
