import { Button, Card, Divider, ExpansionPanelDetails, InputBase, Paper, Table, TableCell, TableHead, TableRow, Toolbar, Typography, useMediaQuery } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import BarChart from '@material-ui/icons/BarChart';
import List from '@material-ui/icons/List';
import React, { useEffect, useState } from 'react';
import api from '../../../../Api';
import UDatePicker from '../../../../components/udatepicker';
import USearchField from '../../../../components/usearchfield';
import Dashboard from '../../../dashboard';
import { useStyles } from './styles';
import Cookies from "js-cookie";

export const ListaOrcamentoCompras = props => {
    const matches = useMediaQuery('(max-width:480px)');
    const matches2 = useMediaQuery('(max-width:900px) and (orientation: landscape)');
    const classes = useStyles();
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [centroCusto, setCentroCusto] = useState(null);
    const [pessoas, setPessoas] = useState([]);
    const [pessoa, setPessoa] = useState(null);
    const [dataInicio, setDataInicio] = useState(new Date());
    const [dataFinal, setDataFinal] = useState(new Date());

    useEffect(() => {

        let listaPessoa = [];
        let listaCentroCusto = [];

        api.get('/pessoa', {
            headers: {
                Authorization: 'Bearer ' + Cookies.get('token'),
            }
        }).then(response => {
            response.data.forEach(item => {
                listaPessoa.push({
                    label: item.nome,
                    value: item.idpessoa,
                })
            })
            setPessoas(listaPessoa);
        })

        api.get('/centroCusto', {
            headers: {
                Authorization: 'Bearer ' + api.get('token'),
            }
        }).then(response => {
            response.data.forEach(item => {
                listaCentroCusto.push({
                    label: item.nome,
                    value: item.idcentroCusto,
                })
            })
            setCentrosCusto(listaCentroCusto);
        })
    }, [])

    return (
        <div className={classes.expansion}>
            {!matches && !matches2 ? (<Typography className={classes.titulo}>Orçamento de Compras</Typography>) : <Typography className={classes.titulo}>Orçamento Compras</Typography>}
            <div className={classes.lista2}>
                <Card elevation={5}>
                    {!matches && !matches2 ? (
                        <div className={classes.testevai}>
                            <Typography className={classes.heading}>Utilize filtros em sua pesquisa</Typography>
                            <Button className={classes.button}>Aplicar Filtros</Button>
                        </div>) : null}
                    <Divider />
                    <ExpansionPanelDetails className={classes.filtros}>
                        <div className={classes.search}>
                            <USearchField {...{ itens: centrosCusto, value: centroCusto, setValue: setCentroCusto, label: 'Centro de Custo' }} />
                        </div>

                        <div className={classes.search}>
                            <USearchField {...{ itens: pessoas, value: pessoa, setValue: setPessoa, label: 'Viajante' }} />
                        </div>
                        <div className={classes.udate}>
                            <div className={classes.data1}>
                                <UDatePicker {...{ date: dataInicio, setDate: setDataInicio, label: 'Data Inicio' }} />
                            </div>
                            <div className={classes.data}>
                                <UDatePicker {...{ date: dataFinal, setDate: setDataFinal, label: 'Data Final' }} />
                            </div>
                        </div>
                        <div className={classes.search2}>
                            <USearchField {...{ itens: centrosCusto, value: centroCusto, setValue: setCentroCusto, label: 'Período' }} />
                        </div>
                        {matches || matches2 ? (
                            <div className={classes.iconzitobutton}>
                                <Button className={classes.button}>Pesquisar</Button>
                            </div>) : null}
                    </ExpansionPanelDetails>
                </Card>
            </div>
            <div className={classes.lista}>
                <Paper>
                    <Toolbar>
                        <div className={classes.separar}>
                            <div className={classes.botoes}>
                                <div className={classes.botoes1}>
                                    <Button >
                                        <List className={classes.icone}></List>
                                        Lista
                                    </Button>
                                </div>
                                <div className={classes.botoes2}>
                                    <Button>
                                        <BarChart className={classes.icone}></BarChart>
                                        Gráfico
                                    </Button>
                                </div>
                            </div>
                            <div className={classes.search3}>
                                <div className={classes.searchIcon}>
                                    <Search />
                                </div>
                                <InputBase
                                    placeholder="Pesquise…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </div>
                        </div>
                    </Toolbar>
                    <Table>
                        <TableHead>
                            <TableRow >
                                {!matches && !matches2 ? (
                                    <div className={classes.dados}>
                                        <TableCell className={classes.heads} align="center"></TableCell>
                                        <TableCell className={classes.heads} align="center"></TableCell>
                                        <TableCell className={classes.heads} align="center">Data</TableCell>
                                        <TableCell className={classes.heads} align="center">Disponivel</TableCell>
                                        <TableCell className={classes.heads} align="center">Gasto</TableCell>
                                        <TableCell className={classes.heads} align="center">Orçamento</TableCell>
                                        <TableCell className={classes.heads} align="center"></TableCell>
                                    </div>) :
                                    <div>
                                        <div className={classes.cardtable}>
                                            <label className={classes.cardtab}>Data</label>
                                            <Card elevation={4} className={classes.cardtab}><TableCell className={classes.heads} align="center"></TableCell></Card>
                                            <label className={classes.cardtab} style={{ color: 'green' }}>Disponivel</label>
                                            <Card elevation={4} className={classes.cardtab}><TableCell className={classes.heads} align="center"></TableCell></Card>
                                            <label className={classes.cardtab} style={{ color: 'red' }}>Gasto</label>
                                            <Card elevation={4} className={classes.cardtab}><TableCell className={classes.heads} align="center"></TableCell></Card>
                                            <label className={classes.cardtab} style={{ color: 'DarkOrchid' }}>Orçamento</label>
                                            <Card elevation={4} className={classes.cardtab}><TableCell className={classes.heads} align="center"></TableCell></Card>
                                        </div>
                                    </div>
                                }
                            </TableRow>
                        </TableHead>
                    </Table>
                </Paper>
            </div>
        </div>
    )
};

export const Lista = props => {
    return (
        <div>
            <Dashboard {...props} component={ListaOrcamentoCompras} />
        </div>
    )
};
export default Lista;
