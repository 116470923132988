import { ArrowForwardIos, Visibility, VisibilityOff, ArrowBack } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, Typography } from "@mui/material";
import { formatarParaNomeStatusAdiantamento } from "../../../enums/enumPrestacaoDeContas";
import DunutChart from "../../../assets/charts/donutchart";

export const CardSaldo_mob = ({
    viagem,
    showBalance,
    handleToggleBalance,
    setMyLimits,
    myLimits,
    totalchart,
    chartData,
    chartColors,
    categorias
}) => {
    return (
        <Grid
            item xs={12}
            style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "10px", width: "100%", maxWidth: 360 }}
        >
            <Box
                sx={{
                    padding: '10px 20px',
                    borderRadius: '5px',
                    fontFamily: 'Arial, sans-serif',
                }}
            >
                {viagem.totalinicial === 0 ? (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                            <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939', display: "flex" }}>
                                Total
                            </Typography>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "right", textAlign: "right", paddingBottom: "-6px" }}>
                                <Button className="limites_mob" onClick={() => setMyLimits(true)} size="large" endIcon={<ArrowForwardIos sx={{ width: "15px" }} />} style={{ color: "#1C252E" }}>
                                    Meus Limites
                                </Button>
                            </Grid>
                        </Box>
                        <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "18px", textAlign: "left" }}>
                            {showBalance ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.total) : '***'}
                            <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                {showBalance ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </Typography>
                    </>
                ) : (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                            <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "20px", textAlign: "left", display: "flex" }}>
                                <div style={{ display: "flex" }}>
                                    {showBalance ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial - viagem.total) : '***'}
                                </div>
                                <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                    {showBalance ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </Typography>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "right", textAlign: "right", paddingBottom: "-6px" }}>
                                <Button className="limites_mob" onClick={() => setMyLimits(true)} size="large" endIcon={<ArrowForwardIos sx={{ width: "15px" }} />} style={{ color: "#1C252E" }}>
                                    Meus limites
                                </Button>
                            </Grid>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2px" }}>
                            <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                Saldo Atual
                            </Typography>
                        </Box>
                        <Grid item xs={12} style={{ textAlign: "center", padding: "10px" }}>
                            <Typography variant="h6">
                                {formatarParaNomeStatusAdiantamento(viagem.adiantamento[0]?.status)}
                            </Typography>
                        </Grid>
                    </>
                )}
            </Box>
            <Dialog
                onClose={() => setMyLimits(false)}
                style={{ borderRadius: "20px" }}
                aria-labelledby="customized-dialog-title"
                fullScreen
                open={myLimits}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Grid container item xs={12}>
                        <Grid item xs={1} style={{ textAlign: "right", justifyContent: "flex-end", alignItems: "center", display: "flex" }}>
                            <IconButton
                                aria-label="close"
                                onClick={() => setMyLimits(false)}
                                sx={{
                                    position: 'absolute',
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <ArrowBack />
                            </IconButton>
                        </Grid>
                        <Grid item xs={11} style={{ textAlign: "center", marginLeft: "-10px" }}>
                            <Typography style={{ fontWeight: "600", fontSize: "18px" }}>Seus Limites</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ padding: "20px", fontFamily: "sans-serif" }}>
                    <Grid container item xs={12}>
                        {totalchart >= 0 && (
                            <Grid item xs={12}>
                                <DunutChart
                                    title="Por despesa"
                                    subheader="O quanto você pode gastar por categoria"
                                    totalcenter={totalchart}
                                    chart={chartData}
                                    colors={chartColors}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} style={{ marginBottom: "16px" }}>
                            <Typography variant="body2" style={{ color: "#3D3D3D" }}>
                                Cada categoria de despesa contém o limite máximo de gasto. Caso você exceda esse limite,
                                a aprovação dependerá do auditor e do setor financeiro.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            {categorias.filter((item) =>
                                item.name !== 'Hodometro' &&
                                item.name !== 'Outros' &&
                                item.name !== 'Pendente' &&
                                item.name !== 'SOLICITAÇÃO' &&
                                item.name !== 'AEREO' &&
                                item.name !== 'HOTEL'
                            ).map((item) => (
                                <>
                                    <Typography variant="h6">{item.name}</Typography>
                                    <Box sx={{ padding: '10px', borderRadius: '8px' }}>
                                        <List>
                                            {item.produtos.map((sub) => (
                                                <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }} key={sub.name}>
                                                    <Box>
                                                        <Typography variant="body1">
                                                            {sub.name}
                                                        </Typography>
                                                        <Typography variant="body2" sx={{ color: '#4F4F4F', fontWeight: "600" }}>
                                                            Por despesa
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="body1">
                                                        {sub.limite > 0 ? (
                                                            <>
                                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(sub.limite)}
                                                            </>
                                                        ) : (
                                                            "Sem Limites"
                                                        )}
                                                    </Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                </>
                            ))}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid>
    );
};
