import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: 80,

  },
  select: {
    width: '90%',
    marginTop: '10px'
  },
  TextField: {
    width: '90%',
    '@media (max-width:480px)': {
      justifyContent: 'center',
      alignItems: 'center',
      displey: 'flex',
      flexDirection: 'column',
      width: '90%'
    },
  },
  linha: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '10px',
    '@media (max-width:480px)': {
      justifyContent: 'center',
      alignItems: 'center',
      displey: 'flex',
      flexDirection: 'column',
    },
  },
  button: {
    marginLeft: '5px'
  },
  switch: {
    width: '100%',
    paddingLeft: theme.spacing(4),
    '@media (max-width:900px) and (orientation: landscape)': {
      paddingLeft: theme.spacing(7),
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      paddingLeft: theme.spacing(7),
    },
  }


}));