import {
  AppBar,
  Avatar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  CssBaseline,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  AccessTimeOutlined,
  BookOutlined,
  BuildOutlined,
  BusinessCenter,
  CloseOutlined,
  ContactMail,
  CreditCardOutlined,
  DescriptionOutlined,
  EcoOutlined,
  ExitToAppOutlined,
  FindReplaceOutlined,
  FlightOutlined,
  HelpOutlineOutlined,
  HotelOutlined,
  ListAltOutlined,
  LocalAtmOutlined,
  LockOpen,
  MailOutline,
  MonetizationOnOutlined,
  PolicyOutlined,
  Receipt,
  SettingsApplicationsOutlined,
  TrendingDownOutlined,
  TrendingUpOutlined,
  WorkOutlineOutlined,
} from "@material-ui/icons";
import AddLocationOutlinedIcon from "@material-ui/icons/AddLocationOutlined";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import BarChartIcon from "@material-ui/icons/BarChart";
import CardTravelOutlined from "@material-ui/icons/CardTravelOutlined";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import GavelRoundedIcon from "@material-ui/icons/GavelRounded";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleIcon from "@material-ui/icons/People";
import SettingsApplicationsOutlinedIcon from "@material-ui/icons/SettingsApplicationsOutlined";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import { buscarAereo } from "../../actions/LinkAereoActions";
import {
  updateDadosSessao,
  updatePermissao,
  updatePoliticaViagem,
} from "../../actions/permissaoActions";
import { clearDataPesquisaAereo } from "../../actions/VariadosAction";
import ULoaderVoo from "../../components/uloadervoo";
import USelect from "../../components/uselect";
import USpeedDial from "../../components/uspeeddial";
import UTutorial from "../../components/Ututorial";
import permissaoRepositorio from "../../repositorios/permissao";
import PoliticaViagemRepositorio from "../../repositorios/politicaViagem";
import viagemPoliticaDocRepositorio from "../../repositorios/politicaViagemDoc/index";
import usuarioRepositorio from "../../repositorios/usuario";
import UsuarioTenantRepositorio from "../../repositorios/usuarioTenant";
import DialogExit from "./dashboard/DialogExit";
import DialogTerms from "./dashboard/DialogTerms";
import { useStyles } from "./styles";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { PaymentOutlined } from "@mui/icons-material";
import { prestacaoDeContasConfigRepositorio } from "../../repositorios/prestacaodecontas/config";

const UMenu = withStyles({
  paper: {
    background: "#464646",
    color: "white",
  },
})(Menu);

const Navegacao = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(null);
  const dispatch = useDispatch();

  const [navVisibility, setNavVisibility] = useState("");
  const politicaViagem = useSelector((state) => state.permissao.politicaViagem);
  const [gerenciarVisibility, setGerenciarVisibility] = useState(false);
  const [ajustesVisibility, setAjustesVisibility] = useState(false);
  const [actionsServicos, setActionsServicos] = useState([]);
  const permissaoDashboard = useSelector(
    (state) => state.permissao.permissaoDashboard
  );
  const permissaoState = useSelector((state) => state);
  const [permissions, setPermissions] = useState([]);
  // const dadosSessao1 = useSelector((state) => state.permissao.dadosSessao);

  // const [loading, setLoading]

  const getMyPermission = async () => {
    try {
      const response =
        await prestacaoDeContasConfigRepositorio.getPermissionUsuario();
      setPermissions(response.permissoes);
    } catch {
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    getMyPermission();
    const carregarDadosSessao = async () => {
      let actions = [
        {
          icon: <AssignmentIcon />,
          name: "Solicitação",
          action: () => {
            props.history.push("/cadastroSolicitacao");
          },
        },
        {
          icon: <Receipt />,
          name: "Prestação de conta",
          action: () => {
            props.history.push("/listaDespesasViagem");
          },
        },
        {
          icon: (
            <Badge
              badgeContent={<AutoAwesomeIcon style={{ color: "#c62828" }} />}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              overlap="circular"
            >
              <Receipt />
            </Badge>
          ),
          name: "Prestação de contas",
          action: () => {
            props.history.push("/PrestacaoDeContas");
          },
        },
        ...(!permissions.permissao_financeiro
          ? [
              {
                icon: <PaymentOutlined />,
                name: "Financeiro",
                action: () => {
                  props.history.push("/Financeiro");
                },
              },
            ]
          : []),
        {
          icon: <EcoOutlined />,
          name: "Eco Gestão",
          action: () => {
            props.history.push("/ecogestao");
          },
        },
        {
          icon: <BookOutlined />,
          name: "Agenda de Pacotes",
          action: () => {
            props.history.push("/listaAgendaPacotes");
          },
        },
        ...(permissaoDashboard?.permiteverhotel
          ? [
              {
                icon: <HotelOutlined />,
                name: "Hotel",
                action: () => {
                  props.history.push("/hotel");
                },
              },
            ]
          : []),
        {
          icon: <FlightOutlined />,
          name: "Aéreo",
          action: () => {
            props.history.push("/consultaAereo");
          },
        },
      ];

      try {
        const dadosSessao1 = await usuarioRepositorio.getDadosSessao();

        // Se idempresa for 25, remover itens com o nome "Prestação de contas"
        if (dadosSessao1.data.idempresa === 25) {
          actions = actions.filter(
            (action) => action.name !== "Prestação de contas"
          );
        } else {
          actions = actions.filter(
            (action) => action.name !== "Prestação de conta"
          );
        }

        setActionsServicos(actions); // Atualiza o estado com a lista filtrada
      } catch (error) {
        console.error("Erro ao carregar dados da sessão:", error);
      }
    };

    carregarDadosSessao();
    // const dadosSessao1 = useSelector((state) => state.permissao.dadosSessao);
    // dispatch(
    //   updateDadosSessao({
    //     imagem: dadosSessao1.data.imagem,
    //     usuario: dadosSessao1.data.usuario,
    //     idempresa: dadosSessao1.data.idempresa,
    //     nivelpermissao: dadosSessao1.data.nivelpermissao,
    //   })
    // );
  }, []);

  const filtrarCadastrosPorPermissao = (listaCadastros, dtoPermissao) => {
    const carregarDadosSessao = async () => {
      let actions = [
        {
          icon: <AssignmentIcon />,
          name: "Solicitação",
          action: () => {
            props.history.push("/cadastroSolicitacao");
          },
        },
        {
          icon: <Receipt />,
          name: "Prestação de conta",
          action: () => {
            props.history.push("/listaDespesasViagem");
          },
        },
        {
          icon: (
            <Badge
              badgeContent={<AutoAwesomeIcon style={{ color: "#c62828" }} />}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              overlap="circular"
            >
              <Receipt />
            </Badge>
          ),
          name: "Prestação de contas",
          action: () => {
            props.history.push("/PrestacaoDeContas");
          },
        },
        ...(!permissions.permissao_financeiro
          ? [
              {
                icon: <PaymentOutlined />,
                name: "Financeiro",
                action: () => {
                  props.history.push("/Financeiro");
                },
              },
            ]
          : []),
        {
          icon: <EcoOutlined />,
          name: "Eco Gestão",
          action: () => {
            props.history.push("/ecogestao");
          },
        },
        {
          icon: <BookOutlined />,
          name: "Agenda de Pacotes",
          action: () => {
            props.history.push("/listaAgendaPacotes");
          },
        },
        ...(permissaoDashboard?.permiteverhotel
          ? [
              {
                icon: <HotelOutlined />,
                name: "Hotel",
                action: () => {
                  props.history.push("/hotel");
                },
              },
            ]
          : []),
        {
          icon: <FlightOutlined />,
          name: "Aéreo",
          action: () => {
            props.history.push("/consultaAereo");
          },
        },
      ];

      try {
        const dadosSessao1 = await usuarioRepositorio.getDadosSessao();

        // Se idempresa for 25, remover itens com o nome "Prestação de contas"
        if (dadosSessao1.data.idempresa === 25) {
          actions = actions.filter(
            (action) => action.name !== "Prestação de contas"
          );
        } else {
          actions = actions.filter(
            (action) => action.name !== "Prestação de conta"
          );
        }

        setActionsServicos(actions); // Atualiza o estado com a lista filtrada
      } catch (error) {
        console.error("Erro ao carregar dados da sessão:", error);
      }
    };

    carregarDadosSessao();
    let listaActions = [...listaCadastros];
    if (!politicaViagem.utilizarcentrocusto || !dtoPermissao.centroCusto) {
      listaActions = listaActions.filter((item) => item.name !== "Centros");
    }
    if (!dtoPermissao.pessoa) {
      listaActions = listaActions.filter((item) => item.name !== "Viajantes");
    }

    if (!dtoPermissao.usuario) {
      listaActions = listaActions.filter((item) => item.name !== "Usuários");
    }

    if (!dtoPermissao.produto) {
      listaActions = listaActions.filter((item) => item.name !== "Produtos");
    }

    if (!dtoPermissao.fornecedor || !dtoPermissao.usuarioLinestur) {
      listaActions = listaActions.filter(
        (item) => item.name !== "Fornecedores"
      );
    }

    if (!dtoPermissao.empresa || !dtoPermissao.usuarioLinestur) {
      listaActions = listaActions.filter((item) => item.name !== "Empresas");
    }
    setActions(listaActions);
    if (listaActions.length > 0) {
      setGerenciarVisibility(true);
    }
    return listaActions;
  };

  const filtrarConfiguracoesPorPermissao = (
    listaConfiguracoes,
    dtoPermissao
  ) => {
    let listaActions = [...listaConfiguracoes];

    if (!dtoPermissao.permissao) {
      listaActions = listaActions.filter((item) => item.name !== "Permissão");
    }

    if (!dtoPermissao.politica) {
      listaActions = listaActions.filter((item) => item.name !== "Organização");
    }
    if (!dtoPermissao.usuarioLinestur) {
      listaActions = listaActions.filter((item) => item.name !== "Email Admin");
    }

    setActionsAjustes(listaActions);
    if (listaActions.length > 0) {
      setAjustesVisibility(true);
    }
    return listaActions;
  };

  const filtrarServicosPorPermissao = (listaServicos, dtoPermissao) => {
    let listaActions = [...listaServicos];

    if (!dtoPermissao.usuarioLinestur) {
      listaActions = listaActions.filter(
        (item) => item.name !== "Agenda de Pacotes"
      );
      // listaActions = listaActions.filter((item) => item.name !== "Hotel");
    }

    if (!dtoPermissao.permiteVerAereo) {
      listaActions = listaActions.filter((item) => item.name !== "Aéreo");
    }

    // if (!dtoPermissao.permiteverhotel) {
    //   listaActions = listaActions.filter((item) => item.name !== "Hotel");
    // }

    if (!dtoPermissao.permiteVerSolicitacao) {
      listaActions = listaActions.filter((item) => item.name !== "Solicitação");
    }

    setActionsServicos(listaActions);
    return listaActions;
  };

  const [actions, setActions] = useState([
    {
      icon: <AssignmentInd />,
      name: "Usuários",
      action: () => {
        props.history.push("/listaUsuario");
      },
    },
    {
      icon: <PeopleIcon />,
      name: "Viajantes",
      action: () => {
        props.history.push("/listaPessoa");
      },
    },
    {
      icon: <StorefrontIcon />,
      name: "Produtos",
      action: () => {
        props.history.push("/listaProduto");
      },
    },
    {
      icon: <CardTravelOutlined />,
      name: "Fornecedores",
      action: () => {
        props.history.push("/listaFornecedor");
      },
    },
    {
      icon: <LocalAtmIcon />,
      name: "Centros",
      action: () => {
        props.history.push("/listaCentroCusto");
      },
    },
    {
      icon: <ApartmentIcon />,
      name: "Empresas",
      action: () => {
        props.history.push("/listaEmpresa");
      },
    },
  ]);

  const [actionsAjustes, setActionsAjustes] = useState([
    {
      icon: <BusinessCenter />,
      name: "Organização",
      action: () => {
        props.history.push("/listaConfiguracaoOrganizacao");
      },
    },
    {
      icon: <LockOpen />,
      name: "Permissão",
      action: () => {
        props.history.push("/ListaConfiguracaoPermissaoUsuario");
      },
    },
    {
      icon: <ContactMail />,
      name: "Email Admin",
      action: () => {
        props.history.push("/ListaEmails");
      },
    },
    {
      icon: <CreditCardOutlined />,
      name: "Cartões",
      action: () => {
        props.history.push("/Cartoes");
      },
    },
  ]);

  const actionsRelatorios = [
    {
      icon: <AttachMoneyOutlinedIcon />,
      name: "Consolidado",
      action: () => {
        props.history.push("/listaComprasConsolidadas");
      },
    },
    {
      icon: <ShoppingBasketRoundedIcon />,
      name: "Compras",
      action: () => {
        props.history.push("/listaComprasRealizadas");
      },
    },
    {
      icon: <AddLocationOutlinedIcon />,
      name: "Itinerários",
      action: () => {
        props.history.push("/listaItinerarioRealizado");
      },
    },
    {
      icon: <EcoOutlined />,
      name: "Eco Gestão",
      action: () => {
        props.history.push("/listaEcoGestao");
      },
    },
    {
      icon: <GavelRoundedIcon />,
      name: "Decisão",
      action: () => {
        props.history.push("/listaTempoDecisao");
      },
    },
    {
      icon: <TouchAppIcon />,
      name: "Economia Emissão IA",
      action: () => {
        props.history.push("/listaEconomiaNaEmissao");
      },
    },
    {
      icon: <MonetizationOnOutlined />,
      name: "Reduções IA",
      action: () => {
        props.history.push("/listaMenorTarifa");
      },
    },
    {
      icon: <TrendingDownOutlined />,
      name: "Fuga De Capital",
      action: () => {
        props.history.push("/FugaDeCapital");
      },
    },
    {
      icon: <TrendingUpOutlined />,
      name: "Economia Total IA",
      action: () => {
        props.history.push("/listaEconomiaTotal");
      },
    },
    // {
    //   icon: <HourglassEmptyRoundedIcon />,
    //   name: 'Excedentes',
    //   action: () => {
    //     props.history.push('/listaExcedentes');
    //   }
    // },  {
    //   icon: <AttachMoneyOutlinedIcon />,
    //   name: 'Orçamento',
    //   action: () => {
    //     props.history.push('/listaOrcamentoCompras');
    //   }
    // }
  ];
  const [servicos, setServicos] = useState(false);
  const [gerenciar, setGerenciar] = useState(false);
  const [ajustes, setAjustes] = useState(false);
  const [relatorios, setRelatorios] = useState(false);

  useEffect(() => {
    switch (props.location.pathname) {
      case "/dashboard":
        break;
      case "/listaReservas":
        setValue(0);
        break;
      case "/consultaAereo":
      case "/cadastroSolicitacao":
      case "/listaDespesasViagem":
      case "/PrestacaoDeContas":
        setValue(1);
        break;
      case "/listaUsuario":
      case "/listaPessoa":
      case "/listaProduto":
      case "/listaFornecedor":
      case "/listaCentroCusto":
      case "/listaEmpresa":
        setValue(2);
        break;
      case "/listaExcedentes":
      case "/listaItinerarioRealizado":
      case "/listaComprasConsolidadas":
      case "/listaComprasRealizadas":
      case "/listaOrcamentoCompras":
      case "/listaTempoDecisao":
      case "/FugaDeCapital":
      case "/listaMenorTarifa":
        setValue(3);
        break;
      case "/listaConfiguracaoOrganizacao":
      case "/ListaConfiguracaoPermissaoUsuario":
        setValue(4);
        break;
    }
  }, [props.location.pathname]);

  useEffect(() => {
    (async () => {
      const responsePermissao = await permissaoRepositorio.getPorSessao();
      let dtoPermissao = {
        usuario: responsePermissao.data.permiteverusuario,
        pessoa: responsePermissao.data.permiteverpessoa,
        produto: responsePermissao.data.permiteverproduto,
        fornecedor: responsePermissao.data.permiteverfornecedor,
        empresa: responsePermissao.data.permiteverempresa,
        centroCusto: responsePermissao.data.permitevercentrocusto,
        permissao: responsePermissao.data.permiteverpermissao,
        politica: responsePermissao.data.permiteverpoliticaviagem,
        relatorio: responsePermissao.data.permiteverrelatorio,
        relatorioPessoal: responsePermissao.data.permiteverrelatoriopessoal,
        relatorioCentroCusto:
          responsePermissao.data.permiteverrelatoriocentrocusto,
        usuarioLinestur: responsePermissao.data.usuariolinestur,
        permiteautorizarnegar: responsePermissao.data.permiteautorizarnegar,
        permiteEmitir: responsePermissao.data.permiteemitir,
        permiteVerAereo: responsePermissao.data.permiteveraereo,
        permiteVerCaixadeEntrada:
          responsePermissao.data.permitevercaixadeentrada,
        permiteVerSolicitacao: responsePermissao.data.permiteversolicitacao,
        permiteverhotel: responsePermissao.data.permiteverhotel,
      };
      dispatch(
        updatePermissao({
          ...dtoPermissao,
        })
      );
      filtrarCadastrosPorPermissao(actions, dtoPermissao);
      filtrarConfiguracoesPorPermissao(actionsAjustes, dtoPermissao);
      filtrarServicosPorPermissao(actionsServicos, dtoPermissao);

      // const responseUsuarioteste = await usuarioRepositorio.getDadosSessao();
    })();
  }, []);

  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        style={{
          position: "fixed",
          bottom: theme.spacing(7),
          left: -30,
          zIndex: 2000,
        }}
      >
        <Grid
          container
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
          }}
        >
          <Grid item xs></Grid>
          <Grid className={classes.speedDial44} item xs>
            <USpeedDial
              actions={actionsServicos}
              open={servicos}
              tooltipPlacement="right"
              setOpen={setServicos}
              className={classes.speedDial2}
            />
          </Grid>
          {gerenciarVisibility ? (
            <Grid className={classes.speedDial44} item xs>
              <USpeedDial
                actions={actions}
                open={gerenciar}
                tooltipPlacement="left"
                setOpen={setGerenciar}
                className={classes.speedDial2}
              />
            </Grid>
          ) : null}
          <Grid className={classes.speedDial44} item xs>
            <USpeedDial
              actions={actionsRelatorios}
              open={relatorios}
              tooltipPlacement="left"
              setOpen={setRelatorios}
              className={classes.speedDial2}
            />
          </Grid>
          {ajustesVisibility ? (
            <Grid className={classes.speedDial44} item xs>
              <USpeedDial
                actions={actionsAjustes}
                open={ajustes}
                tooltipPlacement="left"
                setOpen={setAjustes}
                className={classes.speedDial2}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          switch (newValue) {
            case 0:
              setValue(newValue);
              props.history.push("/listaReservas");
              break;
            case 1:
              setValue(value);
              setRelatorios(false);
              setAjustes(false);
              setGerenciar(false);
              setServicos(!servicos);
              break;
            case 2:
              setValue(value);
              setRelatorios(false);
              setAjustes(false);
              setServicos(false);
              setGerenciar(!gerenciar);
              break;
            case 3:
              setValue(value);
              setGerenciar(false);
              setAjustes(false);
              setServicos(false);
              setRelatorios(!relatorios);
              break;
            case 4:
              setValue(value);
              setGerenciar(false);
              setRelatorios(false);
              setServicos(false);
              setAjustes(!ajustes);
              break;
            default:
              break;
          }
        }}
        showLabels
        component="div"
        style={{
          display: navVisibility,
          bottom: "0px",
          position: "fixed",
          zIndex: 1200,
          width: "100vw",
        }}
      >
        {permissaoDashboard.permiteVerCaixadeEntrada ? (
          <BottomNavigationAction
            className={classes.bottomNav}
            label="Entrada"
            icon={<MailOutline />}
          />
        ) : null}
        <BottomNavigationAction
          className={classes.bottomNav}
          label="Serviços"
          icon={<WorkOutlineOutlined />}
        />
        {gerenciarVisibility ? (
          <BottomNavigationAction
            className={classes.bottomNav}
            id="bottom-gerenciar"
            label="Gerenciar"
            icon={<ListAltOutlined />}
          />
        ) : null}
        {permissaoDashboard.relatorio ? (
          <BottomNavigationAction
            className={classes.bottomNav}
            label="Relatórios"
            icon={<DescriptionOutlined />}
          />
        ) : null}
        {ajustesVisibility ? (
          <BottomNavigationAction
            className={classes.bottomNav}
            label="Ajustes"
            icon={<SettingsApplicationsOutlined />}
          />
        ) : null}
      </BottomNavigation>
    </React.Fragment>
  );
};

export const Dashboard = (props) => {
  const cookie = new Cookies();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery("(max-width:480px)");
  const matches2 = useMediaQuery(
    "(max-width:900px) and (orientation: landscape)"
  );
  const matches3 = useMediaQuery(
    "(max-width:900px) and (orientation: portrait)"
  );
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(
    (cookie.get("menu") === "open") & !responsive ? true : false
  );
  const [openTerms, setOpenTerms] = useState();
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuRelatorio, setOpenMenuRelatorio] = useState(false);
  const [openModalAjuda, setOpenModalAjuda] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElRel, setAnchorElRel] = useState(null);
  const [anchorTenant, setAnchorTenant] = useState(null);
  const [anchorElRelConfig, setAnchorElRelConfig] = useState(null);
  const dispatch = useDispatch();
  const permissaoDashboard = useSelector(
    (state) => state.permissao.permissaoDashboard
  );
  const dadosSessao = useSelector((state) => state.permissao.dadosSessao);
  const politicaViagem = useSelector((state) => state.permissao.politicaViagem);
  const [empresas, setEmpresas] = useState([]);
  const [empresa, setEmpresa] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openModalPoliticaDoc, setOpenModalPoliticaDoc] = useState(false);
  const [openMenuConfigGerais, setOpenMenuConfigGerais] = useState(false);
  const [nomeEmpresa, setNomeEmpresa] = React.useState("");
  const [backDrop, setBackDrop] = useState(false);
  const [permissions, setPermissions] = useState([]);
  var teste = 0;
  const permissaoState = useSelector((state) => state);
  // const [loading, setLoading]

  const getMyPermission = async () => {
    try {
      const response =
        await prestacaoDeContasConfigRepositorio.getPermissionUsuario();
      setPermissions(response.permissoes);
      // teste = dadosSessao.
    } catch {
      // enqueueSnackbar('Erro ao obter suas permissões.', { variant: 'error' });
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    getMyPermission();
  }, []);

  const handleClickSair = () => {
    cookie.remove("token");
    cookie.remove("sessionId");
    cookie.remove("usession");
    props.history.push("/");
  };

  const handleClickPoliticaViagemDocOpen = async () => {
    let exist = await viagemPoliticaDocRepositorio.getExistPoliticaDoc();
    if (exist) {
      props.history.push("/politicaViagemDoc");
    } else {
      setOpenModalPoliticaDoc(true);
    }
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickMenuRelatorio = (event) => {
    setAnchorElRel(event.currentTarget);
  };

  const handleClickMenuTenant = (event) => {
    setAnchorTenant(event.currentTarget);
  };

  const handleClickMenuConfigGeral = (event) => {
    setAnchorElRelConfig(event.currentTarget);
  };

  const handleCloseTenant = () => {
    setAnchorTenant(null);
  };

  const handleCloseConfigGerais = () => {
    setAnchorElRelConfig(null);
  };

  const handleCloseRelatorio = () => {
    setAnchorElRel(null);
  };

  const handleClick = () => {
    setOpenMenu(!openMenu);
  };

  const handleClickConfigGeral = () => {
    setOpenMenuConfigGerais(!openMenuConfigGerais);
  };

  const handleClickRelatorio = () => {
    setOpenMenuRelatorio(!openMenuRelatorio);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    cookie.set("menu", "open");
  };

  const handleDrawerClose = () => {
    cookie.set("menu", "close");
    setOpenMenu(false);
    setOpenMenuRelatorio(false);
    setOpen(false);
  };

  const onMenuItemClick = (location) => {
    dispatch(clearDataPesquisaAereo());
    props.history.push(location);
  };

  const handleEmpresaChange = async (event) => {
    try {
      let dtoDadosTenant = {
        idempresa: event.target.value,
        sessionId: cookie.get("sessionId"),
      };
      await UsuarioTenantRepositorio.alterarOrganizacao(dtoDadosTenant);
      // if (isMounted) {
      // setBackDrop(false);
      props.history.push("/login");
      // }
    } catch (e) {
      // if (isMounted) {
      //   setBackDrop(false);
      // }
    }

    // Função de limpeza do useEffect
    // return () => {
    //   isMounted = false; // Atualiza a flag quando o componente é desmontado
    // };
  };

  const handleClosePoliticaDoc = () => {
    setOpenModalPoliticaDoc(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickDialog = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    (async () => {
      const responseUsuario = await usuarioRepositorio.getDadosSessao();

      if (!responseUsuario.data.usuario) {
        cookie.remove("token");
        cookie.remove("sessionId");
        cookie.remove("usession");
        props.history.push("/");
        return;
      }

      dispatch(
        updateDadosSessao({
          imagem: responseUsuario.data.imagem,
          usuario: responseUsuario.data.usuario,
          idempresa: responseUsuario.data.idempresa,
          nivelpermissao: responseUsuario.data.nivelpermissao,
        })
      );

      const responseTenant =
        await UsuarioTenantRepositorio.getSearchFieldDashboard();
      if (responseTenant.length > 0) {
        let empresaNome = responseTenant.find(
          (item) => item.value === responseUsuario.data.idempresa
        );
        cookie.set("org", empresaNome.label);
        setNomeEmpresa(empresaNome.label);
      }
      if (responseTenant.length > 1) {
        setEmpresas(responseTenant);
        setEmpresa(responseUsuario.data.idempresa);
      }

      const organizacao = await PoliticaViagemRepositorio.validarConsulta();
      dispatch(updatePoliticaViagem(organizacao.data.politicaViagem));

      const responsePermissao = await permissaoRepositorio.getPorSessao();
      dispatch(
        updatePermissao({
          usuario: responsePermissao.data.permiteverusuario,
          pessoa: responsePermissao.data.permiteverpessoa,
          produto: responsePermissao.data.permiteverproduto,
          fornecedor: responsePermissao.data.permiteverfornecedor,
          empresa: responsePermissao.data.permiteverempresa,
          centroCusto: responsePermissao.data.permitevercentrocusto,
          permissao: responsePermissao.data.permiteverpermissao,
          politica: responsePermissao.data.permiteverpoliticaviagem,
          relatorio: responsePermissao.data.permiteverrelatorio,
          relatorioPessoal: responsePermissao.data.permiteverrelatoriopessoal,
          relatorioCentroCusto:
            responsePermissao.data.permiteverrelatoriocentrocusto,
          usuarioLinestur: responsePermissao.data.usuariolinestur,
          permiteautorizarnegar: responsePermissao.data.permiteautorizarnegar,
          permiteEmitir: responsePermissao.data.permiteemitir,
          permiteVerAereo: responsePermissao.data.permiteveraereo,
          permiteVerCaixadeEntrada:
            responsePermissao.data.permitevercaixadeentrada,
          permiteVersolicitacao: responsePermissao.data.permiteversolicitacao,
          permiteverecogestao: responsePermissao.data.permiteverecogestao,
          permiteeditarecogestao: responsePermissao.data.permiteeditarecogestao,
          permiteverhotel: responsePermissao.data.permiteverhotel,
        })
      );
    })();

    let menu = cookie.get("menu");
    setOpen((menu === "open") & !responsive ? true : false);
  }, []);

  return (
    <div className={classes.root}>
      <ULoaderVoo isLoading={backDrop} />
      <DialogExit
        {...{
          handleClickSair,
          responsive,
          openDialog,
          handleClickDialog,
          handleCloseDialog,
        }}
      />
      <DialogTerms
        {...{
          openDialog: openTerms,
          handleCloseDialog: () => setOpenTerms(false),
        }}
      />
      <Dialog open={openModalPoliticaDoc} onClose={handleClosePoliticaDoc}>
        <AppBar position="relative">
          <Toolbar className={classes.toolbarCustom}>
            <Typography className={classes.typographyTitle}>
              Política de Viagens
            </Typography>
            <IconButton onClick={handleClosePoliticaDoc}>
              <CloseOutlined className={classes.buttonExit} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.typographyTitle}>
            A organização não possui política de viagem cadastrada.
          </Typography>
        </DialogContent>
      </Dialog>
      <UTutorial open={openModalAjuda} setOpen={setOpenModalAjuda} />
      {empresas.length > 1 ? (
        <UMenu
          id="simple-menu"
          anchorEl={anchorTenant}
          keepMounted
          open={Boolean(anchorTenant)}
          onClose={handleCloseTenant}
        >
          <MenuItem>
            <USelect
              custom={true}
              className={classes.selectOrganizacao}
              itens={empresas}
              value={empresa}
              setValue={setEmpresa}
              onChange={handleEmpresaChange}
              label="Organização"
            />
          </MenuItem>
        </UMenu>
      ) : null}
      {matches || matches2 || matches3 ? (
        <div className={classes.appb}>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]:
                (cookie.get("menu") === "open") & !responsive ? true : false,
            })}
          >
            <Toolbar className={classes.tools}>
              <div className={classes.divTools2}>
                <Typography
                  onClick={() => onMenuItemClick("/dashboard")}
                  className={classes.home}
                  variant="h6"
                  noWrap={false}
                >
                  {cookie.get("org")}
                </Typography>
                <Typography style={{ fontSize: "9px" }}>
                  {cookie.get("org")}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#c62828",
                  zIndex: 2,
                }}
              >
                <Tooltip title="Política de viagens">
                  <IconButton onClick={handleClickPoliticaViagemDocOpen}>
                    <PolicyOutlined style={{ color: "#ffffff" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Ajuda">
                  <IconButton onClick={() => setOpenModalAjuda(true)}>
                    <HelpOutlineOutlined style={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Sair">
                  <IconButton onClick={handleClickDialog}>
                    <ExitToAppOutlined style={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={dadosSessao.usuario}
                  onClick={handleClickMenuTenant}
                >
                  <Avatar src={dadosSessao.imagem} />
                </Tooltip>
              </div>
            </Toolbar>
          </AppBar>
          <Navegacao {...props} />
        </div>
      ) : (
        <div>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar className={classes.tools}>
              <div className={classes.divTools}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  onClick={() => onMenuItemClick("/dashboard")}
                  className={classes.home}
                  variant="h6"
                  noWrap
                >
                  {cookie.get("org")}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {/* <Typography style={{ marginRight: '10px' }}>{nomeEmpresa}</Typography> */}

                {dadosSessao.idempresa === 5 &&
                (cookie.get("usession") === "claudio" ||
                  cookie.get("usession") === "levi") ? (
                  <Tooltip title="Contrato de Prestação de Serviço Linestur Digital">
                    <IconButton onClick={() => setOpenTerms(!openTerms)}>
                      <DescriptionOutlined style={{ color: "#ffffff" }} />
                    </IconButton>
                  </Tooltip>
                ) : null}

                <Tooltip title="Política de viagens">
                  <IconButton
                    onClick={handleClickPoliticaViagemDocOpen}
                    hidden={true}
                  >
                    <PolicyOutlined style={{ color: "#ffffff" }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Ajuda">
                  <IconButton onClick={() => setOpenModalAjuda(true)}>
                    <HelpOutlineOutlined style={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
                <IconButton onClick={handleClickDialog}>
                  <Tooltip title="Sair">
                    <ExitToAppOutlined style={{ color: "#fff" }} />
                  </Tooltip>
                </IconButton>
                <Tooltip
                  style={{ cursor: "pointer" }}
                  onClick={handleClickMenuTenant}
                  title={dadosSessao.usuario}
                >
                  <Avatar src={dadosSessao.imagem} />
                </Tooltip>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
            open={open}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon style={{ color: "white" }} />
                ) : (
                  <ChevronLeftIcon style={{ color: "white" }} />
                )}
              </IconButton>
            </div>
            <Divider />
            <List>
              {permissaoDashboard.permiteVerCaixadeEntrada ? (
                <Tooltip placement="right" title="Caixa de entrada">
                  <ListItem
                    button
                    onClick={(e) => onMenuItemClick("/listaReservas")}
                  >
                    <ListItemIcon>
                      <MailOutline className={classes.icone} fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.icone}
                      primary="Caixa de Entrada"
                    />
                  </ListItem>
                </Tooltip>
              ) : null}
              {permissaoDashboard.permiteVerAereo ? (
                <Tooltip
                  placement="right"
                  title="Aéreo Nacional e Internacional"
                >
                  <ListItem
                    button
                    onClick={(e) => onMenuItemClick("/consultaAereo")}
                  >
                    <ListItemIcon>
                      <FlightOutlined
                        className={classes.icone}
                        fontSize="large"
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.icone}
                      primary={
                        <Typography>
                          {" "}
                          Aéreo Nacional e<br></br> Internacional
                        </Typography>
                      }
                    />
                  </ListItem>
                </Tooltip>
              ) : null}
              {permissaoDashboard.permiteverhotel ? (
                <Tooltip placement="right" title="Hotel">
                  <ListItem button onClick={(e) => onMenuItemClick("/hotel")}>
                    <ListItemIcon>
                      <HotelOutlined
                        className={classes.icone}
                        fontSize="large"
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.icone}
                      primary={<Typography>Hotel</Typography>}
                    />
                  </ListItem>
                </Tooltip>
              ) : null}
              {permissaoDashboard.permiteVersolicitacao ? (
                <Tooltip placement="right" title="Criar Solicitação">
                  <ListItem
                    button
                    className={classes.icone}
                    onClick={(e) => onMenuItemClick("/cadastroSolicitacao")}
                  >
                    <ListItemIcon>
                      <AssignmentIcon
                        className={classes.icone}
                        fontSize="large"
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.icone}
                      primary="Criar Solicitação"
                    />
                  </ListItem>
                </Tooltip>
              ) : null}
              {permissaoDashboard.usuarioLinestur ? (
                <Tooltip placement="right" title="Agenda de pacotes">
                  <ListItem
                    button
                    onClick={(e) => onMenuItemClick("/listaAgendaPacotes")}
                  >
                    <ListItemIcon>
                      <BookOutlined
                        className={classes.icone}
                        fontSize="large"
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.icone}
                      primary={<Typography>Agenda de pacotes</Typography>}
                    />
                  </ListItem>
                </Tooltip>
              ) : null}
              {permissaoDashboard.permiteverecogestao ? (
                <Tooltip placement="right" title="Eco gestão">
                  <ListItem
                    button
                    className={classes.icone}
                    onClick={(e) => onMenuItemClick("/ecogestao")}
                  >
                    <ListItemIcon>
                      <EcoOutlined className={classes.icone} fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.icone}
                      primary={<Typography>Eco gestão</Typography>}
                    />
                  </ListItem>
                </Tooltip>
              ) : null}
              {permissaoState.permissao.politicaViagem.idtenant === 25 && (
                <Tooltip placement="right" title="Prestação de contas">
                  <ListItem
                    button
                    className={classes.icone}
                    onClick={(e) => onMenuItemClick("/listaDespesasViagem")}
                  >
                    <ListItemIcon>
                      <Receipt className={classes.icone} fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.icone}
                      primary={
                        <Typography>
                          Despesas por <br></br>viagem
                        </Typography>
                      }
                    />
                  </ListItem>
                </Tooltip>
              )}
              {permissaoState.permissao.politicaViagem.idtenant !== 25 && (
                <Tooltip placement="right" title="Prestação de contas">
                  <ListItem
                    button
                    className={classes.icone}
                    onClick={(e) => onMenuItemClick("/PrestacaoDeContas")}
                  >
                    <ListItemIcon>
                      <Badge
                        badgeContent={
                          <AutoAwesomeIcon style={{ color: "#c62828" }} />
                        }
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        overlap="circular"
                      >
                        <Receipt className={classes.icone} fontSize="large" />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText
                      className={classes.icone}
                      primary={<Typography>Prestação de contas</Typography>}
                    />
                  </ListItem>
                </Tooltip>
              )}
              {permissions.permissao_financeiro && (
                <Tooltip placement="right" title="Financeiro">
                  <ListItem
                    button
                    className={classes.icone}
                    onClick={(e) => onMenuItemClick("/Financeiro")}
                  >
                    <ListItemIcon>
                      <PaymentOutlined
                        className={classes.icone}
                        fontSize="large"
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.icone}
                      primary={<Typography>Financeiro</Typography>}
                    />
                  </ListItem>
                </Tooltip>
              )}
              {permissaoDashboard.usuario ? (
                <Tooltip placement="right" title="Usuários">
                  <ListItem
                    button
                    onClick={(e) => onMenuItemClick("/listaUsuario")}
                  >
                    <ListItemIcon>
                      <AssignmentInd
                        className={classes.icone}
                        fontSize="large"
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.icone}
                      primary="Usuários"
                    />
                  </ListItem>
                </Tooltip>
              ) : null}

              {permissaoDashboard.pessoa ? (
                <Tooltip placement="right" title="Viajantes">
                  <ListItem
                    button
                    onClick={(e) => onMenuItemClick("/listaPessoa")}
                  >
                    <ListItemIcon>
                      <PeopleIcon className={classes.icone} fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.icone}
                      primary="Viajantes"
                    />
                  </ListItem>
                </Tooltip>
              ) : null}

              {permissaoDashboard.usuarioLinestur &&
              (permissaoDashboard.produto ||
                permissaoDashboard.empresa ||
                permissaoDashboard.fornecedor) ? (
                <>
                  <Tooltip Tooltip placement="right" title="Gerais">
                    <ListItem
                      button
                      onClick={
                        open
                          ? handleClickConfigGeral
                          : handleClickMenuConfigGeral
                      }
                    >
                      <ListItemIcon>
                        <BuildOutlined
                          className={classes.icone}
                          fontSize="large"
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.icone}
                        primary="Gerais"
                      />
                      {openMenuConfigGerais ? (
                        <ExpandLess className={classes.icone} />
                      ) : (
                        <ExpandMore className={classes.icone} />
                      )}
                    </ListItem>
                  </Tooltip>
                  <UMenu
                    id="simple-menu"
                    anchorEl={anchorElRelConfig}
                    keepMounted
                    open={Boolean(anchorElRelConfig)}
                    onClose={handleCloseConfigGerais}
                  >
                    {permissaoDashboard.produto &&
                    permissaoDashboard.usuarioLinestur ? (
                      <MenuItem
                        onClick={(e) => onMenuItemClick("/listaProduto")}
                      >
                        <StorefrontIcon />
                        <Typography className={classes.texto}></Typography>
                        Produtos
                      </MenuItem>
                    ) : null}
                    {permissaoDashboard.fornecedor &&
                    permissaoDashboard.usuarioLinestur ? (
                      <MenuItem
                        onClick={(e) => onMenuItemClick("/listaFornecedor")}
                      >
                        <CardTravelOutlined />
                        <Typography className={classes.texto}></Typography>
                        Fornecedores
                      </MenuItem>
                    ) : null}
                    {permissaoDashboard.empresa &&
                    permissaoDashboard.usuarioLinestur ? (
                      <MenuItem
                        onClick={(e) => onMenuItemClick("/listaEmpresa")}
                      >
                        <ApartmentIcon />
                        <Typography className={classes.texto}></Typography>
                        Empresas
                      </MenuItem>
                    ) : null}
                  </UMenu>
                  <Collapse
                    in={openMenuConfigGerais}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {permissaoDashboard.produto &&
                      permissaoDashboard.usuarioLinestur ? (
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={(e) => onMenuItemClick("/listaProduto")}
                        >
                          <ListItemIcon>
                            <StorefrontIcon
                              className={classes.icone}
                              fontSize="medium"
                            />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.icone}
                            primary="Produtos"
                          />
                        </ListItem>
                      ) : null}
                      {permissaoDashboard.fornecedor &&
                      permissaoDashboard.usuarioLinestur ? (
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={(e) => onMenuItemClick("/listaFornecedor")}
                        >
                          <ListItemIcon>
                            <CardTravelOutlined
                              className={classes.icone}
                              fontSize="medium"
                            />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.icone}
                            primary="Fornecedor"
                          />
                        </ListItem>
                      ) : null}
                      {permissaoDashboard.empresa &&
                      permissaoDashboard.usuarioLinestur ? (
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={(e) => onMenuItemClick("/listaEmpresa")}
                        >
                          <ListItemIcon>
                            <ApartmentIcon
                              className={classes.icone}
                              fontSize="medium"
                            />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.icone}
                            primary="Empresas"
                          />
                        </ListItem>
                      ) : null}
                    </List>
                  </Collapse>
                </>
              ) : null}
              {permissaoDashboard.centroCusto &&
              politicaViagem.utilizarcentrocusto ? (
                <Tooltip placement="right" title="Centro de Custo">
                  <ListItem
                    button
                    onClick={(e) => onMenuItemClick("/listaCentroCusto")}
                  >
                    <ListItemIcon>
                      <LocalAtmIcon
                        className={classes.icone}
                        fontSize="large"
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.icone}
                      primary="Centro de Custo"
                    />
                  </ListItem>
                </Tooltip>
              ) : null}
              {/* {permissaoDashboard.permissao || permissaoDashboard.politica ? ( */}
              <div>
                {permissaoDashboard.permiteEmitir ? (
                  <Tooltip placement="right" title="Configurações">
                    <ListItem
                      button
                      onClick={open ? handleClick : handleClickMenu}
                    >
                      <ListItemIcon>
                        <SettingsApplicationsOutlinedIcon
                          className={classes.icone}
                          fontSize="large"
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.icone}
                        primary="Configurações"
                      />
                      {openMenu ? (
                        <ExpandLess className={classes.icone} />
                      ) : (
                        <ExpandMore className={classes.icone} />
                      )}
                    </ListItem>
                  </Tooltip>
                ) : null}
                {/* ) : null} */}
                <UMenu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={(e) => onMenuItemClick("/Cartoes")}>
                    <CreditCardOutlined />{" "}
                    <Typography className={classes.texto}>Cartões</Typography>
                  </MenuItem>
                  {permissaoDashboard.usuarioLinestur ? (
                    <MenuItem onClick={(e) => onMenuItemClick("/ListaEmails")}>
                      <ContactMail />{" "}
                      <Typography className={classes.texto}>
                        Email Admin
                      </Typography>
                    </MenuItem>
                  ) : null}
                  {permissaoDashboard.permissao ? (
                    <MenuItem
                      onClick={(e) =>
                        onMenuItemClick("/ListaConfiguracaoPermissaoUsuario")
                      }
                    >
                      <LockOpen />{" "}
                      <Typography className={classes.texto}>
                        Permissão
                      </Typography>
                    </MenuItem>
                  ) : null}
                  {permissaoDashboard.politica ? (
                    <MenuItem
                      onClick={(e) =>
                        onMenuItemClick("/listaConfiguracaoOrganizacao")
                      }
                    >
                      <BusinessCenter />
                      <Typography className={classes.texto}>
                        Organização
                      </Typography>
                    </MenuItem>
                  ) : null}
                </UMenu>
                <Collapse in={openMenu} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={(e) => onMenuItemClick("/Cartoes")}
                    >
                      <ListItemText
                        className={classes.icone}
                        primary="Cartões"
                      />
                    </ListItem>
                    {permissaoDashboard.politica ? (
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={(e) =>
                          onMenuItemClick("/listaConfiguracaoOrganizacao")
                        }
                      >
                        <ListItemText
                          className={classes.icone}
                          primary="Organizações"
                        />
                      </ListItem>
                    ) : null}
                    {permissaoDashboard.permissao ? (
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={(e) =>
                          onMenuItemClick("/ListaConfiguracaoPermissaoUsuario")
                        }
                      >
                        <ListItemText
                          className={classes.icone}
                          primary="Permissão"
                        />
                      </ListItem>
                    ) : null}
                    {permissaoDashboard.usuarioLinestur ? (
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={(e) => onMenuItemClick("/ListaEmails")}
                      >
                        <ListItemText
                          className={classes.icone}
                          primary="Email Admin"
                        />
                      </ListItem>
                    ) : null}
                  </List>
                </Collapse>
              </div>
              {/* ) : null} */}
              {permissaoDashboard.relatorio ? (
                <Tooltip placement="right" title="Relatórios">
                  <ListItem
                    button
                    onClick={
                      open ? handleClickRelatorio : handleClickMenuRelatorio
                    }
                  >
                    <ListItemIcon>
                      <BarChartIcon
                        className={classes.icone}
                        fontSize="large"
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.icone}
                      primary="Relatórios"
                    />
                    {openMenuRelatorio ? (
                      <ExpandLess className={classes.icone} />
                    ) : (
                      <ExpandMore className={classes.icone} />
                    )}
                  </ListItem>
                </Tooltip>
              ) : null}
              <UMenu
                id="simple-menu"
                anchorEl={anchorElRel}
                keepMounted
                open={Boolean(anchorElRel)}
                onClose={handleCloseRelatorio}
              >
                <MenuItem
                  onClick={(e) => onMenuItemClick("/listaEconomiaTotal")}
                >
                  <TrendingUpOutlined />{" "}
                  <Typography className={classes.texto}></Typography>Economia
                  Total IA
                </MenuItem>
                <MenuItem onClick={(e) => onMenuItemClick("/FugaDeCapital")}>
                  <TrendingDownOutlined />
                  <Typography className={classes.texto}></Typography>Fuga De
                  Capital
                </MenuItem>
                <MenuItem onClick={(e) => onMenuItemClick("/listaMenorTarifa")}>
                  <MonetizationOnOutlined />
                  <Typography className={classes.texto}></Typography>Reduções IA
                </MenuItem>
                <MenuItem
                  onClick={(e) => onMenuItemClick("/listaEconomiaNaEmissao")}
                >
                  <TouchAppIcon />{" "}
                  <Typography className={classes.texto}></Typography>Economia
                  Emissão IA
                </MenuItem>
                <MenuItem
                  onClick={(e) => onMenuItemClick("/listaTempoDecisao")}
                >
                  <GavelRoundedIcon />{" "}
                  <Typography className={classes.texto}></Typography>Tempo de
                  Decisão
                </MenuItem>
                <MenuItem onClick={(e) => onMenuItemClick("/listaEcoGestao")}>
                  <EcoOutlined />
                  <Typography className={classes.texto}></Typography>Eco Gestão
                </MenuItem>
                <MenuItem
                  onClick={(e) => onMenuItemClick("/listaItinerarioRealizado")}
                >
                  <AddLocationOutlinedIcon />{" "}
                  <Typography className={classes.texto}></Typography>Itinerário
                  Realizado
                </MenuItem>
                <MenuItem
                  onClick={(e) => onMenuItemClick("/listaComprasRealizadas")}
                >
                  <ShoppingBasketRoundedIcon />{" "}
                  <Typography className={classes.texto}></Typography>Compras
                  Realizadas
                </MenuItem>
                <MenuItem
                  onClick={(e) => onMenuItemClick("/listaComprasConsolidadas")}
                >
                  <AttachMoneyOutlinedIcon />
                  <Typography className={classes.texto}></Typography>Compras
                  Consolidadas
                </MenuItem>
                {/* 
                  <MenuItem onClick={e => onMenuItemClick('/listaOrcamentoCompras')}><AccountBalanceOutlinedIcon /><Typography className={classes.texto}></Typography>Orçamento de Compras</MenuItem>
                  <MenuItem onClick={e => onMenuItemClick('/listaExcedentes')}><TrendingDownOutlinedIcon /> <Typography className={classes.texto}></Typography>Excedentes</MenuItem> */}
              </UMenu>
              <Collapse in={openMenuRelatorio} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={(e) => onMenuItemClick("/FugaDeCapital")}
                  >
                    <ListItemText
                      className={classes.icone}
                      primary="Fuga De Capital"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={(e) =>
                      onMenuItemClick("/listaComprasConsolidadas")
                    }
                  >
                    <ListItemText
                      className={classes.icone}
                      primary="Compras Consolidadas"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={(e) => onMenuItemClick("/listaEcoGestao")}
                  >
                    <ListItemText
                      className={classes.icone}
                      primary="Eco Gestão"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={(e) => onMenuItemClick("/listaMenorTarifa")}
                  >
                    <ListItemText
                      className={classes.icone}
                      primary="Reduções IA"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={(e) =>
                      onMenuItemClick("/listaItinerarioRealizado")
                    }
                  >
                    <ListItemText
                      className={classes.icone}
                      primary="Itinerário Realizado"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={(e) => onMenuItemClick("/listaComprasRealizadas")}
                  >
                    <ListItemText
                      className={classes.icone}
                      primary="Compras Realizadas"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={(e) => onMenuItemClick("/listaTempoDecisao")}
                  >
                    <ListItemText
                      className={classes.icone}
                      primary="Tempo de Decisão"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={(e) => onMenuItemClick("/listaEconomiaNaEmissao")}
                  >
                    <ListItemText
                      className={classes.icone}
                      primary="Economia Emissão IA"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={(e) => onMenuItemClick("/listEconomiaTotal")}
                  >
                    <ListItemText
                      className={classes.icone}
                      primary="Economia Total IA"
                    />
                  </ListItem>

                  {/* 
                    <ListItem button className={classes.nested} onClick={e => onMenuItemClick('/listaOrcamentoCompras')}>
                      <ListItemText className={classes.icone} primary="Orçamento de Compras" />
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={e => onMenuItemClick('/listaExcedentes')}>
                      <ListItemText className={classes.icone} primary="Excedentes" />
                    </ListItem> */}
                </List>
              </Collapse>
            </List>
          </Drawer>
        </div>
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.component ? <props.component {...props} /> : null}
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    aereos: state.linkAereo.aereos,
    buscaVoo: state.linkAereo.buscaVoo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ buscarAereo }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
