import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import LinesTextField from '../../../../../components/LinesTextField';
import Snackbar from '../../../../../components/snackbar';
import viagemRepositorio from '../../../../../repositorios/viagem';
import { useStyles } from './styles';

const DialogTextContabilidade = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [snackState, setSnackState] = useState({ visible: false });
    const [snackVariant, setSnackVariant] = useState('success');
    const [snackMessage, setSnackMessage] = useState('');
    const [valueDescricao, setValueDescricao] = useState("");

    const handleClickSalvar = async () => {
        try {
            let dto = {
                idviagemdespesa: props.item.idviagemdespesa,
                descricaocontroladoria: valueDescricao,
            }
            await viagemRepositorio.salvarDescricaoControladoria(dto)

            props.handleSave(props.item, true)
            props.handleClose()
            props.setDescricao({
                ...props.descricoes,
                [`item${props.item.idviagemdespesa}`]: valueDescricao
            })
            setSnackVariant('success');
            setSnackMessage('Descrição salva com sucesso.');
            setSnackState({
                visible: true
            });
        } catch {
            setSnackVariant('error');
            setSnackMessage('Erro ao salvar descrição.');
            setSnackState({
                visible: true
            });
        }
    }

    useEffect(() => {
        setValueDescricao(props.descricoes[`item${props.item.idviagemdespesa}`] === undefined ? props.item.descricaocontroladoria : props.descricoes[`item${props.item.idviagemdespesa}`])
    }, [props.item, props.descricoes])

    return (
        <>
            <Snackbar {...{ variant: snackVariant, message: snackMessage, visible: snackState.visible, setSnackbar: setSnackState }} />
            <Dialog
                open={props.open}
                maxWidth="xs"
                fullWidth
                fullScreen={responsive}
            >
                <AppBar position="relative" style={{ background: 'none' }}>
                    <Toolbar className={classes.appbar} >
                        <Typography>Descrição</Typography>

                        <IconButton onClick={() => {
                            props.handleClose()
                            setValueDescricao(props.descricoes[`item${props.item.idviagemdespesa}`] === undefined ? props.item.descricaocontroladoria : props.descricoes[`item${props.item.idviagemdespesa}`])
                        }}>
                            <Close className={classes.iconButton} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText >
                        <LinesTextField
                            fullWidth
                            variant="outlined"
                            multiline
                            disabled={props.status === 3}
                            value={valueDescricao}
                            onChange={(e) => setValueDescricao(e.target.value)}
                            maxRows={6}
                            rows={6}
                            placeholder="Digite a descrição do motivo da prestação estar indo para a controladoria."
                            className={classes.inner}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {props.status !== 2 ? null : (
                        <>
                            <Button
                                className={classes.buttonConfirm}
                                onClick={() => {
                                    props.handleClose()
                                    setValueDescricao(props.descricoes[`item${props.item.idviagemdespesa}`] === undefined ? props.item.descricaocontroladoria : props.descricoes[`item${props.item.idviagemdespesa}`])
                                }}
                            >
                                CANCELAR
                            </Button>
                            <Button
                                className={classes.buttonConfirm}
                                onClick={handleClickSalvar}
                            >
                                SALVAR
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DialogTextContabilidade;
