import { Container, Grid, Typography } from "@mui/material"
import { ReceiptOutlined } from "@mui/icons-material";
import PalavrasConfigQrCode from "./palavras";
import { useCallback, useEffect, useState } from "react";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import { prestacaoDeContasConfigRepositorio } from "../../../../../repositorios/prestacaodecontas/config";
import ULoaderVoo from "../../../../../components/uloadervoo";   

export const ControlConfig = () => {
    const [loading, setLoading] = useState(false)
    const [gruposPalavras, setGruposPalavras] = useState([])

    const { enqueueSnackbar } = useCustomSnackbar();

    const getAllGroups = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasConfigRepositorio.getAllGroupQrCode();
            setGruposPalavras(response);
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar grupos.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, setGruposPalavras, enqueueSnackbar]);

    const handleClickCreateGroup = async (newGroup) => {
        setLoading(true)
        try {
            await prestacaoDeContasConfigRepositorio.postCreateGroupQrCode(newGroup);
            getAllGroups()
            enqueueSnackbar('Grupo criado com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao adicionar grupo.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickEditGroup = async (id, newGroup) => {
        setLoading(true)
        try {
            await prestacaoDeContasConfigRepositorio.putEditGroupQrCode(id, newGroup);
            getAllGroups()
            enqueueSnackbar('Grupo editado com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao adicionar grupo.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickExcluirGroup = async (idgroup) => {
        setLoading(true)
        try {
            await prestacaoDeContasConfigRepositorio.deleteGroup(idgroup);
            getAllGroups()
            enqueueSnackbar('Grupo criado com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao editar grupo.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getAllGroups()
    }, [getAllGroups])

    return (
        <Container maxWidth="lg">
            <ULoaderVoo isLoading={loading} />
            <Grid container item xs={12} style={{ marginBottom: "10px", backgroundColor: "white", borderRadius: "16px" }}>
                <Grid container item xs={12} style={{ boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)", borderRadius: "16px", padding: "20px" }}>
                    <Grid container item xs={12} style={{ display: "flex" }}>
                        <ReceiptOutlined />
                        <Typography variant="h6" style={{ marginLeft: "20px" }}>
                            Gerenciar Grupos
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ padding: "5px", color: "#575757", marginBottom: "15px" }}>
                            Defina e configure as regras específicas para cada tipo de despesa. As Categorias de Controle permitem
                             que você organize suas despesas, garantindo que cada gasto esteja de acordo com as políticas estabelecidas. 
                             Ao adicionar uma despesa, selecione a categoria correspondente e o sistema automaticamente
                              verificará a conformidade com as regras definidas.</Typography>
                        <PalavrasConfigQrCode grupos={gruposPalavras} handleClickCreateGroup={handleClickCreateGroup} handleClickEditGroup={handleClickEditGroup} handleClickExcluirGroup={handleClickExcluirGroup} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ height: "40px" }}>
            </Grid>
        </Container>
    )
}