import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Toolbar, Typography } from "@material-ui/core";
import { AccessAlarmOutlined, Apple, AssignmentOutlined, AutorenewOutlined, Beenhere, Cancel, CancelPresentation, CloseOutlined, ConfirmationNumber, EventNoteOutlined, FindReplaceOutlined, LockOpen, NoEncryptionOutlined, PersonOutline, QueryBuilder } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Enums from "../../../../enums";
import reservaRepositorio from "../../../../repositorios/reserva";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { useStyles } from "./styles";

export const HistoryBooking = (props) => {
    const open = props.open;
    const responsive = props.responsive;
    const setIsLoading = props.setIsLoading;
    const idreserva = props.bookingSelected.idreserva;
    const setSnackMessage = props.setSnackMessage;
    const setSnackVariant = props.setSnackVariant;
    const setSnackState = props.setSnackState;

    const classes = useStyles();
    const [dataHistoric, setDataHistoric] = useState([]);

    //Começo daquiii

    const getHistoric = useCallback(async () => {
        try {
            setIsLoading(true);
            let response = await reservaRepositorio.getReservaHistorico(idreserva);
            setDataHistoric(response.data);
        } catch (e) {
            setSnackMessage('Falha ao buscar histórico.');
            setSnackVariant('error');
            setSnackState({ visible: true });
        } finally {
            setIsLoading(false);
        }
    }, [idreserva, setIsLoading, setDataHistoric, setSnackMessage, setSnackVariant, setSnackState]);


    const getStatusHistorico = () => {
        let tempResp = {
            titulo: Enums.getReservaStatusDescricao(props.bookingSelected.status),
            cor: Enums.getReservaStatusCor(props.bookingSelected.status)
        }
        return tempResp;
    };

    const getIconsHistorico = {
        1: { icon: <QueryBuilder fontSize="large" />, iconColor: "orange", iconDirection: "left", },
        2: { icon: <LockOpen fontSize="large" />, iconColor: "royalblue", iconDirection: "right", },
        3: { icon: <NoEncryptionOutlined fontSize="large" />, iconColor: "#c62828", iconDirection: "right", },
        4: { icon: <QueryBuilder fontSize="large" />, iconColor: "orange", iconDirection: "left", },
        5: { icon: <Beenhere fontSize="large" />, iconColor: "deepskyblue", iconDirection: "right", },
        6: { icon: <Cancel fontSize="large" />, iconColor: "#c62828", iconDirection: "right", },
        7: { icon: <ConfirmationNumber fontSize="large" />, iconColor: "darkgreen", iconDirection: "right", },
        8: { icon: <CancelPresentation fontSize="large" />, iconColor: "#c62828", iconDirection: "right", },
        9: { icon: <Apple />, iconColor: "orange", iconDirection: "left", },
        10: { icon: <Apple />, iconColor: "orange", iconDirection: "left", },
        11: { icon: <Apple />, iconColor: "darkgreen", iconDirection: "right", },
        12: { icon: <FindReplaceOutlined fontSize="large" />, iconColor: "darkgreen", iconDirection: "right", },
        13: { icon: <MonetizationOnIcon fontSize="large" />, iconColor: "green", iconDirection: "right", },
        14: { icon: <AccessAlarmOutlined fontSize="large" />, iconColor: "grey", iconDirection: "right", },
        15: { icon: <AutorenewOutlined fontSize="large" />, iconColor: "orange", iconDirection: "right", }
    };

    useEffect(() => {
        getHistoric()
    }, [getHistoric]);

    return (
        <>
            <Dialog open={open} onClose={() => props.handleClickCloseHistory()} fullWidth={true} fullScreen={responsive} maxWidth="lg">
                <AppBar position="relative">
                    <Toolbar className={classes.appbar}>
                        <Typography><b>Autorizar/Negar</b></Typography>
                        <IconButton onClick={() => props.handleClickCloseHistory()}><CloseOutlined className={classes.iconClose} /></IconButton>
                    </Toolbar>
                </AppBar>
                <AppBar className={classes.appbarGrey}>
                    <Toolbar>
                        <Box className={classes.boxStatus} style={{ background: getStatusHistorico().cor }}>
                            <Typography variant="h6" ><b>{getStatusHistorico().titulo}</b></Typography>
                        </Box>
                    </Toolbar>
                </AppBar>
                <DialogContent id="dialogContentHistory" className={classes.dialogContent}>
                    <Grid container>
                        {dataHistoric.length > 0 ? (
                            <VerticalTimeline>
                                {dataHistoric.map((item) => (
                                    <VerticalTimelineElement
                                        position={getIconsHistorico[item.acao].iconDirection}
                                        icon={getIconsHistorico[item.acao].icon}
                                        iconStyle={{ background: getIconsHistorico[item.acao].iconColor, color: "#fff", }}>
                                        {getIconsHistorico[item.acao].iconDirection === "right" || responsive ? (
                                            <Grid container>
                                                <Grid item xs={12} >
                                                    <Typography variant="body1"><b>{item.titulo}</b></Typography>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <Typography variant='body2' className={classes.typographyCenter}><EventNoteOutlined />{` ${new Date(item.datacriacao).toLocaleString()} `}</Typography>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <Typography variant="body2" className={classes.typographyCenter}><PersonOutline />{` ${item.usuario.usuario} `}</Typography>
                                                </Grid>
                                                {item.acao === 12 || (item.justificativa) ? (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Typography variant="body2" className={classes.typographyCenter}><AssignmentOutlined />Descrição</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <Typography variant="body2">{item.justificativa}</Typography>
                                                        </Grid>
                                                    </>
                                                ) : null}
                                            </Grid>
                                        ) : null}

                                        {getIconsHistorico[item.acao].iconDirection === "left" && !responsive ? (
                                            <Grid container >
                                                <Grid item xs={12} className={classes.gridEnd}>
                                                    <Typography variant="body1"><b>{item.titulo}</b></Typography>
                                                </Grid>
                                                <Grid item xs={12} className={classes.gridEnd}>
                                                    <Typography variant="body2" className={classes.typographyCenter}>{new Date(item.datacriacao).toLocaleString() + " "} <EventNoteOutlined /></Typography>
                                                </Grid>
                                                <Grid item xs={12} className={classes.gridEnd}>
                                                    <Typography variant="body2" className={classes.typographyCenter}>{` ${item.usuario.usuario} `} <PersonOutline /></Typography>
                                                </Grid>
                                                {item.acao === 12 ? (
                                                    <>
                                                        <Grid item xs={12} className={classes.typographyCenter}>
                                                            <Typography variant="body2">Justificativa<AssignmentOutlined /></Typography>
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <Typography variant="body2">{item.justificativa}</Typography>
                                                        </Grid>
                                                    </>
                                                ) : null}
                                            </Grid>
                                        ) : null}
                                    </VerticalTimelineElement>
                                ))}
                            </VerticalTimeline>
                        ) : (
                            <Typography variant="h5" variantMapping={'body1'} className={classes.typographyNoHistory}>Não há nada no histórico!</Typography>
                        )}
                    </Grid>
                </DialogContent>
                {props.permissaoDashboard.permiteautorizarnegar && (props.bookingSelected.status === 9 || props.bookingSelected.status === 14 || props.bookingSelected.status === 1 || props.bookingSelected.status === 4) ? (
                    <DialogActions id="dialogActionHistory" className={classes.dialogActions} >
                        <Button disabled={props.isLoading} className={classes.buttonAutorizar} onClick={() => props.handleClickAuthorizeNegate(1)}>Autorizar</Button>
                        <Button disabled={props.isLoading} className={classes.buttonNegar} onClick={() => props.handleClickAuthorizeNegate(2)}>Negar</Button>
                    </DialogActions>
                ) : null}
            </Dialog >
        </>
    )
}
