import { Box, Button, Divider, Grid, IconButton } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import USearchField from "../../../../../components/usearchfield";
import Faturado from "../../../../../images/faturado.svg";
import aereoRepositorio from "../../../../../repositorios/aereo";
import aereoBilhete from "../../../../../repositorios/aereoBilhete";
import { ConfirmIssueDialog } from "../ConfirmIssueDialog";
import { useStyles } from "./styles";

export const RegisteredPaymentMethod = ({ booking, responsive, isLoading, setIsLoading, setSnackMessage, setSnackState, setSnackVariant, setModalEmissao }) => {
    const classes = useStyles();
    const cookies = new Cookies();
    const [itensLista, setItensLista] = useState(null);
    const [selecionado, setSelecionado] = useState(null);
    const [selecionadoOpcoes, setSelecionadoOpcoes] = useState(null);
    const [itensListaOpcoes, setItensListaOpcoes] = useState(null);
    const [showOpcoes, setShowOpcoes] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleClickOpenConfirmDialog = () => { setOpenConfirmDialog(true) }
    const handleClickCloseConfirmDialog = () => { setOpenConfirmDialog(false) }

    const loadData = useCallback(async () => {
        setIsLoading(true);
        try {
            let response = await aereoBilhete.getCreditCards();
            setItensLista(response.data.itens);

            let response2 = await aereoBilhete.getReserveCard(booking.idreserva);
            setSelecionado(response2.data.cardSelecionado);

            if (response2.data.parcelSelecionado?.value >= 0) {
                setShowOpcoes(true);
                setSelecionadoOpcoes(response2.data.parcelSelecionado);
            }
        } catch (e) {
            setSnackMessage('Falha ao buscar método cadastrado.');
            setSnackState({ visible: true });
            setSnackVariant('error');
        } finally {
            setIsLoading(false);
        }
    }, [setIsLoading, setSnackMessage, setSnackState, setSnackVariant, booking.idreserva, setItensLista, setSelecionado, setShowOpcoes, setSelecionadoOpcoes]);

    const handleClickSearchParcelas = async () => {
        let dto = null;
        setIsLoading(true);
        try {
            dto = { idreserva: booking.idreserva, idcartao: selecionado.value };
            let response = await aereoBilhete.postSelectedCreditCard(dto);
            setItensListaOpcoes(response.data.itens);
        } catch (e) {
            setSnackMessage('Falha ao buscar parcelas.');
            setSnackState({ visible: true });
            setSnackVariant('error');
        } finally {
            setIsLoading(false);
        }
        setShowOpcoes(true);
    };

    const emitirCartaoSalvo = async () => {
        let dto = {
            idcartao: selecionado?.value,
            idreserva: booking.idreserva,
            descParcela: selecionadoOpcoes?.label,
            nParcelas: selecionadoOpcoes?.value,
            idparcela: selecionadoOpcoes?.idparcela,
        };

        let dtoDados = {
            companhiaaerea: booking.companhiaaerea,
            localizador: booking.localizador,
            idreserva: booking.idreserva,
            sessionId: cookies.get("sessionId"),
            tipoPagamento: 3,
            valor: booking.total,
        };

        if (dto.idparcela === undefined) {
            setSnackMessage("Opção de parcelas não pode ser vazio.");
            setSnackState({ visible: true });
            setSnackVariant('error');
            return
        }

        try {
            setIsLoading(true)
            const response = await aereoRepositorio.emitir(dtoDados);
            if (response.data.status === "SUCCESS") {
                await aereoBilhete.putSaveReserveCard(dto);
                setSnackMessage("Aéreo emitida.");
                setSnackState({ visible: true });
                setSnackVariant('success');
                // await loadReserva();
            }
            setModalEmissao(false)
        } catch (e) {
            setSnackMessage("Erro ao emitir.");
            setSnackState({ visible: true });
            setSnackVariant('error');
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setItensLista([]);
        setItensListaOpcoes([]);
        loadData()
    }, [loadData])

    return (
        <>
            <ConfirmIssueDialog  {...{
                responsive,
                booking,
                open: openConfirmDialog,
                handleClickCloseConfirmDialog,
            }}
                yesEvent={emitirCartaoSalvo}
            />
            <Grid container spacing={2}>
                <Grid item xs={responsive ? 12 : 6} className={classes.gridCenter}>
                    <img src={Faturado} alt="Imagem não encontrada" width={responsive ? 100 : 290} />
                </Grid>
                <Grid item xs={responsive ? 12 : 6} className={classes.gridCenter}>
                    <Box className={classes.boxCustom} >
                        <Grid container>
                            <Grid item container xs={12}>
                                <Grid item xs={10}>
                                    <USearchField
                                        disabled={false}
                                        itens={itensLista}
                                        value={selecionado}
                                        onChange={() => { setItensListaOpcoes([]); setSelecionadoOpcoes(null) }}
                                        setValue={setSelecionado}
                                        label="Opções de pagamento"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton onClick={() => handleClickSearchParcelas()}><SearchOutlined /></IconButton>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                {showOpcoes && selecionado?.value >= 0 && !isLoading ? (
                                    <USearchField
                                        disabled={false}
                                        itens={itensListaOpcoes}
                                        value={selecionadoOpcoes}
                                        setValue={setSelecionadoOpcoes}
                                        label="Opções de parcelamento"
                                    />
                                ) : null}
                            </Grid>
                            <Grid item xs={12}>
                                <Button disabled={isLoading} onClick={() => handleClickOpenConfirmDialog()} className={classes.buttonEmissao}>Emitir</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
