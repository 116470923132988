import { Box, Button, ButtonGroup, Chip, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, Menu, MenuItem, Typography, alpha, styled } from "@mui/material";
import { warning } from "../../../../../theme/palette";
import DialogComponent from "../../../Desktop/sections/dialogs-desktop";
import { EnumTipo, formatarParaNomeStatusAdiantamento, formatarParaNomeStatusViagem, formatarValorComMoeda, hasApprovedAdiantamento } from "../../../../../enums/enumPrestacaoDeContas";
import { CreationDate } from "../../../Desktop/sections";
import { IconsSourceSvg } from "../../../../../images/Icons";
import CustomDateRangePicker from "../../../Desktop/sections/custom-date-range-picker";
import DateDifference from "../../../Desktop/sections/custom-date-difference";
import EditIcon from '@mui/icons-material/Edit';
import { useStyles } from "./styles";
import { useCallback, useEffect, useState } from "react";
import { ArrowBack, ArrowForwardIos, Delete, EditRounded, MoreVertOutlined, NavigateNextOutlined, QrCode2Outlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { AdicionarDespesaComponentM } from "./AdicionarDespesa";
import { EditarDespesaComponentM } from "./EditarDespesa";
import { HodometroComponentM } from "./Hodometro";
import { ViewHodometerMComponent } from "./HodometroInfo";
import { ExtrasDespesasComponentM } from "./Extras";
import prestacaoDeContasRepositorio from "../../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import { ViewQrCodeProducts } from "../../../../../components/autalii_expanses/viewqrcode";
import DunutChart from "../../../../../assets/charts/donutchart";
import TransferComponent from "../../../../../components/autalii_expanses/card_transfer";
import { TourComponent } from "../../../../../components/tutorial/index2";
import { startTourViagemCriada_Mobile } from "../../../../../components/tutorial";
import { CardAddInViagem_mob } from "../../../../../components/autalii_expanses/card_addinviagem";
import { CardSaldo_mob } from "../../../../../components/autalii_expanses/card_saldo/index_mob";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export const ViagemComponentM = ({ viagemData, handleCreate, handleEdit, handleDelete, setTemDespesa, handleIniciarViagem, setTypeGlobal, handleFinalizar, handleClickCreateHodometro, handleClickEditHodometro }) => {
    const classes = useStyles();
    //Type 0
    const [type, setType] = useState(0);
    const [viagem, setViagem] = useState(viagemData)
    const [openDeleteDespesa, setOpenDeleteDespesa] = useState(false)
    const [openFinalizarDespesa, setOpenFinalizarDespesa] = useState(false);
    const [despesas, setDespesas] = useState([])
    const [despesa, setDespesa] = useState([]);
    const [extras, setExtras] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [typehodometer, setTypeHodometer] = useState(0);
    const [currentTab, setCurrentTab] = useState(1);
    const open = Boolean(anchorEl);
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [openQrCodeView, setOpenQrCodeView] = useState(false)
    const [showBalance, setShowBalance] = useState(true);
    const [pendente, setPendente] = useState(false);
    const [myLimits, setMyLimits] = useState(false)
    const [categorias, setCategorias] = useState([])
    const [chartData, setChartData] = useState({
        series: [
        ],
        options: {
        },
    })
    const [totalchart, setTotalChart] = useState(0)

    const chartColors = ['#FF4560', '#00E396', '#008FFB', '#775DD0'];

    const handleToggleBalance = () => {
        setShowBalance(!showBalance);
    };

    const { enqueueSnackbar } = useCustomSnackbar();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickDespesa = (event, despesa) => {
        setAnchorEl(event.currentTarget);
        setDespesa(despesa)
    }

    const getAlltipos = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas();

            let totalGeral = 0;

            const chartDataq = {
                series: response
                    .filter(categoria =>
                        categoria.name !== 'Hodometro' &&
                        categoria.name !== 'Outros' &&
                        categoria.name !== 'Pendente' &&
                        categoria.name !== 'SOLICITAÇÃO' &&
                        categoria.name !== 'AEREO' &&
                        categoria.name !== 'HOTEL' 
                    )
                    .map(categoria => {
                        const total = categoria.produtos.reduce(
                            (acc, produto) => acc + (produto.limite !== -1 ? produto.limite : 0),
                            0 
                        );

                        totalGeral += total;

                        return {
                            label: categoria.name,
                            value: total,
                        };
                    }),
                options: {},
            };

            setTotalChart(totalGeral);
            setChartData(chartDataq);
            setCategorias(response);
        } catch {
            // enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [
        setLoading,
        setTotalChart,
        setChartData,
        setCategorias,
        enqueueSnackbar
    ]);


    const getDespesas = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(viagemData.id);
            setDespesas(response.despesas);
            setExtras(response.extras);
            setPendente(false);

            if (response.despesas.length === 0) {
                setTemDespesa(false)
            } else {
                setTemDespesa(true)
            }

            response.despesas.forEach(item => {
                if (item.mensagem !== "" && item.mensagem !== null) {
                    setPendente(true);
                }
            });
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [
        setLoading,
        viagemData,
        setDespesas,
        setExtras,
        setPendente,
        enqueueSnackbar
    ]);

    //Type 1
    const handleClickDeleteDespesa = async () => {
        setOpenDeleteDespesa(false)
        handleDelete({
            idviagem: viagem.id,
            id: despesa.iddespesa
        })
    }

    const handleClickOpenQrCode = (despesa) => {
        setDespesa(despesa)
        setOpenQrCodeView(true)
    }

    const handleClickFinalizarViagem = () => {
        setOpenFinalizarDespesa(false)
        handleFinalizar(viagem.id)
    }

    const handleChangeTab = useCallback((newValue) => {
        setCurrentTab(newValue);
    }, []);

    useEffect(() => {
        let c = 0;
        extras?.forEach(extra => {
            if (!extra.add) {
                c += 1;
            }
        })
        setCount(c)
    }, [extras])

    useEffect(() => {
        if (viagemData != null) {
            getAlltipos()
            getDespesas()
            setViagem(viagemData)
        }
    }, [viagemData, getAlltipos, getDespesas])

    useEffect(() => {
        setTypeGlobal(type)
    }, [type, setTypeGlobal])

    return (
        <Grid>
            <DialogComponent
                title={
                    <>
                        Informações do QrCode
                    </>
                }
                open={openQrCodeView}
                handleClose={() => setOpenQrCodeView(false)}
                body={
                    <ViewQrCodeProducts QrCode={despesa?.qrCode} />
                }
                action={
                    <></>
                }
                fullScreen={true}
            />
            {type === 0 && viagem !== null ? (
                <Container maxWidth={"lg"} style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    {viagem.status === EnumTipo.viagemstatus.emviagem || viagem.status === EnumTipo.viagemstatus.aguardandoinicio || viagem.status === EnumTipo.viagemstatus.pendenteacorrecao ? (
                        <CardAddInViagem_mob
                            viagem={viagem}
                            despesas={despesas}
                            openFinalizarDespesa={openFinalizarDespesa}
                            setOpenFinalizarDespesa={setOpenFinalizarDespesa}
                            loading={loading}
                            pendente={pendente}
                            handleClickFinalizarViagem={handleClickFinalizarViagem}
                            hasApprovedAdiantamento={hasApprovedAdiantamento}
                            setType={setType}
                        />
                    ) : (null)}
                    <Grid container item xs={12} className="total_mob">
                        {(viagem.status !== 1 && viagem.status !== 0) && (
                            <Grid item xs={12} style={{ marginTop: "8px", fontFamily: "sans-serif", textAlign: "center" }}>
                                {formatarParaNomeStatusViagem(viagem.status)}
                            </Grid>
                        )}
                        <Grid container item xs={12} style={{ textAlign: "left", display: "flex", maxWidth: 360, padding: "0px", marginTop: "10px" }}>
                            {!viagem.tem_multimonetario ? (
                                <CardSaldo_mob
                                    viagem={viagem}
                                    showBalance={showBalance}
                                    handleToggleBalance={handleToggleBalance}
                                    setMyLimits={setMyLimits}
                                    myLimits={myLimits}
                                    totalchart={totalchart}
                                    chartData={chartData}
                                    chartColors={chartColors}
                                    categorias={categorias}
                                />
                            ) : (
                                <Grid container item xs={12} style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "10px", width: "100%", maxWidth: 360 }}>
                                    {viagem.adiantamento?.map((item) => (
                                        <Grid item xs={12} style={{ marginTop: "10px" }}>
                                            <TransferComponent viagem={viagem} despesas={despesas} totalex={item.total} totalbrl={item.total_brl} moeda={item.tipo_moeda} status={item.status} />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {viagem !== null && count !== 0 ? (
                        <Grid item xs={12} style={{ justifyContent: "center", alignContent: "center", textAlign: "center", marginBottom: "0px" }}>
                            <ButtonGroup variant="outlined" color="inherit" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                                {[{ value: 1, label: "Despesas" }, { value: 2, label: "Conciliação" }].map((tab) => (
                                    <Button
                                        key={tab.value}
                                        onClick={() => handleChangeTab(tab.value)}
                                        style={{
                                            textTransform: "none", // Mantém a capitalização original do texto
                                            fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                            borderColor: "#D6D6D6"
                                        }}
                                    >
                                        <Typography variant="h6" style={{ fontSize: "15px", fontWeight: currentTab === tab.value ? 600 : 400 }}>
                                            {tab.label}
                                        </Typography>
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Grid>
                    ) : (null)}

                    {currentTab === 1 && viagem !== null && (
                        <>
                            <DialogComponent
                                title={"Deletar despesa"}
                                open={openDeleteDespesa}
                                handleClose={() => setOpenDeleteDespesa(false)}
                                body={
                                    <Grid container item xs={12}>
                                        <Typography>Deseja realmente deletar essa despesa?</Typography>
                                    </Grid>
                                }
                                action={
                                    <Grid>
                                        <Button size="large" onClick={() => setOpenDeleteDespesa(false)}>Cancelar</Button>
                                        <Button size="large" onClick={() => handleClickDeleteDespesa()}>Confimar</Button>
                                    </Grid>
                                }
                                width={"sm"}
                            />
                            {viagem !== null && despesas.length !== 0 ? (
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "20px", padding: "10px" }}>
                                    {despesas
                                        .sort((a, b) => {
                                            // Itens com descrição "Pendente" ficam no topo
                                            const isAPendente = a.despesa_desc.includes("Pendente") ? 1 : 0;
                                            const isBPendente = b.despesa_desc.includes("Pendente") ? 1 : 0;

                                            return isBPendente - isAPendente; // Inverte a ordem para "Pendente" ficar no topo
                                        }).map((value) => (
                                        <>
                                            {!value.aprovado || (viagem.status !== EnumTipo.viagemstatus.finalizado && viagem.status !== EnumTipo.viagemstatus.viagemfinalizada) ? (
                                                <Grid
                                                    item xs={12}
                                                    className={classes.card}
                                                    style={{ marginTop: "20px", padding: "20px" }}
                                                    container
                                                >
                                                    {value.mensagem !== "" && value.mensagem !== null ? (
                                                        <>
                                                            <Grid item xs={9} style={{ textAlign: "left" }}>
                                                                <Typography variant="body1">
                                                                    <Chip label={value.controladoria ? "Controladoria" : "Atenção"} color="warning" style={{ color: "white" }} />
                                                                </Typography>
                                                                <Typography variant="body2" sx={{ mt: 1 }}><strong>Mensagem:</strong> {value.mensagem}</Typography>
                                                            </Grid>
                                                            <Grid container item xs={3}>
                                                                {viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && (
                                                                    <Box style={{ display: "flex", width: "100%" }}>
                                                                        {!hasApprovedAdiantamento(viagem.adiantamento) && (
                                                                            <IconButton
                                                                                size="large"
                                                                                id="demo-customized-button"
                                                                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                                                                aria-haspopup="true"
                                                                                aria-expanded={open ? 'true' : undefined}
                                                                                variant="contained"
                                                                                onClick={() => { setDespesa(value); setOpenDeleteDespesa(true); setAnchorEl(null); }} disableRipple>
                                                                                <Delete />
                                                                            </IconButton>
                                                                        )}
                                                                        <IconButton
                                                                            size="large"
                                                                            id="demo-customized-button"
                                                                            color="alert"
                                                                            variant="outlined"
                                                                            disableElevation
                                                                            onClick={() => { setDespesa(value); setType(despesa.hodometro != null ? 4 : 3); }} // Passando o item atual para a função
                                                                        >
                                                                            <EditRounded />
                                                                        </IconButton>
                                                                    </Box>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider sx={{ mt: 1, marginBottom: "8px" }} />
                                                            </Grid>
                                                        </>
                                                    ) : (null)}
                                                    <Grid container item xs={10}>
                                                        <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify", justifyContent: "center" }}>
                                                            <Typography style={{ fontWeight: "600" }}>{value.hodometro ? ("Hodometro") : ("Despesa")}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2} style={{ display: "flex", justifyContent: "right" }}>
                                                        <StyledMenu
                                                            id="demo-customized-menu"
                                                            MenuListProps={{
                                                                'aria-labelledby': 'demo-customized-button',
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                        >
                                                            <MenuItem onClick={() => { setType(despesa.hodometro != null ? 4 : 3); setAnchorEl(null); }} disableRipple>
                                                                Editar
                                                            </MenuItem>
                                                            <MenuItem onClick={() => { setOpenDeleteDespesa(true); setAnchorEl(null); }} disableRipple>
                                                                Excluir
                                                            </MenuItem>
                                                        </StyledMenu>
                                                        {value.qrCode !== null && (
                                                            <IconButton
                                                                onClick={() => handleClickOpenQrCode(value)}
                                                            >
                                                                <QrCode2Outlined />
                                                            </IconButton>
                                                        )}
                                                        {viagem.status === EnumTipo.viagemstatus.aguardandoinicio ||
                                                            viagem.status === 1 ||
                                                            viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && !hasApprovedAdiantamento(viagem.adiantamento) ? (
                                                            <>
                                                                <IconButton
                                                                    id="demo-customized-button"
                                                                    aria-controls={open ? 'demo-customized-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={open ? 'true' : undefined}
                                                                    variant="contained"
                                                                    disableElevation
                                                                    onClick={(event) => handleClickDespesa(event, value)} // Passando o item atual para a função
                                                                >
                                                                    <MoreVertOutlined />
                                                                </IconButton>
                                                            </>
                                                        ) : (
                                                            <>
                                                            </>
                                                        )}

                                                    </Grid>
                                                    <Grid item xs={12} style={{ textAlign: "left" }}>
                                                        <Typography style={{ fontWeight: "500", color: "#595959" }}>{value.descricao}</Typography>
                                                    </Grid>
                                                    <Grid item xs={viagem.status === 8 ? 7 : 12} style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignContent: "flex-start",
                                                        alignItems: "flex-start",
                                                        flexDirection: "row"
                                                    }}>
                                                        <Typography variant="body2">
                                                            {
                                                                viagem.status === EnumTipo.viagemstatus.viagemfinalizada ||
                                                                    viagem.status === EnumTipo.viagemstatus.pendenteacorrecao ||
                                                                    viagem.status === EnumTipo.viagemstatus.contablidade ||
                                                                    viagem.status === EnumTipo.viagemstatus.correcaorealizada ||
                                                                    viagem.status === EnumTipo.viagemstatus.financeiro ?
                                                                    (
                                                                        <Grid style={{ marginRight: "10px" }}>
                                                                            {value.aprovado ? (
                                                                                <Chip label="Aprovado" color="primary" />
                                                                            ) : (
                                                                                null
                                                                            )}
                                                                        </Grid>
                                                                    ) : (null)
                                                            }
                                                        </Typography>
                                                        {value?.tipo === 7 ? (<Typography style={{ fontWeight: "600", fontSize: "small", color: warning.main }}>Pendente</Typography>) : (null)}
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <Typography variant="body2" style={{ textAlign: "left" }}>
                                                            {value.despesa_desc}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9} style={{ display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                                                        <CreationDate data={value?.datacriacao} />
                                                    </Grid>
                                                    <Grid item xs={3} style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignContent: "flex-end",
                                                        alignItems: "flex-end",
                                                        flexDirection: "row"
                                                    }}>
                                                        {value.hodometro !== null ?
                                                            (
                                                                <Typography style={{ fontWeight: "500", color: "#595959", fontSize: "13px" }}>{value.hodometro.kmtotal.toFixed(1)} Km</Typography>
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{formatarValorComMoeda(value.tipo_moeda, value.total)}</Typography>
                                                                </>
                                                            )}
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <>
                                                    <Grid
                                                        item xs={12}
                                                        className={classes.card}
                                                        style={{ marginTop: "20px", padding: "20px" }}
                                                        container
                                                    >
                                                        <Grid container item xs={10}>
                                                            <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify", justifyContent: "center" }}>
                                                                <Typography style={{ fontWeight: "600" }}>{value.hodometro ? ("Hodometro") : ("Despesa")}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={2} style={{ display: "flex", justifyContent: "right" }}>

                                                        </Grid>
                                                        <Grid item xs={12} style={{ textAlign: "left" }}>
                                                            <Typography style={{ fontWeight: "500", color: "#595959" }}>{value.descricao}</Typography>
                                                            {value.mensagem !== "" && value.mensagem !== null ? (
                                                                <div style={{ padding: "20px" }}>
                                                                    <Typography variant="body1">
                                                                        <Chip label="Pendente a correção" color="warning" style={{ color: "white" }} />
                                                                    </Typography>
                                                                    <Typography variant="body2" sx={{ mt: 1 }}>{value.mensagem}</Typography>
                                                                </div>
                                                            ) : (null)}

                                                        </Grid>
                                                        <Grid item xs={viagem.status === 8 ? 7 : 12} style={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            alignContent: "flex-start",
                                                            alignItems: "flex-start",
                                                            flexDirection: "row"
                                                        }}>
                                                            <Typography variant="body2">
                                                                <Grid style={{ marginRight: "10px" }}>
                                                                    {value.aprovado ? (
                                                                        <Chip label="Aprovado" color="primary" />
                                                                    ) : (
                                                                        <Chip label="Pendente" color="secondary" />
                                                                    )}
                                                                </Grid>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9} style={{ display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                                                            <CreationDate data={value.datadecriacao} />
                                                        </Grid>
                                                        <Grid item xs={3} style={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignContent: "flex-end",
                                                            alignItems: "flex-end",
                                                            flexDirection: "row"
                                                        }}>
                                                            {value.tipo === 6 ?
                                                                (
                                                                    <Typography style={{ fontWeight: "500", color: "#595959", fontSize: "13px" }}>{value.hodometro.kmtotal.toFixed(1)} Km</Typography>
                                                                )
                                                                :
                                                                (
                                                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.total)}</Typography>
                                                                )}
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    ))}
                                </Grid>
                            ) : (
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "20px" }}>
                                    <img alt="Imagem de pastas" src={IconsSourceSvg.pastas}></img>
                                    <Typography>Nenhuma despesa encontrada</Typography>
                                    <TourComponent startTour={startTourViagemCriada_Mobile} />
                                </Grid>
                            )}
                        </>
                    )}
                    {currentTab === 2 && (
                        <>
                            <ExtrasDespesasComponentM viagem={viagem} extras={extras} />
                        </>
                    )}
                </Container>
            ) : (
                null
            )
            }
            {
                type === 1 ? (
                    <>
                        <AdicionarDespesaComponentM viagem={viagem} handleCreate={handleCreate} setType={setType} />
                    </>
                ) : (
                    null
                )
            }
            {
                type === 3 ? (
                    <>
                        <EditarDespesaComponentM viagem={viagem} despesa={despesa} handleEdit={handleEdit} setType={setType} />
                    </>
                ) : (
                    null
                )
            }
            {
                type === 2 ? (
                    <HodometroComponentM viagem={viagem} handleClickCreateHodometro={handleClickCreateHodometro} setType={setType} />
                ) : (null)
            }
            {
                type === 4 ? (
                    <ViewHodometerMComponent viagem={viagem} hodometro={despesa} setType={setType} editHodometro={handleClickEditHodometro} typehodometro={typehodometer} />
                ) : (null)
            }
        </Grid >
    )
}

export const ConfirmButtonViagem = ({ viagem, confirmFunction, setModel }) => {
    const [datainicio, setDataInicio] = useState(viagem.datainicio);
    const [datafim, setDataFim] = useState(viagem.datafim);
    const [openOptionsDate, setOpenOptionsDate] = useState(false);

    const onCloseDateOptions = useCallback(() => {
        setOpenOptionsDate(false);
    }, []);

    const onChangeStartDate = (newValue) => {
        setDataInicio(newValue);
    };

    const onChangeEndDate = (newValue) => {
        setDataFim(newValue);
    };

    return (
        <>
            <Grid item xs={8} style={{ textAlign: "left", display: "flex" }}>
                <Typography style={{ fontWeight: "500", fontSize: "12px" }}>
                    Total inicial: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial)}
                </Typography>
                {/* Usando um span para controle fino sobre o caractere e espaçamento */}
                <span style={{ margin: "0 4px" }}>•</span>
                <Typography style={{ fontWeight: "600", fontSize: "12px" }}>
                    Total: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.total)}
                    <span style={{ margin: "0 4px" }}>•</span>
                    <span style={{ color: viagem.totalinicial - viagem.total > 0 ? "#FFAB00" : "#FF0000" }}>
                        Restante: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial - viagem.total)}
                    </span>
                </Typography>
            </Grid>

            <Grid item xs={4} style={{
                textAlign: "right",
                justifyContent: "right",
                display: "inherit",
                flexWrap: "nowrap",
                alignItems: "right",
                flexDirection: "row-reverse"
            }}>
                <Typography style={{ fontWeight: "600", display: "flex" }}>
                    <DateDifference startDate={datainicio} endDate={datafim} />
                    {viagem.status === 8 ? (
                        null
                    ) : (
                        <EditIcon style={{ cursor: "pointer" }} variant="contained" color="info" onClick={() => setOpenOptionsDate(true)} />
                    )}
                </Typography>
                <CustomDateRangePicker
                    open={openOptionsDate}
                    startDate={datainicio}
                    endDate={datafim}
                    onChangeStartDate={onChangeStartDate}
                    onChangeEndDate={onChangeEndDate}
                    onClose={onCloseDateOptions}
                    title="Definir periódo da viagem"
                />
            </Grid>
        </>
    )
}

