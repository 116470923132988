import {
  Box,
  Button,
  Grid,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  AddRounded,
  GetApp,
  SearchOutlined,
  SendOutlined,
} from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UDatePicker from "../../../components/udatepicker";
import UFloatButton from "../../../components/ufloatbutton";
import ULoaderVoo from "../../../components/uloadervoo";
import USearchField from "../../../components/usearchfield";
import centroCustoRepositorio from "../../../repositorios/centroCusto";
import permissaoRepositorio from "../../../repositorios/permissao";
import pessoaRepositorio from "../../../repositorios/pessoa";
import viagemRepositorio from "../../../repositorios/viagem";
import Snackbar from "../../../components/snackbar";
import Dashboard from "../../dashboard";
import CadastroViagem from "./Cadastro";
import DespesasGrid from "./DespesasGrid";
import DespesasList from "./DespesasList";
import DialogAccept from "./DialogAccept";
import DialogConfirmExportExcel from "./DialogConfirmExportExcel";
import DialogConfirmPagamentoAgendado from "./DialogConfirmPagamentoAgendado";
import { useStyles } from "./styles";
import { DialogDelete } from "./DialogDeleteViagem";

export const DespesaViagem = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const permissao = useSelector((state) => state.permissao.permissaoDashboard);
  const politicaViagem = useSelector((state) => state.permissao.politicaViagem);
  const [permissaoUser, setPermissaoUser] = useState({});
  let today = Date.now();
  const [dataInicio, setDataInicio] = useState(new Date(today - 604800000)); //7 days
  const [dataFim, setDataFim] = useState(new Date());
  const [backDrop, setBackDrop] = useState(false);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [centroCusto, setCentroCusto] = useState(null);
  const [viajantes, setViajantes] = useState([]);
  const [viajante, setViajante] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalAccept, setOpenModalAccept] = useState(false);
  const [openModalExcluir, setOpenModalExcluir] = useState(false);
  const [openDialogPagamento, setOpenDialogPagamento] = useState(false);
  const [openDialogImportExcel, setOpenDialogExportExcel] = useState(false);
  const [dataConfirm, setDataConfirm] = useState({});
  const [modalTeste, setModalTeste] = useState({});
  const [viagens, setViagens] = useState([]);
  const [viagensGrid, setViagensGrid] = useState([]);
  const [viagemdespesasvazias, setViagemDespesasVazias] = useState([]);
  const [idDelete, setIddelete] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tipoSnack, setTipoSnack] = useState("success");
  const [mensagemSnack, setMensagemSnack] = useState("");
  const [idpessoa, setIdPessoa] = useState(0);
  const [snackbarSucess, setSnackbarSucess] = useState({
    visible: false,
  });
  const [viagem, setViagem] = useState({
    despesas: [],
  });

  const handleClickAddViagem = (item = {}) => {
    setViagem(item);
    setOpenModal(true);
  };

  const getData = useCallback(async () => {
    try {
      setBackDrop(true);

      const response = await viagemRepositorio.getAll({
        dataInicio,
        dataFim,
        idCentroCusto: centroCusto?.value,
        idViajante: viajante?.value,
      });

      const responseCentroCusto = await centroCustoRepositorio.getSearchField();
      const responseViajantes = await pessoaRepositorio.getSearchField();

      setCentrosCusto(responseCentroCusto);
      setViajantes(responseViajantes);
      setViagens(response.data);

      setViagensGrid(
        response.data.map((item) => {
          return {
            idviagemtenant: item.idviagemtenant,
            id: item.id,
            idviagem: item.idviagem,
            viagem: item.titulo,
            viajante: `${item.usuario.pessoa.nome} ${item.usuario.pessoa.sobrenome}`,
            status: item.status,
            dataIda: new Date(item.datacriacao).toLocaleDateString(),
            visualizar: item.idviagem,
            idpessoa: item.idpessoa,
            total: item.total,
          };
        })
      );

      setBackDrop(false);
    } catch (e) {
      return;
    } finally {
      setBackDrop(false);
    }
  }, [
    setBackDrop,
    setCentrosCusto,
    setViajantes,
    setViagens,
    setViagensGrid,
    dataInicio,
    dataFim,
    centroCusto?.value,
    viajante?.value,
  ]);

  const handleClickSearch = async () => {
    await getData();
  };

  const handleClickReturnList = async (item) => {
    try {
      setIsLoading(true);
      await viagemRepositorio.AttDespesasForViagem([item], 0);
      handleClickEditar(viagem.idviagem, idpessoa);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickTransferirDespesa = async (item) => {
    try {
      setIsLoading(true);
      await viagemRepositorio.AttDespesasForViagem([item], viagem.idviagem);
      handleClickEditar(viagem.idviagem, idpessoa);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickDeleteAutoDespesa = async (item) => {
    try {
      setIsLoading(true);
      await viagemRepositorio.removeViagemAutoDespesa(item.idviagemdespesa);
      handleClickEditar(viagem.idviagem, idpessoa);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickEditar = async (idviagem, idpessoa) => {
    try {
      const response = await viagemRepositorio.get(idviagem);
      const responseexpanses = await viagemRepositorio.getAllEmptyexpanses(
        idpessoa
      );
      setIdPessoa(idpessoa);
      setViagemDespesasVazias(responseexpanses.data);
      setViagem(response.data);
      setOpenModal(true);
    } catch (e) {
      return;
    }
  };

  const handleClickModalAccept = async (id) => {
    setOpenModalAccept(true);
    try {
      const response = await viagemRepositorio.get(id);
      setModalTeste(response);
    } catch (e) {
      return;
    }
  };

  const openSetPermissaoUser = (permissao) => {
    setPermissaoUser(permissao);
  };

  const handleClickDialogConfirmPagamentoAgendadoOpen = () => {
    setDataConfirm(viagens);
    setOpenDialogPagamento(true);
  };

  const handleClickDDialogConfirmExportExcel = () => {
    setDataConfirm(viagens);
    setOpenDialogExportExcel(true);
  };

  const handleOpenExcluirViagem = async (id) => {
    setOpenModalExcluir(true);
    setIddelete(id);
  };

  const handleExcluirViagem = async () => {
    try {
      setIsLoading(true);
      let response = await viagemRepositorio.deleteViagem(idDelete);
      if (response.data) {
        setMensagemSnack("Viagem excluida com sucesso!");
        setTipoSnack("success");
        setSnackbarSucess({ visible: true });
      } else {
        setMensagemSnack(
          'Somente o viajante pode excluir e quando em status de "Em viagem"!'
        );
        setTipoSnack("warning");
        setSnackbarSucess({ visible: true });
      }
    } catch (e) {
      setMensagemSnack("Erro ao excluir a viagem!");
      setTipoSnack("error");
      setSnackbarSucess({ visible: true });
    } finally {
      setOpenModalExcluir(false);
      setIsLoading(false);
      getData();
    }
  };

  useEffect(() => {
    setOpenModalAccept(false);
    setOpenModal(false);
    setOpenDialogPagamento(false);
    (async () => {
      await getData();
    })();
  }, [setOpenModalAccept, setOpenModal, setOpenDialogPagamento]);

  useEffect(() => {
    (async () => {
      const response = await permissaoRepositorio.getPorSessao();
      openSetPermissaoUser(response.data);
    })();
  }, []);

  return (
    <div className={classes.root}>
      <Snackbar
        {...{
          variant: tipoSnack,
          message: mensagemSnack,
          visible: snackbarSucess.visible,
          setSnackbar: setSnackbarSucess,
        }}
      />
      <ULoaderVoo isLoading={backDrop} />
      <DialogDelete
        {...{
          handleExcluirViagem,
          setOpenModalExcluir,
          openModalExcluir,
          isLoading,
        }}
      />
      <DialogConfirmPagamentoAgendado
        getData={getData}
        open={openDialogPagamento}
        dataConfirm={dataConfirm}
        setOpenDialogPagamento={setOpenDialogPagamento}
      />
      <DialogConfirmExportExcel
        getData={getData}
        open={openDialogImportExcel}
        dataConfirm={dataConfirm}
        setOpenDialogExportExcel={setOpenDialogExportExcel}
        dto={{
          dataInicio,
          dataFim,
          idCentroCusto: centroCusto?.value,
          centroCusto: centroCusto?.label,
          idViajante: viajante?.value,
          viajante: viajante?.label,
        }}
      />
      {openModal ? (
        <CadastroViagem
          getData={getData}
          centrosCusto={centrosCusto}
          open={openModal}
          viagem={viagem}
          setViagem={setViagem}
          viagemdespesasvazias={viagemdespesasvazias}
          handleClickTransferirDespesa={handleClickTransferirDespesa}
          handleClickReturnList={handleClickReturnList}
          handleClickDeleteAutoDespesa={handleClickDeleteAutoDespesa}
          setOpen={setOpenModal}
        />
      ) : null}
      <DialogAccept
        getData={getData}
        open={openModalAccept}
        setModalTeste={setModalTeste}
        modalTeste={modalTeste}
        setOpenModalAccept={setOpenModalAccept}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Prestação de contas</Typography>
        {!responsive ? (
          <Tooltip title="Adicionar viagem">
            <Button
              onClick={() => handleClickAddViagem()}
              className={classes.button}
            >
              <AddRounded style={{ color: "inherited" }} />
            </Button>
          </Tooltip>
        ) : (
          <UFloatButton
            actions={[]}
            handleClick={() => handleClickAddViagem()}
          />
        )}
      </Box>
      <Box mt={1}>
        <Paper elevation={3}>
          <Box p={2}>
            <form autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <UDatePicker
                    date={dataInicio}
                    setDate={setDataInicio}
                    label="Data Início"
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <UDatePicker
                    date={dataFim}
                    setDate={setDataFim}
                    label="Data Fim"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  {permissao.relatorioCentroCusto ? (
                    <USearchField
                      disabled={!politicaViagem.utilizarcentrocusto}
                      label="Centro de custo"
                      itens={centrosCusto}
                      value={centroCusto}
                      setValue={setCentroCusto}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12} md={3}>
                  {permissao.relatorioPessoal ? (
                    <USearchField
                      label="Viajante"
                      itens={viajantes}
                      value={viajante}
                      setValue={setViajante}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12} md={1} style={{ alignSelf: "center" }}>
                  <Button
                    onClick={handleClickSearch}
                    className={classes.button}
                    variant="contained"
                    fullWidth
                  >
                    <SearchOutlined />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={classes.button}
                    onClick={() => handleClickDDialogConfirmExportExcel()}
                  >
                    <GetApp />
                    Exportar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
        <Box mt={4} mb={responsive ? 7 : 0}>
          {responsive ? (
            <Box>
              <Toolbar
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                {permissaoUser.permitecontroletotaldespesasviagem === true ||
                permissaoUser.permiteserfinanceiro === true ? (
                  <Button
                    onClick={() =>
                      handleClickDialogConfirmPagamentoAgendadoOpen()
                    }
                    type="submit"
                    disabled={false}
                    className={classes.buttonSave}
                  >
                    <Typography className={classes.typographButton}>
                      Avançar (Pagamento Agendado)
                    </Typography>
                    <SendOutlined fontSize="small" />
                  </Button>
                ) : null}
              </Toolbar>
              <DespesasList
                {...{
                  openModalExcluir,
                  setOpenModalExcluir,
                  handleOpenExcluirViagem,
                }}
                onClickModalAccept={handleClickModalAccept}
                onClickEdit={handleClickEditar}
                data={viagens}
              />
            </Box>
          ) : (
            <Paper elevation={5}>
              <Toolbar style={{ display: "flex", justifyContent: "flex-end" }}>
                {permissaoUser.permitecontroletotaldespesasviagem === true ||
                permissaoUser.permiteserfinanceiro === true ? (
                  <Button
                    onClick={() =>
                      handleClickDialogConfirmPagamentoAgendadoOpen()
                    }
                    type="submit"
                    disabled={false}
                    className={classes.buttonSave}
                  >
                    <Typography className={classes.typographButton}>
                      Avançar (Pagamento Agendado)
                    </Typography>
                    <SendOutlined fontSize="small" />
                  </Button>
                ) : null}
              </Toolbar>
              <DespesasGrid
                {...{
                  openModalExcluir,
                  setOpenModalExcluir,
                  handleOpenExcluirViagem,
                }}
                onClickModalAccept={handleClickModalAccept}
                onClickEdit={handleClickEditar}
                isLoading={isLoading}
                data={viagensGrid}
              />
            </Paper>
          )}
        </Box>
      </Box>
    </div>
  );
};

export const NavBar = (props) => {
  return (
    <div>
      <Dashboard {...props} component={DespesaViagem} />
    </div>
  );
};
export default NavBar;
