import { CloseOutlined } from "@material-ui/icons";
import React from "react";
import { useStyles } from "./styles";
import Cookies from "universal-cookie";
import Enums from "../../../../enums";
import reservaRepositorio from "../../../../repositorios/reserva";
import aereoRepositorio from "../../../../repositorios/aereo";
import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Toolbar, Typography } from "@material-ui/core";

export const ConfirmCancelBooking = (props) => {
    const open = props.open;
    const responsive = props.responsive;
    const classes = useStyles();
    const cookies = new Cookies();
    const booking = props.bookingSelected;

    const handleEventOnClick = async (type) => {
        switch (type) {
            case 'yes':
                cancelBooking();
                break;
            case 'no':
                props.handleClickCloseCancelBooking();
                break;
            default:
                console.warn(`Tipo inesperado: ${type}`); // Lida com qualquer outro valor inesperado
                break;
        }
    };

    const cancelBooking = async () => {
        try {
            props.setIsLoading(true);
            if (booking.tipo === 1) {
                let temp_response = await reservaRepositorio.get(booking.idreserva);
                await aereoRepositorio.cancelarAereo(temp_response.idaereo);
            } else if (booking.tipo === 2) {
                let dtoStatusReserva = {
                    idreserva: booking.idreserva,
                    status: Enums.reservaStatus.cancelado,
                    justificativa: "",
                    politicaAereo: false,
                    statusTipo: Enums.statusSolicitacao.cancelado,
                    sessionId: cookies.get("sessionId"),
                };
                await reservaRepositorio.alterarStatus(dtoStatusReserva);
            } else { throw new Error('Sem tipo.') }

            props.setSnackMessage('Cancelada com sucesso.');
            props.setSnackVariant('success');
            props.setSnackState({ visible: true });
            props.getBookingList();
            props.handleClickCloseCancelBooking();
        } catch (error) {
            props.setSnackMessage('Falha ao tentar cancelar.');
            props.setSnackVariant('error');
            props.setSnackState({ visible: true });
        } finally {
            props.setIsLoading(false)
        }
    };

    return (
        <>
            <Dialog open={open} fullWidth maxWidth="xs" fullScreen={responsive}>
                <AppBar position="relative">
                    <Toolbar className={classes.appbar}>
                        <Typography >Confirmação</Typography>
                        <IconButton onClick={() => props.handleClickCloseCancelBooking()} ><CloseOutlined className={classes.iconButton} /></IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText>
                        Deseja realmente efetuar o cancelamento?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={'outlined'} disabled={props.isLoading} className={classes.buttonNegar} onClick={() => handleEventOnClick('no')}>Não</Button>
                    <Button variant={'outlined'} disabled={props.isLoading} className={classes.buttonAutorizar} onClick={() => handleEventOnClick('yes')}>Sim</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}
