import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { HistoryOutlined } from "@material-ui/icons";
import { XGrid } from "@material-ui/x-grid";
import React from "react";
import CustomNoRowsOverlay from "../../../configuracao/email/lista/emptyList";

function MenorTarifaGrid(props) {
  const openHistoricoReserva = (item) => {
    props.setOpenDialoHistorico(true);
    props.setDialogIdReserva(item.idreserva);
    props.setIdreservatenant(item.idreservatenant);
    props.setReduction(item.diferenca);
  };

  const concatStrigNome = (names) => {
    let result = "";
    names.forEach((nome) => { result += nome + ", "; });
    return <Typography>{result}</Typography>;
  };

  const columns = [
    { field: "id", headerName: "ID", width: 250, hide: true },
    { field: "idreservatenant", headerName: "ID", width: 80 },
    { field: "assunto", headerName: "Descrição", width: 116 },
    { field: "solicitante", headerName: "Solicitante", width: 180 },
    { field: "quantidadePassageiros", headerName: "Quantidade", width: 80 },
    {
      field: "passageiros",
      headerName: "Passageiros",
      width: 180,
      renderCell: (item) => (
        <>
          <Tooltip title={item.row.passageiros.map((nome) => { return (<Typography>{nome}</Typography>) })}          >
            {concatStrigNome(item.row.passageiros)}
          </Tooltip>
        </>
      ),
    },
    // { field: 'centroDeCusto', headerName: 'Centro de Custo', width: 168 },
    { field: "dataCriacao", headerName: "Data Criação", width: 118, valueFormatter: p => new Date(p?.value).toLocaleDateString() },
    { field: "precoInicial", headerName: "Preço Inicial", width: 118, valueFormatter: p => p?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { field: "precoFinal", headerName: "Preço Final", width: 118, valueFormatter: p => p?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    {
      field: "diferenca",
      headerName: "Valor da Redução",
      width: 170,
      valueFormatter: p => p?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      // renderCell: (item) => (
      //   <>
      //     <Typography style={{ color: "green" }}>
      //       {/* {item.row.diferenca.} */}
      //     </Typography>
      //   </>
      // ),
    },
    {
      field: "diferenca_percent",
      headerName: "% da Redução",
      width: 90,
      valueFormatter: p => `${p?.value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %`
      // renderCell: (item) => (
      //   <>
      //     <Typography style={{ color: "green" }}>
      //       {/* {item.row.diferenca.} */}
      //     </Typography>
      //   </>
      // ),
    },
    {
      field: "historico",
      headerName: "Histórico",
      width: 88,
      sortable: false,
      renderCell: (item) => (
        <>
          <IconButton onClick={() => openHistoricoReserva(item.row)}>
            <HistoryOutlined style={{ color: "#c62828" }} />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ height: "52vh", width: "100%" }}>
        <XGrid
          disableColumnFilter={true}
          disableColumnMenu={true}
          id={Math.random()}
          rows={props.data}
          columns={columns}
          loading={props.loading}
          components={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          hideFooterSelectedRowCount
          disableSelectionOnClick
          hideFooterRowCount
          pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </div>
    </>
  );
}

export default MenorTarifaGrid;
