import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useStyles } from './styles';
import { CloseOutlined, MobileFriendlyOutlined, MobileOffOutlined, WhatsApp } from '@material-ui/icons';
import { BookingTicketDesktop } from './BookingTicketDesktop/index.jsx';
import { useEffect } from 'react';
import aereoRepositorio from '../../../repositorios/aereo';
import { useState } from 'react';
import reservaRepositorio from '../../../repositorios/reserva';
import { useSelector } from 'react-redux';
import api01 from '../../../images/api/01.svg';
import api02 from '../../../images/api/02.svg';
import ChangeValuePax from './ChangeValuePax';
import ChangeValueCC from './ChangeValueCC';
import ChangeValuePayment from './ChangeValuePayment';
import AddAttachmentsTicket from './AddAttachmentsTicket';
import { BookingTicketMobile } from './BookingTicketMobile';
import whatappRepositorio from '../../../repositorios/whatsapp';
import CustomizedSnackbars from '../../../components/snackbar';

export const BookingTicket = (props) => {
    const responsive = props.responsive;
    const { setIsLoading, bookingSelected } = props;
    const open = props.openDialogBookingTicket;
    const permissaoDashboard = useSelector((state) => state.permissao.permissaoDashboard);
    const classes = useStyles();
    const [bookingTicketData, setBookingTicketData] = useState({})
    const [valuesStatusEnviado, setValuesStatusEnviado] = useState(null);
    const [openChangeValuePax, setOpenChangeValuePax] = useState(false);
    const [itemSelectedChangeValuePax, setItemSelectedChangeValuePax] = useState({ item: {}, type: '' });
    const [openChangeValueCC, setOpenChangeValueCC] = useState(false);
    const [itemSelectedChangeValueCC, setItemSelectedChangeValueCC] = useState({});
    const [openChangeValuePayment, setOpenChangeValuePayment] = useState(false);
    const [itemSelectedChangeValuePayment, setItemSelectedChangeValuePayment] = useState({ statusBilheteView: false, idReserva: 0 });
    const [openAddAttchments, setOpenAddAttchments] = useState(false);
    const [itemSelectedAddAttchments, setItemSelectedAddAttchments] = useState({});
    const [snackState, setSnackState] = useState({ visible: false });
    const [snackVariant, setSnackVariant] = useState('success');
    const [snackMessage, setSnackMessage] = useState('');

    const getBookingTicket = useCallback(async () => {
        try {
            setIsLoading(true)
            let responseReserva = await reservaRepositorio.get(bookingSelected);
            let response = await aereoRepositorio.get(responseReserva.idaereo);
            responseReserva.aereo = response.data
            setBookingTicketData(responseReserva);
        } catch (e) {
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, bookingSelected])

    const loadStatusEnviado = useCallback(async () => {
        try {
            setIsLoading(true)
            let response = await reservaRepositorio.getBilheteEnviadoByReserva(bookingSelected);
            setValuesStatusEnviado(response);
        } catch (e) {
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, bookingSelected])

    const handleClickOpenChangeValuePax = (item, type) => {
        setItemSelectedChangeValuePax({ item: item, type: type })
        setOpenChangeValuePax(true)
    }

    const handleClickOpenChangeValueCC = (item) => {
        setItemSelectedChangeValueCC(item)
        setOpenChangeValueCC(true)
    }

    const handleClickOpenAddAttachments = () => {
        setItemSelectedAddAttchments(bookingTicketData.aereo)
        setOpenAddAttchments(true)
    }

    const handleClickOpenChangeValuePayment = (idStatus, idReserva) => {
        switch (idStatus) {
            case 7: case 12: case 10:
                setItemSelectedChangeValuePayment({ statusBilheteView: true, idReserva: idReserva })
                break;
            default:
                setItemSelectedChangeValuePayment({ statusBilheteView: false, idReserva: idReserva })
                break;

        }
        // setItemSelectedChangeValuePayment({ statusBilheteView: false, idReserva: idReserva })
        setOpenChangeValuePayment(true)
    }

    const getLogoAPI = (id) => {
        switch (id) {
            case 1:
                return (
                    <object
                        data={api01}
                        width={80}
                        height={80}
                        type="image/svg+xml"
                        aria-labelledby="api01-desc"
                    >
                        <div id="api01-desc">Logo for API 01</div>
                    </object>
                );
            case 2:
                return (
                    <object
                        data={api02}
                        width={80}
                        height={80}
                        type="image/svg+xml"
                        aria-labelledby="api02-desc"
                    >
                        <div id="api02-desc">Logo for API 02</div>
                    </object>
                );
            default:
                return null;
        }
    };


    const getDataFormatada = (data) => {
        let dataFormatada = new Date(data).toLocaleDateString('pt-Br');
        let horaFormatada = new Date(data).toLocaleTimeString('pt-Br');
        return `${dataFormatada} às ${horaFormatada}`;
    }

    const showIconWppByValue = (value, idStatus, issolicitante) => {
        if (valuesStatusEnviado !== null) {
            let validTypePerson = null;
            if (issolicitante)
                validTypePerson = valuesStatusEnviado.solicitante.find(i => i.idpessoa === value);
            else
                validTypePerson = valuesStatusEnviado.passageiros.find(i => i.idpessoa === value);
            switch (validTypePerson.bilheteenviado) {
                case 0:
                    return (
                        <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>Clique para enviar o bilhete.</Typography>)}>
                            <IconButton onClick={() => handleClickSendBilheteByViajante(value, issolicitante)}><WhatsApp style={{ color: '#34af23' }} /></IconButton>
                        </Tooltip>
                    )
                case 1:
                    return (
                        <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>Favor cadastrar/conferir o número do(a) pessoa desejada.</Typography>)}>
                            <IconButton disabled><MobileOffOutlined style={{ color: '#030303' }} /></IconButton>
                        </Tooltip>
                    )
                case 2:
                    return (
                        <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>Já foi enviado o bilhete.</Typography>)}>
                            <IconButton onClick={() => handleClickSendBilheteByViajante(value, issolicitante)}><MobileFriendlyOutlined /></IconButton>
                        </Tooltip>
                    )
                case 3:
                    return (
                        <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>Viajante está configurado para não receber envio automático.</Typography>)}>
                            <IconButton onClick={() => handleClickSendBilheteByViajante(value, issolicitante)}><WhatsApp style={{ color: '#c62828' }} /></IconButton>
                        </Tooltip >
                    )
                default:
                    return null
            }
        }
    }

    const handleClickEditCentroCusto = () => {

    }

    const handleClickSendBilheteByViajante = async (value, idsolicitante) => {
        props.setIsLoading(true)
        let dto = {
            idreserva: props.bookingSelected,
            idpessoa: value,
            solicitante: idsolicitante,
        }
        try {
            let response = await whatappRepositorio.postBilheteByPessoa(dto);
            if (response.status === 204) {
                if (idsolicitante) {
                    var solicitante = [{ idpessoa: value, bilheteenviado: 1 }];
                    setValuesStatusEnviado({ ...valuesStatusEnviado, solicitante: solicitante });
                } else {
                    let passageiros = valuesStatusEnviado.passageiros.filter(p => p.idpessoa !== value);
                    passageiros.push({ idpessoa: value, bilheteenviado: 1 });
                    setValuesStatusEnviado({ ...valuesStatusEnviado, passageiros: passageiros });
                }
                setSnackVariant('warning');
                setSnackMessage('Número inválido.');
                setSnackState({ visible: true });
                return;
            }
            if (response.status === 200) {
                setSnackVariant('success');
                setSnackMessage('Enviado com sucesso.');
                setSnackState({
                    visible: true
                });
                loadStatusEnviado()
            }
        } catch (e) {
            setSnackVariant('error');
            setSnackMessage('Erro ao tentar enviar bilhete.');
            setSnackState({ visible: true });
        } finally {
            props.setIsLoading(false)
        }
    }

    useEffect(() => {
        getBookingTicket()
        loadStatusEnviado();
    }, [getBookingTicket, loadStatusEnviado]);

    return (
        <Dialog open={open} maxWidth='lg' fullWidth fullScreen={responsive}>
            <CustomizedSnackbars {...{ variant: snackVariant, message: snackMessage, visible: snackState.visible, setSnackbar: setSnackState }} />
            {responsive ? (
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: "-10px" }}>
                        <Typography style={{ fontWeight: "600", fontSize: "18px" }}>
                            Aéreo {`${bookingTicketData.idreservatenant ? '- ' + bookingTicketData.idreservatenant : ''}`}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={() => props.setOpenDialogBookingTicket(false)}
                            sx={{
                                color: "#919EAB",
                            }}
                        >
                            <CloseOutlined />
                        </IconButton>
                    </Box>
                </DialogTitle>
            ) : (
                <DialogTitle disableTypography className={classes.dialogTitle}>
                    <Typography variant="body1">Reserva {`${bookingTicketData.idreservatenant ? '- ' + bookingTicketData.idreservatenant : ''}`}</Typography>
                    <IconButton className={classes.closeButton} aria-label="close" onClick={() => props.setOpenDialogBookingTicket(false)}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
            )}
            <DialogContent style={{ padding: responsive ? '0px' : '8px' }}>
                {openChangeValuePax && (
                    <ChangeValuePax {...{
                        setOpenChangeValuePax,
                        openChangeValuePax,
                        itemSelectedChangeValuePax,
                        getBookingTicket
                    }} />
                )}
                {openChangeValueCC && (
                    <ChangeValueCC {...{
                        setOpenChangeValueCC,
                        openChangeValueCC,
                        itemSelectedChangeValueCC,
                        getBookingTicket
                    }} />
                )}
                {openChangeValuePayment && (
                    <ChangeValuePayment {...{
                        setOpenChangeValuePayment,
                        openChangeValuePayment,
                        itemSelectedChangeValuePayment,
                    }} />
                )}
                {openAddAttchments && (
                    <AddAttachmentsTicket {...{
                        setOpenAddAttchments,
                        openAddAttchments,
                        itemSelectedAddAttchments,
                    }} />
                )}
                {Object.keys(bookingTicketData).length !== 0 ? responsive ? (
                    <BookingTicketMobile {...{
                        showIconWppByValue,
                        bookingTicketData,
                        permissaoDashboard,
                        getLogoAPI,
                        getDataFormatada,
                        handleClickEditCentroCusto,
                        handleClickOpenChangeValuePax,
                        handleClickOpenChangeValueCC,
                        handleClickOpenChangeValuePayment,
                        handleClickOpenAddAttachments
                    }} />
                ) : (
                    <BookingTicketDesktop {...{
                        showIconWppByValue,
                        bookingTicketData,
                        permissaoDashboard,
                        getLogoAPI,
                        getDataFormatada,
                        handleClickEditCentroCusto,
                        handleClickOpenChangeValuePax,
                        handleClickOpenChangeValueCC,
                        handleClickOpenChangeValuePayment,
                        handleClickOpenAddAttachments
                    }} />
                ) : null}
            </DialogContent>
        </Dialog>
    )
};
