import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme => ({
    card: {
        transition: 'background-color 0.5s ease', // Suaviza a transição do efeito de escurecimento
        marginTop: "50px",
        cursor: 'default', // Cursor padrão quando não está em hover
        backgroundColor: "#FFFFFF",
        textAlign: 'center',
        borderRadius: "10px",
        boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
        '&:hover': {
            cursor: 'pointer', // Muda o cursor para pointer quando em hover
            backgroundColor: "#F6F6F6",
            boxShadow: "0px 0px 3px 1px rgba(0,0,0,0.13)",
        },
    }
})));