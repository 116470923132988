import { Box, Button, ButtonGroup, Container, Grid, Hidden, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { GetApp, SearchOutlined } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '../../../../components/snackbar';
import UDatePicker from '../../../../components/udatepicker';
import ExportExcel from '../../../../components/UExportExcel';
import ULoaderVoo from '../../../../components/uloadervoo';
import USearchField from '../../../../components/usearchfield';
import centroCustoRepositorio from '../../../../repositorios/centroCusto';
import pessoaRepositorio from '../../../../repositorios/pessoa';
import tempoDecisaoRepositorio from '../../../../repositorios/relatorio/tempoDecisao';
import Dashboard from '../../../dashboard';
import { useStyles } from './styles';
import TempoDecisaoGrid from './tempoDecisaoGrid';
import TempoDecisaoList from './tempoDecisaoList';

function isValidDate(date) {
    return moment(date).isValid();
}

export const ListaTempoDecisao = props => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const permissao = useSelector(state => state.permissao.permissaoDashboard);
    const [dataInicio, setDataInicio] = useState(new Date());
    const [dataFim, setDataFim] = useState(new Date());
    const [centroCusto, setCentroCusto] = useState(null);
    const [listaRelatorio, setListaRelatorio] = useState([]);
    const [viajante, setViajante] = useState(null);
    const [viajantes, setViajantes] = useState([]);
    const [colunasExcel, setColunasExcel] = useState([]);
    const [rowsExcel, setRowsExcel] = useState([]);
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [dadosRelatorio, setDadosRelatorio] = useState({
        totalComprado: 0,
        quantidadeEmitidas: 0,
        totalGanhos: 0,
        totalPerdas: 0,
        resultados: [],
    });
    const [snackVariant, setSnackVariant] = useState('error');
    const [snackMessage, setSnackMessage] = useState('');
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [isLoading, setIsLoading] = useState(false);

    const mapToData = lista => {
        const listaFormatada = lista.map((item, index) => {
            return {
                id: item.id,
                assunto: item.assunto,
                valor_antigo: item.valor_antigo,
                valor_atual: item.valor_atual,
                diferenca: {
                    total: item.diferenca,
                    porcentagem: item.percentoDiferenca
                },
                solicitante: item.solicitante,
                dataCriacao: new Date(item.dataCriacao).toLocaleString().substr(0, 17),
                dataCriacao_antigo: new Date(item.dataCriacao_antigo).toLocaleString().substr(0, 17)
            }
        });
        return listaFormatada;
    }

    const handleClickPesquisar = async () => {
        if (dataInicio > dataFim) {
            setSnackVariant('error');
            setSnackMessage('Data de início maior que data final.');
            setSnackVisible({ visible: true })
            return;
        }

        if (!isValidDate(dataInicio) || !isValidDate(dataFim)) {
            setSnackVariant('error');
            setSnackMessage('Data Inválida.');
            setSnackVisible({ visible: true });
            return;
        }

        let dto = {
            dataInicio,
            dataFim,
            idCentroCusto: centroCusto ? centroCusto.value : 0,
            idViajante: viajante ? viajante.value : 0
        }
        try {
            setIsLoading(true);
            var response = await tempoDecisaoRepositorio.getRelatorioTempoDecisao(dto);

            let datas = response.data.resultados.map(item => {
                return [
                    item.id,
                    String(item.assunto).replace('<br />', '    '),
                    item.dataCriacao_antigo,
                    item.valor_antigo,
                    item.dataCriacao,
                    item.valor_atual,
                    `R$ ${Number(item.diferenca).toFormatDefault()} (${Number(item.percentoDiferenca).toFormatDefault()}%)`,
                    item.solicitante.nomeUsuario,
                ];
            });            

            setRowsExcel(datas);
            setListaRelatorio(mapToData(response.data.resultados));
            setDadosRelatorio(response.data);
        } catch (e) {
            setSnackVariant('error');
            setSnackMessage('Erro ao carregar relatório.');
            setSnackVisible({ visible: true })
        } finally {
            setIsLoading(false);
        }
    }


    const setPeriodoPesquisa = (periodo) => {
        let inicio = new Date();
        let fim = new Date();
        switch (periodo) {
            case 1:
                inicio.setDate(inicio.getDate() - 7);
                break;
            case 2:
                inicio = new Date(inicio.getFullYear(), inicio.getMonth(), 1);
                fim = new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0);
                break;
            case 3:
                inicio = new Date(inicio.getFullYear(), 0, 1);
                fim = new Date(inicio.getFullYear(), 11, 31);
                break;
            default: 
                console.warn(`Esperado periodo ${periodo}`)
                break;
        }

        setDataInicio(inicio);
        setDataFim(fim);
    }

    const handleChangeData = () => {
        let inicio = new Date();
        if ((dataInicio !== '' && dataInicio !== undefined && dataInicio !== null) && (dataFim !== '' && dataFim !== undefined && dataFim !== null)) {

            if (dataInicio.toLocaleDateString() === new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString() &&
                dataFim.toLocaleDateString() === new Date().toLocaleDateString()) {
                return 1;
            }
            if (dataInicio.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth(), 1).toLocaleDateString() &&
                dataFim.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0).toLocaleDateString()) {
                return 2;
            }
            if (dataInicio.toLocaleDateString() === new Date(inicio.getFullYear(), 0, 1).toLocaleDateString() &&
                dataFim.toLocaleDateString() === new Date(inicio.getFullYear(), 11, 31).toLocaleDateString()) {
                return 3;
            }

            return 4;
        }
    }

    useEffect(() => {
        (async () => {
            const responseViajantes = await pessoaRepositorio.getSearchField();
            const responseCentrosCusto = await centroCustoRepositorio.getSearchField();

            // await getData();

            setViajantes(responseViajantes);
            setCentrosCusto(responseCentrosCusto);
        })()
    }, [])

    useEffect(() => {
        setColunasExcel(["ID", "Descrição", "Data Inicial", "Valor Anterior", "Data Final", "Valor Emitido", "Diferença", "Solicitante"])
    }, []);

    return (

        <section>
            <Hidden smUp>
                <ULoaderVoo isLoading={isLoading} />
            </Hidden>
            <Snackbar {...{ variant: snackVariant, message: snackMessage, visible: snackVisible.visible, setSnackbar: setSnackVisible }} />
            <Container maxWidth="xl">
                <Box mt={2} mb={responsive ? 10 : 3}>
                    <Box mb={1}>
                        <Grid container>
                            <Grid item xs={responsive ? 12 : 2}>
                                <Typography variant="h6" component="h1">Tempo de Decisão</Typography>
                            </Grid>
                            {responsive ? null : (
                                <>
                                    <Grid item xs={8}>
                                        <Grid item xs={12} className={classes.groupButton}>
                                            <ButtonGroup>
                                                <Button
                                                    style={{
                                                        background: handleChangeData() === 1 ? '#c62828' : 'transparent',
                                                        color: handleChangeData() === 1 ? 'white' : '#c62828',
                                                        border: '1px solid #c62828',
                                                        '&:hover': {
                                                            background: '#c62828',
                                                            color: 'white'
                                                        }
                                                    }}
                                                    onClick={() => setPeriodoPesquisa(1)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>Semanal</Typography>
                                                </Button>
                                                <Button
                                                    style={{
                                                        background: handleChangeData() === 2 ? '#c62828' : 'transparent',
                                                        color: handleChangeData() === 2 ? 'white' : '#c62828',
                                                        border: '1px solid #c62828',
                                                        '&:hover': {
                                                            background: '#c62828',
                                                            color: 'white'
                                                        }
                                                    }}
                                                    onClick={() => setPeriodoPesquisa(2)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>Mensal</Typography>
                                                </Button>
                                                <Button
                                                    style={{
                                                        background: handleChangeData() === 3 ? '#c62828' : 'transparent',
                                                        color: handleChangeData() === 3 ? 'white' : '#c62828',
                                                        border: '1px solid #c62828',
                                                        '&:hover': {
                                                            background: '#c62828',
                                                            color: 'white'
                                                        }
                                                    }}
                                                    onClick={() => setPeriodoPesquisa(3)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>Anual</Typography>
                                                </Button>
                                                <Button
                                                    style={{
                                                        background: handleChangeData() === 4 ? '#c62828' : 'transparent',
                                                        color: handleChangeData() === 4 ? 'white' : '#c62828',
                                                        border: '1px solid #c62828',
                                                        '&:hover': {
                                                            background: '#c62828',
                                                            color: 'white'
                                                        }
                                                    }}
                                                    onClick={() => setPeriodoPesquisa(4)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>Personalizado</Typography>
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                    <Paper elevation={3}>
                        <Box p={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={2}>
                                    <UDatePicker date={dataInicio} setDate={setDataInicio} label="Data início" />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <UDatePicker date={dataFim} setDate={setDataFim} label="Data Fim" />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {permissao.relatorioCentroCusto ? (
                                        <USearchField itens={centrosCusto} value={centroCusto} setValue={setCentroCusto} label="Centro de custo" />
                                    ) : null}
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    {permissao.relatorioPessoal ? (
                                        <USearchField itens={viajantes} value={viajante} setValue={setViajante} label="Viajante" />
                                    ) : null}
                                </Grid>
                                <Grid item xs={12} md={1} style={{ alignSelf: 'center' }}>
                                    <Button onClick={handleClickPesquisar} className={classes.button} fullWidth>
                                        <SearchOutlined />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    <Box mt={2}>
                        {responsive ? (
                            <>
                                <Grid container style={{ display: 'flex', flexDirection: 'column' }} spacing={1}>
                                    <Grid item xs={12} >
                                        <Box border={1} borderColor={'#41A5D1'} borderRadius={4} padding={1} display={'flex'} sx={{ flexDirection: 'column' }} justifyContent={'center'} alignItems={'center'}>
                                            <Typography className={classes.typography_B} variant='body1'>Compras: R${Number(dadosRelatorio.totalComprado).toFormatDefault()}</Typography>
                                            <Typography className={classes.typography_B} variant="body2">Quantidade emitida: {dadosRelatorio.quantidadeEmitidas}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box border={1} borderColor={'#43B947'} borderRadius={4} padding={1} sx={{ height: '100%' }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <Typography className={classes.typography_G} variant='body1'>Ganhos: R${Number(dadosRelatorio.totalGanhos).toFormatDefault()}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box border={1} borderColor={'#E50008'} borderRadius={4} padding={1} sx={{ height: '100%' }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <Typography className={classes.typography_R} variant='body1'>Perdas: R${Number(dadosRelatorio.totalPerdas).toFormatDefault()}</Typography>
                                            <br />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridRowCenter}>
                                        <ExportExcel name="Relatório Tempo de Decisão" columns={colunasExcel} data={rowsExcel} element={
                                            <>
                                                <Button className={classes.button}>
                                                    <GetApp />
                                                    Exportar
                                                </Button>
                                            </>
                                        } />
                                    </Grid>
                                </Grid>
                                <TempoDecisaoList data={listaRelatorio} />
                            </>
                        ) : (
                            <Paper elevation={3}>
                                <Grid container spacing={1} style={{ margin: '0px' }}>
                                    <Grid item xs={4} >
                                        <Box border={1} borderColor={'#41A5D1'} borderRadius={4} padding={1} display={'flex'} sx={{ flexDirection: 'column' }} justifyContent={'center'} alignItems={'center'}>
                                            <Typography className={classes.typography_B} variant='body1'>Compras: R${Number(dadosRelatorio.totalComprado).toFormatDefault()}</Typography>
                                            <Typography className={classes.typography_B} variant="body2">Quantidade emitida: {dadosRelatorio.quantidadeEmitidas}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box border={1} borderColor={'#43B947'} borderRadius={4} padding={1} sx={{ height: '100%' }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <Typography className={classes.typography_G} variant='body1'>Ganhos: R${Number(dadosRelatorio.totalGanhos).toFormatDefault()}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box border={1} borderColor={'#E50008'} borderRadius={4} padding={1} sx={{ height: '100%' }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <Typography className={classes.typography_R} variant='body1'>Perdas: R${Number(dadosRelatorio.totalPerdas).toFormatDefault()}</Typography>
                                            <br />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} className={classes.gridRowCenter}>
                                        <ExportExcel name="Relatório Tempo de Decisão" columns={colunasExcel} data={rowsExcel} element={
                                            <>
                                                <Button className={classes.button}>
                                                    <GetApp />
                                                    Exportar
                                                </Button>
                                            </>
                                        } />
                                    </Grid>
                                </Grid>
                                <TempoDecisaoGrid isLoading={isLoading} data={listaRelatorio} />
                            </Paper>
                        )}

                    </Box>
                </Box>
            </Container>
        </section >

    )
};

export const Lista = props => {
    return (
        <div>
            <Dashboard {...props} component={ListaTempoDecisao} />
        </div>
    )
};
export default Lista;
