import { makeStyles } from '@material-ui/core/styles';
import { indigo } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme => ({
    root: {
        '@media (max-width:480px)': {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
        },
        '@media (max-width:900px) and (orientation: landscape)': {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
        },
        '@media (min-width:600px) and (orientation: portrait)': {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
        }
    },

    textField3: {
        '@media (max-width:480px)': {
            width: '100%',
            marginBottom: theme.spacing(1)
        },
    },

    listSection: {
        backgroundColor: 'inherit',
    },

    ul: {
        backgroundColor: 'inherit',
        padding: theme.spacing(0),
    },

    body: {
        height: 400,
        width: '100%',
    },

    body2: {
        height: 400
    },

    linha: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'space-between',
    },

    appba: {
        '&.MuiAppBar-colorPrimary': {
            backgroundColor: '#d1d1d1'
        },
        background: '#d1d1d1',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px'
    },

    paperList: {
        margin: theme.spacing(0, 0),
        width: '100%'
    },

    utime: {
        width: 155,
        minWidth: 155
    },

    textField2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        width: '100%',
    },

    txt: {
        width: '90%',
    },

    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },

    restricao: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '@media (max-width:480px)': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
    },

    select: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },

    excecao: {
        marginTop: '25px'
    },

    cidade: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },

    cidade2: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '4%'
    },

    tipo: {
        '@media (max-width:480px)': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
    },

    tipo: {
        '@media (max-width:480px)': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        },
    },

    configuracoes: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },

    adicionar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },

    button: {
        background: '#b91119',
        color: 'white',
        '&:hover': {
            background: '#810b11'
        },
    },

    dialogTitle: {
        background: '#616161',
        color: 'white'
    }
})));
