import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Grow,
  IconButton,
  InputBase,
  List,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  AirlineSeatReclineNormalOutlined,
  AssignmentTurnedInOutlined,
  AttachMoneyOutlined,
  CalendarTodayOutlined,
  MoneyOffOutlined,
  MoreVertOutlined,
  Search,
  SubjectOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import LinesTextField from "../../../../components/LinesTextField";
import UDatePicker from "../../../../components/udatepicker";
import ExportExcel from "../../../../components/UExportExcel";
import ULoaderVoo from "../../../../components/uloadervoo";
import USearchField from "../../../../components/usearchfield";
import Enums from "../../../../enums";
import centroCustoRepositorio from "../../../../repositorios/centroCusto";
import permissaoRepositorio from "../../../../repositorios/permissao";
import pessoaRepositorio from "../../../../repositorios/pessoa";
import tempoAntecedenciaRepositorio from "../../../../repositorios/relatorio/tempoAntecedencia";
import Dashboard from "../../../dashboard";
import { useStyles } from "./styles";

export const ListaTempoAntecedencia = (props) => {
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const matches = useMediaQuery("(max-width:480px)");
  const matches2 = useMediaQuery(
    "(max-width:900px) and (orientation: landscape)"
  );
  const matches3 = useMediaQuery(
    "(max-width:800px) and (orientation: portrait)"
  );
  const classes = useStyles();
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [showGrow, setShowGrow] = useState(false);
  const [comprasDias, setComprasDias] = useState(0);
  const [noPrazoDias, setNoPrazoDias] = useState(0);
  const [foraPrazoDias, setForaPrazoDias] = useState(0);
  const [comprasValor, setComprasValor] = useState(0);
  const [noPrazoValor, setNoPrazoValor] = useState(0);
  const [foraPrazoValor, setForaPrazoValor] = useState(0);
  const [comprasEmitidas, setComprasEmitidas] = useState(0);
  const [noPrazoEmitidas, setNoPrazoEmitidas] = useState(0);
  const [foraPrazoEmitidas, setForaPrazoEmitidas] = useState(0);
  const politicaViagem = useSelector((state) => state.permissao.politicaViagem);
  const [colunasExcel, setColunasExcel] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [verCentroCusto, setVerCentroCusto] = useState(false);
  const [verViajante, setVerViajante] = useState(false);
  const [centroCusto, setCentroCusto] = useState(null);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [relatorios, setRelatorios] = useState([]);
  const [relatoriosFiltro, setRelatoriosFiltro] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [itensPerPage, setItensPergPage] = useState(10);

  const pages = Math.ceil(relatoriosFiltro.length);
  const s = page * itensPerPage;
  const r = itensPerPage + s;
  const relatoriosPag = relatoriosFiltro.slice(s, r);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setItensPergPage(event.target.value);
  };

  const [pessoas, setPessoas] = useState([]);
  const [pessoa, setPessoa] = useState(null);
  const [dataInicio, setDataInicio] = useState(new Date());
  const [dataFinal, setDataFinal] = useState(new Date());
  const [periodo, setPeriodo] = useState(4);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMenuMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuMore = () => {
    setAnchorEl(null);
  };

  const definirAssunto = (assunto) => {
    if (!assunto) {
      return "";
    }
    const textos = assunto.split("<br />");
    return (
      <div>
        {textos.map((item) => (
          <div>{item}</div>
        ))}
      </div>
    );
  };

  const onClickFiltrarCompras = () => {
    let filtro = relatorios;
    setRelatoriosFiltro(filtro);
  };

  const onClickFiltrarDentroPrazo = () => {
    let filtro = relatorios.filter((item) => item.prazo);
    setRelatoriosFiltro(filtro);
  };

  const onClickFiltrarForaPrazo = () => {
    let filtro = relatorios.filter((item) => !item.prazo);
    setRelatoriosFiltro(filtro);
  };

  const handlePesquisarChanged = (event) => {
    let filtro = relatorios.filter(
      (item) =>
        event.target.value
          .toLowerCase()
          .includes(String(item.idreserva).toLowerCase()) ||
        item.solicitante
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        item.assunto.toLowerCase().includes(event.target.value.toLowerCase()) ||
        item.centroCusto
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        item.passageiro
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        String(item.antecedencia)
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    setRelatoriosFiltro(filtro);
  };

  const onClickPesquisar = async () => {
    setLoaderVisible(true);
    let dtoDadosRelatorio = {
      sessionId: Cookies.get("sessionId"),
      dataInicio: dataInicio,
      dataFim: dataFinal,
      idCentroCusto: centroCusto ? centroCusto.value : 0,
      idViajante: pessoa ? pessoa.value : 0,
    };
    const listaRelatorio = await tempoAntecedenciaRepositorio.get(
      dtoDadosRelatorio
    );
    preencherRelatorio(listaRelatorio.data);
    setLoaderVisible(false);
  };

  const preencherRelatorio = (listaRelatorio) => {
    let dentroPrazo = listaRelatorio.filter((item) => item.prazo);
    let foraPrazo = listaRelatorio.filter((item) => !item.prazo);

    setRelatorios(listaRelatorio);
    setRelatoriosFiltro(listaRelatorio);

    let diasCompras =
      listaRelatorio.reduce((total, item) => {
        return total + item.antecedencia || 0;
      }, 0) / listaRelatorio.length || 0;

    setComprasDias(Math.round(diasCompras));

    setComprasValor(
      listaRelatorio
        .reduce((total, item) => {
          return total + item.consolidado || 0;
        }, 0)
        .toFixed(2)
    );

    setComprasEmitidas(listaRelatorio.length || 0);

    let diasNoPrazo =
      dentroPrazo.reduce((total, item) => {
        return total + item.antecedencia || 0;
      }, 0) / dentroPrazo.length || 0;

    setNoPrazoDias(Math.round(diasNoPrazo));

    setNoPrazoValor(
      dentroPrazo
        .reduce((total, item) => {
          return total + item.consolidado || 0;
        }, 0)
        .toFixed(2)
    );

    setNoPrazoEmitidas(dentroPrazo.length || 0);

    let diasForaPrazo =
      foraPrazo.reduce((total, item) => {
        return total + item.antecedencia || 0;
      }, 0) / foraPrazo.length || 0;

    setForaPrazoDias(Math.round(diasForaPrazo));

    setForaPrazoValor(
      foraPrazo
        .reduce((total, item) => {
          return total + item.perda || 0;
        }, 0)
        .toFixed(2)
    );

    setForaPrazoEmitidas(foraPrazo.length || 0);
  };

  useEffect(() => {
    setColunasExcel([
      "ID",
      "Descrição",
      "Solicitante",
      "Antecedência",
      "Emissão",
      "Consolidado",
      "Perda",
      "Passageiro",
      "Centro de Custo",
    ]);

    const datas = relatoriosFiltro.map((item) => {
      return [
        item.idreserva,
        item.assunto,
        item.solicitante,
        item.antecedencia,
        new Date(item.dataEmissao).toLocaleDateString(),
        "R$" + String(item.consolidado.toFixed(2)).replace(".", ","),
        "- R$" + String(item.perda.toFixed(2)).replace(".", ","),
        item.passageiro,
        item.centroCusto,
      ];
    });

    setDataExcel(datas);
  }, [relatoriosFiltro]);

  useEffect(() => {
    if (periodo) {
      let inicio = new Date();
      let fim = new Date();

      switch (periodo) {
        case 1:
          inicio.setDate(inicio.getDate() - 7);
          break;
        case 2:
          inicio = new Date(inicio.getFullYear(), inicio.getMonth(), 1);
          fim = new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0);
          break;
        case 3:
          inicio = new Date(inicio.getFullYear(), 0, 1);
          fim = new Date(inicio.getFullYear(), 11, 31);
          break;
        default:
          console.warn(`Esperado periodo`)
          break;
      }

      setDataInicio(inicio);
      setDataFinal(fim);
    }
  }, [periodo]);

  useEffect(() => {
    (async () => {
      setLoaderVisible(true);

      const relatorioPessoal = await permissaoRepositorio.temPermissao(
        Enums.moduloSistema.relatorios,
        Enums.acaoRequerida.relatoriopessoal
      );
      const relatorioCentroCusto = await permissaoRepositorio.temPermissao(
        Enums.moduloSistema.relatorios,
        Enums.acaoRequerida.relatoriocentrocusto
      );

      setVerViajante(relatorioPessoal.data);
      setVerCentroCusto(relatorioCentroCusto.data);

      const listaPessoa = await pessoaRepositorio.getSearchField();
      const listaCentroCusto = await centroCustoRepositorio.getSearchField();
      setPessoas(listaPessoa);
      setCentrosCusto(listaCentroCusto);

      let agora = new Date();
      let depois = new Date();

      setDataInicio(agora);
      setDataFinal(depois);

      const dtoDadosRelatorio = {
        sessionId: Cookies.get("sessionId"),
        dataInicio: agora,
        dataFim: depois,
        idCentroCusto: 1,
        idViajante: 1,
      };

      const listaRelatorio = await tempoAntecedenciaRepositorio.get(
        dtoDadosRelatorio
      );

      preencherRelatorio(listaRelatorio.data);
      setLoaderVisible(false);
      setShowGrow(true);
    })();
  }, []);

  return (
    <div className={classes.expansion}>
      <ULoaderVoo isLoading={loaderVisible} />
      <Grow in={showGrow} timeout={500}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={fullscreen ? 12 : 2} style={fullscreen ? { margin: "10px", marginLeft: "20px" } : { margin: "10px", marginLeft: "40px", marginRight: "-20px" }}>
              <Typography variant="h6" component="h1">
                Fuga De Capital
              </Typography>
            </Grid>
            {!matches && !matches2 && !matches3 ? (
              <Grid
                item
                xs={8}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={4} className={classes.groupButton}>
                  <ButtonGroup>
                    <Button
                      style={{
                        background: periodo === 1 ? "#c62828" : "transparent",
                        color: periodo === 1 ? "white" : "#c62828",
                        border: "1px solid #c62828",
                        "&:hover": {
                          background: "#c62828",
                          color: "white",
                        },
                      }}
                      onClick={() => setPeriodo(1)}
                    >
                      <Typography style={{ fontSize: 11 }}>Semanal</Typography>
                    </Button>
                    <Button
                      style={{
                        background: periodo === 2 ? "#c62828" : "transparent",
                        color: periodo === 2 ? "white" : "#c62828",
                        border: "1px solid #c62828",
                        "&:hover": {
                          background: "#c62828",
                          color: "white",
                        },
                      }}
                      onClick={() => setPeriodo(2)}
                    >
                      <Typography style={{ fontSize: 11 }}>Mensal</Typography>
                    </Button>
                    <Button
                      style={{
                        background: periodo === 3 ? "#c62828" : "transparent",
                        color: periodo === 3 ? "white" : "#c62828",
                        border: "1px solid #c62828",
                        "&:hover": {
                          background: "#c62828",
                          color: "white",
                        },
                      }}
                      onClick={() => setPeriodo(3)}
                    >
                      <Typography style={{ fontSize: 11 }}>Anual</Typography>
                    </Button>
                    <Button
                      style={{
                        background: periodo === 4 ? "#c62828" : "transparent",
                        color: periodo === 4 ? "white" : "#c62828",
                        border: "1px solid #c62828",
                        "&:hover": {
                          background: "#c62828",
                          color: "white",
                        },
                      }}
                      onClick={() => setPeriodo(4)}
                    >
                      <Typography style={{ fontSize: 11 }}>
                        Personalizado
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          <div className={classes.lista2}>
            <Paper elevation={5} style={{ padding: 15 }}>
              <Grid
                container
                item
                spacing={2}
                xs={12}
                style={{
                  margin: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={fullscreen ? 12 : 2}>
                  <UDatePicker
                    {...{
                      date: dataInicio,
                      setDate: setDataInicio,
                      label: "Data Inicio",
                    }}
                  />
                </Grid>
                <Grid item xs={fullscreen ? 12 : 2}>
                  <UDatePicker
                    {...{
                      date: dataFinal,
                      setDate: setDataFinal,
                      label: "Data Final",
                    }}
                  />
                </Grid>

                {verCentroCusto && politicaViagem.utilizarcentrocusto ? (
                  <Grid item xs={fullscreen ? 12 : 4}>
                    <USearchField
                      {...{
                        itens: centrosCusto,
                        value: centroCusto,
                        setValue: setCentroCusto,
                        label: "Centro de Custo",
                      }}
                    />
                  </Grid>
                ) : null}
                {verViajante ? (
                  <Grid
                    item
                    xs={
                      politicaViagem.utilizarcentrocusto
                        ? fullscreen
                          ? 12
                          : 3
                        : fullscreen
                          ? 12
                          : 6
                    }
                  >
                    <USearchField
                      {...{
                        itens: pessoas,
                        value: pessoa,
                        setValue: setPessoa,
                        label: "Viajante",
                      }}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={fullscreen ? 12 : 1}>
                  <Button onClick={onClickPesquisar} className={classes.button}>
                    Pesquisar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
      </Grow>
      <Grow in={showGrow} timeout={1000}>
        <div className={classes.cards}>
          <List className={classes.listCard}>
            <ListItem>
              <Card className={classes.card}>
                <CardMedia
                  style={{
                    height: 30,
                    minHeight: 30,
                    background: "#2196f3",
                  }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{
                      color: "#2196f3",
                    }}
                  >
                    <b> Compras</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Antecedência Média: {comprasDias} dias
                    <br></br>
                    Valor Comprado: R${Number(comprasValor).toFormatDefault()}
                    <br></br>
                    Emissões: {comprasEmitidas}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={onClickFiltrarCompras}
                    style={{
                      background: "#2196f3",
                      color: "white",
                    }}
                  >
                    Filtrar
                  </Button>
                </CardActions>
              </Card>
            </ListItem>
            <ListItem>
              <Card className={classes.card}>
                <CardMedia
                  style={{
                    height: 30,
                    minHeight: 30,
                    background: "#43a047",
                  }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{
                      color: "#43a047",
                    }}
                  >
                    <b> Dentro do Prazo</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Antecedência Média: {noPrazoDias} dias
                    <br></br>
                    Valor Comprado: R${Number(noPrazoValor).toFormatDefault()}
                    <br />
                    Emissões: {noPrazoEmitidas}
                    <br />
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={onClickFiltrarDentroPrazo}
                    style={{
                      background: "#43a047",
                      color: "white",
                    }}
                  >
                    Filtrar
                  </Button>
                </CardActions>
              </Card>
            </ListItem>
            <ListItem>
              <Card className={classes.card}>
                <CardMedia
                  style={{
                    height: 30,
                    minHeight: 30,
                    background: "#b91119",
                  }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{
                      color: "#b91119",
                    }}
                  >
                    <b>Fora do Prazo</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Antecedência Média: {foraPrazoDias} dias
                    <br></br>
                    Valor Comprado: R$
                    {Number(comprasValor - noPrazoValor).toFormatDefault()}
                    <br></br>
                    Valor Perdido: R${Number(foraPrazoValor).toFormatDefault()}
                    <br></br>
                    Emissões: {foraPrazoEmitidas}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={onClickFiltrarForaPrazo}
                    style={{
                      background: "#b91119",
                      color: "white",
                    }}
                  >
                    Filtrar
                  </Button>
                </CardActions>
              </Card>
            </ListItem>
          </List>
        </div>
      </Grow>
      {relatoriosPag.length > 0 ? (
        <Grow in={showGrow} timeout={1500}>
          <div className={classes.lista}>
            <Paper>
              <Toolbar>
                {!matches && !matches2 ? (
                  <Grid container spacing={1} className={classes.separar}>
                    {!matches && !matches2 && !matches3 ? (
                      <>
                        <Grid item xs={4}>
                          <div className={classes.botoes}>
                            <div className={classes.botao}>
                              <ExportExcel
                                name="fugaDeCapital"
                                columns={colunasExcel}
                                data={dataExcel}
                                element={
                                  <Button className={classes.botao3}>
                                    Excel
                                  </Button>
                                }
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <TablePagination
                            style={{ minWidth: "max-content" }}
                            labelRowsPerPage=""
                            component="div"
                            count={pages}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[10, 25, 50]}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <div className={classes.search3}>
                            <div className={classes.searchIcon}>
                              <Search />
                            </div>
                            <InputBase
                              onChange={handlePesquisarChanged}
                              placeholder="Pesquise…"
                              classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                              }}
                              inputProps={{ "aria-label": "search" }}
                            />
                          </div>
                        </Grid>
                      </>
                    ) : (
                      <div className={classes.separar}>
                        <div className={classes.botoes}>
                          <div className={classes.botao}>
                            <ExportExcel
                              name="fugaDeCapital"
                              columns={colunasExcel}
                              data={dataExcel}
                              element={
                                <Button className={classes.botao3}>
                                  Excel
                                </Button>
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <TablePagination
                            style={{ minWidth: "max-content" }}
                            labelRowsPerPage=""
                            component="div"
                            count={pages}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[10, 25, 50]}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                        <div className={classes.search3}>
                          <div className={classes.searchIcon}>
                            <Search />
                          </div>
                          <InputBase
                            onChange={handlePesquisarChanged}
                            placeholder="Pesquise…"
                            classes={{
                              root: classes.inputRoot,
                              input: classes.inputInput,
                            }}
                            inputProps={{ "aria-label": "search" }}
                          />
                        </div>
                      </div>
                    )}
                  </Grid>
                ) : (
                  <Grid
                    container
                    style={{
                      margin: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    item
                    xs={12}
                  >
                    <Grid item xs={11}>
                      <LinesTextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        placeholder="Pesquisar..."
                        onChange={handlePesquisarChanged}
                      />
                    </Grid>

                    <Grid item xs={1}>
                      <div>
                        <IconButton onClick={handleClickMenuMore}>
                          <MoreVertOutlined />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenuMore}
                        >
                          <ExportExcel
                            name="fugaDeCapital"
                            columns={colunasExcel}
                            data={dataExcel}
                            element={<MenuItem>Exportar Excel</MenuItem>}
                          />
                        </Menu>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <TablePagination
                        style={{ minWidth: "max-content" }}
                        labelRowsPerPage=""
                        component="div"
                        count={pages}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[10, 25, 50]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Grid>
                  </Grid>
                )}
              </Toolbar>
              {!matches && !matches2 && !matches3 ? (
                <div className={classes.dados}>
                  <TableContainer style={{ minHeight: 500, maxHeight: 500 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.heads} align="center">
                            ID
                          </TableCell>
                          <TableCell className={classes.heads} align="center">
                            Descrição
                          </TableCell>
                          <TableCell className={classes.heads} align="center">
                            Solicitante
                          </TableCell>
                          <TableCell className={classes.heads} align="center">
                            Antecedência
                          </TableCell>
                          <TableCell className={classes.heads} align="center">
                            Emissão
                          </TableCell>
                          <TableCell className={classes.heads} align="center">
                            Consolidado
                          </TableCell>
                          <TableCell className={classes.heads} align="center">
                            Perda
                          </TableCell>
                          <TableCell className={classes.heads} align="center">
                            Passageiro
                          </TableCell>
                          {politicaViagem.utilizarcentrocusto ? (
                            <TableCell className={classes.heads} align="center">
                              Centro De Custo
                            </TableCell>
                          ) : null}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {relatoriosFiltro.map((item) => (
                          <TableRow>
                            <TableCell className={classes.heads} align="center">
                              {item.idreserva}
                            </TableCell>
                            <TableCell className={classes.heads} align="center">
                              {definirAssunto(item.assunto)}
                            </TableCell>
                            <TableCell className={classes.heads} align="center">
                              {item.solicitante}
                            </TableCell>
                            <TableCell className={classes.heads} align="center">
                              {item.antecedencia}
                            </TableCell>
                            <TableCell className={classes.heads} align="center">
                              {new Date(item.dataEmissao).toLocaleDateString()}
                            </TableCell>
                            <TableCell className={classes.heads} align="center">
                              {"R$" +
                                Number(item.consolidado).toFormatDefault()}
                            </TableCell>
                            <TableCell className={classes.heads} align="center">
                              {"- R$" + Number(item.perda).toFormatDefault()}
                            </TableCell>
                            <TableCell className={classes.heads} align="center">
                              {item.passageiro}
                            </TableCell>
                            {politicaViagem.utilizarcentrocusto ? (
                              <TableCell
                                className={classes.heads}
                                align="center"
                              >
                                {item.centroCusto}
                              </TableCell>
                            ) : null}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div>
                  {relatoriosFiltro.map((item) => (
                    <Grid
                      container
                      spacing={2}
                      style={{ margin: 0 }}
                      item
                      xs={12}
                    >
                      <Grid item xs={12}>
                        <Paper>
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={1}
                            style={{
                              margin: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <SubjectOutlined fontSize="small" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography noWrap style={{ fontSize: 12 }}>
                                {definirAssunto(item.assunto)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <AirlineSeatReclineNormalOutlined fontSize="small" />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography noWrap style={{ fontSize: 12 }}>
                                {item.passageiro}
                              </Typography>
                            </Grid>
                            {politicaViagem.utilizarcentrocusto ? (
                              <React.Fragment>
                                <Grid
                                  item
                                  xs={2}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <AssignmentTurnedInOutlined fontSize="small" />
                                </Grid>
                                <Grid item xs={10}>
                                  <Typography noWrap style={{ fontSize: 12 }}>
                                    {item.centroCusto}
                                  </Typography>
                                </Grid>
                              </React.Fragment>
                            ) : null}
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CalendarTodayOutlined fontSize="small" />
                            </Grid>
                            <Grid item xs={4}>
                              <Typography noWrap style={{ fontSize: 12 }}>
                                {new Date(
                                  item.dataEmissao
                                ).toLocaleDateString()}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <AttachMoneyOutlined fontSize="small" />
                            </Grid>
                            <Grid item xs={4}>
                              <Typography noWrap style={{ fontSize: 12 }}>
                                {"R$" +
                                  Number(item.consolidado).toFormatDefault()}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <MoneyOffOutlined
                                fontSize="small"
                                style={{ color: "red" }}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                noWrap
                                style={{ fontSize: 12, color: "red" }}
                              >
                                {"R$" + Number(item.perda).toFormatDefault()}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              )}
            </Paper>
          </div>
        </Grow>
      ) : null}
    </div>
  );
};

export const Lista = (props) => {
  return (
    <div>
      <Dashboard {...props} component={ListaTempoAntecedencia} />
    </div>
  );
};
export default Lista;
