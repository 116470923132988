import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, Container, Grid, Hidden, Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { ExpandMoreOutlined, GetApp, SearchOutlined } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomizedSnackbars from "../../../components/snackbar";
import UDatePicker from '../../../components/udatepicker';
import ULoaderVoo from "../../../components/uloadervoo";
import USearchField from '../../../components/usearchfield';
import centroCustoRepositorio from "../../../repositorios/centroCusto";
import ecogestaoRepositorio from "../../../repositorios/ecogestao";
import pessoaRepositorio from "../../../repositorios/pessoa";
import Dashboard from "../../dashboard";
import { RelatorioDesktopEcoGestao } from "./relatorioDesktop";
import { RelatorioMobileEcoGestao } from "./relatorioMobile";
import { useStyles } from "./styles";
import { RelatorioChartEcoGestao } from "./relatorioChart";
import ExportExcel from "../../../components/UExportExcel";

function isValidDate(date) {
    return moment(date).isValid();
}

const opcoesFiltros = [
    { label: "Translado", value: 1 },
    { label: "Aéreo", value: 2 }
];

export const EcoGestaoRelatorio = () => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down("sm"));
    const [snackVariant, setSnackVariant] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [dataInicioPesquisa, setDataInicioPesquisa] = useState(new Date());
    const [dataFimPequisa, setDataFimPequisa] = useState(new Date());
    const permissaoDashboard = useSelector((state) => state.permissao.permissaoDashboard);
    const politicaViagem = useSelector((state) => state.permissao.politicaViagem);
    const [dataPesquisa, setDataPesquisa] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [centroCusto, setCentroCusto] = useState(null);
    const [viajante, setViajante] = useState(null);
    const [viajantes, setViajantes] = useState([]);
    const [periodo, setPeriodo] = useState();
    const [opcoesMotoristas, setOpcoesMotoristas] = useState([]);
    const [opcaoMotorista, setOpcaoMotorista] = useState({});
    const [colunasExcel, setColunasExcel] = useState([]);
    const [rowsExcel, setRowsExcel] = useState([]);
    
    const [opcaoFiltro, setOpcaoFiltro] = useState({});
    const [totaisRelatorio, setTotaisRelatorio] = useState({});
    const [co2dto, setCo2dto] = useState([
        {
            name: "Translado",
            y: 0,
            color: "#9ACD32"
        },
        {
            name: "Emitido aéreo",
            y: 0,
            color: "#FFA500"
        }, 
        {
            name: "Preservado",
            y: 0,
            color: "#00BFFF"
        }
    ])

    const handleClickSearch = async () => {
        if (!isValidDate(dataInicioPesquisa) || !isValidDate(dataFimPequisa)) {
            setSnackVariant('error');
            setSnackMessage('Data Inválida.');
            setSnackVisible({ visible: true });
            return;
        }

        if (dataInicioPesquisa > dataFimPequisa) {
            setSnackVariant('error');
            setSnackMessage('Data de início maior que data final.');
            setSnackVisible({ visible: true })
            return;
        }

        let dto = {
            dataInicio: dataInicioPesquisa,
            dataFim: dataFimPequisa,
            idCentroCusto: centroCusto ? centroCusto.value : 0,
            idViajante: viajante ? viajante.value : 0,
        };

        let filtro = {
            idmotorista: opcaoMotorista === null ? 0 : opcaoMotorista.idmotorista,
            tipo: opcaoFiltro ? opcaoFiltro.value : undefined
        };

        try {
            setIsLoading(true);
            const response = await ecogestaoRepositorio.getRelatorio(dto, filtro);
            setDataPesquisa(response.data.passageiros);
            setTotaisRelatorio(response.data.total);
            setCo2dto([
                {
                    name: "Translado",
                    y: response.data.total.totalEmitidoTranslado,
                    color: "#9ACD32"
                },
                {
                    name: "Emitido aéreo",
                    y: response.data.total.totalEmitidoAereo,
                    color: "#FFA500"
                },
                {
                    name: "Preservado",
                    y: response.data.total.totalPreservado,
                    color: "#00BFFF"
                }
            ]);
        } catch (error) {
            setSnackVariant("error");
            setSnackMessage("Falha ao realizar pesquisa.");
            setSnackVisible({ visible: true });
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeData = () => {
        let inicio = new Date();
        if (isValidDate(dataInicioPesquisa) && isValidDate(dataFimPequisa)) {
            if (dataInicioPesquisa.toLocaleDateString() === new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString() &&
                dataFimPequisa.toLocaleDateString() === new Date().toLocaleDateString()
            ) { return 1; }
            if (
                dataInicioPesquisa.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth(), 1).toLocaleDateString() &&
                dataFimPequisa.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0).toLocaleDateString()
            ) { return 2; }
            if (dataInicioPesquisa.toLocaleDateString() === new Date(inicio.getFullYear(), 0, 1).toLocaleDateString() &&
                dataFimPequisa.toLocaleDateString() === new Date(inicio.getFullYear(), 11, 31).toLocaleDateString()
            ) { return 3; }
            return 4;
        }
    };

    useEffect(() => {
        if (periodo) {
            let inicio = new Date();
            let fim = new Date();
            switch (periodo) {
                case 1:
                    inicio.setDate(inicio.getDate() - 7);
                    break;
                case 2:
                    inicio = new Date(inicio.getFullYear(), inicio.getMonth(), 1);
                    fim = new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0);
                    break;
                case 3:
                    inicio = new Date(inicio.getFullYear(), 0, 1);
                    fim = new Date(inicio.getFullYear(), 11, 31);
                    break;
                default:
                    console.warn('Esperado periodo')
                    break;
            }
            setDataInicioPesquisa(inicio);
            setDataFimPequisa(fim);
        }
    }, [periodo]);

    useEffect(() => {
        (async () => {
            const responseCentroCustos = await centroCustoRepositorio.getSearchField();
            const responseViajantes = await pessoaRepositorio.getSearchField();
            const responseMotoristas = await ecogestaoRepositorio.getSearchFieldMotoristas();
            setCentrosCusto(responseCentroCustos);
            setViajantes(responseViajantes);
            setOpcoesMotoristas(responseMotoristas);
        })();
    }, []);

    useEffect(() => {
        setColunasExcel(['Passageiro', 'Localizador', 'Trecho', 'Sentido', 'Data de criação', 'CO² Inicial', "CO² Emitido", "Eco", "tipo"]);
        const datas = dataPesquisa?.map(item => {
            return [
                item.passageiro,
                item.loc,
                item.trecho,
                item.sentido,
                item.datavoo,
                item.emissaoInicial,
                item.emissao,
                item.eco,
                item.tipo,
            ]
        })
        setRowsExcel(datas);
    }, [dataPesquisa])

    return (
        <section>
            <Hidden smUp>
                <ULoaderVoo isLoading={isLoading} />
            </Hidden>
            <CustomizedSnackbars
                {...{
                    variant: snackVariant,
                    message: snackMessage,
                    visible: snackVisible.visible,
                    setSnackbar: setSnackVisible,
                }}
            />
            <Container maxWidth="xl">
                <Box mt={2} mb={responsive ? 10 : 0}>
                    <Box mb={1}>
                        <Grid container>
                            <Grid item xs={responsive ? 12 : 2}>
                                <Typography variant="h6" component="h1">
                                    Eco Gestão
                                </Typography>
                            </Grid>
                            {responsive ? null : (
                                <>
                                    <Grid item xs={8}>
                                        <Grid item xs={12} className={classes.groupButton}>
                                            <ButtonGroup>
                                                <Button style={{
                                                    background: handleChangeData() === 1 ? "#c62828" : "transparent",
                                                    color: handleChangeData() === 1 ? "white" : "#c62828",
                                                    border: "1px solid #c62828",
                                                    "&:hover": {
                                                        background: "#c62828",
                                                        color: "white",
                                                    },
                                                }}
                                                    onClick={() => setPeriodo(1)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>
                                                        Semanal
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    style={{
                                                        background:
                                                            handleChangeData() === 2
                                                                ? "#c62828"
                                                                : "transparent",
                                                        color:
                                                            handleChangeData() === 2 ? "white" : "#c62828",
                                                        border: "1px solid #c62828",
                                                        "&:hover": {
                                                            background: "#c62828",
                                                            color: "white",
                                                        },
                                                    }}
                                                    onClick={() => setPeriodo(2)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>
                                                        Mensal
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    style={{
                                                        background:
                                                            handleChangeData() === 3
                                                                ? "#c62828"
                                                                : "transparent",
                                                        color:
                                                            handleChangeData() === 3 ? "white" : "#c62828",
                                                        border: "1px solid #c62828",
                                                        "&:hover": {
                                                            background: "#c62828",
                                                            color: "white",
                                                        },
                                                    }}
                                                    onClick={() => setPeriodo(3)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>
                                                        Anual
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    style={{
                                                        background:
                                                            handleChangeData() === 4
                                                                ? "#c62828"
                                                                : "transparent",
                                                        color:
                                                            handleChangeData() === 4 ? "white" : "#c62828",
                                                        border: "1px solid #c62828",
                                                        "&:hover": {
                                                            background: "#c62828",
                                                            color: "white",
                                                        },
                                                    }}
                                                    onClick={() => setPeriodo(4)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>
                                                        Personalizado
                                                    </Typography>
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                    <Paper elevation={3}>
                        <Box p={responsive ? 1 : 3}>
                            <form autoComplete="off">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={2}>
                                        <UDatePicker
                                            date={dataInicioPesquisa}
                                            setDate={setDataInicioPesquisa}
                                            label="Data Início"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <UDatePicker
                                            date={dataFimPequisa}
                                            setDate={setDataFimPequisa}
                                            label="Data Fim"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        {permissaoDashboard.relatorioCentroCusto ? (
                                            <USearchField
                                                disabled={!politicaViagem.utilizarcentrocusto}
                                                label="Centro de custo"
                                                itens={centrosCusto}
                                                value={centroCusto}
                                                setValue={setCentroCusto}
                                            />
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        {permissaoDashboard.relatorioPessoal ? (
                                            <USearchField
                                                label="Viajante"
                                                itens={viajantes}
                                                value={viajante}
                                                setValue={setViajante}
                                            />
                                        ) : null}
                                    </Grid>
                                    {responsive ? null : (
                                        <Grid item xs={12} md={1} style={{ alignSelf: "center" }}>
                                            <Button
                                                onClick={handleClickSearch}
                                                className={classes.button}
                                                variant="contained"
                                                fullWidth
                                            >
                                                <SearchOutlined />
                                            </Button>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} md={3}>
                                        <USearchField label="Motorista" itens={opcoesMotoristas} value={opcaoMotorista} setValue={setOpcaoMotorista} />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <USearchField label="Filtrar por" itens={opcoesFiltros} value={opcaoFiltro} setValue={setOpcaoFiltro} />
                                    </Grid>

                                </Grid>
                                <Grid container spacing={1}>
                                    {responsive ? (
                                        <Button
                                            onClick={handleClickSearch}
                                            className={classes.button}
                                            variant="contained"
                                            fullWidth
                                        >
                                            <SearchOutlined />
                                        </Button>
                                    ) : null}
                                </Grid>
                            </form>
                        </Box>
                    </Paper>
                    {dataPesquisa.length !== 0 ? (

                        <Paper elevation={1} style={{ marginTop: '10px' }}>
                            <Accordion square>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreOutlined />}
                                    id="relatorio_grafico"
                                >
                                    <Typography className={classes.heading}>
                                        Gráfico - Conservação CO²
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RelatorioChartEcoGestao {...{ co2dto, totaisRelatorio }} />
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                    ) : null}

                    <Box mt={2}>
                        {responsive ? (
                            <RelatorioMobileEcoGestao
                                {...{ dataPesquisa, isLoading }}
                            />
                        ) : (
                            <Paper elevation={3}>
                                <Grid style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'right',
                                    padding: "10px"
                                }}>
                                    <ExportExcel name="Relatório da Economia Emissão IA" columns={colunasExcel} data={rowsExcel} element={
                                        <>
                                            <Button className={classes.button}>
                                                <GetApp />
                                                Exportar
                                            </Button>
                                        </>
                                    } />
                                </Grid>
                                <RelatorioDesktopEcoGestao
                                    {...{ dataPesquisa, isLoading }}
                                />
                            </Paper>
                        )}
                    </Box>
                </Box>
            </Container>
        </section>
    );
};

export const ListaEcoGestao = (props) => {
    return (
        <div>
            <Dashboard {...props} component={EcoGestaoRelatorio} />
        </div>
    );
};
export default ListaEcoGestao;
