import React, { useEffect, useState } from 'react';
import pessoaRepositorio from '../../../../repositorios/pessoa';
import { Button, Grid, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import USearchField from '../../../../components/usearchfield';
import { AddCircleOutline, DeleteOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import validatorCPF from '../../../../utils/cpfValidator';
import centroCustoRepositorio from '../../../../repositorios/centroCusto';
import { useStyles } from './styles';
import "../../../../fonts/fonts.css"
import { AdicionarNovaPessoa } from '../Pessoas'; 
import ULoaderVoo from '../../../../components/uloadervoo';

const RoomOccupants = (props) => {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const politicaViagem = useSelector(state => state.permissao.politicaViagem);
    const [occupants, setOccupants] = useState([{ name: '', age: '' }]);
    const [indexPassageiro, setIndexPassageiro] = useState(null);
    const [passageiro, setPassageiro] = useState({});
    const [pessoas, setPessoas] = useState(null);
    const [pessoa, setPessoa] = useState(null);
    const [crianca, setCrianca] = useState(null);
    const [criancas, setCriancas] = useState([]);
    const [centrosdecusto, setCentrosDeCusto] = useState(null);
    const [centrodecusto, setCentroDeCusto] = useState(null);
    const [centrodecustokid, setCentroDeCustoKid] = useState(null);
    const [hospedes, setHospedes] = useState([])
    const [hospedesCriancas, setHospedesCriancas] = useState([])
    const [listHospedes, setListHospedes] = useState([])
    const [limit, setLimit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [desabledButton, setDisabledButton] = useState(false)
    const [tipoSnack, setTipoSnack] = useState('success');
    const [mensagemSnack, setMensagemSnack] = useState('');
    const [snackbarSucess, setSnackbarSucess] = useState({
        visible: false
    });

    const rows = hospedes.map((item, index) => ({
        id: item.pessoa.pessoa.idpessoa,
        nome: item.pessoa.pessoa.nome,
        sobrenome: item.pessoa.pessoa.sobrenome,
        rg: item.pessoa.pessoa.rg,
        index: index,
        centrodecusto: item.centroCusto.label
        // centroCusto: item.centroCusto.label
        // outras propriedades conforme necessário...
    }));

    const rows2 = hospedesCriancas.map((item, index) => ({
        id: item.pessoa.pessoa.idpessoa,
        nome: item.pessoa.pessoa.nome,
        sobrenome: item.pessoa.pessoa.sobrenome,
        rg: item.pessoa.pessoa.rg,
        index: index,
        centrodecusto: item.centroCusto.label
        // centroCusto: item.centroCusto.label
        // outras propriedades conforme necessário...
    }));

    const handleClickRemoverHospede = (index) => {
        let newPaxForRooms = props.paxsForRooms;
        if (newPaxForRooms.length !== 0) {
            newPaxForRooms.forEach((paxs) => {
                // Encontre o índice do hóspede a ser removido dentro de cada paxs.hospedes

                let retornar = pessoas;

                // setPessoas(retornar.push(hospedes[index].pessoa))
                retornar.push(hospedes[index].pessoa)

                setPessoas(retornar)
                const hospedeIndexToRemove = paxs.hospedes.findIndex(hospede => hospedes[index].pessoa.label === hospede.pessoa.label);

                // Remova o hóspede se ele for encontrado
                if (hospedeIndexToRemove !== -1) {
                    paxs.hospedes.splice(hospedeIndexToRemove, 1);
                }
            });
        }
        props.setPaxsForRooms(newPaxForRooms)

        // Atualize a array hospedes removendo o hóspede no índice especificado
        setHospedes(hospedes.filter((_, i) => i !== index));
    };

    const handleClickRemoverHospedeCrianca = (index) => {
        let newPaxForRooms = props.paxsForRooms;
        if (newPaxForRooms.length !== 0) {
            newPaxForRooms.forEach((paxs) => {
                // Encontre o índice do hóspede a ser removido dentro de cada paxs.hospedes

                let retornar = criancas;

                // setPessoas(retornar.push(hospedes[index].pessoa))
                retornar.push(hospedesCriancas[index].pessoa)

                setCriancas(retornar)

                const hospedeIndexToRemove = paxs.hospedes.findIndex(hospede => hospedesCriancas[index].pessoa.label == hospede.pessoa.label);

                // Remova o hóspede se ele for encontrado
                if (hospedeIndexToRemove !== -1) {
                    paxs.hospedes.splice(hospedeIndexToRemove, 1);
                }
            });
        }
        props.setPaxsForRooms(newPaxForRooms)

        // Atualize a array hospedes removendo o hóspede no índice especificado
        setHospedesCriancas(hospedesCriancas.filter((_, i) => i !== index));
    };

    const getCentroDeCusto = async () => {
        setLoading(true)
        try {
            await centroCustoRepositorio.getSearchField().then(response => setCentrosDeCusto(response));
        } catch {
        } finally {
            setLoading(false)
        }
    }

    // Função para calcular a idade com base na data de nascimento
    function calcularIdade(dataNascimento) {
        const hoje = new Date();
        const dataNascimentoObj = new Date(dataNascimento);
        let idade = hoje.getFullYear() - dataNascimentoObj.getFullYear();
        const mesAtual = hoje.getMonth();
        const diaAtual = hoje.getDate();

        // Verifica se ainda não fez aniversário este ano
        if (mesAtual < dataNascimentoObj.getMonth() || (mesAtual === dataNascimentoObj.getMonth() && diaAtual < dataNascimentoObj.getDate())) {
            idade--;
        }

        return idade;
    }

    // Exemplo de utilizaçã

    const getPessoas = async () => {
        setLoading(true);
        try {
            let responseViajantes = await pessoaRepositorio.getSearchField();
            // Verifica a idade de cada pessoa e decide se deve ser inserida em setCriancas()
            let kids = [];
            responseViajantes.forEach(pessoa => {
                const idade = calcularIdade(pessoa.pessoa.datanascimento);
                if (idade < 17) {
                    kids.push(pessoa)
                }
            });

            setCriancas(kids);

            setPessoas(responseViajantes);
        } catch {
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (hospedes.length === 0) {
            return;
        }

        let hospedesEQuartos = {
            quarto: props.valueRoom,
            hospedes: []
        };

        // Adiciona os elementos da primeira array
        if (hospedes.length > 0) {
            hospedesEQuartos.hospedes = hospedes.slice(); // copia os elementos da array hospedes
        }

        // Adiciona os elementos da segunda array, se não estiver vazia
        if (hospedesCriancas.length > 0) {
            hospedesEQuartos.hospedes = hospedesEQuartos.hospedes.concat(hospedesCriancas);
        }

        props.setPaxsForRooms(prevState => {
            // Procura por um índice de um objeto existente com o mesmo 'quarto'
            const existingIndex = prevState.findIndex(item => item.quarto === props.valueRoom);

            if (existingIndex !== -1) {
                // Se existir, substitui esse objeto
                const newState = [...prevState];
                newState[existingIndex] = hospedesEQuartos;
                return newState;
            } else {
                // Se não existir, adiciona um novo objeto
                return [...prevState, hospedesEQuartos];
            }
        });
    }, [hospedes, hospedesCriancas]);

    useEffect(() => {
        if (props.paxsForRooms.length != 0) {
            props.paxsForRooms.forEach((paxs) => {
                if (paxs.hospedes.length >= (props.stepperAdultos)) {
                } else {
                }

            })
        }
    }, [props.paxsForRooms, hospedes])

    // useEffect(() => {
    //     if (pessoa !== '' && centrodecusto !== '') {
    //         handleClickAdicionarHospede("adult");
    //     } else if (crianca !== '' && centrodecusto !== '') {
    //         handleClickAdicionarHospede("kid");
    //     }
    // }, [pessoa, centrodecusto, crianca]);

    // useEffect(() => {
    //     if (crianca !== '' && centrodecusto !== '') {
    //         handleClickAdicionarHospede("kid");
    //     }
    // }, [crianca, centrodecusto]);

    const addViajante = async (dto) => {
        const viajanteComQuarto = { ...dto, quarto: { label: "Nenhum quarto", value: 0 } };
        setHospedes(oldValues => [...oldValues, viajanteComQuarto]);
        setPessoa(null)
        setCentroDeCusto(null)
        setDisabledButton(false)
    }

    const addViajanteCrianca = async (dto) => {
        const viajanteComQuarto = { ...dto, quarto: { label: "Nenhum quarto", value: 0 } };
        setHospedesCriancas(oldValues => [...oldValues, viajanteComQuarto]);
        setCrianca(null)
        setCentroDeCustoKid(null)
        setDisabledButton(false)
    }

    const handleClickAdicionarHospede = async () => {
        setDisabledButton(true)
        let dto = {
            pessoa: pessoa,
            centroCusto: politicaViagem.utilizarcentrocusto ? centrodecusto : centrosdecusto[0]
        }

        const novaListaPessoas = pessoas.filter(p => p.value !== pessoa.value);
        // Atualiza o estado da lista de pessoas com a nova lista filtrada
        setPessoas(novaListaPessoas);

        if (!pessoa) {
            setMensagemSnack('Viajante não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            setDisabledButton(false)
            return;
        }

        if (!dto.centroCusto) {
            setMensagemSnack('Centro de Custo não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            setDisabledButton(false)
            return;
        }

        let validateData = true

        if (!validatorCPF(pessoa.pessoa.cpf) && pessoa.pessoa.nacionalidade === 1) {
            setMensagemSnack('CPF do(a) viajante está inválido.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            validateData = false
        }

        if (!pessoa.pessoa.datanascimento) {
            setMensagemSnack('A data de nascimento do(a) viajante está vazia.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            validateData = false
        }

        if (pessoa.pessoa.telefone === "(__)_____-____" || pessoa.pessoa.telefone === null || pessoa.pessoa.telefone === 'undefined' || pessoa.pessoa.telefone.trim() === "") {
            setMensagemSnack('O telefone do(a) viajante está vazio.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            validateData = false
        }

        try {
            let dtoSend = { idpassageiro: pessoa.pessoa.idpessoa }
            let response = await pessoaRepositorio.postVerificarCpf(dtoSend)
            if (response.status === 207) {
                setMensagemSnack(response.data.message);
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
                setDisabledButton(false)
                return
            }

            if (response.status === 202) {
                setMensagemSnack(response.data.message);
                setTipoSnack('info');
                setSnackbarSucess({ visible: true });
                setDisabledButton(false)
            }
        } catch (e) {
            setMensagemSnack('Falha ao verificar dados do(a) viajante.');
            setTipoSnack('error');
            setDisabledButton(false)
            setSnackbarSucess({ visible: true });
            return
        }

        addViajante(dto)
    }

    const handleClickAdicionarCrianca = async () => {
        setDisabledButton(true)
        let dto = {
            pessoa: crianca,
            centroCusto: politicaViagem.utilizarcentrocusto ? centrodecustokid : centrodecustokid[0]
        }

        const novaListaPessoas = criancas.filter(p => p.value !== crianca.value);
        // Atualiza o estado da lista de pessoas com a nova lista filtrada
        setCriancas(novaListaPessoas);

        if (!crianca) {
            setMensagemSnack('Viajante não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            setDisabledButton(false)
            return;
        }

        if (!dto.centroCusto) {
            setMensagemSnack('Centro de Custo não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            setDisabledButton(false)
            return;
        }

        let validateData = true

        if (!validatorCPF(crianca.pessoa.cpf) && crianca.pessoa.nacionalidade === 1) {
            setMensagemSnack('CPF do(a) viajante está inválido.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            validateData = false
        }

        if (!crianca.pessoa.datanascimento) {
            setMensagemSnack('A data de nascimento do(a) viajante está vazia.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            validateData = false
        }

        if (crianca.pessoa.telefone === "(__)_____-____" || crianca.pessoa.telefone === null || crianca.pessoa.telefone === 'undefined' || crianca.pessoa.telefone.trim() === "") {
            setMensagemSnack('O telefone do(a) viajante está vazio.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            validateData = false
        }

        try {
            let dtoSend = { idpassageiro: crianca.pessoa.idpessoa }
            let response = await pessoaRepositorio.postVerificarCpf(dtoSend)
            if (response.status === 207) {
                setMensagemSnack(response.data.message);
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
                setDisabledButton(false)
                return
            }

            if (response.status === 202) {
                setMensagemSnack(response.data.message);
                setTipoSnack('info');
                setSnackbarSucess({ visible: true });
                setDisabledButton(false)
            }
        } catch (e) {
            setMensagemSnack('Falha ao verificar dados do(a) viajante.');
            setTipoSnack('error');
            setDisabledButton(false)
            setSnackbarSucess({ visible: true });
            return
        }

        addViajanteCrianca(dto)
    }

    const handleOccupantChange = (index, key, value) => {
        const newOccupants = [...occupants];
        newOccupants[index][key] = value;
        setOccupants(newOccupants);
    };

    const addOccupant = () => {
        setOccupants([...occupants, { name: '', age: '' }]);
    };

    const removeOccupant = (index) => {
        const newOccupants = occupants.filter((_, occupantIndex) => occupantIndex !== index);
        setOccupants(newOccupants);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        alert(`Occupants for Room ${props.roomNumber}: ${JSON.stringify(occupants)}`);
    };

    useEffect(() => {
        getPessoas()
        getCentroDeCusto()
        {
            Array.from({ length: props.stepperAdultos + 1 }, (_, i) => (
                setListHospedes([...listHospedes, { pessoa: null, centrodecusto: null }])
            ))
        }
    }, [])

    return (
        <form>
            <ULoaderVoo isLoading={loading} />
            {occupants.map((occupant, index) => (
                <Grid item xs={12} container spacing={1} key={index} style={{ height: "auto" }}>
                    {responsive ? (
                        <>
                            {rows.map((value, i) => (
                                <>
                                    <Grid container item xs={12} style={{
                                        textAlign: "left", padding: "20px",
                                        width: '100%',
                                        height: 'auto',
                                        flexShrink: 0,
                                        fill: '#FFF',
                                        top: "40%",
                                        filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.0))',
                                        borderRadius: '10px',
                                        backgroundColor: "#FFFFFF",
                                        boxShadow: "0 0 0.5rem rgba(0,0,0,.102)",
                                        borderBottom: 'none',
                                    }}>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: "14px" }}>{i == 0 ? "Titular" : ""}</Typography>
                                            <Typography style={{}}>Hóspede {i + 1} - { }Adulto </Typography>
                                            <Typography style={{ fontFamily: "HindMedium" }}>{value.nome} {value.sobrenome} - {value.centrodecusto}</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ textAlign: "right" }}>
                                            <IconButton onClick={() => handleClickRemoverHospede(i)}>
                                                <DeleteOutlined />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ marginTop: "10px" }}>
                                        {i == 0 && rows.length > 1 ? (
                                            <Typography style={{ fontSize: "14px", fontWeight: "600" }}>{"Adulto - " + (i + 2)}</Typography>
                                        ) : (
                                            null
                                        )}
                                    </Grid>
                                </>
                            ))}
                            <>
                                {props.stepperAdultos > 1 ? (
                                    <>
                                        <Grid item xs={12} style={{ textAlign: "right", marginTop: "2px" }}>
                                            <Typography style={{ fontSize: "12px", fontWeight: "600" }}>Hóspede(s): {rows.length + rows2.length}/{props.stepperAdultos + props.stepperCriancas}</Typography>
                                        </Grid>
                                    </>
                                ) : (
                                    null
                                )}
                                {props.stepperAdultos > 0 && !(hospedes.length >= props.stepperAdultos) ? (
                                    <>
                                        <Grid item xs={12}>
                                            <USearchField label="Hóspede" itens={pessoas} value={pessoa} setValue={setPessoa} />
                                        </Grid>
                                        <Grid item xs={12} style={{ display: "flex" }}>
                                            <USearchField itens={centrosdecusto} value={centrodecusto} setValue={setCentroDeCusto} label="Centro de Custo" />
                                        </Grid>
                                        <Grid item xs={12} style={{ alignSelf: "center" }}>
                                            <Tooltip title="Adicionar Hóspede">
                                                <Button
                                                    fullWidth
                                                    disabled={desabledButton}
                                                    className={classes.button}
                                                    onClick={() => handleClickAdicionarHospede()}
                                                    startIcon={<AddCircleOutline style={{ marginLeft: "10px" }} />}
                                                >
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        <Grid container item xs={12} style={{
                                        }}>
                                            <AdicionarNovaPessoa {...{ passageiro, setPassageiro, index: indexPassageiro, setIndex: setIndexPassageiro }} />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                    </>
                                )}
                            </>
                            <hr style={{ width: "100%", backgroundColor: "#DFDFDF", height: "1px", border: "none" }}></hr>
                            {props.stepperCriancas > 0 ? (
                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                    <Typography style={{ fontWeight: "600", fontSize: "20px", marginLeft: "2px", marginBottom: "0px" }}>Crianças no quarto</Typography>
                                </Grid>
                            ) : (
                                null
                            )}
                            {rows2.map((value, i) => (
                                <Grid container item xs={12} style={{
                                    textAlign: "left",
                                    padding: "20px",
                                    width: '100%',
                                    height: 'auto',
                                    flexShrink: 0,
                                    fill: '#FFF',
                                    top: "40%",
                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.0))',
                                    borderRadius: '10px',
                                    backgroundColor: "#FFFFFF",
                                    boxShadow: "0 0 0.5rem rgba(0,0,0,.102)",
                                    borderBottom: 'none',
                                    marginBottom: "10px"
                                }}>
                                    <Grid item xs={12}>
                                        <Typography style={{}}>Hóspede {i + 1} - Criança</Typography>
                                        <Typography style={{ fontFamily: "HindMedium" }}>{value.nome} {value.sobrenome}</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: "right" }}>
                                        <IconButton onClick={() => handleClickRemoverHospedeCrianca(i)}>
                                            <DeleteOutlined />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            {props.stepperCriancas > 0 && !(hospedesCriancas.length >= props.stepperCriancas) ? (
                                <>
                                    {/* <p>{criancas.length}</p> */}
                                    {criancas.length >= 0 ? (
                                        <>
                                            <Grid item xs={12}>
                                                <USearchField itens={criancas} value={crianca} setValue={setCrianca} label="Hóspede criança" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <USearchField itens={centrosdecusto} value={centrodecustokid} setValue={setCentroDeCustoKid} label="Centro de Custo Criança" />
                                            </Grid>
                                            <Grid item xs={12} style={{ alignSelf: "center" }}>
                                                <Tooltip title="Adicionar Hóspede">
                                                    <Button
                                                        fullWidth
                                                        disabled={desabledButton}
                                                        className={classes.button}
                                                        onClick={() => handleClickAdicionarCrianca()}
                                                        startIcon={<AddCircleOutline style={{ marginLeft: "10px" }} />}
                                                    >
                                                    </Button>
                                                </Tooltip>
                                            </Grid>
                                            <Grid container item xs={12} style={{
                                            }}>
                                                <AdicionarNovaPessoa {...{ passageiro, setPassageiro, index: indexPassageiro, setIndex: setIndexPassageiro }} />
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography style={{ fontSize: "14px" }}>Nenhuma criança cadastrada no sistema, adicione-a</Typography>
                                            </Grid>
                                            <Grid container item xs={12} style={{
                                            }}>
                                                <AdicionarNovaPessoa {...{ passageiro, setPassageiro, index: indexPassageiro, setIndex: setIndexPassageiro }} />
                                            </Grid>
                                        </>

                                    )}
                                </>
                            ) : (
                                <>

                                </>
                            )}
                            {/* <Grid container item xs={12} style={{
                                marginRight: "10px"
                            }}>
                                <AdicionarNovaPessoa {...{ passageiro, setPassageiro, index: indexPassageiro, setIndex: setIndexPassageiro }} />
                            </Grid> */}
                        </>
                    ) : (
                        <>
                            {rows.map((value, i) => (
                                <>
                                    <Grid container item xs={12} style={{
                                        textAlign: "left", padding: "20px",
                                        width: '100%',
                                        height: 'auto',
                                        flexShrink: 0,
                                        fill: '#FFF',
                                        top: "40%",
                                        filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.0))',
                                        borderRadius: '10px',
                                        backgroundColor: "#FFFFFF",
                                        boxShadow: "0 0 0.5rem rgba(0,0,0,.102)",
                                        borderBottom: 'none',
                                    }}>
                                        <Grid item xs={9}>
                                            <Typography style={{ fontSize: "14px" }}>{i == 0 ? "Titular" : ""}</Typography>
                                            <Typography style={{}}>Hóspede {i + 1} - { }Adulto </Typography>
                                            <Typography style={{ fontFamily: "HindMedium" }}>{value.nome} {value.sobrenome} - {value.centrodecusto}</Typography>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: "right" }}>
                                            <IconButton onClick={() => handleClickRemoverHospede(i)}>
                                                <DeleteOutlined />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ marginTop: "10px" }}>
                                        {i == 0 && rows.length > 1 ? (
                                            <Typography style={{ fontSize: "14px", fontWeight: "600" }}>{"Adulto - " + (i + 2)}</Typography>
                                        ) : (
                                            null
                                        )}
                                    </Grid>
                                </>
                            ))}
                            <>
                                {props.stepperAdultos > 1 ? (
                                    <>
                                        <Grid item xs={12} style={{ textAlign: "right", marginTop: "-22px" }}>
                                            <Typography style={{ fontSize: "12px", fontWeight: "600" }}>Hóspede(s): {rows.length + rows2.length}/{props.stepperAdultos + props.stepperCriancas}</Typography>
                                        </Grid>
                                    </>
                                ) : (
                                    null
                                )}
                                {props.stepperAdultos > 0 && !(hospedes.length >= props.stepperAdultos) ? (
                                    <>
                                        <Grid item xs={5}>
                                            <USearchField label="Hóspede" itens={pessoas} value={pessoa} setValue={setPessoa} />
                                        </Grid>
                                        <Grid item xs={5} style={{ display: "flex" }}>
                                            <USearchField itens={centrosdecusto} value={centrodecusto} setValue={setCentroDeCusto} label="Centro de Custo" />
                                        </Grid>
                                        <Grid item xs={1} style={{ alignSelf: "center" }}>
                                            <Tooltip title="Adicionar Hóspede">
                                                <Button
                                                    fullWidth
                                                    disabled={desabledButton}
                                                    className={classes.button}
                                                    onClick={() => handleClickAdicionarHospede()}
                                                    startIcon={<AddCircleOutline style={{ marginLeft: "10px" }} />}
                                                >
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        <Grid container item xs={1} style={{
                                        }}>
                                            <AdicionarNovaPessoa {...{ passageiro, setPassageiro, index: indexPassageiro, setIndex: setIndexPassageiro }} />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                    </>
                                )}
                            </>
                            <hr style={{ width: "100%", backgroundColor: "#DFDFDF", height: "1px", border: "none" }}></hr>
                            {props.stepperCriancas > 0 ? (
                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                    <Typography style={{ fontWeight: "600", fontSize: "20px", marginLeft: "2px", marginBottom: "0px" }}>Crianças no quarto</Typography>
                                </Grid>
                            ) : (
                                null
                            )}
                            {rows2.map((value, i) => (
                                <Grid container item xs={12} style={{
                                    textAlign: "left",
                                    padding: "20px",
                                    width: '100%',
                                    height: 'auto',
                                    flexShrink: 0,
                                    fill: '#FFF',
                                    top: "40%",
                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.0))',
                                    borderRadius: '10px',
                                    backgroundColor: "#FFFFFF",
                                    boxShadow: "0 0 0.5rem rgba(0,0,0,.102)",
                                    borderBottom: 'none',
                                    marginBottom: "10px"
                                }}>
                                    <Grid item xs={9}>
                                        <Typography style={{}}>Hóspede {i + 1} - Criança</Typography>
                                        <Typography style={{ fontFamily: "HindMedium" }}>{value.nome} {value.sobrenome}</Typography>
                                    </Grid>
                                    <Grid item xs={3} style={{ textAlign: "right" }}>
                                        <IconButton onClick={() => handleClickRemoverHospedeCrianca(i)}>
                                            <DeleteOutlined />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            {props.stepperCriancas > 0 && !(hospedesCriancas.length >= props.stepperCriancas) ? (
                                <>
                                    {/* <p>{criancas.length}</p> */}
                                    {criancas.length > 0 ? (
                                        <>
                                            <Grid item xs={5}>
                                                <USearchField itens={criancas} value={crianca} setValue={setCrianca} label="Hóspede criança" />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <USearchField itens={centrosdecusto} value={centrodecustokid} setValue={setCentroDeCustoKid} label="Centro de Custo Criança" />
                                            </Grid>
                                            <Grid item xs={1} style={{ alignSelf: "center" }}>
                                                <Tooltip title="Adicionar Hóspede">
                                                    <Button
                                                        disabled={desabledButton}
                                                        className={classes.button}
                                                        style={{ width: "10px" }}
                                                        onClick={() => handleClickAdicionarCrianca()}
                                                        startIcon={<AddCircleOutline style={{ marginLeft: "10px" }} />}
                                                    >
                                                    </Button>
                                                </Tooltip>
                                            </Grid>
                                            <Grid container item xs={1} style={{
                                            }}>
                                                <AdicionarNovaPessoa {...{ passageiro, setPassageiro, index: indexPassageiro, setIndex: setIndexPassageiro }} />
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: "14px" }}>Nenhuma criança cadastrada no sistema, adicione-a</Typography>
                                        </Grid>
                                    )}
                                </>
                            ) : (
                                <>
                                </>
                            )}
                            {/* <Grid container item xs={12} style={{
                                marginRight: "10px"
                            }}>
                                <AdicionarNovaPessoa {...{ passageiro, setPassageiro, index: indexPassageiro, setIndex: setIndexPassageiro }} />
                            </Grid> */}
                        </>
                    )}

                </Grid>
            ))}
        </form>
    );
};

export default RoomOccupants;
