import { XGrid } from '@material-ui/x-grid';
import React from 'react';
import EnumCompany from '../../../../enums/enumFlightCompany';
import CustomNoRowsOverlay from '../../../configuracao/email/lista/emptyList';

function definirAssunto(assunto) {
    if (!assunto) {
        return '';
    }
    let textos = assunto.split('<br />');
    return (
        <div>
            {textos.map(item => (
                <div>{item}</div>
            ))}
        </div>
    );
}

function ComprasRealizadasGrid(props) {
    const columns = [
        { field: 'idreserva', headerName: 'ID', width: 80 },
        { field: 'viajante', headerName: 'Viajante', width: 160 },
        // {
        //     field: 'cia', headerName: 'CIA', width: 120, editable: false,
        //     renderCell: (item) => (
        //         <>
        //             {item.row.cia.trim() !== '-' ? (
        //                 <img width={80} src={EnumCompany.getLogoCompanhiaAereaEnum(item.value).logo} style={item.value === '8I' ? { height: 60 } : { height: "auto" }} />
        //             ) : "-"}
        //         </>
        //     )
        // },
        // {
        //     field: 'fornecedor', headerName: 'Fornecedor', width: 120, editable: false,
        //     renderCell: (item) => (
        //         <>
        //             {item.row.fornecedor && item.row.fornecedor.trim() !== '-' ? item.row.fornecedor : "-"}

        //         </>
        //     )
        // },
        {
            field: 'ciaFornecedor', headerName: 'Fornecedor', width: 120, editable: false,
            renderCell: (item) => (
                <>
                    {item.row.fornecedor && item.row.fornecedor.trim() !== '-' ? (
                        item.row.fornecedor
                    ) : (
                        item.row.cia.trim() !== '-' ? (
                            <img
                                width={80}
                                alt="Logo"
                                src={EnumCompany.getLogoCompanhiaAereaEnum(item.row.cia).logo}
                                style={item.row.cia === '8I' ? { height: 60 } : { height: "auto" }}
                            />
                        ) : "-"
                    )}
                </>
            )
        },
        {
            field: 'trecho', headerName: 'Trecho', width: 190, editable: false,
            renderCell: (item) => (
                <>
                    {definirAssunto(item.row.trecho)}
                </>
            )
        },
        { field: 'datainicio', headerName: 'Data Ida', width: 140, editable: false },
        // { field: 'datafim', headerName: 'Data Volta', width: 140, editable: false },
        {
            field: 'datafim',
            headerName: 'Data Volta',
            width: 140,
            editable: false,
            renderCell: (item) => {
                const trecho = item.row.trecho || "";
                const dataInicio = item.row.datainicio || "";
                const dataFim = item.row.datafim || "";
                const fornecedor = item.row.fornecedor || null;

                const cleanTrecho = trecho.replace(/<br\s*\/?>/g, '').trim();
                const containsArrow = cleanTrecho.includes("→");

                if (!fornecedor && containsArrow && dataInicio === dataFim) {
                    return "-";
                } else {
                    return dataFim;
                }
            }
        },
        { field: 'loc', headerName: 'Localizador', width: 140, editable: false },
        { field: 'tipo', headerName: 'Tipo', width: 140, editable: false },
        { field: 'valor', headerName: 'Valor', width: 140, editable: false },
        { field: 'solicitante', headerName: 'Solicitante', width: 140, editable: false },
        { field: 'centrodecusto', headerName: 'Centro de Custo', width: 210, editable: false }
    ];

    return (
        <>
            <div style={{ height: '52vh', width: '100%' }}>
                <XGrid
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    id={Math.random()}
                    rows={props.data.itens}
                    columns={columns}
                    loading={props.isLoading}
                    components={{
                        noRowsOverlay: CustomNoRowsOverlay
                    }}
                    hideFooterSelectedRowCount
                    disableSelectionOnClick
                    hideFooterRowCount
                    pagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                />
            </div>
        </>
    );
};

export default ComprasRealizadasGrid;
