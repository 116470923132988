import { NavigateNextOutlined } from "@mui/icons-material"
import { Grid, IconButton, Typography } from "@mui/material"
import { CreationDate, IniciadoEm } from "../../../views/prestacaodecontas/Desktop/sections"
import { grey } from "@mui/material/colors"
import { formatarParaNomeStatusViagem } from "../../../enums/enumPrestacaoDeContas"

export const CardMyViagem_mob = ({ item, handleClickViagem }) => {
    return (
        <>
            <Grid container item xs={12} style={{ boxShadow: "0px 0px 2.2px 0px rgba(0,0,0,0.5)", marginTop: "14px", padding: "20px", borderRadius: "10px" }}>
                <Grid item xs={10} style={{ marginTop: "10px" }}>
                    <Typography style={{ color: grey[800], fontWeight: "600", fontSize: "15px" }}>{item.titulo}</Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                    <IconButton
                        variant="contained"
                        onClick={() => handleClickViagem(item)} // Passando o item atual para a função
                    >
                        <NavigateNextOutlined />
                    </IconButton>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "4px", textAlign: "left", justifyContent: "left" }}>
                    <CreationDate data={item.datadecriacao} />
                </Grid>
                <Grid container item xs={12} marginTop={1} style={{ fontFamily: "sans-serif" }}>
                    <Typography variant="h6" color="textSecondary" sx={{ mb: 1, fontSize: "14px" }}>
                        {formatarParaNomeStatusViagem(item.status)}
                    </Typography>
                </Grid>
                <Grid container item xs={12} marginTop={1}>
                    <IniciadoEm datainicio={item.datainicio} />
                </Grid>
            </Grid>
        </>
    )
}