import { Avatar, Badge, Box, Button, Card, CardContent, Container, Divider, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, OutlinedInput, Pagination, Select, Tooltip, Typography, alpha, styled, useTheme } from "@mui/material"
import { AdminPanelSettings, AssignmentTurnedInOutlined, Info, MoreVertOutlined, NotificationImportant } from "@mui/icons-material";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { CreationDate, IniciadoEm } from "../../Desktop/sections";
import { EnumTipo, formatarParaNomeStatusAdiantamentoList, formatarParaNomeStatusAdiantamentoListI, formatarParaNomeStatusViagem, mapaNomesViagemStatus } from "../../../../enums/enumPrestacaoDeContas";
import DialogComponent from "../../Desktop/sections/dialogs-desktop";
import { DetalhesViagemComponentM } from "./detalhesViagem";
import { AprovarViagemComponentM } from "./aprovarDespesas";
import { ControladoriaDespesasComponentM } from "./controladoriaDespesas";
import UtaliiDatePickerInput from "../../Desktop/sections/custom-date-picker-start_and_end";
import centroCustoRepositorio from "../../../../repositorios/centroCusto";
import pessoaRepositorio from "../../../../repositorios/pessoa";
import prestacaoDeContasRepositorio from "../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../components/snackbar2/NewSnackBar";
import ULoaderVoo from "../../../../components/uloadervoo";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export const AuditorM = ({ permissions }) => {
    const theme = useTheme();
    const [viagens, setViagens] = useState([]);
    const [personName, setPersonName] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [viagem, setViagem] = useState(null);
    const [openDetails, setOpenDetails] = useState(false)
    const [openAprove, setOpenAprove] = useState(false)
    const [openControladoria, setOpenControladoria] = useState(false)
    // paginação
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = viagens.slice(startIndex, endIndex);
    const [selectedDate, setSelectedDate] = useState([null, null]);
    const [centrosDeCusto, setCentrosDeCusto] = useState([]);
    const [centroDeCusto, setCentroDeCusto] = useState();
    const [pessoas, setPessoas] = useState([])
    const [pessoa, setPessoa] = useState();
    const [loading, setLoading] = useState(false)

    const { enqueueSnackbar } = useCustomSnackbar();

    const statusOptions = Object.entries(mapaNomesViagemStatus);

    useLayoutEffect(() => {
        (async () => {
            const responsePessoas = await pessoaRepositorio.getSearchField();
            const response = await centroCustoRepositorio.getSearchField();
            // await getData();
            setCentrosDeCusto(response);
            setPessoas(responsePessoas)
        })()
    }, []);


    const getAllViagens = useCallback(async () => {
        setLoading(true);
        try {
            let model = {
                data_inicial: new Date(new Date().getFullYear(), 0, 1), // Início do ano: 1º de janeiro do ano atual
                data_final: new Date(new Date().getFullYear(), 11, 31), // Fim do ano: 31 de dezembro do ano atual
                id_pessoa: 0,
                id_centrocusto: 0,
                minhasviagens: false
            };
    
            const response = await prestacaoDeContasRepositorio.getAllViagensUser(model);
            setViagens(response.viagens);
        } catch {
            enqueueSnackbar('Erro ao obter as viagens.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, setViagens, enqueueSnackbar]);

    const handleClick = (event, value) => {
        setAnchorEl(event.currentTarget);
        setViagem(value)
    };

    const handleClose = () => {
        
        setAnchorEl(false);
    };

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
    };

    const handleChange = (event) => {
        const { target: { value } } = event;
        setPersonName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleClickDetails = () => {
        setOpenDetails(true)
        handleClose()
    }

    const handleClickApprove = () => {
        setOpenAprove(true)
        handleClose()
    }

    const handleClickControladoria = () => {
        setOpenControladoria(true)
        handleClose()
    }

    const handleChangeCentrodecusto = (event) => {
        setCentroDeCusto(event.value);
    };

    const handleChangePessoa = (event) => {
        setPessoa(event.value);
    };

    useEffect(() => {
        getAllViagens()
    }, [getAllViagens])

    const handleClickFilterMobile = async () => {
        setLoading(true)
        try {
            let datai = null;
            let dataf = null
            let filtredStatus = [];

            personName.forEach(item => {
                filtredStatus.push(EnumTipo.viagemstatus[item]);
            });


            if (selectedDate[0] !== null && selectedDate[1] !== null) {
                datai = selectedDate[0].$d
                dataf = selectedDate[1].$d
            }

            let model = {
                data_inicial: datai !== null ? new Date(datai) : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                data_final: dataf !== null ? new Date(dataf) : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                id_pessoa: pessoa !== null ? pessoa : 0,
                id_centrocusto: centroDeCusto != null ? centroDeCusto : 0,
                minhasviagens: false
            }

            const response = await prestacaoDeContasRepositorio.getAllViagensUser(model);

            if (filtredStatus.length !== 0) {
                const filteredViagens = response.viagens.filter(viagem => {
                    const statusMatch = filtredStatus.length === 0 || filtredStatus.includes(viagem.status);
                    return statusMatch;
                });

                setViagens(filteredViagens)
 
                return
            }

            setViagens(response.viagens)
        } catch {
            enqueueSnackbar('Erro ao filtrar as viagens.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container maxWidth={"lg"}>
            <ULoaderVoo isLoading={loading} />
            {viagem !== null && (
                <>
                    <DialogComponent
                        title={
                            <>
                                Detalhes
                            </>
                        }
                        fullScreen={true}
                        open={openDetails}
                        handleClose={() => setOpenDetails(false)}
                        body={
                            <Grid style={{ display: "flex", justifyContent: "center" }}>
                                <DetalhesViagemComponentM viagem={viagem} />
                            </Grid>
                        }
                        action={
                            <Grid container item xs={12} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                            </Grid>
                        }
                        width={"sm"}
                    />
                    <DialogComponent
                        title={
                            <>
                                Aprovar viagem
                            </>
                        }
                        fullScreen={true}
                        open={openAprove}
                        handleClose={() => setOpenAprove(false)}
                        body={
                            <Grid style={{ display: "flex", justifyContent: "center" }}>
                                <AprovarViagemComponentM viagem={viagem} setOpen={setOpenAprove} getViagensReload={getAllViagens} />
                            </Grid>
                        }
                        action={
                            <Grid container item xs={12} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                            </Grid>
                        }
                        width={"sm"}
                    />
                    <DialogComponent
                        title={
                            <>
                                Controladoria
                            </>
                        }
                        fullScreen={true}
                        open={openControladoria}
                        handleClose={() => setOpenControladoria(false)}
                        body={
                            <Grid style={{ display: "flex", justifyContent: "center" }}>
                                <ControladoriaDespesasComponentM viagem={viagem} setViagem={setViagem} setOpen={setOpenControladoria} getViagensReload={getAllViagens} />
                            </Grid>
                        }
                        action={
                            <Grid container item xs={12} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                            </Grid>
                        }
                        width={"sm"}
                    />
                </>
            )}
            <Grid style={{ display: "flex", justifyContent: "center" }} item xs={12}>
                <Grid item xs={12} style={{ maxWidth: "400px" }}>
                    <FormControl sx={{ width: "100%", marginBottom: "8px" }}>
                        <UtaliiDatePickerInput
                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                    </FormControl>

                    <FormControl style={{ width: "100%", marginBottom: "8px" }}>
                        <InputLabel id="demo-simple-select-label">Centro de custo</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={centroDeCusto}
                            defaultValue={0}
                            label="Centro de custo"

                        >
                            <MenuItem value={0} onClick={() => handleChangeCentrodecusto({ label: "Todos", value: 0 })}>Todos</MenuItem>
                            {centrosDeCusto?.map((e) => (
                                <MenuItem value={e.value} onClick={() => handleChangeCentrodecusto({ label: e.label, value: e.value })}>{e.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Pessoa</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={pessoa}
                            defaultValue={0}
                            label="Pessoa"

                        >
                            <MenuItem value={0} onClick={() => handleChangePessoa({ label: "Todos", value: 0 })}>Todos</MenuItem>
                            {pessoas?.map((e) => (
                                <MenuItem value={e.value} onClick={() => handleChangePessoa({ label: e.label, value: e.value })}>{e.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ width: "100%", marginTop: "8px" }}>
                        <InputLabel id="demo-multiple-chip-label">Status Da Viagem</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={personName}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Status Da Viagem" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <>
                                            {formatarParaNomeStatusViagem(value)}
                                        </>
                                    ))}
                                </Box>
                            )}
                            MenuProps={{ PaperProps: { style: { maxHeight: 224, width: 250 } } }}
                        >
                            {statusOptions.map(([key, label]) => (
                                <MenuItem
                                    key={key}
                                    value={key}
                                    style={getStyles(key, personName, theme)}
                                >
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button size="large" onClick={() => handleClickFilterMobile()} variant="contained" fullWidth color="primary">
                        Pesquisar
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center", flexDirection: "row", flexWrap: "wrap", marginTop: "15px" }}>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => handleClickDetails()}
                        disableRipple
                    >
                        <Info />
                        Detahes
                    </MenuItem>
                    {viagem?.status === EnumTipo.viagemstatus.controladoria && permissions.permissao_controladoria ? (
                        <MenuItem
                            onClick={() => handleClickControladoria()}
                            disableRipple
                        >
                            <AdminPanelSettings />
                            Controladoria
                        </MenuItem>
                    ) : (null)}
                    {viagem?.status === 8 || viagem?.status === 9 || viagem?.status === 10 || viagem?.status === 2 && permissions.permissao_contabilidade ? ( 
                        <MenuItem
                            onClick={() => handleClickApprove()}
                            disableRipple
                        >
                            <AssignmentTurnedInOutlined />
                            Revisar Despesas
                        </MenuItem>
                    ) : (null)}
                </StyledMenu>
                {currentItems.map((v) => (
                    <>
                        <Card sx={{ width: "100%", maxWidth: 325, boxShadow: 3, borderRadius: "10px", margin: "3px" }}>
                            <CardContent>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <Avatar
                                        src={v.pessoa.img}
                                        alt={v.pessoa.nome}
                                        sx={{ width: 40, height: 40 }}
                                    />
                                    <Box sx={{ ml: 2, flexGrow: 1 }}>
                                        <Typography variant="subtitle1">{v.pessoa.nome}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {v.qtd_despesas} Despesas
                                        </Typography>
                                    </Box>

                                    <Badge
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                borderRadius: '10px 0px 0px 10px', // Aplica o border-radius apenas no lado esquerdo
                                            },
                                        }}
                                        color="error"
                                        badgeContent={
                                            <Tooltip title="Fora da política">
                                                <NotificationImportant fontSize="small" style={{ color: "#FFFFFF" }} />
                                            </Tooltip>
                                        }
                                        invisible={v.necessita_atencao != null && v.necessita_atencao ? false : true}
                                    >
                                        <IconButton onClick={(e) => handleClick(e, v)} aria-label="settings">
                                            <MoreVertOutlined />
                                        </IconButton>
                                    </Badge>
                                </Box>
                                <Typography variant="h6" sx={{ mb: 1 }}>
                                    {v.titulo}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                                    <CreationDate data={v.datadecriacao} />
                                </Typography>
                                <Typography variant="h6" color="textSecondary" sx={{ mb: 1, fontSize: "14px" }}>
                                    {formatarParaNomeStatusViagem(v.status)}
                                    <div style={{ height: "4px" }}></div>
                                    {v.tem_multimonetario ? (
                                        <>
                                            {formatarParaNomeStatusAdiantamentoListI(v.adiantamento)}
                                        </>
                                    ) : (
                                        <>
                                            <Tooltip title={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(v.totalinicial)}>
                                                {formatarParaNomeStatusAdiantamentoList(v.adiantamento, v.totalinicial)}
                                            </Tooltip>
                                        </>
                                    )}
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <Typography variant="body2" color="textSecondary">
                                        <IniciadoEm datainicio={v.datainicio} />
                                    </Typography>
                                </Box>
                                {
                                    !v.tem_multimonetario && (
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography variant="body2" color="textSecondary">
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                                    Total:
                                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(v.total)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )
                                }
                            </CardContent>
                        </Card>
                    </>
                ))}
            </Grid>
            <Grid item xs={12} marginBottom={8} marginTop={2} style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                    color="primary"
                    count={Math.ceil(viagens.length / itemsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    siblingCount={0}
                    shape="rounded"
                />
            </Grid>
        </Container>
    )
}
