import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  Paper,
  Switch,
  TextareaAutosize,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import {
  AddCircleOutline,
  CancelOutlined,
  CheckOutlined,
  Close,
  DeleteOutline,
  EditOutlined,
  InfoOutlined,
  Receipt,
  SaveOutlined,
  WarningOutlined,
} from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LinesTextField from "../../../../components/LinesTextField";
import LaunchIcon from "@material-ui/icons/Launch";
import Snackbar from "../../../../components/snackbar";
import USearchField from "../../../../components/usearchfield";
import viagemRepositorio from "../../../../repositorios/viagem";
import CadastroDespesa from "./CadastroDespesa";
import DialogTextCancelamentoDespesa from "./DialogTextCancelamentoDespesa";
import DialogTextContabilidade from "./DialogTextContabilidade";
import Recibo from "./Recibo";
import { useStyles } from "./styles";
import "../../../../fonts/fonts.css";
import ListaDeDespesasPendentes from "./ListaDeDespesasPendentes";

const TipoDespesa = {
  alimentacao: 0,
  transporte: 1,
  estadia: 2,
  aereo: 3,
  outros: 4,
  hodometro: 5,
};

const RedSwitch = withStyles({
  switchBase: {
    color: "#C62828",
    "&$checked": {
      color: "#C62828",
    },
    "&$checked + $track": {
      backgroundColor: red[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

export const CadastroViagem = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const permissaoAutoDespesa = useSelector(
    (state) => state.permissao.politicaViagem.permiteautodespesas
  );
  const [openModalCancelar, setOpenModalCancelar] = React.useState(false);
  const politicaViagem = useSelector((state) => state.permissao.politicaViagem);
  const dadosSessao = useSelector((state) => state.permissao.dadosSessao);
  const [openCadastro, setOpenCadastro] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openRecibo, setOpenRecibo] = useState(false);
  const [despesa, setDespesa] = useState({});
  const [snackState, setSnackState] = useState({ visible: false });
  const [snackVariant, setSnackVariant] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [centroCusto, setCentroCusto] = useState(null);
  const [showAuditarValor, setShowAuditarValor] = useState(false);
  const [showToolTipJustificativa, setShowToolTipJustificativa] =
    useState(false);
  const [hideCancelar, setHideCancelar] = useState(true);
  const [hideConfirmar, setHideConfirmar] = useState(false);
  const [showEmViagem, setShowEmViagem] = useState(false);
  const [showSwitchAuditar, setShowSwitchAuditar] = useState(false);
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [arrayCancelList, setArrayCancelList] = useState([]);
  const [titleEdited, setTitleEdited] = useState(props.viagem.titulo);
  const [idviagemVAR, setIdviagemVAR] = useState();
  const [valueValorAuditado, setValueValorAuditado] = useState({});
  const [openTextContabilidade, setOpenTextContabilidade] = useState(false);
  const [itemTextContabilidade, setItemTextContabilidade] = useState([]);
  const [checkedSwitchControladoria, setCheckedSwitchControladoria] = useState(
    {}
  );
  const [dialogDescricaoControladoria, setDialogDescricaoControladoria] =
    useState([]);
  const [openModalJustificarCancelar, setOpenModalJustificarCancelar] =
    useState(false);
  const [valueJustifyCancel, setValueJustifyCancel] = useState(null);
  const [valueReadJustify, setValueReadJustify] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [valueJustifyCancelID, setValueJustifyCancelID] = useState("");

  const [valueDefaultJustify, setValueDefaultJustify] = useState("");

  const handleChangePanel = () => {
    setExpanded(!expanded);
  };

  const handleChangeSwitch = async (item, event) => {
    if (event.target.checked) {
      setOpenTextContabilidade(true);
      setItemTextContabilidade(item);
    } else {
      try {
        let dto = {
          idviagemdespesa: item.idviagemdespesa,
          descricaocontroladoria: null,
        };
        await viagemRepositorio.salvarDescricaoControladoria(dto);

        handleClickSaveControladoria(item, false);

        setDialogDescricaoControladoria({
          ...dialogDescricaoControladoria,
          [`item${item.idviagemdespesa}`]: "",
        });
      } catch {
        setSnackVariant("error");
        setSnackMessage("Erro ao cancelar descrição da controladoria.");
        setSnackState({
          visible: true,
        });
      }
    }
  };

  const handleClickSaveControladoria = async (item, event) => {
    try {
      const viagemAtual = Object.assign({}, props.viagem);

      const despesaAtual = {
        ...item,
        confirmacao: event,
      };

      const index = viagemAtual?.despesas?.findIndex(
        (v) => v.idviagemdespesa === item.idviagemdespesa
      );

      const dtoViagemDespesa = {
        idviagemdespesa: item.idviagemdespesa,
        confirmacao: event,
      };

      viagemAtual?.despesas?.splice(index, 1, despesaAtual);

      setCheckedSwitchControladoria({
        ...checkedSwitchControladoria,
        [`item${item.idviagemdespesa}`]: event,
      });

      await viagemRepositorio.confirmarAuditoria(dtoViagemDespesa);
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage("Erro ao alterar auditoria.");
      setSnackState({
        visible: true,
      });
    }
  };

  const checkShowAuditarValor = useCallback(
    async (config = null) => {
      try {
        let configUsuario = config;

        setIsLoading(true);
        const response = await viagemRepositorio.verificarStatusViagem(
          props.viagem.idviagem
        );

        if (response.data === "EmViagem") {
          setShowSwitchAuditar(false);
          setShowAuditarValor(false);
          setShowEmViagem(true);
          setShowToolTipJustificativa(false);
          setHideCancelar(true);
          setHideConfirmar(false);
          return;
        } else {
          setShowEmViagem(false);
        }

        if (response.data === "Contabilidade") {
          if (
            configUsuario.permitecontroletotaldespesasviagem ||
            configUsuario.permitesercontabilidade
          ) {
            setShowAuditarValor(true);
            setShowSwitchAuditar(true);
            setHideConfirmar(false);
            setHideCancelar(false);
            setShowToolTipJustificativa(true);
          } else {
            setShowAuditarValor(false);
            setShowSwitchAuditar(false);
            setHideCancelar(true);
            setHideConfirmar(true);
            setShowToolTipJustificativa(false);
          }
          return;
        } else {
          setShowAuditarValor(false);
          setShowSwitchAuditar(false);
        }

        if (response.data === "Controladoria") {
          if (
            configUsuario.permitecontroletotaldespesasviagem ||
            configUsuario.permitesercontroladoriaauditoria
          ) {
            setHideConfirmar(false);
            setShowSwitchAuditar(false);
            setShowEmViagem(false);
            setShowAuditarValor(true);
            setHideCancelar(false);
            setShowToolTipJustificativa(true);
          } else {
            setHideConfirmar(true);
            setShowSwitchAuditar(false);
            setShowEmViagem(false);
            setShowAuditarValor(false);
            setHideCancelar(true);
            setShowToolTipJustificativa(false);
          }
          return;
        } else {
          setShowAuditarValor(false);
        }

        if (response.data === "Financeiro") {
          if (
            configUsuario.permitecontroletotaldespesasviagem ||
            configUsuario.permiteserfinanceiro
          ) {
            setShowEmViagem(false);
            setShowToolTipJustificativa(true);
            setShowAuditarValor(false);
            setHideCancelar(false);
            setHideConfirmar(false);
          } else {
            setHideConfirmar(true);
            setShowSwitchAuditar(false);
            setShowEmViagem(false);
            setShowAuditarValor(false);
            setHideCancelar(true);
            setShowToolTipJustificativa(false);
          }
          return;
        } else {
          setShowEmViagem(false);
          setHideCancelar(true);
        }
      } catch (e) {
        return;
      } finally {
        setIsLoading(false);
      }
    },
    [
      props.viagem.idviagem,
      setIsLoading,
      setShowSwitchAuditar,
      setShowAuditarValor,
      setShowEmViagem,
      setShowToolTipJustificativa,
      setHideCancelar,
      setHideConfirmar,
    ]
  );

  const handleOpenModalCancelar = (idviagem) => {
    setIdviagemVAR(idviagem);
    setOpenModalCancelar(true);
  };

  const handleCloseModalCancelar = () => {
    setOpenModalCancelar(false);
  };

  const handleCloseJustificarCancel = (id, bool) => {
    if (bool) {
      let teste = arrayCancelList.indexOf(id);
      arrayCancelList.splice(teste, 1);
    }
    setOpenModalJustificarCancelar(false);
  };

  const onBlurSaveValorAuditado = async (item, value) => {
    try {
      if (item.valorauditado === null) {
        item.valorauditado = item.valor;
      }
      var float = value.replace(".", "");
      const dtoViagemDespesa = {
        idviagemdespesa: item.idviagemdespesa,
        valorauditado: parseFloat(float.replace(",", ".")),
      };
      await viagemRepositorio.salvarValorAuditado(dtoViagemDespesa);

      setSnackVariant("success");
      setSnackMessage("Valor Auditado salvo.");
      setSnackState({
        visible: true,
      });
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage("Erro ao salvar o Valor Auditado.");
      setSnackState({
        visible: true,
      });
      return;
    }
  };

  const handleCloseModal = () => {
    setCentroCusto(null);
    props.setOpen(false);
    setValueValorAuditado({});
  };

  const handleSubmitConfirmar = async () => {
    try {
      const dtoViagem = {
        ...props.viagem,
        titulo: titleEdited,
        idcentrocusto: !politicaViagem.utilizarcentrocusto
          ? props.centrosCusto[0].value
          : centroCusto.value,
      };

      if (!titleEdited) {
        setSnackVariant("error");
        setSnackMessage("Titulo não pode ser vazio.");
        setSnackState({
          visible: true,
        });
        return;
      }

      if (!dtoViagem.idcentrocusto) {
        setSnackVariant("error");
        setSnackMessage("centro de custo não pode ser vazio.");
        setSnackState({
          visible: true,
        });
        return;
      }

      setIsLoading(true);
      if (props.viagem.idviagem) {
        let response = await viagemRepositorio.put(dtoViagem);
        if (response.status === 204) {
          setSnackVariant("warning");
          setSnackMessage(
            "Somente o viajante desta viagem pode atualizar o título."
          );
          setSnackState({
            visible: true,
          });
          return;
        }
        setSnackVariant("success");
        setSnackMessage("Viagem atualizada.");
        setSnackState({
          visible: true,
        });
        await props.getData();
        handleCloseModal();
        return;
      }
      const response = await viagemRepositorio.addViagem(dtoViagem);
      await props.getData();
      props.setViagem(response.data);
      setSnackVariant("success");
      setSnackMessage("Viagem salva.");
      setSnackState({
        visible: true,
      });
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage("Erro ao salvar viagem.");
      setSnackState({ visible: true });
    } finally {
      setIsLoading(false);
    }
  };

  const getStatus = (idStatus) => {
    switch (idStatus) {
      case TipoDespesa.alimentacao:
        return "Alimentação";
      case TipoDespesa.transporte:
        return "Transporte";
      case TipoDespesa.estadia:
        return "Estadia";
      case TipoDespesa.aereo:
        return "Aéreo";
      case TipoDespesa.hodometro:
        return "Hodômetro";
      case TipoDespesa.outros:
        return "Outros";
      default:
        return "N/A";
    }
  };

  const handleClickAddDespesa = (item) => {
    setDespesa(item);
    setOpenCadastro(true);
  };

  const handleClickDeleteDespesa = async (item) => {
    try {
      setIsLoading(true);
      await viagemRepositorio.removeViagemDespesa(item.idviagemdespesa);
      const responseViagem = await viagemRepositorio.get(item.idviagem);
      props.setViagem(responseViagem.data);
      await props.getData();
      setSnackVariant("success");
      setSnackMessage("Despesa removida.");
      setSnackState({
        visible: true,
      });
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage("Erro ao remover despesa.");
      setSnackState({
        visible: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getInitialCentroCusto = useCallback(() => {
    const centroCustoInitial = props.centrosCusto.find(
      (item) => item.value === props.viagem.idcentrocusto
    );

    if (centroCustoInitial) {
      setCentroCusto(centroCustoInitial);
      return;
    }

    if (centroCusto !== null) {
      return;
    }

    setCentroCusto(null);
  }, [
    props.centrosCusto,
    props.viagem.idcentrocusto,
    centroCusto,
    setCentroCusto,
  ]);

  const handleClickShowRecibo = (item) => {
    setDespesa(item);
    setOpenRecibo(true);
  };

  const handleChanged = (event) => {
    props.setViagem({
      ...props.viagem,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCancelList = async (idviagem) => {
    if (arrayCancelList.length === 0) {
      handleCloseModalCancelar();
      setSnackVariant("info");
      setSnackMessage(
        "Sem despesas marcadas. Favor selecionar as que deseja cancelar."
      );
      setSnackState({
        visible: true,
      });
      return;
    }
    try {
      setIsLoading(true);
      for (var i = 0; i < arrayCancelList.length; i++) {
        await viagemRepositorio.removeDespesaRetroceder(arrayCancelList[i]);
      }
      await viagemRepositorio.estornarViagem(idviagem);
      await props.getData();
      handleCloseModal();
    } catch {
      setSnackVariant("error");
      setSnackMessage("Erro ao cancelar despesas.");
      setSnackState({
        visible: true,
      });
    } finally {
      setIsLoading(false);
      setOpenModalCancelar(false);
    }
  };

  const handleDialogTextContabilidade = () => {
    setOpenTextContabilidade(!openTextContabilidade);
  };

  const handleOpenJustificarCancel = (item, checked) => {
    if (checked) {
      setArrayCancelList([...arrayCancelList, item.idviagemdespesa]);
      setOpenModalJustificarCancelar(true);
    } else {
      let teste = arrayCancelList.indexOf(item.idviagemdespesa);
      arrayCancelList.splice(teste, 1);
      handleSaveJustifyCancel(null, item.idviagemdespesa);
    }
    setValueReadJustify(false);
    setValueJustifyCancelID(item.idviagemdespesa);
    setValueJustifyCancel("");
  };

  const handleReadJustificarCancel = async (idviagemdespesa) => {
    try {
      let response = await viagemRepositorio.getJustificativaCancelamento(
        idviagemdespesa
      );
      if (response.data === null) {
        setValueDefaultJustify("");
      } else {
        setValueDefaultJustify(response.data);
      }
      setValueReadJustify(true);
      setOpenModalJustificarCancelar(true);
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage(
        "Erro ao carregar a justificativa de cancelamento do item solicitado."
      );
      setSnackState({
        visible: true,
      });
    }
  };

  const handleSaveJustifyCancel = async (texto, idviagemdespesa) => {
    try {
      let dto = {
        idviagemdespesa: idviagemdespesa,
        descricaocontroladoria: texto,
      };
      await viagemRepositorio.addJustificativaCancelamento(dto);
      handleCloseJustificarCancel(idviagemdespesa, false);
      setSnackVariant("success");
      setSnackMessage("Êxito ao salvar justificativa de cancelamento do item.");
      setSnackState({
        visible: true,
      });
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage(
        "Erro ao salvar a justificativa de cancelamento do item."
      );
      setSnackState({
        visible: true,
      });
    }
  };

  const verificarButtonActive = useCallback(async () => {
    let response = await viagemRepositorio.verificarStatusViagem(
      props.viagem.idviagem
    );

    if (dadosSessao.nivelpermissao === 1 && response.data !== "EmViagem") {
    }
  }, [
    props.viagem.idviagem,
    dadosSessao.nivelpermissao,
  ]);

  useEffect(() => {
    getInitialCentroCusto();
    (async () => {
      let response = null;
      try {
        response = await viagemRepositorio.permissaoDespesaByViagem();
      } catch (e) {
        return;
      }
      if (
        props.viagem.idviagem !== null &&
        props.viagem.idviagem !== undefined
      ) {
        await checkShowAuditarValor(response.data);
        await verificarButtonActive();
      }
    })();
    setArrayCancelList(
      props.viagem?.despesas?.reduce((array, item) => {
        if (item?.justificativacancelamento !== null) {
          array.push(item?.idviagemdespesa);
        }
        return array;
      }, [])
    );
  }, [
    props.viagem,
    checkShowAuditarValor,
    verificarButtonActive,
    getInitialCentroCusto,
    setArrayCancelList,
  ]);

  return (
    <>
      <Grow>
        <Paper>
          <DialogTextContabilidade
            status={props.viagem?.status}
            open={openTextContabilidade}
            handleClose={handleDialogTextContabilidade}
            item={itemTextContabilidade}
            handleSave={handleClickSaveControladoria}
            setDescricao={setDialogDescricaoControladoria}
            descricoes={dialogDescricaoControladoria}
          />
          <DialogTextCancelamentoDespesa
            handleSaveJustifyCancel={handleSaveJustifyCancel}
            setValueJustifyCancel={setValueJustifyCancel}
            valueReadJustify={valueReadJustify}
            valueJustifyCancelID={valueJustifyCancelID}
            valueJustifyCancel={valueJustifyCancel}
            valueDefaultJustify={valueDefaultJustify}
            setValueDefaultJustify={setValueDefaultJustify}
            handleCloseJustificarCancel={handleCloseJustificarCancel}
            openModalJustificarCancelar={openModalJustificarCancelar}
          />
          <Dialog onClose={handleCloseModalCancelar} open={openModalCancelar}>
            <AppBar position="relative" style={{ background: "none" }}>
              <Toolbar className={classes.appbar}>
                <Typography>Confirmação de cancelamento</Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <DialogContentText>
                <Typography>
                  Tem certeza que deseja cancelar esta(s) despesa(s)? Ao clicar
                  em 'SIM', a viagem retornara para o Coloborar responsável
                  corrigi-lá.
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleChangeCancelList(idviagemVAR)}
                className={classes.buttonConfirm}
              >
                SIM
              </Button>
              <Button
                onClick={handleCloseModalCancelar}
                className={classes.buttonConfirm}
              >
                NÃO
              </Button>
            </DialogActions>
          </Dialog>
          {openCadastro ? (
            <CadastroDespesa
              // setData={ }
              setViagem={props.setViagem}
              viagem={props.viagem}
              open={openCadastro}
              despesa={despesa}
              setOpen={setOpenCadastro}
            />
          ) : null}
          <Recibo despesa={despesa} open={openRecibo} setOpen={setOpenRecibo} />
          <Dialog
            open={props.open}
            onClose={handleCloseModal}
            maxWidth="md"
            fullWidth
            fullScreen={responsive}
            style={{ padding: "-10px" }}
          >
            <Snackbar
              {...{
                variant: snackVariant,
                message: snackMessage,
                visible: snackState.visible,
                setSnackbar: setSnackState,
              }}
            />
            <AppBar position="relative" style={{ background: "none" }}>
              <Toolbar
                style={{
                  background: "#c62828",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>
                    {props.viagem.titulo || "Nova Viagem"}
                  </Typography>
                  <IconButton
                    className={classes.button}
                    onClick={handleCloseModal}
                  >
                    <Close style={{ color: "inherited" }} />
                  </IconButton>
                </Box>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ minHeight: 400 }}>
              <Grid height="100%" width="100%">
                <Box position="relative" style={{ marginBottom: "20px" }}>
                  <Accordion
                    expanded={expanded}
                    onChange={() => handleChangePanel()}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Dados</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid spacing={1} container>
                        <Grid item xs={12} md={8}>
                          <LinesTextField
                            disabled={
                              props.viagem.idviagemtenant !== undefined &&
                              props.viagem.status !== 1
                            }
                            value={titleEdited}
                            defaultValue={props.viagem.titulo}
                            name="titulo"
                            onChange={(e) => {
                              setTitleEdited(e.target.value);
                            }}
                            label="Título da viagem"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <LinesTextField
                            disabled={props.viagem.idviagemtenant !== undefined}
                            value={props.viagem.localizador}
                            name="localizador"
                            onChange={handleChanged}
                            label="Localizador"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                          />
                        </Grid>
                        <Grid item xs={responsive ? 12 : 8}>
                          <USearchField
                            disabled={!politicaViagem.utilizarcentrocusto}
                            itens={props.centrosCusto}
                            value={centroCusto}
                            setValue={setCentroCusto}
                            label="Centro de Custo"
                          />
                        </Grid>
                        <Grid item xs={responsive ? 12 : 4}>
                          <LinesTextField
                            disabled={props.viagem.idviagemtenant !== undefined}
                            value={props.viagem.saldoinicial}
                            name="saldoinicial"
                            onChange={handleChanged}
                            label="Saldo Inicial"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position={"start"}>
                                  R$
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            margin="dense"
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Box hidden={!showEmViagem}>
                    {props.viagem.idviagem && props.viagem.idviagem > 0 ? (
                      <Toolbar>
                        <Box width="100%" display="flex">
                          <Grid container item xs={12}>
                            {permissaoAutoDespesa ? (
                              <Grid
                                item
                                xs={responsive ? 12 : 6}
                                style={{
                                  textAlign: "left",
                                  justifyContent: "left",
                                }}
                              >
                                <ListaDeDespesasPendentes
                                  componentRenderizado={
                                    <>
                                      {props.viagemdespesasvazias?.map(
                                        (item) => (
                                          <>
                                            <Grid item xs={12} container>
                                              <Grid
                                                item
                                                xs={responsive ? 12 : 9}
                                              >
                                                <Grid container>
                                                  <Grid
                                                    item
                                                    xs={1}
                                                    style={{
                                                      minHeight: "70px",
                                                      display: "flex",
                                                      marginRight: "15px",
                                                      justifyContent: "center",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <Tooltip title="Clique para visualizar o comprovante">
                                                      <Avatar
                                                        style={{
                                                          background: "#c62828",
                                                          cursor: "pointer",
                                                          marginTop: "10px",
                                                        }}
                                                        alt="Remy Sharp"
                                                      >
                                                        <IconButton
                                                          onClick={() =>
                                                            handleClickShowRecibo(
                                                              item
                                                            )
                                                          }
                                                        >
                                                          {!item.retrocedido ? (
                                                            <Receipt
                                                              style={{
                                                                color: "white",
                                                              }}
                                                            />
                                                          ) : (
                                                            <WarningOutlined
                                                              style={{
                                                                color: "white",
                                                              }}
                                                            />
                                                          )}
                                                        </IconButton>
                                                      </Avatar>
                                                    </Tooltip>
                                                  </Grid>
                                                  <Grid item xs={10}>
                                                    <Grid
                                                      container
                                                      style={{
                                                        minHeight: "65px",
                                                      }}
                                                    >
                                                      <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <Typography
                                                          style={{
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          {getStatus(
                                                            item.tipodespesa
                                                          )}{" "}
                                                          {item.tipodespesa ===
                                                          5
                                                            ? null
                                                            : "- R$" +
                                                              Number(
                                                                item.valor
                                                              ).toFormatDefault()}
                                                          {item.kmida !== 0
                                                            ? `- ${
                                                                item.kmvolta -
                                                                item.kmida
                                                              } Km`
                                                            : null}
                                                        </Typography>
                                                        <Typography
                                                          variant="subtitle2"
                                                          style={{
                                                            marginLeft: "0px",
                                                          }}
                                                        >
                                                          {new Date(
                                                            item.dataemissao
                                                          ).toLocaleDateString()}
                                                        </Typography>
                                                        <TextareaAutosize
                                                          className={
                                                            classes.inner
                                                          }
                                                          disabled
                                                          maxRows={
                                                            responsive ? 5 : 4
                                                          }
                                                          value={`${item.descricao}`}
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={responsive ? 12 : 3}
                                              >
                                                <Grid container>
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        !showEmViagem
                                                          ? "center"
                                                          : "space-around",
                                                      alignItems: !showEmViagem
                                                        ? "flex-end"
                                                        : "center",
                                                      flexDirection:
                                                        !showEmViagem
                                                          ? "column"
                                                          : "inherit",
                                                    }}
                                                  >
                                                    <Grid container>
                                                      <Grid
                                                        item
                                                        xs={responsive ? 7 : 5}
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "flex-end",
                                                        }}
                                                      >
                                                        {showSwitchAuditar ? (
                                                          <Box
                                                            style={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            <Tooltip title="Reportar despesa">
                                                              <FormGroup>
                                                                <FormControlLabel
                                                                  style={{
                                                                    marginInlineEnd:
                                                                      "auto",
                                                                  }}
                                                                  control={
                                                                    <RedSwitch
                                                                      checked={
                                                                        checkedSwitchControladoria[
                                                                          `item${item.idviagemdespesa}`
                                                                        ] ===
                                                                        undefined
                                                                          ? item.confirmacao
                                                                          : checkedSwitchControladoria[
                                                                              `item${item.idviagemdespesa}`
                                                                            ]
                                                                      }
                                                                      name="auditar"
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeSwitch(
                                                                          item,
                                                                          e
                                                                        )
                                                                      }
                                                                    />
                                                                  }
                                                                  label="Autorizador"
                                                                />
                                                              </FormGroup>
                                                            </Tooltip>
                                                          </Box>
                                                        ) : null}
                                                      </Grid>

                                                      {showToolTipJustificativa &&
                                                      props.viagem.status !==
                                                        5 ? (
                                                        <>
                                                          <Grid
                                                            item
                                                            xs={
                                                              responsive ? 1 : 0
                                                            }
                                                          />
                                                          <Grid
                                                            item
                                                            xs={
                                                              responsive ? 4 : 2
                                                            }
                                                            style={
                                                              responsive
                                                                ? {
                                                                    bakcground:
                                                                      "green",
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "flex-end",
                                                                  }
                                                                : {
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "flex-start",
                                                                  }
                                                            }
                                                          >
                                                            <Tooltip
                                                              title={
                                                                <Typography>
                                                                  {dialogDescricaoControladoria[
                                                                    `item${item.idviagemdespesa}`
                                                                  ] ===
                                                                  undefined
                                                                    ? item.descricaocontroladoria
                                                                    : dialogDescricaoControladoria[
                                                                        `item${item.idviagemdespesa}`
                                                                      ]}
                                                                </Typography>
                                                              }
                                                            >
                                                              <IconButton
                                                                disabled={
                                                                  dialogDescricaoControladoria[
                                                                    `item${item.idviagemdespesa}`
                                                                  ] ===
                                                                  undefined
                                                                    ? !item.descricaocontroladoria
                                                                    : !dialogDescricaoControladoria[
                                                                        `item${item.idviagemdespesa}`
                                                                      ]
                                                                }
                                                                style={
                                                                  props.viagem
                                                                    .status ===
                                                                    3 &&
                                                                  item.descricaocontroladoria !==
                                                                    null
                                                                    ? {
                                                                        color:
                                                                          "#C62828",
                                                                      }
                                                                    : null
                                                                }
                                                                onClick={() => {
                                                                  setOpenTextContabilidade(
                                                                    true
                                                                  );
                                                                  setItemTextContabilidade(
                                                                    item
                                                                  );
                                                                }}
                                                              >
                                                                <InfoOutlined />
                                                              </IconButton>
                                                            </Tooltip>
                                                          </Grid>

                                                          <Grid
                                                            item
                                                            xs={
                                                              responsive ? 7 : 5
                                                            }
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "flex-end",
                                                            }}
                                                          >
                                                            {showAuditarValor ? (
                                                              <LinesTextField
                                                                name="valorAuditado"
                                                                label="Valor Auditado"
                                                                variant="outlined"
                                                                margin="dense"
                                                                InputLabelProps={{
                                                                  shrink: true,
                                                                }}
                                                                style={{
                                                                  width:
                                                                    "140px",
                                                                }}
                                                                defaultValue={
                                                                  item.valorauditado ===
                                                                  null
                                                                    ? Number(
                                                                        item.valor
                                                                      ).toFormatDefault()
                                                                    : Number(
                                                                        item.valorauditado
                                                                      ).toFormatDefault()
                                                                }
                                                                value={
                                                                  valueValorAuditado[
                                                                    `item${item.idviagemdespesa}`
                                                                  ]
                                                                }
                                                                onBlur={(e) => {
                                                                  if (
                                                                    e.target
                                                                      .value ===
                                                                      "" ||
                                                                    e.target
                                                                      .value ===
                                                                      null
                                                                  ) {
                                                                    setValueValorAuditado(
                                                                      {
                                                                        ...valueValorAuditado,
                                                                        [`item${item.idviagemdespesa}`]:
                                                                          Number(
                                                                            item.valor
                                                                          ).toFormatDefault(),
                                                                      }
                                                                    );
                                                                  } else {
                                                                    var float =
                                                                      e.target.value.replace(
                                                                        ".",
                                                                        ""
                                                                      );
                                                                    setValueValorAuditado(
                                                                      {
                                                                        ...valueValorAuditado,
                                                                        [`item${item.idviagemdespesa}`]:
                                                                          parseFloat(
                                                                            float.replace(
                                                                              ",",
                                                                              "."
                                                                            )
                                                                          ).toFormatDefault(),
                                                                      }
                                                                    );
                                                                  }
                                                                }}
                                                                onChange={(e) =>
                                                                  setValueValorAuditado(
                                                                    {
                                                                      ...valueValorAuditado,
                                                                      [`item${item.idviagemdespesa}`]:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  )
                                                                }
                                                                InputProps={{
                                                                  endAdornment:
                                                                    (
                                                                      <Tooltip
                                                                        title={
                                                                          "Clique para Salvar."
                                                                        }
                                                                      >
                                                                        <IconButton
                                                                          onClick={() =>
                                                                            onBlurSaveValorAuditado(
                                                                              item,
                                                                              valueValorAuditado[
                                                                                `item${item.idviagemdespesa}`
                                                                              ]
                                                                            )
                                                                          }
                                                                        >
                                                                          <SaveOutlined />
                                                                        </IconButton>
                                                                      </Tooltip>
                                                                    ),
                                                                }}
                                                              />
                                                            ) : null}
                                                          </Grid>
                                                        </>
                                                      ) : null}
                                                      <Grid
                                                        item
                                                        xs={responsive ? 12 : 0}
                                                      />
                                                      <Grid
                                                        item
                                                        xs={
                                                          responsive ? 12 : 12
                                                        }
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "flex-end",
                                                        }}
                                                      >
                                                        {!showEmViagem ? (
                                                          !hideCancelar ? (
                                                            <Box
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "right",
                                                              }}
                                                            >
                                                              <Checkbox
                                                                onChange={(e) =>
                                                                  handleOpenJustificarCancel(
                                                                    item,
                                                                    e.target
                                                                      .checked
                                                                  )
                                                                }
                                                                checked={
                                                                  arrayCancelList.indexOf(
                                                                    item.idviagemdespesa
                                                                  ) === -1
                                                                    ? false
                                                                    : true
                                                                }
                                                                style={{
                                                                  color:
                                                                    "#C62828",
                                                                }}
                                                              />
                                                              <Typography
                                                                variant="subtitle2"
                                                                style={{
                                                                  marginLeft:
                                                                    "5px",
                                                                }}
                                                              >
                                                                Cancelar
                                                              </Typography>
                                                              <IconButton
                                                                onClick={() =>
                                                                  handleReadJustificarCancel(
                                                                    item.idviagemdespesa
                                                                  )
                                                                }
                                                              >
                                                                <InfoOutlined />
                                                              </IconButton>
                                                            </Box>
                                                          ) : null
                                                        ) : (
                                                          <Box>
                                                            {item.retrocedido ? (
                                                              <IconButton
                                                                onClick={() =>
                                                                  handleReadJustificarCancel(
                                                                    item.idviagemdespesa
                                                                  )
                                                                }
                                                              >
                                                                <InfoOutlined />
                                                              </IconButton>
                                                            ) : null}
                                                            <Tooltip title="Editar despesa">
                                                              <IconButton
                                                                onClick={() =>
                                                                  handleClickAddDespesa(
                                                                    item
                                                                  )
                                                                }
                                                                style={{
                                                                  color:
                                                                    "#c62828",
                                                                }}
                                                                edge="end"
                                                                aria-label="delete"
                                                              >
                                                                <EditOutlined />
                                                              </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Remover despesa">
                                                              <IconButton
                                                                onClick={() =>
                                                                  props.handleClickDeleteAutoDespesa(
                                                                    item
                                                                  )
                                                                }
                                                                style={{
                                                                  color:
                                                                    "#c62828",
                                                                }}
                                                                edge="end"
                                                                aria-label="delete"
                                                              >
                                                                <DeleteOutline />
                                                              </IconButton>
                                                            </Tooltip>
                                                            <IconButton
                                                              fullWidth
                                                              onClick={() =>
                                                                props.handleClickTransferirDespesa(
                                                                  item
                                                                )
                                                              }
                                                              style={{
                                                                color:
                                                                  "#c62828",
                                                              }}
                                                            >
                                                              <AddCircleOutline />
                                                            </IconButton>
                                                          </Box>
                                                        )}
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Divider
                                              style={{
                                                marginTop: "15px",
                                                marginBottom: "15px",
                                              }}
                                            />
                                          </>
                                        )
                                      )}
                                    </>
                                  }
                                  count={props.viagemdespesasvazias.length}
                                  responsive={responsive}
                                />
                              </Grid>
                            ) : null}
                            {responsive ? (
                              <Grid
                                item
                                xs={12}
                                style={{
                                  textAlign: "center",
                                  justifyContent: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <Tooltip title="Adicionar despesa">
                                  <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => handleClickAddDespesa({})}
                                    style={{ color: "#c62828" }}
                                  >
                                    <AddCircleOutline />
                                    <Typography
                                      style={{
                                        fontFamily: "HindBold",
                                        color: "#c62828",
                                      }}
                                    >
                                      Adicionar despesa
                                    </Typography>
                                  </Button>
                                </Tooltip>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={permissaoAutoDespesa ? 6 : 12}
                                style={{
                                  textAlign: "right",
                                  justifyContent: "right",
                                }}
                              >
                                <Tooltip title="Adicionar despesa">
                                  <Button
                                    variant="outlined"
                                    onClick={() => handleClickAddDespesa({})}
                                    style={{ color: "#c62828" }}
                                  >
                                    <AddCircleOutline />
                                    <Typography
                                      style={{
                                        fontFamily: "HindBold",
                                        color: "#c62828",
                                      }}
                                    >
                                      Adicionar despesa
                                    </Typography>
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </Toolbar>
                    ) : null}
                  </Box>
                </Box>
                {!isLoading ? (
                  <>
                    {props.viagem.idviagem && props.viagem.idviagem > 0 ? (
                      <>
                        {props.viagem.despesas?.map((item, index) => (
                          <>
                            <Grid container>
                              <Grid item xs={responsive ? 12 : 7}>
                                <Grid container item xs={12}>
                                  <Grid
                                    item
                                    xs={responsive ? 12 : 1}
                                    style={
                                      responsive
                                        ? {
                                            textAlign: "center",
                                            minHeight: "70px",
                                            display: "flex",
                                            justifyContent: "center",
                                          }
                                        : {
                                            minHeight: "70px",
                                            display: "flex",
                                            marginRight: "15px",
                                            justifyContent: "center",
                                            alignItems: "flex-start",
                                          }
                                    }
                                  >
                                    <Tooltip title="Clique para visualizar o comprovante">
                                      <Avatar
                                        style={{
                                          background: "#c62828",
                                          cursor: "pointer",
                                          marginTop: "10px",
                                        }}
                                        alt="Remy Sharp"
                                      >
                                        <IconButton
                                          onClick={() =>
                                            handleClickShowRecibo(item)
                                          }
                                        >
                                          {!item.retrocedido ? (
                                            <Receipt
                                              style={{ color: "white" }}
                                            />
                                          ) : (
                                            <WarningOutlined
                                              style={{ color: "white" }}
                                            />
                                          )}
                                        </IconButton>
                                      </Avatar>
                                    </Tooltip>
                                  </Grid>
                                  <Grid item xs={responsive ? 12 : 9}>
                                    <Grid
                                      container
                                      style={
                                        responsive
                                          ? {
                                              textAlign: "center",
                                              justifyContent: "center",
                                              minHeight: "65px",
                                            }
                                          : { minHeight: "65px" }
                                      }
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ width: "100%" }}
                                      >
                                        <Typography
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {getStatus(item.tipodespesa)}{" "}
                                          {item.tipodespesa === 5
                                            ? null
                                            : "- R$" +
                                              Number(
                                                item.valor
                                              ).toFormatDefault()}
                                          {item.kmida !== 0
                                            ? `- ${
                                                item.kmvolta - item.kmida
                                              } Km`
                                            : null}
                                        </Typography>
                                        <Typography
                                          variant="subtitle2"
                                          style={{ marginLeft: "0px" }}
                                        >
                                          {new Date(
                                            item.dataemissao
                                          ).toLocaleDateString()}
                                        </Typography>
                                        <TextareaAutosize
                                          className={classes.inner}
                                          disabled
                                          maxRows={responsive ? 5 : 4}
                                          value={`${item.descricao}`}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={responsive ? 12 : 5}>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      display: "flex",
                                      justifyContent: !showEmViagem
                                        ? "center"
                                        : "space-around",
                                      alignItems: !showEmViagem
                                        ? "flex-end"
                                        : "center",
                                      flexDirection: !showEmViagem
                                        ? "column"
                                        : "inherit",
                                    }}
                                  >
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={responsive ? 12 : 5}
                                        style={
                                          responsive
                                            ? {
                                                textAlign: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                              }
                                            : {
                                                display: "flex",
                                                justifyContent: "flex-end",
                                              }
                                        }
                                      >
                                        {showSwitchAuditar ? (
                                          <Box
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Tooltip title="Reportar despesa">
                                              <FormGroup>
                                                <FormControlLabel
                                                  style={{
                                                    marginInlineEnd: "auto",
                                                  }}
                                                  control={
                                                    <RedSwitch
                                                      checked={
                                                        checkedSwitchControladoria[
                                                          `item${item.idviagemdespesa}`
                                                        ] === undefined
                                                          ? item.confirmacao
                                                          : checkedSwitchControladoria[
                                                              `item${item.idviagemdespesa}`
                                                            ]
                                                      }
                                                      name="auditar"
                                                      onChange={(e) =>
                                                        handleChangeSwitch(
                                                          item,
                                                          e
                                                        )
                                                      }
                                                    />
                                                  }
                                                  label="Autorizador"
                                                />
                                              </FormGroup>
                                            </Tooltip>
                                          </Box>
                                        ) : null}
                                      </Grid>

                                      {showToolTipJustificativa &&
                                      props.viagem.status !== 5 ? (
                                        <>
                                          <Grid item xs={responsive ? 1 : 0} />
                                          <Grid
                                            item
                                            xs={responsive ? 12 : 2}
                                            style={
                                              responsive
                                                ? {
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }
                                                : {
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                  }
                                            }
                                          >
                                            <Tooltip
                                              title={
                                                <Typography>
                                                  {dialogDescricaoControladoria[
                                                    `item${item.idviagemdespesa}`
                                                  ] === undefined
                                                    ? item.descricaocontroladoria
                                                    : dialogDescricaoControladoria[
                                                        `item${item.idviagemdespesa}`
                                                      ]}
                                                </Typography>
                                              }
                                            >
                                              <IconButton
                                                disabled={
                                                  dialogDescricaoControladoria[
                                                    `item${item.idviagemdespesa}`
                                                  ] === undefined
                                                    ? !item.descricaocontroladoria
                                                    : !dialogDescricaoControladoria[
                                                        `item${item.idviagemdespesa}`
                                                      ]
                                                }
                                                style={
                                                  props.viagem.status === 3 &&
                                                  item.descricaocontroladoria !==
                                                    null
                                                    ? { color: "#C62828" }
                                                    : null
                                                }
                                                onClick={() => {
                                                  setOpenTextContabilidade(
                                                    true
                                                  );
                                                  setItemTextContabilidade(
                                                    item
                                                  );
                                                }}
                                              >
                                                <InfoOutlined />
                                              </IconButton>
                                            </Tooltip>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={responsive ? 12 : 5}
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            {showAuditarValor ? (
                                              <LinesTextField
                                                name="valorAuditado"
                                                label="Valor Auditado"
                                                variant="outlined"
                                                margin="dense"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                style={{ width: "140px" }}
                                                defaultValue={
                                                  item.valorauditado === null
                                                    ? Number(
                                                        item.valor
                                                      ).toFormatDefault()
                                                    : Number(
                                                        item.valorauditado
                                                      ).toFormatDefault()
                                                }
                                                value={
                                                  valueValorAuditado[
                                                    `item${item.idviagemdespesa}`
                                                  ]
                                                }
                                                onBlur={(e) => {
                                                  if (
                                                    e.target.value === "" ||
                                                    e.target.value === null
                                                  ) {
                                                    setValueValorAuditado({
                                                      ...valueValorAuditado,
                                                      [`item${item.idviagemdespesa}`]:
                                                        Number(
                                                          item.valor
                                                        ).toFormatDefault(),
                                                    });
                                                  } else {
                                                    var float =
                                                      e.target.value.replace(
                                                        ".",
                                                        ""
                                                      );
                                                    setValueValorAuditado({
                                                      ...valueValorAuditado,
                                                      [`item${item.idviagemdespesa}`]:
                                                        parseFloat(
                                                          float.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        ).toFormatDefault(),
                                                    });
                                                  }
                                                }}
                                                onChange={(e) =>
                                                  setValueValorAuditado({
                                                    ...valueValorAuditado,
                                                    [`item${item.idviagemdespesa}`]:
                                                      e.target.value,
                                                  })
                                                }
                                                InputProps={{
                                                  endAdornment: (
                                                    <Tooltip
                                                      title={
                                                        "Clique para Salvar."
                                                      }
                                                    >
                                                      <IconButton
                                                        onClick={() =>
                                                          onBlurSaveValorAuditado(
                                                            item,
                                                            valueValorAuditado[
                                                              `item${item.idviagemdespesa}`
                                                            ]
                                                          )
                                                        }
                                                      >
                                                        <SaveOutlined />
                                                      </IconButton>
                                                    </Tooltip>
                                                  ),
                                                }}
                                              />
                                            ) : null}
                                          </Grid>
                                        </>
                                      ) : null}
                                      <Grid item xs={responsive ? 1 : 0} />
                                      <Grid
                                        item
                                        xs={responsive ? 12 : 12}
                                        style={
                                          responsive
                                            ? {
                                                textAlign: "center",
                                                justifyContent: "center",
                                              }
                                            : {
                                                display: "flex",
                                                justifyContent: "flex-end",
                                              }
                                        }
                                      >
                                        {!showEmViagem ? (
                                          !hideCancelar ? (
                                            <Box
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "right",
                                              }}
                                            >
                                              <Checkbox
                                                onChange={(e) =>
                                                  handleOpenJustificarCancel(
                                                    item,
                                                    e.target.checked
                                                  )
                                                }
                                                checked={
                                                  arrayCancelList.indexOf(
                                                    item.idviagemdespesa
                                                  ) === -1
                                                    ? false
                                                    : true
                                                }
                                                style={{ color: "#C62828" }}
                                              />
                                              <Typography
                                                variant="subtitle2"
                                                style={{ marginLeft: "5px" }}
                                              >
                                                Cancelar
                                              </Typography>
                                              <IconButton
                                                onClick={() =>
                                                  handleReadJustificarCancel(
                                                    item.idviagemdespesa
                                                  )
                                                }
                                              >
                                                <InfoOutlined />
                                              </IconButton>
                                            </Box>
                                          ) : null
                                        ) : (
                                          <Box>
                                            {item.retrocedido ? (
                                              <IconButton
                                                onClick={() =>
                                                  handleReadJustificarCancel(
                                                    item.idviagemdespesa
                                                  )
                                                }
                                              >
                                                <InfoOutlined />
                                              </IconButton>
                                            ) : null}
                                            <Tooltip title="Editar despesa">
                                              <IconButton
                                                onClick={() =>
                                                  handleClickAddDespesa(item)
                                                }
                                                style={{ color: "#c62828" }}
                                                edge="end"
                                                aria-label="delete"
                                              >
                                                <EditOutlined />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Remover despesa">
                                              <IconButton
                                                onClick={() =>
                                                  handleClickDeleteDespesa(item)
                                                }
                                                style={{ color: "#c62828" }}
                                                edge="end"
                                                aria-label="delete"
                                              >
                                                <DeleteOutline />
                                              </IconButton>
                                            </Tooltip>
                                            {permissaoAutoDespesa ? (
                                              <Tooltip title="Desvincular despesa">
                                                <IconButton
                                                  onClick={() =>
                                                    props.handleClickReturnList(
                                                      item
                                                    )
                                                  }
                                                  style={{ color: "#c62828" }}
                                                  edge="end"
                                                  aria-label="delete"
                                                >
                                                  <LaunchIcon />
                                                </IconButton>
                                              </Tooltip>
                                            ) : null}
                                          </Box>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Divider
                              style={{
                                marginTop: "15px",
                                marginBottom: "15px",
                              }}
                            />
                          </>
                        ))}
                      </>
                    ) : null}
                  </>
                ) : (
                  <Box
                    mt={!showEmViagem ? "120px" : "80px"}
                    display="flex"
                    justifyContent="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Box
                px={1}
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid container>
                  <Grid item xs={responsive ? 12 : 8}>
                    {responsive ? (
                      <Grid container>
                        {props.viagem.idviagem && props.viagem.idviagem > 0 ? (
                          <>
                            <Grid item xs={12}>
                              <Typography style={{ fontWeight: 600 }}>
                                Total gasto: R$
                                {Number(props.viagem.total).toFormatDefault()}
                              </Typography>
                            </Grid>
                          </>
                        ) : null}
                        {props.viagem.saldoinicial !== undefined &&
                        props.viagem.saldoinicial !== null ? (
                          <>
                            <Grid item xs={12}>
                              <Typography
                                style={
                                  Number(
                                    props.viagem?.saldoinicial -
                                      props.viagem.total
                                  ) < 0
                                    ? { fontWeight: 600, color: "#C62828" }
                                    : { fontWeight: 600 }
                                }
                              >
                                Saldo: R$
                                {Number(
                                  props.viagem?.saldoinicial -
                                    props.viagem.total
                                ).toFormatDefault()}
                              </Typography>
                            </Grid>
                          </>
                        ) : null}
                        {props.viagem.totalAuditado &&
                        props.viagem.totalAuditado > 0 &&
                        (props.viagem.status === 4 ||
                          props.viagem.status === 5 ||
                          props.viagem.status === 6) ? (
                          <>
                            <Grid item xs={12}>
                              <Typography
                                style={{ fontWeight: 600, color: "green" }}
                              >
                                Total Auditado: R$
                                {Number(
                                  props.viagem.totalAuditado
                                ).toFormatDefault()}
                              </Typography>
                            </Grid>
                          </>
                        ) : null}
                        {props.viagem.saldoinicial !== undefined &&
                        props.viagem.saldoinicial !== null &&
                        props.viagem.totalAuditado &&
                        props.viagem.totalAuditado > 0 &&
                        (props.viagem.status === 4 ||
                          props.viagem.status === 5 ||
                          props.viagem.status === 6) ? (
                          <>
                            <Grid item xs={12}>
                              <Typography
                                style={
                                  Number(
                                    props.viagem?.saldoinicial -
                                      props.viagem.totalAuditado
                                  ) < 0
                                    ? { fontWeight: 600, color: "#C62828" }
                                    : { fontWeight: 600 }
                                }
                              >
                                Saldo Final: R$
                                {Number(
                                  props.viagem?.saldoinicial -
                                    props.viagem.totalAuditado
                                ).toFormatDefault()}
                              </Typography>
                            </Grid>
                          </>
                        ) : null}
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={2}>
                          <Grid container>
                            <Grid item xs={12}>
                              {props.viagem.idviagem &&
                              props.viagem.idviagem > 0 ? (
                                <>
                                  <Typography style={{ fontWeight: 600 }}>
                                    Total gasto:{" "}
                                  </Typography>
                                </>
                              ) : null}
                            </Grid>
                            <Grid item xs={12}>
                              {props.viagem.saldoinicial !== undefined &&
                              props.viagem.saldoinicial !== null ? (
                                <>
                                  <Typography
                                    style={
                                      Number(
                                        props.viagem?.saldoinicial -
                                          props.viagem.total
                                      ) < 0
                                        ? { fontWeight: 600, color: "#C62828" }
                                        : { fontWeight: 600 }
                                    }
                                  >
                                    Saldo:
                                  </Typography>
                                </>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container>
                            <Grid item xs={12}>
                              {props.viagem.idviagem &&
                              props.viagem.idviagem > 0 ? (
                                <>
                                  <Typography style={{ fontWeight: 600 }}>
                                    R${" "}
                                    {Number(
                                      props.viagem.total
                                    ).toFormatDefault()}
                                  </Typography>
                                </>
                              ) : null}
                            </Grid>
                            <Grid item xs={12}>
                              {props.viagem.saldoinicial !== undefined &&
                              props.viagem.saldoinicial !== null ? (
                                <>
                                  <Typography
                                    style={
                                      Number(
                                        props.viagem?.saldoinicial -
                                          props.viagem.total
                                      ) < 0
                                        ? { fontWeight: 600, color: "#C62828" }
                                        : { fontWeight: 600 }
                                    }
                                  >
                                    R${" "}
                                    {Number(
                                      props.viagem?.saldoinicial -
                                        props.viagem.total
                                    ).toFormatDefault()}
                                  </Typography>
                                </>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container>
                            <Grid item xs={12}>
                              {props.viagem.totalAuditado &&
                              props.viagem.totalAuditado > 0 &&
                              (props.viagem.status === 4 ||
                                props.viagem.status === 5 ||
                                props.viagem.status === 6) ? (
                                <>
                                  <Typography
                                    style={{ fontWeight: 600, color: "green" }}
                                  >
                                    Total Auditado:{" "}
                                  </Typography>
                                </>
                              ) : null}
                            </Grid>
                            <Grid item xs={12}>
                              {props.viagem.saldoinicial !== undefined &&
                              props.viagem.saldoinicial !== null &&
                              props.viagem.totalAuditado &&
                              props.viagem.totalAuditado > 0 &&
                              (props.viagem.status === 4 ||
                                props.viagem.status === 5 ||
                                props.viagem.status === 6) ? (
                                <Typography
                                  style={
                                    Number(
                                      props.viagem?.saldoinicial -
                                        props.viagem.totalAuditado
                                    ) < 0
                                      ? { fontWeight: 600, color: "#C62828" }
                                      : { fontWeight: 600 }
                                  }
                                >
                                  Saldo Final:
                                </Typography>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container>
                            <Grid item xs={12}>
                              {props.viagem.totalAuditado &&
                              props.viagem.totalAuditado > 0 &&
                              (props.viagem.status === 4 ||
                                props.viagem.status === 5 ||
                                props.viagem.status === 6) ? (
                                <>
                                  <Typography
                                    style={{ fontWeight: 600, color: "green" }}
                                  >
                                    R${" "}
                                    {Number(
                                      props.viagem.totalAuditado
                                    ).toFormatDefault()}
                                  </Typography>
                                </>
                              ) : null}
                            </Grid>
                            <Grid item xs={12}>
                              {props.viagem.saldoinicial !== undefined &&
                              props.viagem.saldoinicial !== null &&
                              props.viagem.totalAuditado &&
                              props.viagem.totalAuditado > 0 &&
                              (props.viagem.status === 4 ||
                                props.viagem.status === 5 ||
                                props.viagem.status === 6) ? (
                                <Typography
                                  style={
                                    Number(
                                      props.viagem?.saldoinicial -
                                        props.viagem.totalAuditado
                                    ) < 0
                                      ? { fontWeight: 600, color: "#C62828" }
                                      : { fontWeight: 600 }
                                  }
                                >
                                  R${" "}
                                  {Number(
                                    props.viagem?.saldoinicial -
                                      props.viagem.totalAuditado
                                  ).toFormatDefault()}
                                </Typography>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={responsive ? 12 : 4}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {hideCancelar === true ? null : (
                      <Tooltip title={"Cancelar"} placement="top">
                        <span>
                          <Button
                            onClick={() =>
                              handleOpenModalCancelar(props.viagem.idviagem)
                            }
                            type="submit"
                            disable={showEmViagem}
                            className={classes.buttonSave}
                            style={
                              responsive
                                ? { marginRight: "10px", marginTop: "10px" }
                                : { marginRight: "10px" }
                            }
                          >
                            <CancelOutlined style={{ color: "#FFFFFF" }} />
                            <Typography>Cancelar </Typography>
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                    {hideConfirmar ? null : (
                      <Tooltip title={"Confirmar"} placement="top">
                        <span>
                          <Button
                            onClick={() => handleSubmitConfirmar()}
                            type="submit"
                            disabled={isLoading}
                            className={classes.buttonSave}
                            style={responsive ? { marginTop: "10px" } : null}
                          >
                            <CheckOutlined />
                            <Typography>Confirmar</Typography>
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </DialogActions>
          </Dialog>
        </Paper>
      </Grow>
    </>
  );
};

export default CadastroViagem;
