import cookie from "universal-cookie";
import api from "../../Api";

const cookies = new cookie();

export const prestacaoDeContasConfigRepositorio = {
  getAllGroupQrCode: async () => {
    const response = await api.get(
      "/PrestacaoContasConfigGroup/BuscarTodosGrupoConfiguracao",
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },
  postCreateGroupQrCode: async (model) => {
    const response = await api.post(
      "/PrestacaoContasConfigGroup/CriarNovoGrupoConfiguracao",
      model,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },

  putEditGroupQrCode: async (idgroup, model) => {
    const response = await api.put(
      "/PrestacaoContasConfigGroup/AtualizarGrupoConfiguracao/" + idgroup,
      model,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },

  deleteGroup: async (idgroup, model) => {
    const response = await api.delete(
      "/PrestacaoContasConfigGroup/DeletarGrupoConfiguracao/" + idgroup,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },

  getAllUsuarios: async () => {
    const response = await api.get(
      "/PrestacaoContasPermissao/BuscarPermissoesUsuarios",
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },

  getPermissionUsuario: async () => {
    const response = await api.get(
      "/PrestacaoContasPermissao/GetPermissaoByUserFront",
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },

  putEditPermissions: async (idusuario, model) => {
    const response = await api.put(
      "/PrestacaoContasPermissao/EditarPermissoesUsuarios/" + idusuario,
      model,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },

  putEditPermissionUser: async (idusuario, idgroup) => {
    const response = await api.put(
      `/Usuario/AtualizarGrupoPrestacaoContas/${idusuario}`,
      idgroup,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
          "Content-Type": "application/json", // Especificando o tipo de conteúdo
        },
      }
    );

    return response.data;
  },

  getBuscarListTipoSub: async () => {
    const response = await api.get(
      `/PrestacaoContasConfigGroup/BuscarTodosGrupoConfiguracao`,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
          "Content-Type": "application/json", // Especificando o tipo de conteúdo
        },
      }
    );

    return response.data;
  },

  postNovaCategoria: async (model) => {
    const response = await api.post(
      `/PrestacaoContaDespesaTipoSubtipo/CriarTipoSubtipoDespesa`, model,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
          "Content-Type": "application/json", // Especificando o tipo de conteúdo
        },
      }
    );

    return response.data;
  },
};
