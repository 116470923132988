import React from 'react';
import { Typography, Grid, Box, Tooltip, Stack, ListItemAvatar, ListItemText } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';
import { ImageComponent } from '../../../../../../components/ImageComponent';
import { CreationDate } from '../../../../Desktop/sections';

export const InfoHodometroMComponent = ({ despesa }) => {
    return (
        <>
            <Grid container item xs={12} style={{ width: "600px" }}>
                <Grid item xs={12} style={{ padding: "8px" }}>
                    {despesa.hodometro?.comprovanteida !== null && despesa.hodometro?.comprovantevolta === "" ? (
                        <>
                            <Tooltip title="Clique e veja foto inicial">
                                <Stack style={{
                                    boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                                    padding: "10px",
                                    borderRadius: "15px",
                                    cursor: "pointer"
                                }} direction="row" spacing={2}>
                                    <ListItemAvatar>
                                        <ImageComponent width={48} height={48} src={despesa.hodometro.comprovanteida} alt={"Imagem não encontrada"} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={"Foto Inicial"}
                                        secondary={
                                            <>
                                                <Box component="span">
                                                    {despesa.hodometro.kmida} Km/l
                                                </Box>
                                            </>
                                        }
                                    />
                                </Stack>
                            </Tooltip>
                        </>
                    ) : (null)}
                    {despesa.hodometro?.comprovantevolta !== null && despesa.hodometro?.comprovantevolta !== "" ? (
                        <>
                            <Tooltip title="Clique e veja foto final">
                                <Stack style={{
                                    boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                                    padding: "10px",
                                    borderRadius: "15px",
                                    cursor: "pointer",
                                    marginTop: "10px"
                                }} direction="row" spacing={2}>
                                    <ListItemAvatar>
                                        <ImageComponent width={48} height={48} src={despesa.hodometro?.comprovantevolta} alt={"Imagem não encontrada"} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={"Foto Inicial"}
                                        secondary={
                                            <>
                                                <Box component="span">
                                                    {despesa.hodometro?.kmfim} Km/l
                                                </Box>
                                            </>
                                        }
                                    />
                                </Stack>
                            </Tooltip>
                        </>
                    ) : (null)}
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 0 }}>
                            <Typography variant="body1">
                                Data de criação
                            </Typography>
                            <Typography variant="body1">
                                Total em km
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 0 }}>
                            <Typography variant="h6" width={140}><CreationDate data={despesa.datadecriacao} /></Typography>
                            <Typography variant="h3">{despesa.hodometro?.kmtotal !== null ? despesa.hodometro?.kmtotal + " Km" : "Não mensionado"}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 0, justifyContent: "flex-end" }}>
                            <ArrowUpward color="success" />
                            <Typography variant="body1" sx={{ ml: 1 }}>
                                Total há receber
                            </Typography>
                        </Box>
                        <Typography variant="h3" sx={{ fontWeight: 'bold', marginLeft: "auto" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.hodometro?.valor || 0)}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}