import React, { useState } from "react";
import { useStyles } from "./styles";
import { Avatar, Badge, Button, Chip, Grid, IconButton, InputAdornment, Tooltip, Typography } from "@material-ui/core";
import { MoreVertOutlined, SearchOutlined, SyncOutlined, TuneOutlined } from "@material-ui/icons";
import LinesTextField from "../../../../components/LinesTextField";

import Enums from "../../../../enums";
import { Pagination } from "@material-ui/lab";
import { useEffect } from "react";


export const BookingListMobile = (props) => {
    const dadosUsuario = props.dadosUsuario;
    const [dataBookingList, setDataBookingList] = useState(props.dataBookingList);
    const [valueTuneStatus, setValueTuneStatus] = useState(props.valueTuneStatus);
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const classes = useStyles();

    const handleClickOpenDialogBookingTicket = (item) => {
        if (item.tipo === 1) {
            props.setBookingSelected(item.idreserva)
            props.handleClickOpenDialogBookingTicket()
        } else if (item.tipo === 2) {
            props.setBookingSelected({ idsolicitacao: item.idsolicitacao, idreservatenant: item.idreserva })
            props.handleClickOpenDialogBookingRequestTicket()
        } else if (item.tipo === 3) {
            props.setBookingSelected(item.idreserva)
            props.handleClickOpenDialogHotelTicket()
        }
    };

    useEffect(() => {
        setDataBookingList(props.dataBookingListSearch.length === 0 ? props.dataBookingList : props.dataBookingListSearch);
    }, [props.dataBookingListSearch, props.dataBookingList]);    

    useEffect(() => {
        setDataBookingList(props.dataBookingList)
    }, [props.dataBookingList])

    useEffect(() => {
        setValueTuneStatus(props.valueTuneStatus)
    }, [props.valueTuneStatus])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleOpenMenuAction = (e, item) => {
        props.setAnchorMenuAction(e.currentTarget)
        props.handleClickOpenMenuAction()
        props.setBookingSelected(item)
    }

    return (
        <>
            <div className={classes.divContainer}>
                <Grid container>
                    <Grid item xs={12}>
                        <Tooltip title="Clique aqui para atualizar a caixa de entrada">
                            <IconButton className={classes.uibutton} onClick={() => props.getBookingList()} ><SyncOutlined /></IconButton>
                        </Tooltip>
                        <Tooltip title="Filtro de Status">
                            <IconButton onClick={props.handleClickTuneStatusOpen}>
                                <Badge color="secondary" variant="dot" invisible={valueTuneStatus !== "0" ? false : true}>
                                    <TuneOutlined style={{ color: "#c62828" }} />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        {dadosUsuario?.nivelpermissao === 6 || dadosUsuario.nivelpermissao === 1 || dadosUsuario?.nivelpermissao === 2 ? (
                            <Button className={classes.buttonCustom} onClick={() => props.handleClickModalAccept()}>
                                Aprovar todos
                            </Button>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} style={{ margin: "5px" }} >
                        <LinesTextField id="pesquisar" variant="outlined" margin="dense" onChange={props.onPesquisarChange} placeholder="Pesquisar..."
                            fullWidth InputProps={{ startAdornment: (<InputAdornment position="start"><SearchOutlined style={{ color: "#c62828" }} /></InputAdornment>), }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center", display: "flex" }}>
                        {/* <TablePagination
                            className={classes.pagination}
                            rowsPerPageOptions={[10, 25, 100, { value: dataBookingList.length, label: "Todos", },]}
                            // labelDisplayedRows={defaultLabelDisplayedRows}
                            component="div"
                            count={dataBookingList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={props.handleChangePage}
                            onChangeRowsPerPage={props.handleChangeRowsPerPage}
                            labelRowsPerPage=""
                            nextIconButtonText="Próxima página"
                            backIconButtonText="Página anterior"
                        /> */}
                        <Pagination count={dataBookingList.length} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
                    </Grid>
                </Grid>
                {dataBookingList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                    <Grid container item spacing={2} style={{ margin: 0 }} xs={12}>
                        <Grid item xs={12}>
                            <Grid container item xs={12}>
                                <Grid item xs={2} className={classes.gridAvatar}>
                                    <Tooltip title={item.solicitante}>
                                        <Avatar src={item.fotouser} />
                                    </Tooltip>
                                </Grid>
                                <Grid container item xs={8} style={{ padding: 10 }} onClick={() => handleClickOpenDialogBookingTicket(item)}>
                                    <Grid item xs={12} className={classes.gridCenter} >
                                        <Typography style={{ fontSize: "12px" }}>
                                            {item.dataultimaatualizacao ?
                                                new Date(item.dataultimaatualizacao).toLocaleString().substr(0, 10) + " " + (new Date(item.dataultimaatualizacao).toLocaleString().substr(11, 6)) : null}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridCenter} >
                                        <Typography>{props.definirAssunto(item.assunto)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridCenter} >
                                        <Chip
                                            size="medium"
                                            label={Enums.getReservaStatusDescricao(item.status)}
                                            style={{ background: Enums.getReservaStatusCor(item.status), color: "#ffffff", }}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid item xs={2} className={classes.gridAction}>
                                    <IconButton onClick={(e) => handleOpenMenuAction(e, item)}>
                                        <MoreVertOutlined />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </div>
        </>
    )
}
