import { Avatar, Chip, Typography } from "@mui/material";
import { tiposdedespesas_Mock } from "../mock/tiposdespesas";
import AddCardIcon from "@mui/icons-material/AddCard";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  CreditCardOffOutlined,
  CreditCardOutlined,
  CreditScoreOutlined,
} from "@mui/icons-material";
import { grey } from "../theme/palette";
import Flag from "react-world-flags";

export const EnumTipo = {
  despesas: {
    alimentacao: 1,
    estadia: 2,
    transporte: 3,
    aereo: 4,
    outros: 5,
    hodometro: 6,
    vazia: 7,
  },
  viagemstatus: {
    aguardandoinicio: 0, // financeiro tem q pagar?
    emviagem: 1, //financeiro ve se quiser
    contablidade: 2, // contablidade em viagem
    controladoria: 3, //financeiro ve se quiser
    financeiro: 4, // financeiro tem que ver
    finalizado: 5, // financeiro ve se quiser
    financeiroProg: 6, // financeiro tem que ver
    cancelado: 7, // não tem como chegar a esse status ainda
    viagemfinalizada: 8, // aprovação pendente (esta no auditor) financeiro não precisa ver, mas se quiser sim mano
    pendenteacorrecao: 9, // financeiro ve se quiser
    correcaorealizada: 10, // financeiro ve se quiser (esta no auditor) financeiro não precisa ver, mas se quiser sim mano
  },
  adiantamento: {
    naosolicitado: 0,
    solicitado: 1,
    aprovado: 2,
    negado: 3,
  },
  permissao: {
    admin: 1,
    auditor: 2,
    controlador: 3,
    usuario: 4,
  },
  moedas: {
    BRL: {
      id: 1,
      nome: "Real Brasileiro (BRL)",
      formatador: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      simbolo: "R$",
      flagCode: "BR",
    },
    ARS: {
      id: 2,
      nome: "Peso Argentino (ARS)",
      formatador: new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
      }),
      simbolo: "$",
      flagCode: "AR",
    },
    BOB: {
      id: 3,
      nome: "Boliviano (BOB)",
      formatador: new Intl.NumberFormat("es-BO", {
        style: "currency",
        currency: "BOB",
      }),
      simbolo: "Bs.",
      flagCode: "BO",
    },
    CLP: {
      id: 4,
      nome: "Peso Chileno (CLP)",
      formatador: new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }),
      simbolo: "$",
      flagCode: "CL",
    },
    COP: {
      id: 5,
      nome: "Peso Colombiano (COP)",
      formatador: new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }),
      simbolo: "$",
      flagCode: "CO",
    },
    EUR: {
      id: 6,
      nome: "Euro (EUR)",
      formatador: new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }),
      simbolo: "€",
      flagCode: "EU",
    },
    USD: {
      id: 7,
      nome: "Dólar Americano (USD)",
      formatador: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }),
      simbolo: "$",
      flagCode: "US",
    },
    UYU: {
      id: 8,
      nome: "Peso Uruguaio (UYU)",
      formatador: new Intl.NumberFormat("es-UY", {
        style: "currency",
        currency: "UYU",
      }),
      simbolo: "$U",
      flagCode: "UY",
    },
    VEF: {
      id: 9,
      nome: "Bolívar Venezuelano (VEF)",
      formatador: new Intl.NumberFormat("es-VE", {
        style: "currency",
        currency: "VEF",
      }),
      simbolo: "Bs.",
      flagCode: "VE",
    },
  },
};

export const formatarParaMoedaString = (valor, total) => {
  const mapaNomes = {
    BRL: "Real Brasileiro (BRL) ",
    ARS: "Peso Argentino (ARS) ",
    BOB: "Boliviano (BOB) ",
    CLP: "Peso Chileno (CLP) ",
    COP: "Peso Colombiano (COP) ",
    EUR: "Euro (EUR) ",
    USD: "Dólar Americano (USD) ",
    UYU: "Peso Uruguaio (UYU) ",
    VEF: "Bolívar Venezuelano (VEF) ",
  };

  if (typeof valor === "string") {
    return valor ? mapaNomes[valor] : undefined;
  } else if (typeof valor === "number") {
    const chave = Object.keys(EnumTipo.moedas).find(
      (key) => EnumTipo.moedas[key].id === valor
    );
    return chave ? mapaNomes[chave] : "Real Brasileiro (BRL)s";
  }

  return "Real Brasileiro (BRL)";
};

export const formatarParaMoeda = (valor, total) => {
  const mapaNomes = {
    BRL: (
      <Typography variant="h6">
        <Chip
          avatar={
            <Avatar
              style={{
                backgroundColor: "transparent",
                marginRight: "8px",
                width: "24px",
                height: "24px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Flag
                code={"BR"}
                style={{
                  width: "auto",
                  height: "100%", // Ajusta a posição da imagem
                }}
                width="100%" // Remove o marginRight para que a imagem preencha completamente
              />
            </Avatar>
          }
          label={
            "Real Brasileiro (BRL) " +
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(total)
          }
          style={{
            backgroundColor: "#1836C7",
            color: "white",
            fontWeight: "600",
          }}
        />
      </Typography>
    ),
    ARS: (
      <Typography variant="h6">
        <Chip
          avatar={
            <Avatar
              style={{
                backgroundColor: "transparent",
                marginRight: "8px",
                width: "24px",
                height: "24px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Flag
                code={"AR"}
                style={{
                  width: "auto",
                  height: "100%", // Ajusta a posição da imagem
                }}
                width="100%"
              />
            </Avatar>
          }
          label={
            "Peso Argentino (ARS) " +
            new Intl.NumberFormat("es-AR", {
              style: "currency",
              currency: "ARS",
            }).format(total)
          }
          style={{
            backgroundColor: "#03A6CB",
            color: "white",
            fontWeight: "600",
          }}
        />
      </Typography>
    ),
    BOB: (
      <Typography variant="h6">
        <Chip
          avatar={
            <Avatar
              style={{
                backgroundColor: "transparent",
                marginRight: "8px",
                width: "24px",
                height: "24px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Flag
                code={"BO"}
                style={{
                  width: "auto",
                  height: "100%", // Ajusta a posição da imagem
                }}
                width="100%"
              />
            </Avatar>
          }
          label={
            "Boliviano (BOB) " +
            new Intl.NumberFormat("es-BO", {
              style: "currency",
              currency: "BOB",
            }).format(total)
          }
          style={{
            backgroundColor: "#43a047",
            color: "white",
            fontWeight: "600",
          }}
        />
      </Typography>
    ),
    CLP: (
      <Typography variant="h6">
        <Chip
          avatar={
            <Avatar
              style={{
                backgroundColor: "transparent",
                marginRight: "8px",
                width: "24px",
                height: "24px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Flag
                code={"CL"}
                style={{
                  width: "auto",
                  height: "100%", // Ajusta a posição da imagem
                }}
                width="100%"
              />
            </Avatar>
          }
          label={
            "Peso Chileno (CLP) " +
            new Intl.NumberFormat("es-CL", {
              style: "currency",
              currency: "CLP",
            }).format(total)
          }
          style={{
            backgroundColor: "#d32f2f",
            color: "white",
            fontWeight: "600",
          }}
        />
      </Typography>
    ),
    COP: (
      <Typography variant="h6">
        <Chip
          avatar={
            <Avatar
              style={{
                backgroundColor: "transparent",
                marginRight: "8px",
                width: "24px",
                height: "24px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Flag
                code={"CO"}
                style={{
                  width: "auto",
                  height: "100%", // Ajusta a posição da imagem
                }}
                width="100%"
              />
            </Avatar>
          }
          label={
            "Peso Colombiano (COP) " +
            new Intl.NumberFormat("es-CO", {
              style: "currency",
              currency: "COP",
            }).format(total)
          }
          style={{
            backgroundColor: "#ff5722",
            color: "white",
            fontWeight: "600",
          }}
        />
      </Typography>
    ),
    EUR: (
      <Typography variant="h6">
        <Chip
          avatar={
            <Avatar
              style={{
                backgroundColor: "transparent",
                marginRight: "8px",
                width: "24px",
                height: "24px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Flag
                code={"EU"}
                style={{
                  width: "auto",
                  height: "100%", // Ajusta a posição da imagem
                }}
                width="100%"
              />
            </Avatar>
          }
          label={
            "Euro (EUR) " +
            new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(total)
          }
          style={{
            backgroundColor: "#105CFF",
            color: "white",
            fontWeight: "600",
          }}
        />
      </Typography>
    ),
    USD: (
      <Typography variant="h6">
        <Chip
          avatar={
            <Avatar
              style={{
                backgroundColor: "transparent",
                marginRight: "8px",
                width: "24px",
                height: "24px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Flag
                code={"US"}
                style={{
                  width: "auto",
                  height: "100%", // Ajusta a posição da imagem
                }}
                width="100%"
              />
            </Avatar>
          }
          label={
            "Dólar Americano (USD) " +
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(total)
          }
          style={{
            backgroundColor: "#1976d2",
            color: "white",
            fontWeight: "600",
          }}
        />
      </Typography>
    ),
    UYU: (
      <Typography variant="h6">
        <Chip
          avatar={
            <Avatar
              style={{
                backgroundColor: "transparent",
                marginRight: "8px",
                width: "24px",
                height: "24px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Flag
                code={"UY"}
                style={{
                  width: "auto",
                  height: "100%", // Ajusta a posição da imagem
                }}
                width="100%"
              />
            </Avatar>
          }
          label={
            "Peso Uruguaio (UYU) " +
            new Intl.NumberFormat("es-UY", {
              style: "currency",
              currency: "UYU",
            }).format(total)
          }
          style={{
            backgroundColor: "#ffca28",
            color: "white",
            fontWeight: "600",
          }}
        />
      </Typography>
    ),
    VEF: (
      <Typography variant="h6">
        <Chip
          avatar={
            <Avatar
              style={{
                backgroundColor: "transparent",
                marginRight: "8px",
                width: "24px",
                height: "24px",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Flag
                code={"VE"}
                style={{
                  width: "auto",
                  height: "100%", // Ajusta a posição da imagem
                }}
                width="100%"
              />
            </Avatar>
          }
          label={
            "Bolívar Venezuelano (VEF) " +
            new Intl.NumberFormat("es-VE", {
              style: "currency",
              currency: "VEF",
            }).format(total)
          }
          style={{
            backgroundColor: "#9c27b0",
            color: "white",
            fontWeight: "600",
          }}
        />
      </Typography>
    ),
  };

  if (typeof valor === "string") {
    return valor ? mapaNomes[valor] : undefined;
  } else if (typeof valor === "number") {
    const chave = Object.keys(EnumTipo.moedas).find(
      (key) => EnumTipo.moedas[key].id === valor
    );
    return chave ? mapaNomes[chave] : undefined;
  }

  return undefined;
};

export const formatarParaMoedaStringComFlag = (valor, total) => {
  const mapaNomes = {
    BRL: (
      <Typography variant="h6">
        <Avatar
          style={{
            backgroundColor: "transparent",
            marginRight: "8px",
            width: "24px",
            height: "24px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Flag
            code={"BR"}
            style={{
              width: "auto",
              height: "100%", // Ajusta a posição da imagem
            }}
            width="100%" // Remove o marginRight para que a imagem preencha completamente
          />
        </Avatar>
        Real Brasileiro (BRL)
      </Typography>
    ),
    ARS: (
      <Typography variant="h6">
        <Avatar
          style={{
            backgroundColor: "transparent",
            marginRight: "8px",
            width: "24px",
            height: "24px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Flag
            code={"AR"}
            style={{
              width: "auto",
              height: "100%", // Ajusta a posição da imagem
            }}
            width="100%"
          />
        </Avatar>
        Peso Argentino (ARS)
      </Typography>
    ),
    BOB: (
      <Typography variant="h6">
        <Avatar
          style={{
            backgroundColor: "transparent",
            marginRight: "8px",
            width: "24px",
            height: "24px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Flag
            code={"BO"}
            style={{
              width: "auto",
              height: "100%", // Ajusta a posição da imagem
            }}
            width="100%"
          />
        </Avatar>
        Boliviano (BOB)
      </Typography>
    ),
    CLP: (
      <Typography variant="h6">
        <Avatar
          style={{
            backgroundColor: "transparent",
            marginRight: "8px",
            width: "24px",
            height: "24px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Flag
            code={"CL"}
            style={{
              width: "auto",
              height: "100%", // Ajusta a posição da imagem
            }}
            width="100%"
          />
        </Avatar>
        Peso Chileno (CLP)
      </Typography>
    ),
    COP: (
      <Typography variant="h6">
        <Avatar
          style={{
            backgroundColor: "transparent",
            marginRight: "8px",
            width: "24px",
            height: "24px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Flag
            code={"CO"}
            style={{
              width: "auto",
              height: "100%", // Ajusta a posição da imagem
            }}
            width="100%"
          />
        </Avatar>
        Peso Colombiano (COP)
      </Typography>
    ),
    EUR: (
      <Typography variant="h6">
        <Avatar
          style={{
            backgroundColor: "transparent",
            marginRight: "8px",
            width: "24px",
            height: "24px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Flag
            code={"EU"}
            style={{
              width: "auto",
              height: "100%", // Ajusta a posição da imagem
            }}
            width="100%"
          />
        </Avatar>
        Euro (EUR)
      </Typography>
    ),
    USD: (
      <Typography variant="h6">
        <Avatar
          style={{
            backgroundColor: "transparent",
            marginRight: "8px",
            width: "24px",
            height: "24px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Flag
            code={"US"}
            style={{
              width: "auto",
              height: "100%", // Ajusta a posição da imagem
            }}
            width="100%"
          />
        </Avatar>
        Dólar Americano (USD)
      </Typography>
    ),
    UYU: (
      <Typography variant="h6">
        <Avatar
          style={{
            backgroundColor: "transparent",
            marginRight: "8px",
            width: "24px",
            height: "24px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Flag
            code={"UY"}
            style={{
              width: "auto",
              height: "100%", // Ajusta a posição da imagem
            }}
            width="100%"
          />
        </Avatar>
        Peso Uruguaio (UYU)
      </Typography>
    ),
    VEF: (
      <Typography variant="h6">
        <Avatar
          style={{
            backgroundColor: "transparent",
            marginRight: "8px",
            width: "24px",
            height: "24px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Flag
            code={"VE"}
            style={{
              width: "auto",
              height: "100%", // Ajusta a posição da imagem
            }}
            width="100%"
          />
        </Avatar>
        Bolívar Venezuelano (VEF)
      </Typography>
    ),
  };

  if (typeof valor === "string") {
    return valor ? mapaNomes[valor] : undefined;
  } else if (typeof valor === "number") {
    const chave = Object.keys(EnumTipo.moedas).find(
      (key) => EnumTipo.moedas[key].id === valor
    );
    return chave ? mapaNomes[chave] : undefined;
  }

  return undefined;
};

export const formatarParaNomeStatusUser = (valor) => {
  valor = 4;
  const mapaNomes = {
    admin: (
      <Chip
        label="Admin"
        variant="outlined"
        style={{ backgroundColor: "#1835C701", fontWeight: "600" }} // cor ajustada
      />
    ),
    auditor: (
      <Chip
        label="Auditor"
        variant="outlined"
        style={{
          backgroundColor: "#1835C701",
          fontWeight: "600",
        }} // cor ajustada
      />
    ),
    controlador: (
      <Chip
        label="Controlador"
        variant="outlined"
        style={{
          backgroundColor: "#1835C701",
          fontWeight: "600",
        }} // cor ajustada
      />
    ),
    usuario: (
      <Chip
        label="Usuário"
        variant="outlined"
        style={{
          backgroundColor: "#1835C701",
          fontWeight: "600",
        }} // cor ajustada
      />
    ),
  };

  if (typeof valor === "string") {
    return valor ? mapaNomes[valor] : undefined;
  } else if (typeof valor === "number") {
    const chave = Object.keys(EnumTipo.permissao).find(
      (key) => EnumTipo.permissao[key] === valor
    );
    return chave ? mapaNomes[chave] : undefined;
  }
};

export const formatarParaNomeStatusViagem = (valor) => {
  const mapaNomes = {
    viagemfinalizada: (
      <Chip
        label="Aprovação pendente"
        style={{ backgroundColor: "#1836C7", color: "white" }} // cor ajustada
      />
    ),
    aguardandoinicio: (
      <Chip
        label="Aguardando Início"
        style={{ backgroundColor: "#3B2E2E", color: "white" }} // cor ajustada
      />
    ),
    emviagem: (
      <Chip
        label="Em viagem"
        style={{ backgroundColor: "#43a047", color: "white" }} // cor ajustada
      />
    ),
    contablidade: (
      // <Chip
      //   label="Contabilidade"
      //   style={{ backgroundColor: "#FFAB00", color: "white" }} // cor ajustada
      // />
      <Chip
        label="Aprovação pendente"
        style={{ backgroundColor: "#1836C7", color: "white" }} // cor ajustada
      />
    ),
    controladoria: (
      <Chip
        label="Controladoria"
        style={{ backgroundColor: "#FFAB00", color: "white" }} // cor ajustada
      />
    ),
    financeiro: (
      <Chip
        label="Financeiro"
        style={{ backgroundColor: "#22C55E", color: "white" }} // cor ajustada
      />
    ),
    financeiroProg: (
      <Chip
        label="Pagamento agendado"
        style={{ backgroundColor: "#00A76F", color: "white" }} // cor ajustada
      />
    ),
    finalizado: (
      <Chip
        label="Finalizado"
        style={{ backgroundColor: "#004B50", color: "white" }} // cor ajustada
      />
    ),
    cancelado: (
      <Chip
        label="Cancelado"
        style={{ backgroundColor: "#A70000", color: "white" }} // cor ajustada
      />
    ),
    pendenteacorrecao: (
      <Chip
        label="Pendente a correção"
        style={{ backgroundColor: "#fdaa20", color: "white" }} // cor ajustada
      />
    ),
    correcaorealizada: (
      <Chip
        label="Correção realizada"
        style={{ backgroundColor: "#3B62D8", color: "white" }} // cor ajustada
      />
    ),
  };

  if (typeof valor === "string") {
    return valor ? mapaNomes[valor] : undefined;
  } else if (typeof valor === "number") {
    const chave = Object.keys(EnumTipo.viagemstatus).find(
      (key) => EnumTipo.viagemstatus[key] === valor
    );
    return chave ? mapaNomes[chave] : undefined;
  }

  // Encontrando a chave correspondente ao valor numérico

  // Retorna o nome formatado se encontrado, caso contrário, retorna undefined
};

export const formatarParaNomeStatusViagemDescription = (valor) => {
  const mapaNomes = {
    viagemfinalizada: "O Auditor está verificando suas despesas",
    aguardandoinicio: "Viagem criada há espera de despesas",
    emviagem: "Viagem em andamento",
    contablidade: "O Auditor está verificando suas despesas",
    controladoria: "Alguma despesa está com problemas",
    financeiro: "Financeiro já está cuidando da sua viagem",
    financeiroProg: "Seu pagamento está agendado",
    finalizado: "Viagem finalizada",
    cancelado: "Viagem cancelada",
    pendenteacorrecao: "Alguma despesa nescessita de correção",
    correcaorealizada: "Correção realizada",
  };

  if (typeof valor === "string") {
    return valor ? mapaNomes[valor] : undefined;
  } else if (typeof valor === "number") {
    const chave = Object.keys(EnumTipo.viagemstatus).find(
      (key) => EnumTipo.viagemstatus[key] === valor
    );
    return chave ? mapaNomes[chave] : undefined;
  }

  // Encontrando a chave correspondente ao valor numérico

  // Retorna o nome formatado se encontrado, caso contrário, retorna undefined
};

export const mapaNomesViagemStatus = {
  viagemfinalizada: "Aprovação pendente",
  aguardandoinicio: "Aguardando Início",
  emviagem: "Em viagem",
  contablidade: "Contablidade",
  controladoria: "Controladoria",
  financeiro: "Financeiro",
  financeiroProg: "Pagamento agendado",
  finalizado: "Finalizado",
  cancelado: "Cancelado",
  pendenteacorrecao: "Pendente a correção",
  correcaorealizada: "Correção realizada",
};

export const formatarParaNomeStatusViagemString = (valor) => {
  // Encontrando a chave correspondente ao valor numérico
  const chave = Object.keys(EnumTipo.viagemstatus).find(
    (key) => EnumTipo.viagemstatus[key] === valor
  );

  // Retorna o nome formatado se encontrado, caso contrário, retorna undefined
  return chave ? mapaNomesViagemStatus[chave] : undefined;
};

export const formatarParaNomeTipoDespesa = (valor) => {
  // Encontrando a descrição correspondente ao valor numérico
  const despesa = tiposdedespesas_Mock.find((d) => d.value === valor);
  return despesa ? despesa.label : undefined;
};

export const formatarParaListaTipoDespesa = (list) => {
  if (list.length > 0) {
    const despesasFormatadas = list
      .map((item) => formatarParaNomeTipoDespesa(item.tipo))
      .filter((nome) => nome !== undefined) // Filtra elementos não definidos
      .join(", ")
      .replace(/, ([^,]*)$/, " e $1");

    return `${despesasFormatadas}.`;
  } else {
    return "Não há despesas registradas nesta viagem.";
  }
};

export const formatarParaNomeStatusAdiantamento = (valor = 0, total = null) => {
  const formatTotal = (total) => {
    return total
      ? `${new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(total)}`
      : "";
  };

  const mapaNomes = {
    solicitado: (
      <Chip
        label={`Solicitado adiantamento ${formatTotal(total)}`}
        color="secondary"
        icon={<AddCardIcon />}
      />
    ),
    naosolicitado: (
      <Chip
        label={`Adiantamento não solicitado`}
        color="default"
        style={{ color: grey[700] }}
        icon={<CreditCardOutlined />}
      />
    ),
    aprovado: (
      <Chip
        label={`Aprovado adiantamento ${formatTotal(total)}`}
        color="success"
        style={{ color: "white" }}
        icon={<CreditScoreOutlined />}
      />
    ),
    negado: (
      <Chip
        label={`Negado adiantamento ${formatTotal(total)}`}
        color="warning"
        icon={<CreditCardOffOutlined />}
      />
    ),
  };

  // Encontrando a chave correspondente ao valor numérico
  const chave = Object.keys(EnumTipo.adiantamento).find(
    (key) => EnumTipo.adiantamento[key] === valor
  );

  // Retorna o nome formatado se encontrado, caso contrário, retorna undefined
  return chave ? (
    mapaNomes[chave]
  ) : (
    <Chip
      label={`Adiantamento não solicitado`}
      color="default"
      style={{ color: grey[700] }}
      icon={<CreditCardOutlined />}
    />
  );
};

export const formatarParaNomeStatusAdiantamentoList = (adiantamentos, adtotal = 0) => {
  const formatTotal = (total) => {
    return total
      ? new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(total)
      : "";
  };

  const mapaNomes = {
    solicitado: (total) => (
      <Chip
        label={`Solicitado adiantamento ${formatTotal(adtotal)}`}
        color="secondary"
        icon={<AddCardIcon />}
      />
    ),
    naosolicitado: (
      <Chip
        label={`Adiantamento não solicitado`}
        color="default"
        style={{ color: grey[700] }}
        icon={<CreditCardOutlined />}
      />
    ),
    aprovado: (total) => (
      <Chip
        label={`Aprovado adiantamento ${formatTotal(adtotal)}`}
        color="success"
        style={{ color: "white" }}
        icon={<CreditScoreOutlined />}
      />
    ),
    negado: (total) => (
      <Chip
        label={`Negado adiantamento ${formatTotal(adtotal)}`}
        color="warning"
        icon={<CreditCardOffOutlined />}
      />
    ),
  };

  // Verifica se algum adiantamento foi solicitado
  const adiantamentoSolicitado = adiantamentos.find(
    (adiantamento) => adiantamento.pedido
  );

  if (!adiantamentoSolicitado) {
    return mapaNomes.naosolicitado;
  }

  // Encontrando a chave correspondente ao valor numérico para o status
  const chave = Object.keys(EnumTipo.adiantamento).find(
    (key) => EnumTipo.adiantamento[key] === adiantamentoSolicitado.status
  );

  // Retorna o nome formatado se encontrado, caso contrário, retorna como não solicitado
  return chave
    ? mapaNomes[chave](adiantamentoSolicitado.total)
    : mapaNomes.naosolicitado;
};

export const formatarParaNomeStatusAdiantamentoListI = (adiantamentos) => {
  const mapaNomes = {
    solicitado: () => (
      <Chip
        label={`Solicitado adiantamento`}
        color="secondary"
        icon={<AddCardIcon />}
      />
    ),
    naosolicitado: (
      <Chip
        label={`Adiantamento não solicitado`}
        color="default"
        style={{ color: grey[700] }}
        icon={<CreditCardOutlined />}
      />
    ),
    aprovado: () => (
      <Chip
        label={`Aprovado adiantamento`}
        color="success"
        style={{ color: "white" }}
        icon={<CreditScoreOutlined />}
      />
    ),
    negado: () => (
      <Chip
        label={`Negado adiantamento`}
        color="warning"
        icon={<CreditCardOffOutlined />}
      />
    ),
  };

  // Verifica se algum adiantamento foi solicitado
  const adiantamentoSolicitado = adiantamentos.find(
    (adiantamento) => adiantamento.pedido
  );

  if (!adiantamentoSolicitado) {
    return mapaNomes.naosolicitado;
  }

  // Encontrando a chave correspondente ao valor numérico para o status
  const chave = Object.keys(EnumTipo.adiantamento).find(
    (key) => EnumTipo.adiantamento[key] === adiantamentoSolicitado.status
  );

  // Retorna o nome formatado se encontrado, caso contrário, retorna como não solicitado
  return chave
    ? mapaNomes[chave](adiantamentoSolicitado.total)
    : mapaNomes.naosolicitado;
};

export const formatarParaNomeStatusAdiantamentoBool = (adiantamentos = null) => {
  if(adiantamentos === null){
    return "No";
  }
  // Verifica se algum adiantamento foi solicitado
  const adiantamentoSolicitado = adiantamentos.find(
    (adiantamento) =>
      adiantamento.pedido &&
      adiantamento.status == EnumTipo.adiantamento.solicitado
  );

  if (!adiantamentoSolicitado) {
    return "pendente";
  } else {
    return "No";
  }

  // Encontrando a chave correspondente ao valor numérico para o status
};

export const formatarParaNomeStatusAdiantamentoString = (valor) => {
  const mapaNomes = {
    solicitado: "Solicitado adiantamento",
    aprovado: "Aprovado adiantamento",
    negado: "Negado adiantamento",
  };

  // Encontrando a chave correspondente ao valor numérico
  const chave = Object.keys(EnumTipo.adiantamento).find(
    (key) => EnumTipo.adiantamento[key] === valor
  );

  // Retorna o nome formatado se encontrado, caso contrário, retorna undefined
  return chave ? mapaNomes[chave] : undefined;
};

export const formatarParaAuditado = (despesas) => {
  let total = 0;

  despesas?.forEach((despesa) => {
    if (despesa.valor_auditado != null && despesa.valor_auditado != 0) {
      total += despesa.total - despesa.valor_auditado;
    }
  });

  return total != 0 ? total : 0;
};

export const formatarTotalDespesasForMoeda = (despesas, status) => {
  let total = 0;

  despesas?.forEach((despesa) => {
    if (despesa.tipo_moeda === status) {
      total += despesa.total;
    }
  });

  return formatarValorComMoeda(status, total);
};

export const getEnumForID = (idMoeda) => {
  // Encontre a moeda pelo ID
  const moedaEncontrada = Object.values(EnumTipo.moedas).find(
    (moeda) => moeda.id === idMoeda
  );

  if (!moedaEncontrada) {
    throw new Error(`Moeda com ID ${idMoeda} não encontrada.`);
  }

  return moedaEncontrada;
};

export const formatarValorComMoeda = (idMoeda, valor) => {
  // Encontre a moeda pelo ID
  const moedaEncontrada = Object.values(EnumTipo.moedas).find(
    (moeda) => moeda.id === idMoeda
  );

  if (!moedaEncontrada) {
    throw new Error(`Moeda com ID ${idMoeda} não encontrada.`);
  }

  return moedaEncontrada.formatador.format(valor);
};

export const converterValor = (valorString) => {
  // Remover os pontos (separadores de milhar)
  const valorSemMilhar = valorString.replace(/\./g, "");

  // Substituir a vírgula pelo ponto
  const valorComPonto = valorSemMilhar.replace(",", ".");

  // Garantir que o parseFloat considere corretamente valores como "0,11"
  // Verifica se o valor começa com "0," e trata de maneira adequada
  const valorFloat = valorComPonto.startsWith("0.")
    ? parseFloat("0" + valorComPonto.substring(1))
    : parseFloat(valorComPonto);

  return valorFloat;
};

export const getCurrencySymbol = (moeda) => {
  if (moeda) {
    let simbol = getEnumForID(moeda);
    return simbol.simbolo;
  } else {
    // Default para BRL caso a moeda não seja encontrada ou seja undefined, null, ou vazio
    return EnumTipo.moedas.BRL.simbolo;
  }
};


export const formatarMoeda = (valor) => {
  // Remove todos os caracteres que não são números
  const valorLimpo = valor.replace(/\D/g, "");

  if (valorLimpo === "" || valor === "-1") {
    return "0,00";
  }

  // Converte o valor limpo para um número
  const valorNumerico = parseFloat(valorLimpo) / 100;

  // Formata o número como moeda com separadores de milhar e duas casas decimais
  return valorNumerico.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const hasApprovedAdiantamento = (adiantamentos) => {
  return adiantamentos.some(adiantamento => adiantamento.status === EnumTipo.adiantamento.aprovado);
};

