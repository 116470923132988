import PropTypes from 'prop-types';
import { useCallback } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

// import { shortDateLabel } from 'src/components/custom-date-range-picker';
import { HighlightOffOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { Typography } from '@mui/material';
import { grey } from '../../../../../theme/palette';
// ----------------------------------------------------------------------

export default function OrderTableFiltersResult({
  filters,
  onFilters,
  handleStatusOption,
  onResetFilters,
  results,
  ...other
}) {
  const clear = useCallback(() => {
    handleStatusOption('todos', [0]);
  }, [handleStatusOption]); // Dependência

  const handleRemoveKeyword = useCallback(() => {
    onFilters('name', '');
    clear()
  }, [onFilters, clear]);

  const handleRemoveStatus = useCallback(() => {
    onFilters('status', 'all');
    clear()
  }, [onFilters, clear]);

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
          Encontrados
        </Box>
      </Box>

      <Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap" alignItems="center">
        {filters.statusFilter !== 'todos' && (
          <Block label="Status:">
            <Chip size="small" label={<Typography variant="subtitle2">{filters.statusFilter}</Typography>} style={{ backgroundColor: "#212B36", color: "white", borderRadius: "6px" }} onDelete={handleRemoveStatus} />
          </Block>
        )}

        {!!filters.name && (
          <Block label="Descrição:">
            <Chip label={filters.name} size="small" style={{ backgroundColor: grey[800], color: "white" }} onDelete={handleRemoveKeyword} deleteIcon={<HighlightOffOutlined style={{ color: "white" }} />} />
          </Block>
        )}

        <Button
          style={{ color: "rgb(255, 86, 48)" }}
          onClick={onResetFilters}
          startIcon={<ClearIcon />}
        >
          Limpar
        </Button>
      </Stack>
    </Stack>
  );
}

OrderTableFiltersResult.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  onResetFilters: PropTypes.func,
  results: PropTypes.number,
};

// ----------------------------------------------------------------------

function Block({ label, children, sx, ...other }) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}

Block.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  sx: PropTypes.object,
};
