import { Box, Button, ButtonGroup, Container, Grid, Hidden, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { GetApp, SearchOutlined } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '../../../components/snackbar';
import UDatePicker from '../../../components/udatepicker';
import ExportExcel from '../../../components/UExportExcel';
import ULoaderVoo from '../../../components/uloadervoo';
import USearchField from '../../../components/usearchfield';
import centroCustoRepositorio from '../../../repositorios/centroCusto';
import pessoaRepositorio from '../../../repositorios/pessoa';
import economiaEmissaoRepositorio from '../../../repositorios/relatorio/economiaEmissao';
import Dashboard from '../../dashboard';
import EconomiaNaEmissaoGrid from './economiaNaEmissaoGrid/index.js';
import EconomiaNaEmissaoList from './economiaNaEmissaoList/index.js';
import { useStyles } from './styles';

function isValidDate(date) {
    return moment(date).isValid();
}

export const ListaEconomiaNaEmissao = () => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const permissao = useSelector(state => state.permissao.permissaoDashboard);
    const politicaViagem = useSelector(state => state.permissao.politicaViagem);
    const [dataInicio, setDataInicio] = useState(new Date());
    const [dataFim, setDataFim] = useState(new Date());
    const [data, setData] = useState({ itens: [], total: { comparativototal: 0, consolidadototal: 0, economiatotal: 0, economiapercent: '0%' } });
    const [centroCusto, setCentroCusto] = useState(null);
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [viajante, setViajante] = useState(null);
    const [viajantes, setViajantes] = useState([]);
    const [colunasExcel, setColunasExcel] = useState([]);
    const [rowsExcel, setRowsExcel] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [snackVariant, setSnackVariant] = useState('error');
    const [snackMessage, setSnackMessage] = useState('');
    const [snackVisible, setSnackVisible] = useState({ visible: false });

    const convertStrToDblBRL = (value) => {
        let result = parseFloat(value)
        return (
            result.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        )
    }

    const handleClickPesquisar = async () => {
        if (dataInicio > dataFim) {
            setSnackVariant('error');
            setSnackMessage('Data de início maior que data final.');
            setSnackVisible({ visible: true })
            return;
        }

        if (!isValidDate(dataInicio) || !isValidDate(dataFim)) {
            setSnackVariant('error');
            setSnackMessage('Data Inválida.');
            setSnackVisible({ visible: true });
            return;
        }
        let dto = {
            dataInicio,
            dataFim,
            idCentroCusto: centroCusto ? centroCusto.value : 0,
            idViajante: viajante ? viajante.value : 0,
        }
        try {
            setIsLoading(true)
            let response = await economiaEmissaoRepositorio.postAllByTenant(dto);
            setData(response.data)
        } catch (e) {
            setSnackVariant('error');
            setSnackMessage('Erro ao pesquisar dados.');
            setSnackVisible({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    const handleChangeData = () => {
        let inicio = new Date();
        if ((dataInicio !== '' && dataInicio !== undefined && dataInicio !== null) && (dataFim !== '' && dataFim !== undefined && dataFim !== null)) {
            if (dataInicio.toLocaleDateString() === new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString() &&
                dataFim.toLocaleDateString() === new Date().toLocaleDateString()) {
                return 1;
            }
            if (dataInicio.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth(), 1).toLocaleDateString() &&
                dataFim.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0).toLocaleDateString()) {
                return 2;
            }
            if (dataInicio.toLocaleDateString() === new Date(inicio.getFullYear(), 0, 1).toLocaleDateString() &&
                dataFim.toLocaleDateString() === new Date(inicio.getFullYear(), 11, 31).toLocaleDateString()) {
                return 3;
            }
            return 4;
        }
    }

    const setPeriodoPesquisa = (periodo) => {
        let inicio = new Date();
        let fim = new Date();
        switch (periodo) {
            case 1:
                inicio.setDate(inicio.getDate() - 7);
                handleClickPesquisar()
                break;
            case 2:
                inicio = new Date(inicio.getFullYear(), inicio.getMonth(), 1);
                fim = new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0);
                handleClickPesquisar()
                break;
            case 3:
                inicio = new Date(inicio.getFullYear(), 0, 1);
                fim = new Date(inicio.getFullYear(), 11, 31);
                handleClickPesquisar()
                break;
            default: 
                console.warn('Esperado periodo')
                break;
        }
        setDataInicio(inicio);
        setDataFim(fim);
    }

    useEffect(() => {
        (async () => {
            let responseCentros = await centroCustoRepositorio.getSearchField();
            let responseViajantes = await pessoaRepositorio.getSearchField();

            setCentrosCusto(responseCentros);
            setViajantes(responseViajantes);
        })()
    }, [])

    useEffect(() => {
        setColunasExcel(['ID', 'Passageiro', 'Trecho', 'Data Criação', 'Loc.', 'Duração (dias)',
            'Consolidado', 'Comparativo', 'Economia']);
        const datas = data?.itens?.map(item => {
            return [
                item.idreserva + '',
                item.passageiro,
                item.trecho,
                item.datacriacao,
                item.loc,
                item.duracao,
                item.consolidado,
                item.comparativo,
                item.economia
            ]
        })
        setRowsExcel(datas);
    }, [data])

    return (
        <section>
            <Hidden smUp>
                <ULoaderVoo isLoading={isLoading} />
            </Hidden>
            <Snackbar {...{ variant: snackVariant, message: snackMessage, visible: snackVisible.visible, setSnackbar: setSnackVisible }} />
            <Container maxWidth="xl">
                <Box mt={2} mb={responsive ? 10 : 0}>
                    <Box>
                        <Box mb={1} >
                            <Grid container>
                                <Grid item xs={responsive ? 12 : 2}>
                                    <Typography variant="h6" component="h1">Economia Emissão IA</Typography>
                                </Grid>
                                {responsive ? null : (
                                    <>
                                        <Grid item xs={8}>
                                            <Grid item xs={12} className={classes.groupButton}>
                                                <ButtonGroup>
                                                    <Button
                                                        style={{
                                                            background: handleChangeData() === 1 ? '#c62828' : 'transparent',
                                                            color: handleChangeData() === 1 ? 'white' : '#c62828',
                                                            border: '1px solid #c62828',
                                                            '&:hover': {
                                                                background: '#c62828',
                                                                color: 'white'
                                                            }
                                                        }}
                                                        onClick={() => setPeriodoPesquisa(1)}
                                                    >
                                                        <Typography style={{ fontSize: 11 }}>Semanal</Typography>
                                                    </Button>
                                                    <Button
                                                        style={{
                                                            background: handleChangeData() === 2 ? '#c62828' : 'transparent',
                                                            color: handleChangeData() === 2 ? 'white' : '#c62828',
                                                            border: '1px solid #c62828',
                                                            '&:hover': {
                                                                background: '#c62828',
                                                                color: 'white'
                                                            }
                                                        }}
                                                        onClick={() => setPeriodoPesquisa(2)}
                                                    >
                                                        <Typography style={{ fontSize: 11 }}>Mensal</Typography>
                                                    </Button>
                                                    <Button
                                                        style={{
                                                            background: handleChangeData() === 3 ? '#c62828' : 'transparent',
                                                            color: handleChangeData() === 3 ? 'white' : '#c62828',
                                                            border: '1px solid #c62828',
                                                            '&:hover': {
                                                                background: '#c62828',
                                                                color: 'white'
                                                            }
                                                        }}
                                                        onClick={() => setPeriodoPesquisa(3)}
                                                    >
                                                        <Typography style={{ fontSize: 11 }}>Anual</Typography>
                                                    </Button>
                                                    <Button
                                                        style={{
                                                            background: handleChangeData() === 4 ? '#c62828' : 'transparent',
                                                            color: handleChangeData() === 4 ? 'white' : '#c62828',
                                                            border: '1px solid #c62828',
                                                            '&:hover': {
                                                                background: '#c62828',
                                                                color: 'white'
                                                            }
                                                        }}
                                                        onClick={() => setPeriodoPesquisa(4)}
                                                    >
                                                        <Typography style={{ fontSize: 11 }}>Personalizado</Typography>
                                                    </Button>
                                                </ButtonGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                    </>
                                )}
                            </Grid>
                        </Box>
                        <Paper elevation={3}>
                            <Box p={3}>
                                <form autoComplete="off">
                                    <Grid container spacing={1} >
                                        <Grid item xs={12} md={2}>
                                            <UDatePicker date={dataInicio} setDate={setDataInicio} label="Data Início" />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <UDatePicker date={dataFim} setDate={setDataFim} label="Data Fim" />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            {permissao.relatorioCentroCusto ? (
                                                <USearchField disabled={!politicaViagem.utilizarcentrocusto} label="Centro de custo" itens={centrosCusto} value={centroCusto} setValue={setCentroCusto} />
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            {permissao.relatorioPessoal ? (
                                                <USearchField label="Viajante" itens={viajantes} value={viajante} setValue={setViajante} />
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={12} md={1} style={{ alignSelf: 'center' }}>
                                            <Button onClick={handleClickPesquisar} className={classes.button} variant="contained" fullWidth >
                                                <SearchOutlined />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Paper>
                    </Box>
                    <Box mt={2} >
                        {responsive ? (
                            <>
                                <Grid container style={{ display: 'flex', flexDirection: 'column' }} spacing={1}>
                                    <Grid item xs={12} >
                                        <Box border={1} borderColor={'#41A5D1'} borderRadius={4} padding={1} display={'flex'} sx={{ flexDirection: 'column' }} justifyContent={'center'} alignItems={'center'}>
                                            <Typography className={classes.typography_B} variant='body1'>Consolidado: </Typography>
                                            <Typography className={classes.typography_B} variant="body2">{convertStrToDblBRL(data.total.consolidadototal)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box border={1} borderColor={'#E50008'} borderRadius={4} padding={1} sx={{ flexDirection: 'column' }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <Typography className={classes.typography_R} variant='body1'>Comparativo: </Typography>
                                            <Typography className={classes.typography_R} variant="body2">{convertStrToDblBRL(data.total.comparativototal)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box border={1} borderColor={'#43B947'} borderRadius={4} padding={1} sx={{ flexDirection: 'column' }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <Typography className={classes.typography_G} variant='body1'>Economia: </Typography>
                                            <Typography className={classes.typography_G} variant="body2">{convertStrToDblBRL(data.total.economiatotal)} ( {data.total.economiapercent})</Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} className={classes.gridRowCenter}>
                                        <ExportExcel name="Relatório da Economia Emissão IA" columns={colunasExcel} data={rowsExcel} element={
                                            <>
                                                <Button className={classes.button}>
                                                    <GetApp />
                                                    Exportar
                                                </Button>
                                            </>
                                        } />
                                    </Grid>
                                </Grid>
                                <EconomiaNaEmissaoList data={data.itens} />
                            </>
                        ) : (
                            <>
                                <Paper elevation={3}>
                                    <Grid container spacing={1} style={{ margin: '0px' }}>
                                        <Grid item xs={10} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                                            <Box border={1} width={'23vw'} borderColor={'#41A5D1'} borderRadius={4} padding={1} display={'flex'} sx={{ flexDirection: 'column' }} justifyContent={'center'} alignItems={'center'}>
                                                <Typography className={classes.typography_B} variant='body1'>Consolidado: </Typography>
                                                <Typography className={classes.typography_B} variant="body2">{convertStrToDblBRL(data.total.consolidadototal)}</Typography>
                                            </Box>
                                            <Box border={1} width={'23vw'} borderColor={'#E50008'} borderRadius={4} padding={1} sx={{ flexDirection: 'column' }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                <Typography className={classes.typography_R} variant='body1'>Comparativo: </Typography>
                                                <Typography className={classes.typography_R} variant="body2">{convertStrToDblBRL(data.total.comparativototal)}</Typography>
                                            </Box>
                                            <Box border={1} width={'23vw'} borderColor={'#43B947'} borderRadius={4} padding={1} sx={{ flexDirection: 'column' }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                <Typography className={classes.typography_G} variant='body1'>Economia: </Typography>
                                                <Typography className={classes.typography_G} variant="body2">{convertStrToDblBRL(data.total.economiatotal)} ( {data.total.economiapercent})</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2} className={classes.gridRowFlexEnd}>
                                            <ExportExcel name="Relatório de Economia Emissão IA" columns={colunasExcel} data={rowsExcel} element={
                                                <>
                                                    <Button className={classes.button}>
                                                        <GetApp />
                                                        Exportar
                                                    </Button>
                                                </>
                                            } />
                                        </Grid>
                                    </Grid>
                                    <EconomiaNaEmissaoGrid isLoading={isLoading} data={data.itens} />
                                </Paper>
                            </>
                        )}
                    </Box>
                </Box>
            </Container>
        </section >
    )
};

export const EconomiaNaEmissao = props => {
    return (
        <div>
            <Dashboard {...props} component={ListaEconomiaNaEmissao} />
        </div>
    )
};
export default EconomiaNaEmissao;
