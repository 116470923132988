import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogFinalizar = (props) => {
    const classes = useStyles();

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleClickConfirmar = () => {
        props.saveEditedItens();
        handleClose()
    }

    return (
        <>
            <form >
                <Dialog open={props.openDialog} onClose={props.handleCloseDialog} TransitionComponent={Transition}>
                    <DialogTitle style={{ background: '#c62828' }}>
                        <Typography variant={'h5'} style={{ color: '#ffffff' }}>Confirmação</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Favor confirmar se deseja enviar todos os itens para o sistema.</Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} className={classes.buttoncustom}>
                            Cancelar
                        </Button>
                        <Button onClick={handleClickConfirmar} className={classes.buttoncustom}>
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </form >
        </>
    )
}

export default DialogFinalizar;
