import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Auth from './components/Auth';
import ConsultaAereo from './views/aereos/consulta';
import ListaAereos from './views/aereos/lista';
import { AgendaPacotes } from './views/agendaPacotes';
import ListaCentroCusto from './views/centrodecusto/lista';
import CartoesPage from './views/configuracao/cartao';
import ListaEmail from './views/configuracao/email/lista';
import ListaConfiguracaoOrganizacao from './views/configuracao/organizacao/lista';
import ListaConfiguracaoPermissaoUsuario from './views/configuracao/permissao/usuario/lista';
import DashboardPage from './views/dashboard/dashboard';
import EcoGestaoDash from './views/ecogestao';
import ListaFornecedores from './views/fornecedores/lista';
import ImprimirBilhete from './views/imprimirBilhete';
import Login from './views/login';
import ListaPessoa from './views/pessoa/lista';
import PoliticaViagemDoc from './views/politicaViagemDoc';
import ListaProdutos from './views/produtos/lista';
import ListaImportarExcel from './views/relatorios/comprasConsolidadas/importarExcel/lista';
import ListaComprasConsolidadas from './views/relatorios/comprasConsolidadas/lista';
import RelatorioComprasRealizadas from './views/relatorios/comprasRealizadas';
import DespesaViagem from './views/relatorios/despesasViagem';
import EconomiaNaEmissao from './views/relatorios/economiaNaEmissao';
import ListaExcedentes from './views/relatorios/excedentes/lista';
import ListaItinerarioRealizado from './views/relatorios/ItinerarioRealizado/lista';
import RelatorioMenorTarifa from './views/relatorios/menorTarifa';
import ListaOrcamentoCompras from './views/relatorios/orcamentoCompras/lista';
import ListaTempoAntecedencia from './views/relatorios/tempoAntecedencia/lista';
import ListaTempoDecisao from './views/relatorios/tempoDecisao/lista';
import CadastroSolicitacao from './views/solicitacao/cadastro';
import ListaUsuario from './views/usuario/lista';
import ListaEmpresa from './views/empresa/lista';
import ListaEcoGestao from './views/relatorios/ecogestao';
import ListaReservas_Main_Dash from './views/reservas_temp';
import { ListaEconomiaTotal } from './views/relatorios/economiaTotal';
import HotelPageDash from './views/hotel';
import PagePrestacaoDeContas from './views/prestacaodecontas';
import FinanceiroComponentDash from './views/financeiro';

const PrivateRoute = ({ component: Component, ...rest }) => {

    return (
        <Route {...rest} render={props => (
            <Auth>
                <Component {...{ ...props }} />
            </Auth>
        )} />

    )
};

export const Routes = () => {
    const cookies = new Cookies();

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" render={props => (
                    cookies.get('token') ? (
                        <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
                    ) : (
                        <Login {...props} />
                    )
                )} />

                {/* Não funcionou token autenticado via url ele tem / e vai pra outra URL e não reconhece arrumar isso */}
                <PrivateRoute exact path="/dashboard" component={props => <DashboardPage {...props} />} />
                <PrivateRoute exact path="/listaReservas" component={props => <ListaReservas_Main_Dash {...props} />} />
                <PrivateRoute exact path="/listaReservas/:idreserva" component={props => <ListaReservas_Main_Dash {...props} />} />
                <PrivateRoute exact path="/listaReservas/:idreserva/:idacao/:token" component={props => <ListaReservas_Main_Dash {...props} />} />
                <PrivateRoute exact path="/consultaAereo" component={props => <ConsultaAereo {...props} />} /> 
                <PrivateRoute exact path="/listaAereo" component={props => <ListaAereos {...props} />} />
                <PrivateRoute exact path="/listaPessoa" component={props => <ListaPessoa {...props} />} />
                <PrivateRoute exact path="/listaUsuario" component={props => <ListaUsuario {...props} />} />
                <PrivateRoute exact path="/listaProduto" component={props => <ListaProdutos {...props} />} />
                <PrivateRoute exact path="/listaFornecedor" component={props => <ListaFornecedores {...props} />} />
                <PrivateRoute exact path="/listaEmpresa" component={props => <ListaEmpresa {...props} />} />
                <PrivateRoute exact path="/listaCentroCusto" component={props => <ListaCentroCusto {...props} />} />
                <PrivateRoute exact path="/listaConfiguracaoOrganizacao" component={props => < ListaConfiguracaoOrganizacao {...props} />} />
                <PrivateRoute exact path="/listaConfiguracaoPermissaoUsuario" component={props => < ListaConfiguracaoPermissaoUsuario {...props} />} />
                <PrivateRoute exact path="/FugaDeCapital" component={props => < ListaTempoAntecedencia {...props} />} />
                <PrivateRoute exact path="/listaTempoDecisao" component={props => < ListaTempoDecisao {...props} />} />
                <PrivateRoute exact path="/listaOrcamentoCompras" component={props => <ListaOrcamentoCompras  {...props} />} />
                <PrivateRoute exact path="/listaComprasRealizadas" component={props => <RelatorioComprasRealizadas  {...props} />} />
                <PrivateRoute exact path="/listaEconomiaTotal" component={props => <ListaEconomiaTotal  {...props} />} />
                <PrivateRoute exact path="/listaComprasConsolidadas" component={props => <ListaComprasConsolidadas  {...props} />} />
                <PrivateRoute exact path="/listaImportarExcel" component={props => <ListaImportarExcel  {...props} />} />
                <PrivateRoute exact path="/listaEcoGestao" component={props => <ListaEcoGestao  {...props} />} />
                <PrivateRoute exact path="/listaItinerarioRealizado" component={props => <ListaItinerarioRealizado  {...props} />} />
                <PrivateRoute exact path="/listaDespesasViagem" component={props => <DespesaViagem  {...props} />} />
                <PrivateRoute exact path="/PrestacaoDeContas" component={props => <PagePrestacaoDeContas  {...props} />} />
                <PrivateRoute exact path="/listaMenorTarifa" component={props => <RelatorioMenorTarifa  {...props} />} />
                <PrivateRoute exact path="/listaExcedentes" component={props => <ListaExcedentes  {...props} />} />
                <PrivateRoute exact path="/cadastroSolicitacao" component={props => <CadastroSolicitacao  {...props} />} />
                <PrivateRoute exact path="/imprimirBilhete" component={props => <ImprimirBilhete  {...props} />} />
                <PrivateRoute exact path="/ListaEmails" component={props => <ListaEmail  {...props} />} />
                <PrivateRoute exact path="/PoliticaViagemDoc" component={props => <PoliticaViagemDoc  {...props} />} />
                <PrivateRoute exact path="/listaEconomiaNaEmissao" component={props => <EconomiaNaEmissao  {...props} />} />
                <PrivateRoute exact path="/listaAgendaPacotes" component={props => <AgendaPacotes  {...props} />} />
                <PrivateRoute exact path="/Cartoes" component={props => <CartoesPage  {...props} />} />
                <PrivateRoute exact path="/ecogestao" component={props => <EcoGestaoDash  {...props} />} />
                <PrivateRoute exact path="/hotel" component={props => <HotelPageDash  {...props} />} />
                <PrivateRoute exact path="/Financeiro" component={props => <FinanceiroComponentDash  {...props} />} />
                <PrivateRoute exact path="*" component={props => { props.history.push('/dashboard'); return (<PoliticaViagemDoc {...props} />) }} />
            </Switch>
        </BrowserRouter>
    )
};

export default Routes;
