import React from 'react';
import 'shepherd.js/dist/css/shepherd.css';
import { Button } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import './style.css';

export const Tour = ({ startTour, setOpen, handleClickInitialTour, setOpenTutorial }) => {
    const onClickButton = () => {
        startTour(setOpen, setOpenTutorial)
        if (handleClickInitialTour) {
            handleClickInitialTour()
        }
    }

    return (
        <div>
            <Button
                onClick={onClickButton}
                startIcon={<AutoAwesomeIcon className="color-wave-icon" />}
                size="medium"
                variant="outlined"
                color="inherit"
                style={{ justifyContent: "center", borderColor: "#D6D6D6" }}
            >
                Ajuda
            </Button>
        </div>
    );
};

export const TourComponent = ({ startTour, handleClickNext }) => {
    const onClickButton = () => {
        if (handleClickNext != null) {
            startTour(handleClickNext)
            return
        }
        startTour()
    }

    return (
        <div>
            <Button
                onClick={onClickButton}
                startIcon={<AutoAwesomeIcon className="color-wave-icon" />}
                size="medium"
                variant="outlined"
                color="inherit"
                style={{ justifyContent: "center", borderColor: "#D6D6D6" }}
            >
                Ajuda
            </Button>
        </div>
    );
};