import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Slide, Box, FormControlLabel, Checkbox } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { LocalizationProvider, pickersLayoutClasses } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ptBR } from 'date-fns/locale';
import "./style.css"
import dayjs from 'dayjs';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme, ismap, fullScreen }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        padding: ismap ? '0px' : '0px',
        borderRadius: fullScreen ? "0px" : "0px",
    }
}));

export const DialogMobileEditDate = ({ title, startDate, setStartDate, endDate, setEndDate, body, width, action, open, handleClose, fullScreen = true, ismap = false, isNoTitle = false }) => {
    const [isExtendDate, setIsExtendDate] = React.useState(false);

    const handleDateRange = (type) => {
        const today = dayjs();
        let start, end;

        switch (type) {
            case 'Este mês':
                startDate = today.startOf('month');
                endDate = today.endOf('month');
                break;
            case 'Essa semana':
                startDate = today.startOf('week');
                endDate = today.endOf('week');
                break;
            case 'Próxima semana':
                startDate = isExtendDate && endDate ? endDate.add(1, 'day').startOf('week') : today.add(1, 'week').startOf('week');
                endDate = isExtendDate && endDate ? endDate.add(1, 'week').endOf('week') : today.add(1, 'week').endOf('week');
                break;
            case 'Próximo mês':
                startDate = isExtendDate && endDate ? endDate.add(1, 'day').startOf('month') : today.add(1, 'month').startOf('month');
                endDate = isExtendDate && endDate ? endDate.add(1, 'month').endOf('month') : today.add(1, 'month').endOf('month');
                break;
            default:
                startDate = null;
                endDate = null;
        }

        setStartDate(start);
        setEndDate(end);
    };

    const handleDateChange = (newValue) => {
        const [start, end] = newValue;
        if (!startDate) {
            setStartDate(start);
        } else {
            setEndDate(end);
        }
    };

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                style={fullScreen ? { borderRadius: "0px" } : { borderRadius: "20px" }}
                aria-describedby="alert-dialog-slide-description"
                fullScreen={fullScreen}
                maxWidth={width}
                open={open}
                keepMounted
                TransitionComponent={Transition}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Grid container item xs={12}>
                        <Grid item xs={1} style={{ textAlign: "right", justifyContent: "flex-end", alignItems: "center", display: "flex" }}>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <ArrowBack />
                            </IconButton>
                        </Grid>
                        <Grid item xs={11} style={{ textAlign: "center", marginLeft: "-10px" }}>
                            <Typography style={{ fontWeight: "600", fontSize: "18px" }}>{title}</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {/* <Box sx={{ display: 'flex', gap: 1, marginBottom: 2 }}>
                        <Button variant="outlined" onClick={() => handleDateRange('Este mês')}>Este mês</Button>
                        {!isExtendDate && (
                            <Button variant="outlined" onClick={() => handleDateRange('Semana passada')}>Semana passada</Button>
                        )}
                        <Button variant="outlined" onClick={() => handleDateRange('Essa semana')}>Essa semana</Button>
                        <Button variant="outlined" onClick={() => handleDateRange('Próxima semana')}>Próxima semana</Button>
                        <Button variant="outlined" onClick={() => handleDateRange('Próximo mês')}>Próximo mês</Button>
                    </Box>
                    <FormControlLabel
                        control={<Checkbox checked={isExtendDate} onChange={(e) => setIsExtendDate(e.target.checked)} />}
                        label="Estender data"
                    /> */}

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ptBR}>
                            <DemoContainer components={['DateRangePicker', 'DateRangeCalendar']}>
                                <DemoItem component="DateRangeCalendar">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            '.MuiTypography-caption': {
                                                fontSize: '1.25rem', // Aumenta o tamanho das letras dos dias
                                            },
                                            '.MuiPickersCalendarHeader-label': {
                                                fontSize: '1.5rem', // Aumenta o tamanho do cabeçalho do mês
                                                fontWeight: 'bold',
                                            },
                                            '.MuiDateRangeCalendar-root': {
                                                display: 'flex',
                                                flexDirection: 'column', // Altera a direção para coluna
                                            },
                                            '.MuiPickersCalendarHeader-root': {
                                                alignSelf: 'flex-start',
                                            },
                                            '.MuiPickersDay-root': {
                                                fontSize: '1.05rem',
                                                '&.Mui-selected.Mui-disabled': {
                                                    backgroundColor: 'lightcoral',
                                                },
                                            },
                                            '.MuiDateRangeCalendar-container': {
                                                borderRight: "none !important", // Remove a borda direita entre os calendários
                                            },
                                        }}
                                    >
                                        <StaticDateRangePicker
                                            displayStaticWrapperAs="desktop"
                                            value={[startDate, endDate]}
                                            onChange={handleDateChange}
                                            calendars={2}
                                        />
                                    </Box>
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>
                    </Box>
                </DialogContent>
                {action != null ? (
                    <DialogActions>
                        {action}
                    </DialogActions>
                ) : null}
            </BootstrapDialog>
        </>
    )
};
