import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme, ismap, fullScreen }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': { // Ajustado para selecionar corretamente o papel do diálogo
    padding: ismap ? '0px' : '10px',
    borderRadius: fullScreen ? "0px" : "20px", // Adicionado aqui
  }
}));

export default function DialogComponent({ title, body, width, action, open = false, handleClose, fullScreen = false, ismap = false, isNoTitle = false }) {
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        style={fullScreen ? { borderRadius: "0px" } : { borderRadius: "20px" }}
        aria-describedby="alert-dialog-slide-description"
        fullScreen={fullScreen}
        maxWidth={width}
        open={open}
        keepMounted
        TransitionComponent={Transition}
        ismap={ismap}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Grid container item xs={12}>
            <Grid item xs={11}>
              <Typography style={{ fontWeight: "600", fontSize: "18px" }}>{title}</Typography>
            </Grid>
            <Grid item xs={1} style={{ textAlign: "right", justifyContent: "flex-end", alignItems: "center", display: "flex" }}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={ismap ? {
          padding: "0px",
          fontFamily: "sans-serif",
          overflow: "hidden", /* Permite rolagem */
          overflowY: "scroll", /* Mantém a rolagem vertical funcional */
          scrollbarWidth: "none", /* Oculta a barra de rolagem no Firefox */
          msOverflowStyle: "none"
        } : {
          fontFamily: "sans-serif",
          overflow: "hidden", /* Permite rolagem */
          overflowY: "scroll", /* Mantém a rolagem vertical funcional */
          scrollbarWidth: "none", /* Oculta a barra de rolagem no Firefox */
          msOverflowStyle: "none"
        }}>
          {body}
        </DialogContent>
        {action != null ? (
          <DialogActions>
            {action}
          </DialogActions>
        ) : (null)}
      </BootstrapDialog>
    </React.Fragment>
  );
}