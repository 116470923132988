import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import getCroppedImg from './utils/utils';
import { IconButton, MenuItem, Select } from '@mui/material';
import { Crop } from '@mui/icons-material';

function ImageCropUploader({ image, aspect, onCropCompleteInternal, onCropComplete }) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);


    return (
        <div style={{ textAlign: 'center' }}>
            {image && (
                <div>
                    <div style={{ position: 'relative', width: '100%', height: "500px" }}>
                        <Cropper
                            image={image}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect} // Utiliza a proporção selecionada
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropCompleteInternal}
                        />
                    </div>
                    <div>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e, zoom) => setZoom(zoom)}
                        />
                    </div>
                    
                </div>
            )}
        </div>
    );
}

export default ImageCropUploader;
