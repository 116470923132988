import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Button, Divider, IconButton, Menu, MenuItem, Select } from '@mui/material';
import RejectionFiles from './errors-rejection-files';
import Image from '../images/image';
import { CameraAltOutlined, Crop, DocumentScannerOutlined, PhotoLibraryOutlined, Send } from '@mui/icons-material';
import DialogComponent from '../views/prestacaodecontas/Desktop/sections/dialogs-desktop';
import ImageCropUploader from './upload-image';
import { DialogViewImage } from '../components/autalii_expanses/dialogs/dialog_MobileImage';
import getCroppedImg from './utils/utils';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    width: 400,
    boxShadow: "0px 0px 2.2px 0px rgba(0,0,0,0.5)"
  },
}));


export default function UploadAvatar({ error, file, setFile, handleDropAvatar, disabled, helperText, fullScreen = false, sx, ...other }) {
  const [captureMode, setCaptureMode] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openCropDialog, setOpenCropDialog] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [editImage, setEditimage] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [aspect, setAspect] = useState(4 / 3);
  const [showAspectSelector, setShowAspectSelector] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const toggleAspectSelector = () => {
    setShowAspectSelector((prev) => !prev);
  };

  const onCropCompleteInternal = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Função para abrir o diálogo de recorte


  // Função para lidar com o envio da imagem recortada
  const handleCropComplete = async (croppedImg) => {
    setCroppedImage(croppedImg);
    setOpenCropDialog(false);
    setEditimage(false);

    if (croppedImg) {
      try {
        // Converte a imagem recortada para Blob
        const response = await fetch(croppedImg);
        const blob = await response.blob();
        // Cria um novo objeto File
        const croppedFile = new File([blob], 'cropped_image.jpeg', { type: 'image/jpeg' });
        // Define o novo arquivo e aciona handleDropAvatar
        setFile(croppedFile);
        if (typeof handleDropAvatar === 'function') {
          handleDropAvatar([croppedFile]); // Passa o arquivo recortado
        }
      } catch (error) {
        console.error("Erro ao converter imagem recortada para File:", error);
      }
    }
  };

  const isIphone = /iPhone/i.test(navigator.userAgent);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    if (isIphone || fullScreen) {
      setCaptureMode('')
      document.getElementById('file-input').click();
      if (isIphone) {
        setEditimage(true);
      }
      return
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClickConfirm = (mode) => {
    setCaptureMode(mode);
    document.getElementById('file-input').click();
    setEditimage(true);
    handleClose();
  };

  const handleAspectChange = (newAspect) => {
    setAspect(newAspect);
    handleMenuClose();
  };

  const handleMenuOpen = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl2(null);
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled,
    accept: 'image/*',
    capture: captureMode,
    ...other,
  });

  const hasFile = !!file;
  const hasError = isDragReject || !!error;
  const imgUrl = typeof file === 'string' ? file : file?.preview;

  const handleCrop = async () => {
    try {
      const croppedImage = await getCroppedImg(imgUrl, croppedAreaPixels);
      handleCropComplete(croppedImage); // Retorna a imagem recortada para o componente pai
    } catch (e) {
      console.error(e);
    } finally {
      setOpenCropDialog(false);
    }
  };

  useEffect(() => {
    if (editImage && file) {
      setOpenCropDialog(true);
    }
  }, [file, editImage]);

  const renderPreview = hasFile && (
    <Image
      alt="avatar"
      src={imgUrl}
      sx={{
        width: 1,
        height: 1,
        borderRadius: '3%',
      }}
    />
  );

  const renderPlaceholder = (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={1}
      className="upload-placeholder"
      sx={{
        top: 0,
        left: 0,
        width: 1,
        height: 1,
        zIndex: 9,
        borderRadius: '3%',
        position: 'absolute',
        color: 'text.disabled',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
        transition: (theme) =>
          theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
          }),
        '&:hover': {
          opacity: 0.72,
        },
        ...(hasError && {
          color: 'error.main',
          bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        }),
        ...(hasFile && {
          zIndex: 9,
          opacity: 0,
          color: 'common.white',
          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.64),
        }),
      }}
    >
      <DocumentScannerOutlined />
      <Typography variant="caption">{file ? 'Editar comprovante' : 'Enviar comprovante'}</Typography>
    </Stack>
  );

  const renderContent = (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
        borderRadius: '3%',
        position: 'relative',
      }}
    >
      {renderPreview}
      {renderPlaceholder}
    </Box>
  );

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <StyledMenu
        sx={{ borderRadius: "16px", width: "250px" }}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handleClickConfirm("camera")} disableRipple width={100}>
          Galeria
          <PhotoLibraryOutlined style={{ marginLeft: "auto", color: "#313131" }} />
        </MenuItem>
        <Divider variant="li" />
        <MenuItem onClick={() => handleClickConfirm("")}>
          Tirar foto
          <CameraAltOutlined style={{ marginLeft: "auto", color: "#313131" }} />
        </MenuItem>
      </StyledMenu>
      <Box
        {...getRootProps()}
        onClick={handleClick}
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          p: 1,
          m: 'auto',
          width: "100%",
          maxWidth: "250px",
          height: "250px",
          maxHeight: "-webkit-fill-available",
          cursor: 'pointer',
          overflow: 'hidden',
          borderRadius: '3%',
          border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
        }}
      >

        {captureMode === "camera" ? (
          <input {...getInputProps()} id="file-input" capture />
        ) : (
          <input {...getInputProps()} id="file-input" />
        )}

        {renderContent}
      </Box>

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections} />
      <p style={{ display: "none" }}>
        {captureMode}
      </p>
      <DialogViewImage
        title={<div>
          <IconButton onClick={handleMenuOpen} variant="contained">
            <Crop style={{ color: "#FFFFFF" }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl2}
            open={Boolean(anchorEl2)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleAspectChange(1)}>Quadrado (1:1)</MenuItem>
            <MenuItem onClick={() => handleAspectChange(4 / 3)}>Retângulo (4:3)</MenuItem>
            <MenuItem onClick={() => handleAspectChange(16 / 9)}>Retângulo Larga (16:9)</MenuItem>
            <MenuItem onClick={() => handleAspectChange(3 / 4)}>Retrato (3:4)</MenuItem>
          </Menu>
        </div>}
        open={openCropDialog}
        handleClose={() => setOpenCropDialog(false)}
        body={
          <ImageCropUploader image={imgUrl} aspect={aspect} onCropCompleteInternal={onCropCompleteInternal} onCropComplete={handleCropComplete} />
        }
        action={
          <>
            <Button
              size="large"
              onClick={handleCrop}
              variant="outlined"
              color="primary"
              endIcon={
                <Send />
              }
            >
              Enviar
            </Button>
          </>
        }
        fullScreen={true}
      />
    </div>
  );
}

UploadAvatar.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};
