import { Grid, Typography } from "@mui/material";
import { CreationDate } from "../../../../Desktop/sections";

export const ExtrasDespesasComponentM = ({ extras }) => {
    return (
        <>
            <Grid item xs={12}>
                {extras?.map((value) => (
                    <>
                        <Grid
                            item xs={12}
                            style={{
                                padding: "20px", 
                                transition: 'background-color 0.5s ease', // Suaviza a transição do efeito de escurecimento
                                marginTop: "20px",
                                cursor: 'default', // Cursor padrão quando não está em hover
                                backgroundColor: "#FFFFFF",
                                textAlign: 'center',
                                borderRadius: "10px",
                                boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                            }}
                            container
                        >
                            <Grid container item xs={12}>
                                <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify", justifyContent: "center" }}>
                                    {/* <Typography style={{ fontWeight: "600" }}>{"Auto"}</Typography> */}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "left" }}>
                                <Typography style={{ fontWeight: "500", color: "#595959" }}>{value.descricao}</Typography>
                            </Grid>
                            <Grid item xs={12} style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignContent: "flex-start",
                                alignItems: "flex-start",
                                flexDirection: "row"
                            }}>
                                <Typography variant="body2" style={{ textAlign: "left" }}>
                                    {value.despesa_desc}
                                </Typography>
                            </Grid>
                            <Grid item xs={9} style={{ display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                                <CreationDate data={value.datacriacao} />
                            </Grid>
                            <Grid item xs={3} style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignContent: "flex-end",
                                alignItems: "flex-end",
                                flexDirection: "row"
                            }}>
                                <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.total)}</Typography>
                            </Grid>
                        </Grid>
                    </>
                ))}
            </Grid>
        </>
    )
}