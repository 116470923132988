import { Container, Grid, ThemeProvider, Typography, useMediaQuery, useTheme } from "@mui/material";
import Dashboard from "../dashboard";
import { themepalette } from "../../theme/palette";
import { _bankingCreditCard, _bankingRecentTransitions } from "../../mock/financeiro";
import { useState } from "react";
import { PagamentosComponent } from "./Pagamentos";
import IntegrationNotistack from "../../components/snackbar2/NewSnackBar";

const FinanceiroComponent = () => {
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('md'));
    const [currentTab, setCurrentTab] = useState("pagamentos");

    const TABSdesk = [
        {
            value: 'pagamentos',
            label: 'Pagamentos',
            viewer: <PagamentosComponent />
        },
        // {
        //     value: 'relatorios',
        //     label: 'Relatórios',
        //     viewer: <></>
        // },
    ];

    const handleChangeTab = (value) => {
        setCurrentTab(value)
    }

    return (
        <ThemeProvider theme={themepalette}>
            <IntegrationNotistack>
                <Container maxWidth="xl">
                    <Grid container xs={12} style={{ marginTop: "15px", display: "flex", alignContent: "center", alignItems: "center" }}>
                        <Grid item xs={fullscreen?12:6} style={{ padding: "20px" }}>
                            <Typography variant="h4" style={{ color: themepalette.palette.grey[800] }}>
                                Bem vindo de volta 👋 financeiro
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={fullscreen?12:6} style={fullscreen?{ textAlign: "center", padding: "20px" }:{ textAlign: "right", padding: "20px" }}>
                            <ButtonGroup variant="outlined" color="inherit" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                                {TABSdesk.map((tab) => (
                                    <Button
                                        key={tab.value}
                                        onClick={() => handleChangeTab(tab.value)}
                                        style={{
                                            textTransform: "none", // Mantém a capitalização original do texto
                                            fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                            borderColor: "#D6D6D6"
                                        }}
                                    >
                                        {tab.label}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Grid> */}
                        <Grid item xs={12}>
                            {TABSdesk.slice(0, 3).map(
                                (tab) =>
                                    tab.value === currentTab && (
                                        <>
                                            {tab.viewer}
                                        </>
                                    )
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </IntegrationNotistack>
        </ThemeProvider>
    )
}

export const FinanceiroComponentDash = (props) => {
    return (
        <div>
            <Dashboard {...props} component={FinanceiroComponent} />
        </div>
    );
};
export default FinanceiroComponentDash;