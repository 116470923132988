import { Container, Grid } from "@mui/material"
import { useCallback, useState } from "react"
import { PermissionList } from "./permissonslist"
import { AccountTreeOutlined, Category, PrivacyTipOutlined } from "@mui/icons-material"
import { ControlConfig } from "./control"
import DrawerMenu from "./menudrawer"
import { Categorias } from "./categorias"

export const ConfigAll = ({ permissions }) => {
    const TABS_GROUPED = [
        ...(permissions?.permissao_editarpermissao ? [
            {
                groupTitle: 'Pessoas',
                tabs: [
                    {
                        value: 'permissions',
                        label: 'Permissões',
                        icon: <PrivacyTipOutlined style={{ marginRight: "5px", marginTop: "5px" }} />, // Adicione o ícone relevante aqui
                        viewer: <PermissionList permissions={permissions} />
                    },
                ],
            },
        ] : []),
        ...(permissions.permissao_vergrupopalavra ? [
            {
                groupTitle: 'Geral',
                tabs: [
                    {
                        value: 'politicas',
                        label: 'Gerenciar Grupos',
                        icon: <AccountTreeOutlined style={{ marginRight: "5px", marginTop: "5px" }} />, // Adicione o ícone relevante aqui
                        viewer: <ControlConfig />
                    },
                    {
                        value: 'categorias',
                        label: 'Categorias',
                        icon: <Category style={{ marginRight: "5px", marginTop: "5px" }} />, // Adicione o ícone relevante aqui
                        viewer: <Categorias />
                    },
                ],
            },
        ] : []),
        // ...(permissions.permissao_vergrupopalavra ? [
        //     {
        //         groupTitle: 'Geral',
        //         tabs: [
        //             {
        //                 value: 'politicas',
        //                 label: 'Gerenciar Grupos',
        //                 icon: <Category style={{ marginRight: "5px", marginTop: "5px" }} />, // Adicione o ícone relevante aqui
        //                 viewer: <Categorias />
        //             },
        //         ],
        //     },
        // ] : []),
    ];


    const [currentTab, setCurrentTab] = useState("permissions");

    const handleChangeTab = useCallback((event, newValue) => {
        setCurrentTab(newValue);
    }, []);

    const getCurrentViewer = () => {
        for (const group of TABS_GROUPED) {
            for (const tab of group.tabs) {
                if (tab.value === currentTab) {
                    return tab.viewer;
                }
            }
        }
        return null;
    };

    return (
        <>
            <Container maxWidth="lg">
                <Grid container item xs={12}>
                    <Grid item xs={3}>
                        <DrawerMenu
                            TABS_GROUPED={TABS_GROUPED}
                            currentTab={currentTab}
                            handleChangeTab={handleChangeTab} />
                    </Grid>
                    <Grid item xs={9}>
                        {getCurrentViewer()}
                    </Grid>
                </Grid>
                <Grid item xs={12}>

                </Grid>
            </Container>
        </>
    )
}