import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    divContainer: {
        paddingBottom: '56px',
        overflowX: 'hidden'
    },

    gridAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },

    gridAvatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    gridCenter: {
        textAlign: 'center'
    },

    buttonCustom: {
        background: '#c62828',
        color: '#ffffff',
        minWidth: '1rem',
        '&:hover': {
            background: '#c62828',
            color: '#ffffff ',
        },
        borderRadius: "6px"
    }
}));
