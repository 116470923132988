import React, { useRef } from 'react';
import { Box, Typography, Card, CardContent, Divider, Grid, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { EnumTipo, formatarParaMoeda, formatarParaMoedaStringComFlag, formatarValorComMoeda } from '../../../../enums/enumPrestacaoDeContas';
import { formatarData } from '../../../../views/prestacaodecontas/Desktop/sections';
import { formatarFormaPagamentoString } from '../../../../enums/enumFinanceiro';
import { SimCardDownloadOutlined } from '@mui/icons-material';

export const ButtonDialogComprovanteInter = ({ comprovante = { datadepagamento: new Date() }, pessoa, centrocusto }) => {
    const cardRef = useRef();

    const handleDownloadPDF = async () => {
        const element = cardRef.current;
        const canvas = await html2canvas(element);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 190;
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let position = 10;

        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        pdf.save(`Comprovante_${comprovante.idpagamento}.pdf`);
    };

    return (
        <>
            <Grid container item xs={12} style={{
                marginTop: "5px",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
            }}>
                <Grid item xs={12}>
                    <Card elevation={0} sx={{ maxWidth: 600, margin: 'auto', padding: 2 }} ref={cardRef}>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" component="div">
                                    Pagamento #{comprovante.idpagamento}
                                </Typography>
                                <Box display="flex" alignItems="center" color="green">
                                    <CheckCircleOutlineIcon sx={{ marginRight: 1 }} />
                                    <Typography variant="body2" fontWeight="bold">
                                        Pago
                                    </Typography>
                                </Box>
                            </Box>

                            <Typography variant="body2" color="text.secondary" sx={{ marginY: 0 }}>
                                Adiantamento pago em {formatarData(comprovante.data_pag, "d MMM 'de' yyyy")}
                            </Typography>

                            <Divider sx={{ marginY: 2 }} />

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Pago em
                                    </Typography>
                                    <Typography variant="body2">{formatarData(comprovante.data_pag, "d MMM 'de' yyyy")}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Pedido em
                                    </Typography>
                                    <Typography variant="body2">{formatarData(comprovante.data_solicitado, "d MMM 'de' yyyy")}</Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{ marginTop: 2 }}>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        De
                                    </Typography>
                                    <Typography variant="body2">
                                        {comprovante.usuario.pessoa.nome + " " + comprovante.usuario.pessoa.sobrenome}
                                        <br />
                                        <br />
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Para
                                    </Typography>
                                    <Typography variant="body2">
                                        {pessoa.nome}
                                        <br />
                                        <br />
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Typography variant="body2" sx={{ marginTop: 2 }}>
                                Centro de custo
                            </Typography>
                            <Typography variant="body1" fontWeight="bold">
                                {centrocusto}
                            </Typography>

                            <Divider sx={{ marginY: 2 }} />

                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Nota
                                    </Typography>
                                    <Typography variant="body2">{comprovante.nota}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Forma de pagamento
                                    </Typography>
                                    <Typography variant="body2">{formatarFormaPagamentoString(comprovante.forma_pagamento)}</Typography>
                                </Grid>
                            </Grid>

                            <Divider sx={{ marginY: 2 }} />

                            <Typography variant="body1" fontWeight="bold">
                                Total: {formatarValorComMoeda(EnumTipo.moedas.BRL.id, comprovante.total_brl_pago)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} container style={{ display: "flex", justifyContent: "right" }}>
                    <Button startIcon={<SimCardDownloadOutlined />} variant="outlined" onClick={handleDownloadPDF} sx={{ marginTop: 2 }}>
                        Baixar Comprovante
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
