import { Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import UtaliiDatePickerInput from "../../../../prestacaodecontas/Desktop/sections/custom-date-picker-start_and_end";
import { FreeBreakfast, SaveOutlined } from "@mui/icons-material";

export default function DatePickerWrapper({ item, usuarioLinestur, handleClickEdit, setValue }) {
    const [selectedDate, setSelectedDate] = useState([dayjs(item.data_entrada), dayjs(item.data_saida)]);
    const originalDate = [dayjs(item.data_entrada), dayjs(item.data_saida)];

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
        setValue(newValue)
    };

    const hasDateChanged =
        !selectedDate[0]?.isSame(originalDate[0], 'day') ||
        !selectedDate[1]?.isSame(originalDate[1], 'day');

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} style={{ marginLeft: "-17px" }}>
                <UtaliiDatePickerInput
                    value={selectedDate}
                    onChange={handleDateChange}
                    shouldDisableDate={usuarioLinestur}
                    showDirectCalendar={true}
                />
            </Grid>
            {hasDateChanged && (
                <Grid item xs={12} style={{ padding: "20px" }}>
                    <Button onClick={() => handleClickEdit(item)} startIcon={<SaveOutlined />} fullWidth variant="contained" style={{ marginTop: "5px", borderRadius: "6px", backgroundColor: "#c62828" }}>Salvar</Button>
                </Grid>
            )}
        </Grid>
    );
}

export function DateDisplay({ item, title }) {
    const dataEntrada = dayjs(item.data_entrada);
    const dataSaida = dayjs(item.data_saida);
    const noites = dataSaida.diff(dataEntrada, 'day');

    return (
        <Grid item xs={12}>
            <Typography variant="h6" style={{ fontWeight: "600" }}>
                {noites} noite(s) em {title}
            </Typography>
            <Typography style={{ color: "#6C6C6C" }}>
                {dataEntrada.format('D [de] MMM. [de] YYYY')} - {dataSaida.format('D [de] MMM. [de] YYYY')}
            </Typography>

            {item.servicos_quartos === "Café da Manhã" && (
                <Typography style={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
                    <FreeBreakfast style={{ marginRight: "7px" }} /> café da manhã
                </Typography>
            )}
        </Grid>
    );
}
