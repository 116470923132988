import {
  Button,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { RemoveRedEyeOutlined, SendOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { XGrid } from "@material-ui/x-grid";
import React, { useCallback, useEffect } from "react";
import Enums from "../../../../enums";
import permissaoRepositorio from "../../../../repositorios/permissao";
import CustomNoRowsOverlay from "../../../configuracao/email/lista/emptyList";

function DespesasGrid(props) {
  const [arrayVerifyStatus, setArrayVerifyStatus] = React.useState({
    item1: true,
    item2: true,
    item3: true,
    item4: true,
    item5: true,
    item6: true,
  });

  const handleClickEditar = (idviagem, idpessoa) => {
    props.onClickEdit(idviagem, idpessoa);
  };

  const handleClickModalAccept = (id) => {
    props.onClickModalAccept(id);
  };

  const verifyStatus = useCallback(
    (permissao) => {
      if (permissao.permitecontroletotaldespesasviagem) {
        setArrayVerifyStatus((prevState) => ({
          ...prevState,
          item1: false,
          item2: false,
          item3: false,
          item4: false,
          item6: false,
        }));
      } else if (permissao.permitesercontabilidade) {
        setArrayVerifyStatus((prevState) => ({
          ...prevState,
          item1: false,
          item2: false,
        }));
      } else if (permissao.permitesercontroladoriaauditoria) {
        setArrayVerifyStatus((prevState) => ({
          ...prevState,
          item1: false,
          item3: false,
        }));
      } else if (permissao.permiteserfinanceiro) {
        setArrayVerifyStatus((prevState) => ({
          ...prevState,
          item1: false,
          item4: false,
          item6: false,
        }));
      } else {
        setArrayVerifyStatus((prevState) => ({
          ...prevState,
          item1: false,
        }));
      }
    },
    [setArrayVerifyStatus]
  );

  const columns = [
    { field: "id", headerName: "ID", width: 110, hide: true },
    { field: "idviagem", headerName: "ID_VIAGEM", width: 120, hide: true },
    { field: "idviagemtenant", headerName: "ID", width: 120 },
    { field: "viagem", headerName: "Viagem", width: 200 },
    { field: "viajante", headerName: "Viajante", width: 250 },
    { field: "dataIda", headerName: "Ida", width: 150 },
    {
      field: "total",
      headerName: "Total",
      width: 150,
      renderCell: (param) => (
        <Typography>
          {param.row.total.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (param) => (
        <Chip
          label={Enums.getDespesaStatus(param.value)}
          size="small"
          style={{
            color: "#FFFFFF",
            background: Enums.getDespesaCor(param.value),
          }}
        />
      ),
    },
    {
      field: "visualizar",
      headerName: "Visualizar",
      width: 100,
      renderCell: (param) => (
        <>
          <Tooltip title="Clique para visualizar a viagem">
            <span>
              <IconButton
                onClick={() =>
                  handleClickEditar(param.row.idviagem, param.row.idpessoa)
                }
              >
                <RemoveRedEyeOutlined />
              </IconButton>
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "acao",
      headerName: "Ação",
      width: 150,
      renderCell: (param) => (
        <>
          <Grid hidden={arrayVerifyStatus[`item${param.row.status}`]} container>
            <Grid item xs={6}>
              <Button
                disabled={param.row.status === 5}
                onClick={() => handleClickModalAccept(param.row.idviagem)}
                variant="outlined"
                disableElevation
                size="small"
                style={{ color: "#C62828" }}
                endIcon={<SendOutlined style={{ color: "#c62828" }} />}
              >
                <Typography style={{ color: "#c62828" }}>Avançar</Typography>
              </Button>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "excluir",
      headerName: "Excluir",
      width: 150,
      renderCell: (param) => (
        <>
          <Grid item xs={6}>
            <Button
              disabled={param.row.status !== 1}
              onClick={() => props.handleOpenExcluirViagem(param.row.idviagem)}
              variant="outlined"
              disableElevation
              size="small"
              style={{ color: "#808080" }}
            >
              <DeleteIcon style={{ color: "#808080" }} />
            </Button>
          </Grid>
        </>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      await permissaoRepositorio.getPorSessao().then((res) => {
        verifyStatus(res.data);
      });
    })();
  }, []);

  return (
    <>
      <div style={{ height: "60vh", width: "100%" }}>
        <XGrid
          disableColumnFilter={true}
          disableColumnMenu={true}
          id={Math.random()}
          rows={props.data}
          columns={columns}
          loading={props.isLoading}
          components={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          hideFooterSelectedRowCount
          disableSelectionOnClick
          hideFooterRowCount
          pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </div>
    </>
  );
}

export default DespesasGrid;
