import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import DialogComponent from "../../../views/prestacaodecontas/Desktop/sections/dialogs-desktop";
import { ViagemComponentM } from "../../../views/prestacaodecontas/Mobile/minhasviagens/Viagem";
import { CalendarToday, Delete, EditCalendarOutlined, SaveOutlined } from "@mui/icons-material";
import { EnumTipo, hasApprovedAdiantamento } from "../../../enums/enumPrestacaoDeContas";
import { CardButtonEditDate_mob } from "../card_editDate";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export const ViagemViewComponent_mob = ({
    handleChangenameViagem,
    typeDialog,
    viagem,
    openViagem,
    handleCloseViagem,
    alterTitulo,
    handleClickEditViagem,
    openDeleteViagem,
    setOpenDeleteViagem,
    handleClickExcluirViagem,
    temDespesa,
    loading,
    handleClickCriarDespesa,
    handleClickEditDespesa,
    setTemDespesa,
    setTypeDialog,
    handleDeleteDespesa,
    handleIniciarViagem,
    handleFinalizarViagem,
    handleClickCreateHodometro,
    handleClickEditHodometro,
}) => {
    const [startDate, setStartDate] = useState(dayjs(viagem?.datainicio || new Date()).locale('pt-br'));
    const [endDate, setEndDate] = useState(dayjs(viagem?.datafim || new Date()).locale('pt-br')); 
    // Função auxiliar para verificar se o botão de excluir deve ser mostrado
    const shouldShowDeleteButton = () => {
        const noDespesaOrAdiantamento = !temDespesa && !hasApprovedAdiantamento(viagem?.adiantamento);
        const isNotStartedOrInTravel = viagem?.status === 0 || viagem?.status === EnumTipo.viagemstatus.emviagem;
        return noDespesaOrAdiantamento || (isNotStartedOrInTravel && viagem?.adiantamento?.length === 0);
    };

    useEffect(() => {
        setStartDate(dayjs(viagem?.datainicio).locale('pt-br'))
        setEndDate(dayjs(viagem?.datafim).locale('pt-br'))
    },[viagem])

    return (
        <DialogComponent
            title={
                <Grid container item xs={12}>
                    {typeDialog === 0 && viagem && !loading ? (
                        <>
                            {viagem.status === 8 ? (
                                <Typography style={{ fontWeight: "600" }}>{viagem.titulo}</Typography>
                            ) : (
                                <>
                                    <Grid item xs={shouldShowDeleteButton() ? 8 : 11}>
                                        <TextField
                                            size="large"
                                            variant="standard"
                                            fullWidth
                                            defaultValue={viagem.titulo}
                                            onChange={(e) => handleChangenameViagem(e)}
                                            style={{ borderRadius: "15px" }}
                                            className="titulo_mob"
                                        />
                                    </Grid>
                                    <DialogComponent
                                        title={viagem?.titulo}
                                        open={openDeleteViagem}
                                        handleClose={() => setOpenDeleteViagem(false)}
                                        body={
                                            <Grid container item xs={12}>
                                                <Typography>
                                                    {temDespesa ? "Delete todas as despesas da sua viagem antes!" : "Deseja realmente deletar essa viagem?"}
                                                </Typography>
                                            </Grid>
                                        }
                                        action={
                                            !temDespesa && (
                                                <>
                                                    <Button size="large" onClick={() => setOpenDeleteViagem(false)}>Cancelar</Button>
                                                    <Button size="large" onClick={() => handleClickEditViagem(viagem.id)}>Confirmar</Button>
                                                </>
                                            )
                                        }
                                        width={"sm"}
                                    />
                                    {shouldShowDeleteButton() && (
                                        <Grid item xs={4} style={{ display: "flex", justifyContent: "right" }}>
                                            <IconButton
                                                className="excluir_mob"
                                                size="large"
                                                variant="contained"
                                                color="error"
                                                onClick={() => setOpenDeleteViagem(true)}
                                            >
                                                <Delete size="large" />
                                            </IconButton>
                                            <CardButtonEditDate_mob startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                                        </Grid>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <Typography style={{ fontWeight: "600" }}>{viagem?.titulo}</Typography>
                    )}
                </Grid>
            }
            open={openViagem}
            handleClose={handleCloseViagem}
            fullScreen={true}
            ismap={true}
            body={
                <>
                    {alterTitulo && (
                        <Grid item xs={12} style={{ textAlign: "right", justifyContent: "right", padding: "20px", marginTop: "-30px" }}>
                            <Button
                                size="large"
                                variant="outlined"
                                startIcon={<SaveOutlined />}
                                onClick={handleClickEditViagem}
                            >
                                Salvar
                            </Button>
                        </Grid>
                    )}
                    {viagem && !loading && (
                        <ViagemComponentM
                            viagemData={viagem}
                            handleCreate={handleClickCriarDespesa}
                            handleEdit={handleClickEditDespesa}
                            setTemDespesa={setTemDespesa}
                            setTypeGlobal={setTypeDialog}
                            handleDelete={handleDeleteDespesa}
                            handleIniciarViagem={handleIniciarViagem}
                            handleFinalizar={handleFinalizarViagem}
                            handleClickCreateHodometro={handleClickCreateHodometro}
                            handleClickEditHodometro={handleClickEditHodometro}
                        />
                    )}
                </>
            }
            width={"sm"}
        />
    );
};
