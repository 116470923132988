import { Avatar, Badge, Button, Chip, Grid, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@material-ui/core";
import { ArrowDownwardOutlined, ArrowUpwardOutlined, SearchOutlined, SyncOutlined, TuneOutlined } from "@material-ui/icons";
import React, { useCallback } from "react";
import { useState } from "react";
import { useStyles } from "./styles";
import LinesTextField from "../../../../components/LinesTextField";
import Enums from "../../../../enums";
import { useEffect } from "react";
import { useRef } from "react";

export const BookingListDesktop = (props) => {
    const dadosUsuario = props.dadosUsuario;
    const [dataBookingList, setDataBookingList] = useState(props.dataBookingList);
    const totalListCountBooking = props.dataBookingList.length || 0;
    const setPage = props.setPage;
    const getBookingList = props.getBookingList;
    const [valueTuneStatus, setValueTuneStatus] = useState(props.valueTuneStatus);
    const classes = useStyles();
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("dataultimaatualizacao");
    const [page, setPageD] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const boxRef = useRef(null);
    const [headers, setHeaders] = useState([
        { label: "ID", icon: null },
        { label: "Assunto", icon: null },
        { label: "Status", icon: null },
        { label: "Solicitante", icon: null },
        { label: "Ultima Atualização", icon: null },
        { label: "", icon: null },
    ]);
    const headersInicial = [
        { label: "ID", icon: null },
        { label: "Assunto", icon: null },
        { label: "Status", icon: null },
        { label: "Solicitante", icon: null },
        { label: "Ultima Atualização", icon: null },
        { label: "", icon: null },
    ];

    const handleClickOpenDialogBookingTicket = (item) => {
        if (item.tipo === 1) {
            props.setBookingSelected(item.idreserva)
            props.handleClickOpenDialogBookingTicket()
        } else if (item.tipo === 2) {
            props.setBookingSelected({ idsolicitacao: item.idsolicitacao, idreservatenant: item.idreserva })
            props.handleClickOpenDialogBookingRequestTicket()
        } else if (item.tipo === 3) {
            props.setBookingSelected(item.idreserva)
            props.handleClickOpenDialogHotelTicket()
        }
    }

    const handleOpenMenuAction = (e) => {
        props.setAnchorMenuAction(e.currentTarget)
        props.handleClickOpenMenuAction()
    }

    useEffect(() => {
        setDataBookingList(props.dataBookingListSearch.length === 0 ? props.dataBookingList : props.dataBookingListSearch)
    }, [props.dataBookingListSearch, props.dataBookingList])

    useEffect(() => {
        setDataBookingList(props.dataBookingList)
    }, [props.dataBookingList])

    useEffect(() => {
        setValueTuneStatus(props.valueTuneStatus)
    }, [props.valueTuneStatus])

    const getIconAscDesc = () => {
        if (order === "asc") {
            return (<ArrowDownwardOutlined className={classes.iconeOrdem} />)
        } else {
            return (<ArrowUpwardOutlined className={classes.iconeOrdem} />)
        }
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };
    const getComparator = (order, orderBy) => {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const stableSort = (array, comparator) => {
        if (array != null) {
            const stabilizedThis = array.map((el, index) => [el, index]);
            stabilizedThis.sort((a, b) => {
                const order = comparator(a[0], b[0]);
                if (order !== 0) return order;
                return a[1] - b[1];
            });
            return stabilizedThis.map((el) => el[0]);
        }
    };

    const handleScroll = useCallback(() => {
        if (!boxRef?.current) return;  // Verificação para garantir que o boxRef não seja nulo

        const { scrollTop, clientHeight, scrollHeight } = boxRef.current;
        if (scrollTop + clientHeight >= scrollHeight) {
            if (setPage) {
                setPage((prevPageNumber) => prevPageNumber + 1);
            }
            if (getBookingList) {
                getBookingList();
            }
        }
    }, [boxRef, setPage, getBookingList]);  // Dependências da função

    useEffect(() => {
        if (!boxRef?.current) return;  // Verificação para garantir que o boxRef não seja nulo

        const currentBox = boxRef.current;
        currentBox.addEventListener("scroll", handleScroll);

        return () => {
            if (currentBox) {
                currentBox.removeEventListener("scroll", handleScroll);
            }
        };
    }, [boxRef, handleScroll]);  // Dependências do useEffect

    const handleChangePage = (event, newPage) => {
        setPageD(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPageD(0);
    };

    const sortTable = (index) => {
        let header = headersInicial;
        switch (index) {
            case 0:
                setOrder(order === "asc" ? "desc" : "asc");
                setOrderBy("idreservatenant");
                header[0] = { label: "ID", icon: getIconAscDesc() };
                setHeaders(header);
                break;
            case 1:
                setOrder(order === "asc" ? "desc" : "asc");
                setOrderBy("assunto");
                header[1] = { label: "Assunto", icon: getIconAscDesc() };
                setHeaders(header);
                break;
            case 2:
                setOrder(order === "asc" ? "desc" : "asc");
                setOrderBy("status");
                header[2] = { label: "Status", icon: getIconAscDesc() };
                setHeaders(header);
                break;
            case 3:
                setOrder(order === "asc" ? "desc" : "asc");
                setOrderBy("idsolicitante");
                header[3] = { label: "Solicitante", icon: getIconAscDesc() };
                setHeaders(header);
                break;
            case 4:
                setOrder(order === "asc" ? "desc" : "asc");
                setOrderBy("dataultimaatualizacao");
                header[4] = { label: "Ultima Atualização", icon: getIconAscDesc() };
                setHeaders(header);
                break;
            default:
                console.warn(`Índice inesperado: ${index}`); // Ou qualquer outra ação que você queira no default
                break;
        }
        setPage(0);
    };

    return (
        <>
            <div id="box" ref={boxRef} className={classes.root} >
                <Grid container className={classes.mainBar}>
                    <Grid item xs={1} className={classes.gridMainBar}>
                        <Tooltip title="Clique aqui para atualizar a caixa de entrada">
                            <IconButton className={classes.syncButton} onClick={() => props.getBookingList()}>
                                <SyncOutlined />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3} className={classes.gridMainBar}>
                        {dadosUsuario?.nivelpermissao === 6 || dadosUsuario.nivelpermissao === 1 || dadosUsuario?.nivelpermissao === 2 ? (
                            <Button className={classes.buttonAproveAll} onClick={() => props.handleClickModalAccept()}>Aprovar todos</Button>
                        ) : null}
                    </Grid>
                    <Grid item xs={4} className={classes.gridMainBar}>
                        <TablePagination
                            className={classes.pagination}
                            rowsPerPageOptions={[10, 25, 100]}
                            // labelDisplayedRows={defaultLabelDisplayedRows}
                            component="div"
                            count={totalListCountBooking}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage=""
                            nextIconButtonText="Próxima página"
                            backIconButtonText="Página anterior"
                        />
                        {/* <Pagination count={Math.ceil(props.dataBookingListTotal / 25)} page={page} onChange={props.handleChangePage} variant="outlined" shape="rounded" /> */}
                    </Grid>
                    <Grid item xs={4} style={{ display: 'flex' }} className={classes.gridMainBar}>
                        <LinesTextField
                            id="pesquisar"
                            style={{ marginRight: "5px" }}
                            variant="outlined"
                            margin="dense"
                            onChange={props.onPesquisarChange}
                            placeholder="Pesquisar..."
                            fullWidth
                            InputProps={{ startAdornment: (<InputAdornment position="start"><SearchOutlined style={{ color: "#c62828" }} /></InputAdornment>) }}
                        />
                        <Tooltip title="Filtro de Status">
                            <IconButton onClick={() => props.handleClickTuneStatusOpen()}>
                                <Badge color="secondary" variant="dot" invisible={valueTuneStatus !== "0" ? false : true}><TuneOutlined style={{ color: "#c62828" }} /></Badge>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <TableContainer className={classes.tableContainerCustom}>
                    <Table stickyHeader >
                        <TableHead style={{ height: '1rem' }}>
                            <TableRow key={'table-head-row'}>
                                {headers.map((item, index) => (
                                    <TableCell
                                        onClick={() => sortTable(index)}
                                        style={{ cursor: "pointer" }} align="center">
                                        <Typography >{item.label}{item.icon}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(dataBookingList, getComparator(order, orderBy))
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                ?.map((item) => (
                                    <TableRow hover key={item.idreservatenant} >
                                        <TableCell className={classes.tableCellCustom01} align="center" onClick={() => handleClickOpenDialogBookingTicket(item)}>
                                            {item.idreservatenant}
                                        </TableCell>
                                        <TableCell className={classes.tableCellCustom01} align="center" onClick={() => handleClickOpenDialogBookingTicket(item)}>
                                            {props.definirAssunto(item.assunto)}
                                        </TableCell>
                                        <TableCell className={classes.tableCellCustom01} align="center" onClick={() => handleClickOpenDialogBookingTicket(item)}>
                                            <Chip
                                                size="medium"
                                                label={Enums.getReservaStatusDescricao(item.status)}
                                                style={{ background: Enums.getReservaStatusCor(item.status), color: "#ffffff", }}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableCellCustom01} align="center" onClick={() => handleClickOpenDialogBookingTicket(item)}>
                                            <Grid className={classes.imagem} style={{ textAlign: "center" }}>
                                                <Tooltip title={item.solicitante} style={{ textAlign: "center" }}>
                                                    <Avatar
                                                        src={item.fotouser}
                                                        style={{
                                                            display: "inline-block",
                                                            textAlign: "center"
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        </TableCell>
                                        <TableCell className={classes.tableCellCustom01} align="center" onClick={() => handleClickOpenDialogBookingTicket(item)}>
                                            {item.dataultimaatualizacao ?
                                                new Date(item.dataultimaatualizacao).toLocaleString().substr(0, 10) + " " + (new Date(item.dataultimaatualizacao).toLocaleString().substr(11, 6))
                                                : null}
                                        </TableCell>
                                        <TableCell className={classes.tableCellCustom02} align="center">
                                            <Button variant="outlined" className={classes.buttonAction}
                                                //  onClick={(e) => props.handleActionMenuButton(e, item)
                                                onClick={(e) => { handleOpenMenuAction(e); props.setBookingSelected(item) }}>
                                                Ação
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div >
        </>
    )
}
