import React from "react";
import { Card, CardContent, CardMedia, Grid, Grow, List, ListItem, Typography } from "@material-ui/core";
import { useStyles } from "./styles";

const EconomiaTotalMobile = (props) => {
    const classes = useStyles();
    const teste = props.data

    return (
        <>
            <Grid item container xs={12}>
                <Grid item container xs={12} className={classes.gridCard}>
                    <Grow in={true} timeout={1000}>
                        <div className={classes.cards}>
                            <List className={classes.listCard}>
                                <ListItem>
                                    <Card className={classes.card}>
                                        <CardMedia
                                            style={{
                                                height: 30,
                                                minHeight: 30,
                                                background: "#2196f3",
                                            }}
                                        />
                                        <CardContent>
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="h2"
                                                style={{
                                                    color: "#2196f3",
                                                }}
                                            >
                                                <b> Reduzido </b>
                                                {teste.economyIssued.economy !== 0 ? (teste.reduction.reductioned / teste.reduction.original * 100).toFixed(2).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 }) + "%" : null}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                component="p"
                                            >
                                                <Typography className={classes.typography_G} variant="h6">
                                                    {teste.reduction.reductioned.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                </Typography>
                                                Total de reduções sobre as tarifas
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </ListItem>
                                <ListItem>
                                    <Card className={classes.card}>
                                        <CardMedia
                                            style={{
                                                height: 30,
                                                minHeight: 30,
                                                background: "#43a047",
                                            }}
                                        />
                                        <CardContent>
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="h2"
                                                style={{
                                                    color: "#43a047",
                                                }}
                                            >
                                                <b> Economia </b>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                component="p"
                                            >
                                                <Typography className={classes.typography_G} variant="h6">
                                                    {(teste.reduction.reductioned + teste.economyIssued.economy).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                </Typography>
                                                Total economizado com reduções e economia emissão IA.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </ListItem>
                                <ListItem>
                                    <Card className={classes.card}>
                                        <CardMedia
                                            style={{
                                                height: 30,
                                                minHeight: 30,
                                                background: "#ffa000",
                                            }}
                                        />
                                        <CardContent>
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="h2"
                                                style={{
                                                    color: "#ffa000",
                                                }}
                                            >
                                                <b> Emissão Economia IA </b>
                                                {teste.economyIssued.economy !== 0 ? (teste.economyIssued.economy / teste.economyIssued.final * 100).toFixed(2).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 }) + "%" : null}

                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                component="p"
                                            >
                                                <Typography className={classes.typography_G} variant="h6">{teste.economyIssued.economy.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                </Typography>
                                                Total economizado na emissão com IA.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </ListItem>
                            </List>
                        </div>
                    </Grow>
                </Grid>
                {(teste.reduction.reductioned + teste.economyIssued.economy) !== 0 ? (
                    <>
                        <Grid container item xs={12} style={{ borderRadius: "20px", boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.1)", padding: "20px", marginRight: "14px", marginBottom: "15px", marginTop: "10px" }}>
                            <Grid item xs={12} className={classes.gridCard}>
                                <Typography variant="h5"><b>Reduções IA</b></Typography>
                            </Grid>
                            <Grid container item xs={12} className={classes.gridCard}>
                                <Grid item xs={6}>
                                    <Typography className={classes.typography_O_}><span className={classes.dot_O} /> Total Inicial: </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.typography_O_}>{teste.reduction.original.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.typography_P_}><span className={classes.dot_P} /> Total Emitido:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.typography_P_}>{teste.reduction.issued.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.typography_G}><span className={classes.dot_G} /> Total Reduzido:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.typography_G}>{teste.reduction.reductioned.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} style={{ borderRadius: "20px", boxShadow: "0px 0px 5px 5px rgba(0,0,0,0.05)", padding: "20px", marginRight: "14px", marginBottom: "15px" }}>
                            <Grid item xs={12} className={classes.gridCard}>
                                <Typography variant="h5"><b>Economia Emissão IA</b></Typography>
                            </Grid>
                            <Grid container item xs={12} className={classes.gridCard}>
                                <Grid item xs={6}>
                                    <Typography className={classes.typography_O_}><span className={classes.dot_O} /> Total Final:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.typography_O_}>{teste.economyIssued.final.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.typography_P_}><span className={classes.dot_P} /> Total Emitido: </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.typography_P_}> {teste.economyIssued.original.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.typography_G}><span className={classes.dot_G} /> Total Economizado:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.typography_G}>{teste.economyIssued.economy.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ) : (null)}
            </Grid >
        </>
    )
}

export default EconomiaTotalMobile;
