import { makeStyles, fade } from '@material-ui/core/styles';
import { yellow, lightGreen } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme => ({
  card: {
    maxWidth: 345,
    height: '100%',
    width: '450px'
  },
  media: {
    height: 140,
    minHeight: 307.91
  },
  cards: {
    alignItems: 'center',
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '30px'
  },
  teste: {
    display: 'flex',
    flexDirection: 'row'
  },
  titulo: {
    fontSize: '25px',
    padding: theme.spacing(1, 5),
    '@media (max-width: 480px)': {
      padding: theme.spacing(1, 3),
    },
  },
  expansion: {
    paddingTop: 0
  },
  search: {
    width: '70%',
    marginLeft: '1%',
    '@media (max-width: 480px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginLeft: '0'
    },
    '@media (max-width:900px) and (orientation: landscape)': {
      width: '70%',
      marginLeft: '0%',
      marginRight: '0%',
    },
  },
  search2: {
    width: '30%',
    marginLeft: '1%',
    marginRight: '1%',
    '@media (max-width:480px)': {
      width: '100%',
      marginLeft: '0%',
      marginRight: '0%',
    },
    '@media (max-width:900px) and (orientation: landscape)': {
      width: '70%',
      marginLeft: '0%',
      marginRight: '0%',
    },
  },
  heading: {

  },
  filtros: {
    marginTop: '3%',
    marginBottom: '1%',
    '@media (max-width:480px)': {
      marginTop: '0%',
      marginBottom: '0%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '@media (max-width:900px) and (orientation: landscape)': {
      marginTop: '0%',
      marginBottom: '0%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  testevai: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 2)

  },
  botoes: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 480px)': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '@media (max-width: 900px) and (orientation: landscape)': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    
  },
  botao: {
    width:'30%',
      '@media (max-width:480px)': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
      },
      '@media (max-width:900px) and (orientation: landscape)': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
      },
    },
      separar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(1),
        paddingTop:'1%',
        paddingBottom:'0%',
        '@media(max-width:480px)':{
          paddingTop:'5%',
          padding:theme.spacing(0),
        paddingBottom:'10%',
        flexDirection:'column'
        },
        '@media(max-width:900px) and (orientation: landscape)':{
          paddingTop:'5%',
          padding:theme.spacing(0),
        paddingBottom:'1%',
        flexDirection:'row'
        }
      },  
  udate: {
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 480px)': {
      flexDirection: 'column',
      width: '100%',
    },
    '@media (max-width: 900px) and (orientation: landscape)': {
      flexDirection: 'column',
      width: '70%',
    },
  },
  lista2: {
    padding: theme.spacing(0, 5),
    '@media (max-width: 480px)': {
      padding: theme.spacing(0, 3),
    },
  },
  search3: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    '@media(max-width:480px)':{
      width: '95%',
      margin:theme.spacing(1)
    },
    '@media(max-width:900px) and (orientation: landscape)':{
      width: '50%',
      marginRight:theme.spacing(3),
      marginBottom: theme.spacing(1)
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  botao0: {
    marginLeft: '2%',
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'black'
    }
  },
  botao1: {
    marginLeft: '2%',
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'black'
    }
  },
  botao2: {
    marginLeft: '2%',
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'black'
    }
  },
  botao3: {
    marginLeft: '2%',
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'black'
    }
  },
  botao4: {
    marginLeft: '2%',
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'black'
    }
  },
  lista: {
    padding: theme.spacing(5),
    '@media (max-width:480px)': {
      padding: theme.spacing(3),
    },
  },
  cardtab:{
    width:'95%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '@media(max-width:900px) and (orientation: landscape)':{
      width:'97%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    }
  },
  cardtable:{
    paddingBottom:'20%',
    '@media(max-width:900px) and (orientation: landscape)':{
      paddingBottom:'8%'
    }
  },
  iconzitobutton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    alignItems: 'right',
    width: '100%',
    '@media (max-width:480px)': {
      paddingTop: theme.spacing(1.5),
      justifyContent: 'center',
      alignItems: 'center',

    },
    '@media (max-width:900px) and (orientation: landscape)': {
      paddingTop: theme.spacing(1.5),
      justifyContent: 'center',
      alignItems: 'center',

    },
  },
  button: {
    background:'linear-gradient(45deg, black, transparent)',
    '&:hover': {
      background: yellow[500],
    },
    color: 'white',
    '@media(max-width:480px)': {
      background: lightGreen[300],
      '&:hover': {
        background: lightGreen[500],
      },
      width: '100%',
      color:'black'
    },
    '@media(max-width:900px) and (orientation: landscape)': {
      background: lightGreen[300],
      '&:hover': {
        background: lightGreen[500],
      },
      width: '70%',
      color:'black'
    },
  },
  data1:{
    marginLeft:'3%',
    marginRight:'3%',
    '@media(max-width:480px)':{
    marginLeft:'0%',
    marginRight:'0%'
    },
    '@media(max-width:900px) and (orientation: landscape)':{
    marginLeft:'0%',
    marginRight:'0%'
    }
  },
  dados:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-between',
    width:'100%',
  }
})));