import { Box, Grid, IconButton, List, ListItem, TablePagination, Typography } from '@material-ui/core';
import { HistoryOutlined, AirplanemodeActive, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import './style.css'
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';

function MenorTarifaList(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [itensPerPage, setItensPergPage] = useState(10);
    const pages = Math.ceil(props.data.length);
    const s = page * itensPerPage;
    const r = itensPerPage + s;
    const todaTarifa = props.data.slice(s, r);
    const [passageirosVisiveis, setPassageirosVisiveis] = useState(true);
    const permissao = useSelector((state) => state.permissao.permissaoDashboard);

    const openHistoricoReserva = (item) => {
        props.setOpenDialoHistorico(true);
        props.setDialogIdReserva(item.idreserva);
        props.setIdreservatenant(item.idreservatenant);
        props.setReduction(item.diferenca);
    }

    const handleToggleVisibility = () => {
        setPassageirosVisiveis((prevState) => !prevState);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setItensPergPage(event.target.value);
    };

    return (
        <>
            <TablePagination
                style={{ minWidth: 'max-content' }}
                labelRowsPerPage=''
                component="div"
                count={pages}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[10, 25, 50]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <List style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                {todaTarifa.map(item => (
                    <ListItem divider>
                        <div>
                            <Grid
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    flexShrink: 0,
                                    fill: '#FFF',
                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                    borderRadius: '10px 10px 10px 10px',
                                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
                                    borderBottom: 'none',
                                    backgroundColor: "#FFFFFF"
                                }}
                                container
                            >
                                <Grid item xs={12} container style={{ padding: "20px" }}>
                                    <Grid item xs={5} style={{ marginBottom: '-25px' }}>
                                        <Typography style={{ display: 'block', color: "#AFAFAF", fontWeight: "600", fontSize: "12px" }}>DE</Typography>
                                        <Typography style={{ display: 'block', color: "#141414", fontSize: "20px" }}>{item.assunto.split(/⇄|→/)[0]}</Typography>
                                    </Grid>
                                    <Grid item xs={2} style={{ textAlign: "center" }}>
                                        <AirplanemodeActive style={{ color: "#8B8B8B" }} />
                                    </Grid>
                                    <Grid item xs={5} style={{ marginBottom: '-25px', textAlign: "right" }}>
                                        <Typography style={{ display: 'block', color: "#AFAFAF", fontWeight: "600", fontSize: "12px" }}>PARA</Typography>
                                        <Typography style={{ display: 'block', color: "#141414", fontSize: "20px" }}>{item.assunto.split(/⇄|→/)[1]}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container style={{ padding: "20px" }}>
                                    <Grid item xs={12} style={{ textAlign: "center", marginBottom: '20px' }}>
                                        <hr style={{ width: "100%", backgroundColor: "#ECECEC", height: "1px", border: "none" }}></hr>
                                    </Grid>
                                    <Grid item xs={6} style={{ marginBottom: '-20px' }}>
                                        <Typography style={{ display: 'block', color: "#686868", fontWeight: "700", fontSize: "13px" }}>Preço Inicial</Typography>
                                        <Typography style={{ display: 'block', color: "#141414", fontSize: "17px" }}>{item.precoInicial.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{ marginBottom: '-20px', textAlign: "right" }}>
                                        <Typography style={{ display: 'block', color: "#686868", fontWeight: "700", fontSize: "13px" }}>Preço Final</Typography>
                                        <Typography style={{ display: 'block', color: "#141414", fontSize: "17px" }}>{item.precoFinal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container style={{ padding: "20px" }}>
                                    <Grid item xs={6} style={{ marginBottom: '-20px' }}>
                                        <Typography style={{ display: 'block', color: "#686868", fontWeight: "700", fontSize: "13px" }}>Data criação</Typography>
                                        <Typography style={{ display: 'block', color: "#141414", fontWeight: "700", fontSize: "13px" }}>{new Date(item.dataCriacao).toLocaleDateString()}</Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{ marginBottom: '-20px', textAlign: "right" }}>
                                        <Typography style={{ display: 'block', color: "#686868", fontWeight: "700", fontSize: "13px" }}>Aéreo</Typography>
                                        <Typography style={{ display: 'block', color: "#141414", fontWeight: "700", fontSize: "16px" }}>{item.idreservatenant}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "center", marginBottom: '5px', marginTop: "20px" }}>
                                    <hr style={{ width: "100%", backgroundColor: "#ECECEC", height: "1px", border: "none" }}></hr>
                                </Grid>
                                <Grid item xs={12} container style={{ padding: "20px" }}>
                                    <Grid item xs={12} style={{ marginBottom: '-20px', textAlign: "center" }}>
                                        <Typography style={{ display: 'block', color: "#1F1F1F", fontWeight: "bolder", fontSize: "17px" }}>Solicitante</Typography>
                                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                            {passageirosVisiveis && (
                                                <Typography key={item.solicitante} style={{ display: 'block', color: "#141414", fontWeight: "700", fontSize: "13px" }}>{item.solicitante}</Typography>
                                            )}
                                            {!passageirosVisiveis && (
                                                <Skeleton animation="wave" variant="text" width={160} height={20} />
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container style={{ padding: "20px" }}>
                                    <Grid item xs={10} style={{ marginBottom: '10px' }}>
                                        <Typography style={{ display: 'block', color: "#686868", fontWeight: "700", fontSize: "13px" }}>Passageiros</Typography>
                                        {passageirosVisiveis &&
                                            item.passageiros.map((nome) => (
                                                <Typography key={nome} style={{ display: 'block', color: "#141414", fontWeight: "700", fontSize: "13px" }}>{nome}</Typography>
                                            ))}
                                        {!passageirosVisiveis && (
                                            <Skeleton animation="wave" variant="text" width={160} height={20} />
                                        )}
                                    </Grid>
                                    <Grid item xs={2} style={{ textAlign: "right" }}>
                                        <Typography style={{ fontSize: "12px", marginLeft: "12px", textAlign: "center" }}>
                                            {item.quantidadePassageiros}
                                        </Typography>
                                        <Grid style={{ marginRight: "-10px" }}>
                                            {permissao.usuarioLinestur ? (
                                                <IconButton onClick={handleToggleVisibility} style={{}}>
                                                    {passageirosVisiveis ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                                </IconButton>
                                            ) : (
                                                null
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid class="m-ticket">
                                <Grid class="m-linhas">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </Grid>
                            </Grid>
                            <Grid
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    flexShrink: 0,
                                    fill: '#FFF',
                                    top: "40%",
                                    borderRadius: '0px 0px 10px 10px',
                                    borderBottom: 'none',
                                    backgroundColor: "#FFFFFF",
                                    filter: 'none',
                                    boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)",
                                }}
                                container
                            >
                                <Grid item xs={12} container style={{ padding: "20px" }}>
                                    <Grid item xs={12} style={{ marginBottom: '10px' }} container>
                                        <Grid item xs={10}>
                                            <Typography style={{ display: 'block', color: "#686868", fontWeight: "500", fontSize: "13px" }}>Informações sobre redução</Typography>
                                        </Grid>
                                        <Grid item xs={2} style={{ textAlign: "center", marginTop: "-15px" }}>
                                            <IconButton onClick={() => openHistoricoReserva(item)}>
                                                <HistoryOutlined style={{ color: '#c62828' }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginBottom: '-10px' }} container>
                                        <Typography style={{ display: 'block', color: "#89C340", fontWeight: "500", fontSize: "18px" }}>{item.diferenca.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            {` (${item.diferenca_percent.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %)`}</Typography>
                                        <Typography style={{ display: 'block', color: "#363636", fontWeight: "500", fontSize: "12px", marginTop: "4px", marginLeft: "2px" }}>de redução</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </ListItem>
                ))}
            </List >
        </>
    );
}

export default MenorTarifaList;
