import React, { useState } from "react";
import { useStyles } from "./styles";
import Faturado from "../../../../../images/faturado.svg";
import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import Cookies from 'universal-cookie';
import aereoRepositorio from "../../../../../repositorios/aereo";
import { ConfirmIssueDialog } from "../ConfirmIssueDialog";

export const InvoicedPayment = (props) => {
    const responsive = props.responsive;
    const booking = props.booking;
    const classes = useStyles();
    const cookies = new Cookies();
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleClickOpenConfirmDialog = () => { setOpenConfirmDialog(true) }
    const handleClickCloseConfirmDialog = () => { setOpenConfirmDialog(false) }

    const emitirFaturado = async () => {
        let dtoDados = {
            localizador: booking.localizador,
            idreserva: booking.idreserva,
            companhiaaerea: booking.companhiaaerea,
            sessionId: cookies.get("sessionId"),
            tipoPagamento: 1,
            valor: booking.total,
        };

        try {
            props.setIsLoading(true);
            const response = await aereoRepositorio.emitir(dtoDados);
            if (response.data.status === "SUCCESS") {
                props.setSnackMessage('Aéreo emitido com sucesso.');
                props.setSnackState({ visible: true });
                props.setSnackVariant('success');
            } else {
                props.setSnackMessage(`Ocorreu o seguinte erro ao emitir: ${response.data.value.error.message} `);
                props.setSnackState({ visible: true });
                props.setSnackVariant('error');
            }
            props.handleCloseBookingIssue();
        } catch (e) {
            props.setSnackMessage('Não foi possivel Emitir');
            props.setSnackState({ visible: true });
            props.setSnackVariant('error');
        } finally {
            props.setIsLoading(false);
        }
    };

    return (
        <>
            <ConfirmIssueDialog  {...{
                responsive,
                booking,
                open: openConfirmDialog,
                handleClickCloseConfirmDialog,
            }}
                yesEvent={emitirFaturado}
            />
            <Grid container spacing={2} >
                <Grid item xs={responsive ? 12 : 6} className={classes.gridCenter}>
                    <img src={Faturado} alt="Imagem não encontrada" width={responsive ? 125 : 290}></img>
                </Grid>
                <Grid item xs={responsive ? 12 : 6} className={classes.gridCenter}>
                    <Box className={classes.boxCustom} >
                        <Grid container spacing={2} >
                            <Grid item xs={12} className={classes.gridSpace}>
                                <Typography><b>Pagamento</b></Typography>
                                <Typography><b>Quantidade</b></Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.gridSpace}>
                                <Typography>Faturado</Typography>
                                <Typography>1</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} className={classes.gridSpace}>
                                <Typography><b>Total</b></Typography>
                                <Typography><b>{`R$${booking.total.toFormatDefault()}`}</b></Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <Button disabled={props.isLoading} onClick={() => handleClickOpenConfirmDialog()} className={classes.buttonEmissao}>
                                    {`Emitir R$${booking.total.toFormatDefault()}`}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
