import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, TablePagination, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useState } from 'react';
import gif_aviao from './../../../../images/ecogestao/GIF_aviao.gif';
import gif_carro from './../../../../images/ecogestao/GIF_carro.gif';

export const RelatorioMobileEcoGestao = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [itensPerPage, setItensPergPage] = useState(10);
    const pages = Math.ceil(props.dataPesquisa.length);
    const s = page * itensPerPage;
    const r = itensPerPage + s;
    const todaEcoGestao = props.dataPesquisa.slice(s, r);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setItensPergPage(event.target.value);
    };

    const getSizeToPlane = () => {
        let img = document.getElementById('teste')
        if (img === null) {
            return '100px'
        } else {
            return `${img.clientWidth + 30}px`
        }
    };

    return (
        <>
            <TablePagination
                style={{ minWidth: 'max-content' }}
                labelRowsPerPage=''
                component="div"
                count={pages}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[10, 25, 50]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Box>
                {todaEcoGestao?.map((item) => (
                    <Accordion>
                        <AccordionSummary>
                            <Typography>{item.passageiro}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <Typography style={{ fontWeight: "bold" }}>{item.loc}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <Typography style={{ fontSize: "22px", color: "#909090", fontWeight: "800" }}>{item.trecho}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <Typography variant='subtitle2'>{item.sentido}</Typography>
                                    <Typography variant='subtitle2'>{item.tipo}</Typography>
                                </Grid>
                                {item.tipo !== "Translado" ? null : (
                                    <>
                                        <Grid item xs={4} style={{ textAlign: "center" }}>
                                            <Typography style={{ fontSize: "13px", color: "#C62828" }}><b>Emissão</b></Typography>
                                            <Typography style={{ fontSize: "13px", color: "#C62828" }}>{item.emissao}</Typography>
                                        </Grid>
                                        <Grid item xs={4} style={{ textAlign: "center" }}>
                                            <img id='teste' alt="Carro.png" src={gif_carro} width={'100%'}></img>
                                        </Grid>
                                        <Grid item xs={4} style={{ textAlign: "center" }}>
                                            <Typography style={{ fontSize: "13px", color: "#68B544" }}><b>Eco</b></Typography>
                                            <Typography style={{ fontSize: "13px", color: "#68B544" }}>{item.eco}</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                            <Typography style={{ textAlign: "center", fontSize: "13px" }}><b>Tipo</b></Typography>
                                            <Typography style={{ textAlign: "center", fontSize: "13px" }}>{item.tipo}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {item.tipo !== "Aéreo" ? null : (
                                    <>
                                        <Grid item xs={12} style={{ textAlign: "center", marginTop: "10px" }} justify="center">
                                            <img alt="Aviao.png" src={gif_aviao} width={getSizeToPlane()} />
                                        </Grid>
                                        <Grid item xs={12} style={{ textAlign: "center" }}>
                                            <Typography style={{ fontSize: "13px", color: "#C62828" }}><b>Emissão</b></Typography>
                                            <Typography style={{ fontSize: "13px", color: "#C62828" }}>{item.emissao}</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                            <Typography style={{ textAlign: "center", fontSize: "13px" }}><b>Tipo</b></Typography>
                                            <Typography style={{ textAlign: "center", fontSize: "13px" }}>{item.tipo}</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ textAlign: "center" }}>
                                            <Typography>Viagem feita em {format(new Date(item.datavoo), 'dd/MM/yyyy')}</Typography>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </>
    )
}
