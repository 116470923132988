import { Paper, Typography, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { CreationDate } from '../../../../sections';
import { EnumTipoVeiculo } from '../../../../../../../enums/enumVeiculos';
import prestacaoDeContasRepositorio from '../../../../../../../repositorios/prestacaodecontas';
import { useCustomSnackbar } from '../../../../../../../components/snackbar2/NewSnackBar';
import { EnumTipo } from '../../../../../../../enums/enumPrestacaoDeContas';
import ULoaderVoo from '../../../../../../../components/uloadervoo';

export const EditHodometerGoogleMapsD = ({ viagemid, hodometro, editHodometro, setType }) => {
    const [descricao, setDescricao] = useState(hodometro.descricao);
    const inicialFoto = hodometro.hodometro.comprovanteinicial;
    const inicialKm = hodometro.hodometro.kminicial;
    const finalKm = hodometro.hodometro.kmfinal;
    const datadecompra = new Date();
    const [tipoveiculo, setTipoveiculo] = useState(hodometro.hodometro.categoria);
    const [tipocombustivel, setTipocombustivel] = useState(hodometro.hodometro.combustivel);
    const [tipoporte, setTipoporte] = useState(hodometro.hodometro.porte)
    const [loading, setLoading] = useState(false)
    const [produto, setProduto] = useState({})

    const { enqueueSnackbar } = useCustomSnackbar();

    const getAlltipos = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas();

            response.forEach(item => {
                if (item.name === "Hodometro") {
                    setProduto({
                        iddespesa_tipo: item.iddespesa_tipo,
                        iddespesa_subtipo: item.produtos[0].iddespesa_subtipo
                    });
                }
            });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, setProduto, enqueueSnackbar]); // Dependências


    const handleClickEditGoogleMaps = () => {
        const hodometroData = {
            kminicial: inicialKm,
            kmfinal: finalKm,
            kmtotal: finalKm - inicialKm,
            veiculo: {
                categoria: tipoveiculo,
                combustivel: tipocombustivel,
                porte: tipoporte
            }
        };

        const editData = {
            idviagem: viagemid,
            iddespesa: hodometro.iddespesa,
            datadecompra: datadecompra,
            descricao: descricao,
            aprovado: false,
            controladoria: false,
            hodometro: hodometroData,
            produtos: {
                iddespesa_tipo: produto?.iddespesa_tipo,
                iddespesa_subtipo: produto?.iddespesa_subtipo
            },
            total: 0,
            mensagem: "",
            QrCode: null,
            tipo_moeda: EnumTipo.moedas.BRL.id,
            // isExtra: false,
        };

        editHodometro(editData);
        setType(0);
    }

    useEffect(() => {
        getAlltipos()
    }, [getAlltipos])

    return (
        <>
            <ULoaderVoo isLoading={loading} />
            <Grid item xs={12} container style={{ display: "flex", justifyContent: "center" }}>
                <Paper style={{ padding: 15, width: "100%", borderRadius: "10px" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <img alt="Imagem inicial" src={inicialFoto} style={{ width: "-webkit-fill-available" }}></img>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Typography color="textSecondary">
                                    <CreationDate data={datadecompra} />
                                </Typography>
                            </Grid>
                            <TextField
                                style={{ marginBottom: "10px", marginTop: "15px" }}
                                id="filled-multiline-flexible"
                                variant="outlined"
                                fullWidth
                                required
                                value={descricao}
                                onChange={(e) => setDescricao(e.target.value)}
                                multiline
                                maxRows={4}
                                label="Justificativa/Descrição"
                                placeholder="Viagem para..."
                            />
                            <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                <FormControl fullWidth>
                                    <InputLabel id="veiculo-select-label">Veículo utilizado</InputLabel>
                                    <Select
                                        labelId="veiculo-select-label"
                                        id="veiculo-select"
                                        value={tipoveiculo}
                                        onChange={(e) => setTipoveiculo(e.target.value)}
                                        label="Veículo utilizado"
                                    >
                                        {Object.entries(EnumTipoVeiculo.categoria).map(([key, value]) => (
                                            <MenuItem key={value} value={value}>
                                                {key.charAt(0).toUpperCase() + key.slice(1)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                <FormControl fullWidth>
                                    <InputLabel id="veiculo-select-label">Tipo de Combustivel</InputLabel>
                                    <Select
                                        labelId="veiculo-select-label"
                                        id="veiculo-select"
                                        value={tipocombustivel}
                                        onChange={(e) => setTipocombustivel(e.target.value)}
                                        label="Tipo de Combustivel"
                                    >
                                        {Object.entries(EnumTipoVeiculo.combustivel).map(([key, value]) => (
                                            <MenuItem key={value} value={value}>
                                                {key.charAt(0).toUpperCase() + key.slice(1)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                <FormControl fullWidth>
                                    <InputLabel id="veiculo-select-label">Porte do veículo</InputLabel>
                                    <Select
                                        labelId="veiculo-select-label"
                                        id="veiculo-select"
                                        value={tipoporte}
                                        onChange={(e) => setTipoporte(e.target.value)}
                                        label="Porte do veículo"
                                    >
                                        {Object.entries(EnumTipoVeiculo.porte).map(([key, value]) => (
                                            <MenuItem key={value} value={value}>
                                                {key.charAt(0).toUpperCase() + key.slice(1)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12}>
                        <Button variant="contained" color="primary" fullWidth>
                            Em auditar
                        </Button>
                    </Grid> */}
                    </Grid>
                </Paper>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "right" }}>
                    <Button variant="contained" color="primary" autoFocus onClick={() => handleClickEditGoogleMaps()}>
                        Editar despesa
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}