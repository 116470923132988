import { Grid } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import api from '../../../Api';
import LinesTextField from '../../../components/LinesTextField';
import USearchField from '../../../components/usearchfield';
import USelect from '../../../components/uselect';
import USwitch from '../../../components/uswitch';
import Enums from '../../../enums';
import { useStyles } from './styles';
import Cookies from "js-cookie";

export const CadastroProduto = ({ setProduto, produto, picture, setPicture }) => {
    const classes = useStyles();
    const itens = [
        { value: Enums['categoriaProduto'].hotel, label: 'Hotel' },
        { value: Enums['categoriaProduto'].onibus, label: 'Ônibus' },
        { value: Enums['categoriaProduto'].locacaoDeCarro, label: 'Locação de Carro' },
        { value: Enums['categoriaProduto'].seguroViagem, label: 'Seguro Viagem' },
        { value: Enums['categoriaProduto'].pacotes, label: 'Pacotes' },
        { value: Enums['categoriaProduto'].aereo, label: 'Aéreo' },
        { value: Enums['categoriaProduto'].aereoInternacional, label: 'Aéreo Internacional' },
        { value: Enums['categoriaProduto'].outros, label: 'Outros' }
    ];
    const itens2 = [
        { value: Enums['tipoMoeda'].real, label: 'Real Brasileiro' },
        { value: Enums['tipoMoeda'].dolar, label: 'US Dollar' },
        { value: Enums['tipoMoeda'].euro, label: 'Euro' }
    ];

    const [categoria, setCategoria] = useState(produto?.categoria || 0);
    const [moeda, setMoeda] = useState(produto?.moeda || 0);
    const [fornecedor, setFornecedor] = useState(produto?.idproduto ? { label: produto.fornecedor.nome, value: produto.fornecedor.idfornecedor } : null);
    const [fornecedores, setFornecedores] = useState([]);
    const [ativo, setAtivo] = useState(produto?.ativo ?? true);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProduto((prev) => ({ ...prev, [name]: value }));
    };

    const fetchFornecedores = useCallback(async () => {
        try {
            const response = await api.get('/fornecedor', {
                headers: { Authorization: 'Bearer ' + Cookies.get('token') }
            });
            const listaFornecedores = response.data.map(item => ({
                label: item.nome,
                value: item.idfornecedor
            }));
            setFornecedores(listaFornecedores);
        } catch (error) {
            console.error('Erro ao buscar fornecedores:', error);
        }
    }, []);

    useEffect(() => {
        fetchFornecedores();
    }, [fetchFornecedores]);

    // Função para centralizar todas as atualizações de produto
    const updateProduto = useCallback(() => {
        setProduto((prev) => ({
            ...prev,
            idfornecedor: fornecedor?.value || prev.idfornecedor,
            categoria,
            moeda,
            ativo
        }));
    }, [fornecedor, categoria, moeda, ativo, setProduto]);

    // Atualiza o produto sempre que algum valor chave mudar
    useEffect(() => {
        updateProduto();
    }, [updateProduto]);

    return (
        <div className={classes.root}>
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <USwitch {...{ checked: ativo, setChecked: setAtivo, label: 'Ativo' }} />
                </Grid>
                <Grid item xs={12}>
                    <LinesTextField
                        fullWidth
                        required
                        id="teste1"
                        label="Nome"
                        name="nome"
                        variant="outlined"
                        value={produto.nome || ''}
                        onChange={handleChange}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={12}>
                    <USelect {...{ itens, value: categoria, setValue: setCategoria, label: 'Categoria' }} />
                </Grid>
                <Grid item xs={12}>
                    <USearchField {...{ value: fornecedor, setValue: setFornecedor, itens: fornecedores, label: 'Fornecedor' }} />
                </Grid>
                <Grid item xs={12}>
                    <USelect {...{ itens: itens2, value: moeda, setValue: setMoeda, label: 'Moeda Corrente' }} />
                </Grid>
            </Grid>
        </div>
    );
};

export default CadastroProduto;
