import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close, SaveOutlined } from "@material-ui/icons";
import AirlineSeatIndividualSuiteIcon from "@material-ui/icons/AirlineSeatIndividualSuite";
import CommuteIcon from "@material-ui/icons/Commute";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import FlightIcon from "@material-ui/icons/Flight";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import React, { useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import LinesTextField from "../../../../../components/LinesTextField";
import Snackbar from "../../../../../components/snackbar";
import UDatePicker from "../../../../../components/udatepicker";
import ULoaderVoo from "../../../../../components/uloadervoo";
import USelect from "../../../../../components/uselect";
import StepperContainer from "../../../../../container/DespesaPorViagem";
import viagemRepositorio from "../../../../../repositorios/viagem";
import { useStyles } from "./styles";

export const CadastroDespesa = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [tipoDespesa, setTipoDespesa] = useState(null);
  const [despesa, setDespesa] = useState({ valor: 0, descricao: "" });
  const [despesaVerify, setDespesaVerify] = useState();
  const [dataemissao, setDataEmissao] = useState(new Date());
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackState, setSnackState] = useState({ visible: false });
  const [snackVariant, setSnackVariant] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const itens = [
    { value: 0, label: "Alimentação" },
    { value: 1, label: "Transporte" },
    { value: 2, label: "Estadia" },
    { value: 3, label: "Aéreo" },
    { value: 4, label: "Outros" },
    { value: 5, label: "Hôdometro" },
  ];

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleButtonClickHodometroStatus = () => {
    fileInputRef2.current.click();
  };

  const handleInputChange = (event) => {
    if (!file || file === "True") {
      if (event.target.files) {
        setFile(event.target.files[0]);
      }
    }
  };

  const handleInputChangeStatusH = (event) => {
    if (!file2 || file2 === "True") {
      if (event.target.files) {
        setFile2(event.target.files[0]);
      }
    }
  };

  const handleCloseModal = () => {
    props.setOpen(false);
    setDespesa({});
    setTipoDespesa(null);
    setFile(null);
    setFile2(null);
    setDataEmissao(new Date());
  };

  const handleChanged = (event) => {
    setDespesa({ ...despesa, [event.target.name]: event.target.value });
  };

  const handleNumberChanged = (event) => {
    setDespesaVerify(event.target.value);
    setDespesa({ ...despesa, [event.target.name]: event.target.value });
  };

  const resizeFile = (file) => {
    if (file !== null) {
      return new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          1280,
          1280,
          "JPEG",
          80,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
        );
      });
    } else {
      return "";
    }

  };

  const handleClickSalvar = async () => {
    try {
      setIsLoading(true);

      let foto1 = null;
      let foto2 = null;

      if (file !== "True") {
        foto1 = await resizeFile(file);
      } else {
        foto1 = "True";
      }

      if (file2 !== "True") {
        if (file2 !== null) {
          foto2 = await resizeFile(file2);
        }
      } else {
        foto2 = "True";
      }

      let dto = {
        ...despesa,
        dataemissao: dataemissao,
        tipodespesa: tipoDespesa,
        recibo: foto1,
        recibo2: foto2 === null ? "" : foto2,
        idviagem: props.viagem.idviagem,
        confirmacao: false,
        valorauditado:
          parseFloat(despesa.valor) === 0 || parseFloat(despesa.valor) === null
            ? 0
            : parseFloat(despesa.valor),
      };

      if (!dto.recibo) {
        setSnackVariant("error");
        setSnackMessage("É necessário fazer o upload do comprovante.");
        setSnackState({ visible: true });
        return;
      }
      if (!tipoDespesa && tipoDespesa !== 0) {
        setSnackVariant("error");
        setSnackMessage("Tipo de despesa não pode ser vazio.");
        setSnackState({ visible: true });
        return;
      }
      if (!dto.descricao) {
        setSnackVariant("error");
        setSnackMessage("Descrição não pode ser vazia");
        setSnackState({ visible: true });
        return;
      }
      if (despesaVerify < 0) {
        setSnackVariant("error");
        setSnackMessage("Valor da despesa não pode ser negativo.");
        setSnackState({ visible: true });
        return;
      }
      if (!dto.valor && tipoDespesa !== 5) {
        setSnackVariant("error");
        setSnackMessage(
          "Valor não pode ser vazio e deve estar em formato numérico."
        );
        setSnackState({ visible: true });
        return;
      }
      if (file === null) {
        setSnackVariant("error");
        setSnackMessage("Envie uma foto");
        setSnackState({ visible: true });
        return;
      }
      if (!dataemissao) {
        setSnackVariant("error");
        setSnackMessage("Data de emissão não pode ser vazia.");
        setSnackState({ visible: true });
        return;
      }

      if (Object.keys(props.despesa).length === 0) {
        await viagemRepositorio.addViagemDespesa(dto);
        setSnackMessage("Despesa adicionada.");
      } else {
        await viagemRepositorio.editViagemDespesa(dto);
        setSnackMessage("Despesa editada.");
      }
      const responseViagem = await viagemRepositorio.get(props.viagem.idviagem);
      props.setViagem(responseViagem.data);
      handleCloseModal();
      setSnackVariant("success");
      setSnackState({ visible: true });
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage("Erro ao adicionar despesa.", e.message);
      setSnackState({ visible: true });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(props.despesa).length !== 0) {
      setDespesa(props.despesa);
      setTipoDespesa(props.despesa.tipodespesa);
      if (props.despesa.urlrecibo) {
        setFile("True");
      }
      if (props.despesa.urlrecibo_2) {
        setFile2("True");
      }
      setDataEmissao(props.despesa.dataemissao);
    }
  }, [props]);

  return (
    <form>
      <ULoaderVoo open={isLoading} />
      <Snackbar
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackState.visible,
          setSnackbar: setSnackState,
        }}
      />
      <Dialog
        open={props.open}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
        fullScreen={responsive}
      >
        <Snackbar
          {...{
            variant: snackVariant,
            message: snackMessage,
            visible: snackState.visible,
            setSnackbar: setSnackState,
          }}
        />
        <AppBar
          position="relative"
          style={{ background: "transparent", boxShadow: "none" }}
        >
          <Toolbar className={classes.appbar}>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>Despesas</Typography>
              <IconButton className={classes.button} onClick={handleCloseModal}>
                <Close style={{ color: "inherited" }} />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box px={3}>
            <Grid container spacing={1}>
              {tipoDespesa === 0 ? (
                <Grid
                  container
                  justify="center"
                  item
                  xs={12}
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <Avatar
                    style={{
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#c62828",
                      textAlign: "center",
                    }}
                  >
                    <FastfoodIcon />
                  </Avatar>
                </Grid>
              ) : null}
              {tipoDespesa === 1 ? (
                <Grid
                  container
                  justify="center"
                  item
                  xs={12}
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <Avatar
                    style={{
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#c62828",
                      textAlign: "center",
                    }}
                  >
                    <CommuteIcon />
                  </Avatar>
                </Grid>
              ) : null}
              {tipoDespesa === 2 ? (
                <Grid
                  container
                  justify="center"
                  item
                  xs={12}
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <Avatar
                    style={{
                      width: "60px",
                      height: "60px",
                      ackgroundColor: "#c62828",
                      textAlign: "center",
                    }}
                  >
                    <AirlineSeatIndividualSuiteIcon />
                  </Avatar>
                </Grid>
              ) : null}
              {tipoDespesa === 3 ? (
                <Grid
                  container
                  justify="center"
                  item
                  xs={12}
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <Avatar
                    style={{
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#c62828",
                      textAlign: "center",
                    }}
                  >
                    <FlightIcon />
                  </Avatar>
                </Grid>
              ) : null}

              {tipoDespesa === 5 ? (
                <>
                  <StepperContainer {...{ file, file2, handleInputChange }} />
                  <Grid item xs={12} md={12}>
                    <LinesTextField
                      value={despesa.kmida}
                      onChange={handleChanged}
                      name="kmida"
                      label="Km inicial"
                      variant="outlined"
                      multiline
                      fullWidth
                      type="number"
                      margin="dense"
                    />
                  </Grid>
                </>
              ) : null}
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  id="icon-button-file"
                  style={{ display: "none" }}
                  required
                  accept="image/*, application/pdf"
                  type="file"
                  onChange={handleInputChange}
                />
                <label htmlFor="icon-button-file">
                  <input
                    id="icon-button-file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    required
                    accept="image/*, application/pdf"
                    type="file"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="icon-button-file">
                    <Button
                      startIcon={<PhotoCameraIcon />}
                      style={{
                        backgroundColor: "#c62828",
                        color: "white",
                        height: "16px",
                        padding: "20px",
                      }}
                      onClick={handleButtonClick}
                    >
                      {!file ? <p>Enviar foto</p> : <p>Editar foto</p>}
                    </Button>
                  </label>
                </label>
              </Grid>
              {tipoDespesa === 5 ? (
                <>
                  {file !== null ? (
                    <>
                      <Grid item xs={12} md={12}>
                        <LinesTextField
                          value={despesa.kmvolta === 0 ? null : despesa.kmvolta}
                          onChange={handleChanged}
                          name="kmvolta"
                          label="Km final"
                          variant="outlined"
                          type="number"
                          multiline
                          fullWidth
                          margin="dense"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          id="icon-button-file"
                          style={{ display: "none" }}
                          required
                          accept="image/*, application/pdf"
                          type="file"
                          onChange={handleInputChangeStatusH}
                        />
                        <label htmlFor="icon-button-file">
                          <input
                            id="icon-button-file"
                            ref={fileInputRef2}
                            style={{ display: "none" }}
                            required
                            accept="image/*, application/pdf"
                            type="file"
                            onChange={handleInputChangeStatusH}
                          />
                          <label htmlFor="icon-button-file">
                            <Button
                              startIcon={<PhotoCameraIcon />}
                              style={{
                                backgroundColor: "#c62828",
                                color: "white",
                                height: "16px",
                                padding: "20px",
                              }}
                              onClick={handleButtonClickHodometroStatus}
                            >
                              {file2 ? <p>Editar foto</p> : <p>Enviar foto</p>}
                            </Button>
                          </label>
                        </label>
                      </Grid>
                    </>
                  ) : null}
                </>
              ) : null}

              <Grid item xs={12} md={12}>
                <USelect
                  itens={itens}
                  value={tipoDespesa}
                  setValue={setTipoDespesa}
                  label="Tipo de Despesa"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <LinesTextField
                  value={despesa.descricao}
                  onChange={handleChanged}
                  name="descricao"
                  label="Justificativa / Descrição"
                  variant="outlined"
                  multiline
                  fullWidth
                  margin="dense"
                />
              </Grid>
              {tipoDespesa === 5 ? null : (
                <Grid item xs={12} md={6}>
                  <LinesTextField
                    type="number"
                    label="Valor"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={despesa.valor}
                    onChange={handleNumberChanged}
                    name="valor"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={"start"}>R$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <UDatePicker
                  label="Data Emissão"
                  date={dataemissao}
                  setDate={setDataEmissao}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClickSalvar}
            className={classes.buttonSave}
            startIcon={<SaveOutlined />}
            disabled={isLoading}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default CadastroDespesa;
