import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  InputBase,
  Paper,
  TablePagination,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Search, SearchOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import cookie from "universal-cookie";
import LoadingButton from "../../../components/loadingButton";
import Snackbar from "../../../components/snackbar";
import UFloatButton from "../../../components/ufloatbutton";
import ULoaderVoo from "../../../components/uloadervoo";
import Enums from "../../../enums/index";
import permissaoRepositorio from "../../../repositorios/permissao/index";
import pessoaRepositorio from "../../../repositorios/pessoa";
import { validatorCPF } from "../../../utils/cpfValidator";
import Dashboard from "../../dashboard";
import CadastroUsuario from "../cadastro";
import PessoasPagination from "./ListaPessoasPagination";
import { useStyles } from "./styles";

export const ListaPessoa = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery("(maxWidth:480px)");
  const matches2 = useMediaQuery(
    "(maxWidth:900px) and (orientation: landscape)"
  );
  const matches3 = useMediaQuery(
    "(minWidth:600px) and (orientation: portrait)"
  );
  const [pessoasPesquisa, setPessoasPesquisa] = useState([]);
  const [validacao, setValidacao] = useState({ visible: false });
  const [mensagemValidacao, setMensagemValidacao] = useState("");
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const classes = useStyles();
  const [picture, setPicture] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewViajante, setIsNewViajante] = useState(false);
  const [isUnderAge, setIsUnderAge] = useState(false);
  const [pessoas, setPessoas] = useState([]);
  const [pessoa, setPessoa] = useState({});
  const [showIconFab, setShowIconFab] = useState(
    pessoa?.idpessoa ? false : true
  );
  const cookies = new cookie();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [itensPerPage, setItensPergPage] = useState(10);
  const pages = pessoasPesquisa.length;
  const start = page * itensPerPage;
  const r = itensPerPage + start;
  const todasPessoas = pessoasPesquisa.slice(start, r);
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [cadastroOpen, setCadastroOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setItensPergPage(event.target.value);
  };

  const onPesquisar = (event) => {
    const contem = pessoas.filter((item) => {
      return (
        (item.nome + " " + item.sobrenome).includes(
          event.target.value.toUpperCase()
        ) || item.cpf.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });
    setPessoasPesquisa(contem);
  };

  const salvar = async () => {
    try {
      setIsLoading(true);
      const dto = {
        ...pessoa,
        sessionId: cookies.get("sessionId"),
        picture: picture,
      };
      if (dto.ativo) {
        if (!dto.nome) {
          setMensagemValidacao("O campo nome não pode ser vazio");
          setValidacao({ visible: true });
          return;
        }
        if (!dto.sobrenome) {
          setMensagemValidacao("O campo sobrenome não pode ser vazio");
          setValidacao({ visible: true });
          return;
        }
        if (!dto.rg) {
          setMensagemValidacao("O campo RG não pode ser vazio");
          setValidacao({ visible: true });
          return;
        }
        if (isNaN(dto.rg)) {
          setMensagemValidacao("O campo RG deve conter apenas numeros");
          setValidacao({ visible: true });
          return;
        }
        if (!dto.cpf) {
          setMensagemValidacao("O campo CPF não pode ser vazio");
          setValidacao({ visible: true });
          return;
        }
        if (isNaN(!dto.cpf)) {
          setMensagemValidacao("O campo CPF deve conter apenas numeros");
          setValidacao({ visible: true });
          return;
        }
        if (!dto.sexo || dto.sexo === null) {
          setMensagemValidacao("O campo gênero não pode ser vazio");
          setValidacao({ visible: true });
          return;
        }
        if (!dto.datanascimento) {
          setMensagemValidacao("O campo data de nascimento não pode ser vazio");
          setValidacao({ visible: true });
          return;
        }
        if (
          dto.telefone === "(__)_____-____" ||
          dto.telefone === null ||
          dto.telefone === "undefined" ||
          dto.telefone.trim() === ""
        ) {
          setSnackVariant("error");
          setSnackMessage("O campo telefone não pode ser vazio.");
          setSnackVisible({ visible: true });
          setIsLoading(false);
          return;
        }

        let teste = false;

        if (pessoa.nacionalidade !== 1) {
          if (
            pessoa.cpf === null ||
            pessoa.cpf === "" ||
            pessoa.cpf === undefined ||
            pessoa.cpf === "00000000000"
          ) {
            teste = false;
          } else {
            teste = !validatorCPF(pessoa.cpf);
          }
        } else {
          teste = !validatorCPF(pessoa.cpf);
        }

        if (teste) {
          setSnackVariant("error");
          setSnackMessage("CPF inválido.");
          setSnackVisible({
            visible: true,
          });
          setIsLoading(false);
          return;
        }
      }

      if (pessoa.idpessoa) {
        const temPermissaoEditar = await permissaoRepositorio.temPermissao(
          Enums.moduloSistema.pessoa,
          Enums.acaoRequerida.editar
        );
        if (!temPermissaoEditar.data) {
          setMensagemValidacao("Você não possui permissão!");
          setValidacao({ visible: true });
          return;
        }

        let response;
        try {
          response = await pessoaRepositorio.updatePessoa(pessoa.idpessoa, dto);
          if (response.status === 200) {
            setSnackVariant("success");
            setSnackMessage(response.data.message);
            setSnackVisible({
              visible: true,
            });
          } else {
            setSnackVariant("error");
            setSnackMessage(response.data.message);
            setSnackVisible({
              visible: true,
            });
            return;
          }
        } catch (e) {
          return;
        } finally {
          setIsLoading(false);
        }

        let pessoas = await pessoaRepositorio.getAll();
        setPessoas(pessoas.data);
        setPessoasPesquisa(pessoas.data);
        setCadastroOpen(false);
        return response;
      }

      const temPermissaoCriar = await permissaoRepositorio.temPermissao(
        Enums.moduloSistema.pessoa,
        Enums.acaoRequerida.criar
      );
      if (!temPermissaoCriar.data) {
        setMensagemValidacao("Você não possui permissão!");
        setValidacao({ visible: true });
        return;
      }

      let response = null;
      try {
        response = await pessoaRepositorio.postPessoa(dto);
        if (response.status === 200) {
          setSnackVariant("success");
          setSnackMessage(response.data.message);
          setSnackVisible({
            visible: true,
          });
        } else {
          setSnackVariant("error");
          setSnackMessage(response.data.message);
          setSnackVisible({
            visible: true,
          });
          return;
        }
      } catch (e) {
        setSnackVariant("error");
        setSnackMessage("Erro ao tentar salvar.");
        setSnackVisible({
          visible: true,
        });
        return;
      } finally {
        setIsLoading(false);
      }
      let pessoas = await pessoaRepositorio.getAll();
      setPessoas(pessoas.data);
      setPessoasPesquisa(pessoas.data);
      setCadastroOpen(false);
      return response;
    } catch (e) {
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleCadastroOpen = async (item, visible, isNew) => {
    if (isNew) {
      setPessoa({ nacionalidade: 1 });
      setIsNewViajante(true);
      setShowIconFab(true);
      setCadastroOpen(visible);
      return;
    } else {
      setIsNewViajante(false);
      setShowIconFab(false);
    }
    try {
      setIsLoading(true);
      let response = await pessoaRepositorio.get(item.idpessoa);
      setPessoa(response);
      setCadastroOpen(visible);
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage("Erro ao exibir viajante.");
      setSnackVisible({
        visible: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  async function handleButtonClickCheckCPF() {
    setIsLoading(true);

    let teste = false;

    if (pessoa.nacionalidade !== 1) {
      if (
        pessoa.cpf === null ||
        pessoa.cpf === "" ||
        pessoa.cpf === undefined ||
        pessoa.cpf === "000.000.000-00"
      ) {
        teste = false;
      } else {
        teste = !validatorCPF(pessoa.cpf);
      }
    } else {
      teste = !validatorCPF(pessoa.cpf);
    }

    // if (pessoa.nacionalidade) {
    // if (pessoa.cpf === null || pessoa.cpf === '' || !cpfValidator(pessoa.cpf)) {

    if (teste) {
      setSnackVariant("error");
      setSnackMessage("CPF inválido.");
      setSnackVisible({
        visible: true,
      });
      setIsLoading(false);
      return;
    } else {
      setPessoa({ ...pessoa, cpf: "" });
      try {
        let dto = {
          cpf: pessoa.cpf,
          idpassageiro: pessoa.idpessoa,
          nacionalidade: pessoa.nacionalidade,
        };
        let response = await pessoaRepositorio.postVerificarCpf(dto);
        if (response.status === 200) {
          setSnackVariant("success");
          setShowIconFab(false);
          setPessoa({
            ...pessoa,
            datanascimento: response.data.nascimento,
            nome: response.data.nome,
            sobrenome: response.data.sobrenome,
            verifiedCPF: true,
          });
          setIsUnderAge(false);
        } else if (response.status === 206) {
          setSnackVariant("warning");
          setShowIconFab(false);
          setIsUnderAge(true);
          setPessoa({
            ...pessoa,
            datanascimento: new Date(),
            verifiedCPF: true,
          });
        } else if (response.status === 202) {
          setSnackVariant("info");
          setShowIconFab(false);
          setIsUnderAge(true);
          setPessoa({
            ...pessoa,
            datanascimento: new Date(),
            verifiedCPF: true,
          });
        } else {
          setSnackVariant("error");
          setIsUnderAge(false);
        }
        setSnackMessage(response.data.message);
        setSnackVisible({ visible: true });
      } catch (e) {
        setIsUnderAge(false);
        setSnackVariant("error");
        setSnackMessage("Falha ao consultar dados.");
        setSnackVisible({
          visible: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    (async () => {
      const response = await pessoaRepositorio.getAll();
      setPessoas(response.data);
      setPessoasPesquisa(response.data);
    })();
  }, []);

  return (
    <>
      <ULoaderVoo isLoading={isLoading} />
      <div className={classes.root}>
        <Snackbar
          {...{
            variant: "error",
            message: mensagemValidacao,
            visible: validacao.visible,
            setSnackbar: setValidacao,
          }}
        />
        <Snackbar
          {...{
            variant: snackVariant,
            message: snackMessage,
            visible: snackVisible.visible,
            setSnackbar: setSnackVisible,
          }}
        />
        <Dialog
          className={classes.cadastro}
          open={cadastroOpen}
          onClose={() => {
            setIsUnderAge(false);
            setCadastroOpen(false);
          }}
          fullScreen={fullscreen}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogContent
            style={{
              padding: "0px",
              paddingTop: "0px",
            }}
          >
            <CadastroUsuario
              isNewViajante={isNewViajante}
              pessoa={pessoa}
              setPessoa={setPessoa}
              picture={picture}
              setPicture={setPicture}
              setCadastroOpen={setCadastroOpen}
              setSnackMessage={setSnackMessage}
              setSnackVariant={setSnackVariant}
              setSnackVisible={setSnackVisible}
              handleButtonClickCheckCPF={handleButtonClickCheckCPF}
              isUnderAge={isUnderAge}
              setIsUnderAge={setIsUnderAge}
              isLoading={isLoading}
            />
            {showIconFab ? null : (
              <div className={classes.btnFab}>
                <LoadingButton
                  {...{
                    salvar: salvar,
                    modalOpen: cadastroOpen,
                    setModalOpen: setCadastroOpen,
                    isPessoa: true,
                  }}
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            {showIconFab ? (
              <Fab
                aria-label="save"
                color="primary"
                onClick={() => handleButtonClickCheckCPF()}
                style={{
                  color: "#ffffff",
                  background: "#a82223",
                  position: "relative",
                }}
              >
                <SearchOutlined />
              </Fab>
            ) : null}
          </DialogActions>
        </Dialog>
        <Paper elevation={!fullscreen ? 5 : 0}>
          <Toolbar className={classes.toolbar}>
            {!matches && !matches2 && !matches3 ? (
              <Button
                className={classes.button}
                onClick={() => handleCadastroOpen(null, true, true)}
              >
                Novo
              </Button>
            ) : (
              <UFloatButton
                actions={[]}
                handleClick={(e) =>
                  handleCadastroOpen({ tipousuario: 1 }, true, true)
                }
              />
            )}
            {fullscreen ? null : (
              <TablePagination
                component="div"
                count={pages}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[10, 25, 50]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
            <div className={classes.search3}>
              <div className={classes.searchIcon}>
                <Search />
              </div>
              <InputBase
                onChange={onPesquisar}
                placeholder="Pesquise…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Toolbar>
          {fullscreen ? (
            <>
              <TablePagination
                style={{ minWidth: "max-content" }}
                labelRowsPerPage={""}
                component="div"
                count={pages}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[10, 25, 50]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : null}
          <PessoasPagination
            data={pessoasPesquisa}
            {...{ handleCadastroOpen, fullscreen, todasPessoas }}
          />
        </Paper>
      </div>
    </>
  );
};

export const Lista = (props) => {
  return (
    <div>
      <Dashboard {...props} component={ListaPessoa} />
    </div>
  );
};
export default Lista;
