import { useState, useMemo } from 'react';

const usePagination = (items, itemsPerPage) => {
    // Estado para armazenar a página atual
    const [page, setPage] = useState(1);

    // Calcular o índice inicial e final dos itens da página atual
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Memorizar os itens da página atual
    const currentItems = useMemo(() => items.slice(startIndex, endIndex), [items, startIndex, endIndex]);

    // Número total de páginas
    const totalPages = Math.ceil(items.length / itemsPerPage);

    return {
        currentItems,    // Itens da página atual
        page,            // Página atual
        totalPages,      // Número total de páginas
        setPage          // Função para mudar a página
    };
};

export default usePagination;
