import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Skeleton, TextField } from "@mui/material";
import { UploadAvatar } from "../../../../../../upload";
import { UtaliiDatePickerNormal } from "../../../sections/custom-date-picker-normal";
import { useCallback, useEffect, useState } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import prestacaoDeContasRepositorio from "../../../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../../../components/snackbar2/NewSnackBar";
import { EnumTipo, formatarMoeda, getCurrencySymbol, getEnumForID } from "../../../../../../enums/enumPrestacaoDeContas";
import Flag from "react-world-flags";
import ULoaderVoo from "../../../../../../components/uloadervoo";
import { processImage } from "../../../../../../components/autalii_expanses/controlimages";

export const EditarDespesaComponent = ({ viagem, despesa, handleEdit, setType }) => {
    const [file, setFile] = useState(null);
    const [tipodedespesa, setTipodedespesa] = useState(null);
    const [tipoGasto, setTipoGasto] = useState(null)
    const [descricao, setDescricao] = useState("");
    const [datadecompra, setDatadecompra] = useState(new Date());
    const [totalgasto, setTotalgasto] = useState(formatarMoeda(String(despesa.total.toFixed(2))));
    const [loading, setLoading] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [moeda, setMoeda] = useState(despesa.tipo_moeda);
    const [isEdit, setIsEdit] = useState(false)

    const { enqueueSnackbar } = useCustomSnackbar();

    const getAlltipos = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas();
            setCategorias(response);
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, setCategorias, enqueueSnackbar]); // 


    const handleEditDespesa = () => {
        const despesadata = {
            idviagem: viagem.id,
            iddespesa: despesa.iddespesa,
            datadecompra: datadecompra,
            descricao: descricao,
            hodometro: null,
            QrCode: null,
            aprovado: false,
            controladoria: false,
            is_extra: false,
            total: parseFloat(totalgasto.replace(',', '.')),
            mensagem: "",
            comprovante: file,
            tipo_moeda: moeda
        }

        if (tipodedespesa !== null && tipoGasto !== null) {
            despesadata.produtos = {
                iddespesa_tipo: tipodedespesa.value,
                iddespesa_subtipo: tipoGasto.value
            }
            if (despesadata.produtos.iddespesa_tipo !== null && despesadata.produtos.iddespesa_subtipo === null) {
                enqueueSnackbar('Adicione com oque foi gasto.', { variant: 'warning' });
                return
            }
            despesadata.valor_limite = tipoGasto.limite;
        }else{
            despesadata.produtos = {
                iddespesa_tipo: tipodedespesa.value,
                iddespesa_subtipo: tipodedespesa.sub[0].iddespesa_subtipo
            }
        }

        handleEdit(despesadata)
        setType(0)
    }

    const handleChangeTipoDeDespesa = (value) => {
        setTipoGasto(null)
        setTipodedespesa(value);
        setIsEdit(true)
    };

    const handleChangeGasto = (value) => {
        setTipoGasto(value)
        setIsEdit(true)
    }

    const handleDropAvatar = useCallback((acceptedFiles) => {
        const newFile = acceptedFiles[0];
        if (newFile) {
            processImage(Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
            })).then(base64String => {
                setFile(
                    base64String // Aqui você tem a imagem convertida em base64
                );
            }).catch(error => {
                console.error("Erro ao converter imagem para Base64:", error);
            });
        }
        setIsEdit(true)
    }, []);

    const handleValorChange = (event) => {
        const valorInserido = event.target.value;

        // Formata o valor
        const valorFormatado = formatarMoeda(valorInserido);
        // Atualiza o estado com o valor formatado
        setTotalgasto(valorFormatado);
        setIsEdit(true)
    };

    const handleMoedaChange = (event) => {
        const novaMoeda = event.target.value;
        setMoeda(novaMoeda);

        if (totalgasto !== '') {
            setTotalgasto(totalgasto); // Mantém o valor atual, mas ele será formatado na apresentação
        }
        setIsEdit(true)
    };

    useEffect(() => {
        getAlltipos()
    }, [getAlltipos])

    useEffect(() => {
        setFile(null)
        categorias?.forEach(categoria => {
            if (categoria.iddespesa_tipo === despesa.produtos.iddespesa_tipo) {
                setTipodedespesa({ label: categoria.name, value: despesa.produtos.iddespesa_tipo, sub: categoria.produtos });
                categoria.produtos.forEach(sub => {
                    setTipoGasto({ label: sub.name, value: despesa.produtos.iddespesa_subtipo })
                })
            }
        })
        setDescricao(despesa.descricao);
        setDatadecompra(new Date(despesa.datadecompra));
        setTotalgasto(formatarMoeda(String(despesa.total.toFixed(2))));
    }, [despesa, categorias])

    return (
        <>
            <ULoaderVoo isLoading={loading} />
            <Grid container item xs={12} style={{ width: "auto", minWidth: "500px" }}>
                {/* <Grid container item xs={12} style={{ justifyContent: "left", marginTop: "-20px", display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center" }}>
                    <Grid item xs={6}>
                        <Typography style={{ cursor: "pointer" }} onClick={() => setType(0)}>Voltar</Typography>
                    </Grid>
                </Grid> */}
                <Grid container item xs={4} style={{ justifyContent: "center", textAlign: "left", alignContent: "space-between", marginTop: "10px", padding: "10px" }} spacing={1}>
                    <UploadAvatar
                        file={file || despesa.comprovante}
                        accept="image/jpeg,image/png,image/gif"
                        style={{ height: "auto" }}
                        onDrop={handleDropAvatar}
                        fullScreen={true}
                    />
                </Grid>
                <Grid container item xs={8} style={{ marginTop: "10px", paddingLeft: "10px", alignContent: "center" }} spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            id="filled-multiline-flexible"
                            variant="outlined"
                            fullWidth
                            required
                            defaultValue={despesa.descricao}
                            onChange={(e) => setDescricao(e.target.value)}
                            onClick={() => setIsEdit(true)}
                            multiline
                            maxRows={4}
                            label="Justificativa/Descrição"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {categorias.length === 0 ? (
                            <Skeleton>
                            </Skeleton>
                        ) : (
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={tipodedespesa != null ? tipodedespesa.value : 0}
                                    label="Categoria"
                                >
                                    {categorias
                                        ?.filter(categoria =>
                                            categoria.name !== 'Hodometro' &&
                                            categoria.name !== 'Pendente' &&
                                            categoria.name !== 'SOLICITAÇÃO' &&
                                            categoria.name !== 'AEREO' &&
                                            categoria.name !== 'HOTEL'
                                        )
                                        .map((e) => (
                                            <MenuItem
                                                value={e.iddespesa_tipo}
                                                onClick={() => handleChangeTipoDeDespesa({ label: e.name, value: e.iddespesa_tipo, sub: e.produtos })}
                                            >
                                                {e.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                    {tipodedespesa !== null && tipodedespesa?.sub?.lenght !== 0 && tipodedespesa?.label !== "Outros" && tipodedespesa.value !== 7 ? (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Gasto com</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={tipoGasto != null ? tipoGasto.value : null}
                                    defaultValue={0}
                                    label="Categoria"

                                >
                                    {tipodedespesa?.sub?.map((e) => (
                                        <MenuItem value={e.iddespesa_subtipo} onClick={() => handleChangeGasto({ label: e.name, value: e.iddespesa_subtipo })}>{e.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    ) : (
                        null
                    )}
                    {viagem.tem_multimonetario && (
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" style={{ minWidth: 120 }}>
                                <InputLabel id="select-moeda-label">Moeda</InputLabel>
                                <Select
                                    labelId="select-moeda-label"
                                    id="select-moeda"
                                    value={moeda}
                                    onChange={handleMoedaChange}
                                    label="Moeda"
                                >
                                    {viagem.adiantamento?.map((i) => {
                                        const item = getEnumForID(i.tipo_moeda);
                                        return (
                                            <MenuItem key={item.id} value={item.id}>
                                                <Flag code={item.flagCode} width="20" style={{ marginRight: '10px' }} /> {item.nome}
                                            </MenuItem>
                                        );
                                    })}
                                    <MenuItem key={EnumTipo.moedas.BRL.id} value={EnumTipo.moedas.BRL.id}>
                                        <Flag code={EnumTipo.moedas.BRL.flagCode} width="20" style={{ marginRight: '10px' }} /> {EnumTipo.moedas.BRL.nome}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    <Grid item xs={6} style={{ paddingRight: "10px" }}>

                        <TextField
                            fullWidth
                            id="outlined-number"
                            label="Total gasto"
                            value={totalgasto}
                            defaultValue={totalgasto}
                            onChange={handleValorChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {getCurrencySymbol(despesa.tipo_moeda)}
                                    </InputAdornment>
                                ),
                                inputProps: {
                                    inputMode: 'numeric', // Orienta o uso de teclado numérico
                                    style: { fontSize: '18px' }
                                }
                            }}
                        />

                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: "10px" }}>
                        <UtaliiDatePickerNormal
                            value={datadecompra}
                            onChange={setDatadecompra}
                            label="Data de Compra"
                        // Você pode adicionar outras propriedades conforme necessário
                        />
                    </Grid>
                </Grid>
                {/* {tipodedespesa !== null ? (
                    <> */}
                <Grid container item xs={6} style={{ justifyContent: "left", display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center" }}>
                    <Grid item xs={6} style={{}}>
                        <Button
                            onClick={() => setType(0)}
                            startIcon={<ArrowBackIosNewIcon />}
                            color="primary"
                        >
                            Voltar
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={6} style={{ display: "flex", justifyContent: "right" }}>
                    <Button disabled={!isEdit} variant="outlined" color="primary" autoFocus onClick={() => handleEditDespesa()}>
                        Salvar Alteração
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}