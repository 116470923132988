import { Avatar, Box, Button, Card, CardContent, Grid, Grow, IconButton, Tooltip, Typography } from "@mui/material"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import AddCardIcon from '@mui/icons-material/AddCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import { EnumTipo, formatarParaAuditado, formatarParaMoeda, formatarParaMoedaStringComFlag, formatarParaNomeStatusViagem, formatarTotalDespesasForMoeda, formatarValorComMoeda } from "../../enums/enumPrestacaoDeContas";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SavingsIcon from '@mui/icons-material/Savings';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { CreationDate } from "../../views/prestacaodecontas/Desktop/sections";
import { grey, stringAvatar } from "../../theme/palette";

export const DeskCardPersonExpanses = ({ viagem, despesas, fullScreen = false }) => {
    return (
        <>
            {fullScreen ? (
                <>
                    <CardContent>
                        <Box sx={{ display: 'block', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography variant="h6">{viagem?.titulo}</Typography>
                            <Typography variant="h6">
                                {viagem ? formatarParaNomeStatusViagem(viagem.status) : null}
                            </Typography>
                        </Box>
                        <Typography variant="subtitle1" color="textSecondary">{viagem.pessoa.nome}</Typography>
                        {/* <Chip label="Em análise" color="info" sx={{ my: 1 }} /> */}
                        <Typography variant="body2" color="textSecondary">Data de criação</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}><CreationDate data={viagem.datadecriacao} /></Typography>
                        {viagem.tem_multimonetario ? (
                            <>
                                {viagem.adiantamento.map((item) => (
                                    <Grid item xs={12} style={{ padding: "10px" }}>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>
                                                    {formatarParaMoedaStringComFlag(item.tipo_moeda, item.total)}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: "flex", justifyContent: "left" }}>
                                            <Box justifyContent="space-between">
                                                <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Total prestado</Typography>
                                                <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarTotalDespesasForMoeda(despesas, item.tipo_moeda)}</Typography>
                                            </Box>
                                            <Box sx={{ textAlign: 'center', px: 2 }}>
                                                <Box justifyContent="space-between">
                                                    <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Total informado</Typography>
                                                    <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarValorComMoeda(item.tipo_moeda, item.total)}</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ textAlign: 'center', px: 2 }}>
                                                <Box justifyContent="space-between">
                                                    <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Total em reais</Typography>
                                                    <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarValorComMoeda(EnumTipo.moedas.BRL.id, item.total_brl)}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <>
                                <Grid container item xs={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                    <Grid item xs={12} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Gastos
                                        </Typography>
                                        <Typography variant="h3">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.total)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: "flex", justifyContent: "right", marginBottom: "10px" }}>
                                        <Box sx={{ textAlign: 'center', px: 2 }}>
                                            <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                                Adiantamento
                                            </Typography>

                                            <Typography variant="body2" sx={{ color: 'text.secondary' }} style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <AddCardIcon style={{ marginRight: "5px" }} />
                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial)}
                                            </Typography>
                                        </Box>
                                        {viagem.totalinicial !== 0 && (
                                            <Box sx={{ textAlign: 'center', px: 2 }}>
                                                <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                                    Restante
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: 'text.secondary' }} style={{
                                                    display: "flex",
                                                    alignContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <PaymentsIcon style={{ marginRight: "5px" }} />
                                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial - viagem.total)}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                    {despesas?.length > 0 && (
                                        <>
                                            {formatarParaAuditado(despesas) != 0 && (
                                                <>
                                                    <Grid item xs={12} style={{ display: "flex", justifyContent: "right", marginBottom: "10px" }}>
                                                        <Box sx={{ textAlign: 'center', px: 2 }}>
                                                            <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                                                Total Auditado
                                                            </Typography>
                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }} style={{
                                                                display: "flex",
                                                                alignContent: "center",
                                                                alignItems: "center"
                                                            }}>
                                                                <PriceCheckIcon style={{ marginRight: "5px" }} />
                                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                                                                    .format(
                                                                        viagem.total - formatarParaAuditado(despesas)
                                                                    )}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ textAlign: 'center', px: 2 }}>
                                                            <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                                                Total Prestado
                                                            </Typography>
                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }} style={{
                                                                display: "flex",
                                                                alignContent: "center",
                                                                alignItems: "center"
                                                            }}>
                                                                <SavingsIcon style={{ marginRight: "5px" }} />
                                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                                                                    .format(
                                                                        viagem.total + viagem.totalextras
                                                                    )}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                                        {viagem.totalextras != 0 && (
                                            <Box sx={{ textAlign: 'center', px: 2 }}>
                                                <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                                    Conciliação
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: 'text.secondary' }} style={{
                                                    display: "flex",
                                                    alignContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <RequestQuoteIcon style={{ marginRight: "5px" }} />
                                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalextras)}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </CardContent>
                </>
            ) : (
                <Grow
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    timeout={1000} // Atraso na transição para cada card
                >
                    <Grid container item xs={12} style={{ padding: 10, display: 'flex', alignItems: 'center', maxWidth: "auto", boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)", borderRadius: "10px", }}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', maxWidth: "auto", }}>
                            <Avatar
                                size={5}
                                {...stringAvatar(viagem.pessoa.nome)} src={viagem.pessoa.img} style={{ width: 56, height: 56 }}
                            />
                            <div style={{ flexGrow: 1, textAlign: "left", marginLeft: "10px" }}>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                    {viagem.pessoa.nome}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Centro de custo - {viagem.centrocusto}
                                </Typography>
                            </div>
                            {viagem.pessoa.whats !== "" && viagem.pessoa.whats !== null && (
                                <Tooltip title="Contate-o pelo WhatsApp">
                                    <IconButton aria-label="Entrar em contato">
                                        <a href={viagem.pessoa.whats} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                                            <WhatsAppIcon />
                                        </a>
                                    </IconButton>
                                </Tooltip>
                            )}
                            {viagem.pessoa.email !== "" && viagem.pessoa.email !== null && (
                                <Tooltip title="Contate-o pelo Email">
                                    <IconButton aria-label="Entrar em contato">
                                        <a href={`mailto:${viagem.pessoa.email}`} style={{ color: 'inherit' }}>
                                            <EmailIcon />
                                        </a>
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                        {viagem.tem_multimonetario ? (
                            <>
                                {viagem.adiantamento.map((item) => (
                                    <Grid item xs={12} style={{ padding: "10px" }}>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>
                                                    {formatarParaMoedaStringComFlag(item.tipo_moeda, item.total)}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: "flex", justifyContent: "left" }}>
                                            <Box justifyContent="space-between">
                                                <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Total prestado</Typography>
                                                <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarTotalDespesasForMoeda(despesas, item.tipo_moeda)}</Typography>
                                            </Box>
                                            <Box sx={{ textAlign: 'center', px: 2 }}>
                                                <Box justifyContent="space-between">
                                                    <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Total informado</Typography>
                                                    <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarValorComMoeda(item.tipo_moeda, item.total)}</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ textAlign: 'center', px: 2 }}>
                                                <Box justifyContent="space-between">
                                                    <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Total em reais</Typography>
                                                    <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarValorComMoeda(EnumTipo.moedas.BRL.id, item.total_brl)}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <>
                                <Grid container item xs={12} style={{ display: 'flex', alignItems: 'center', maxWidth: "auto", padding: 10 }}>
                                    <Grid item xs={4} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Gastos
                                        </Typography>
                                        <Typography variant="h4">
                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                                                .format(viagem.total - formatarParaAuditado(despesas))}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: "flex", justifyContent: "right" }}>
                                        <Box sx={{ textAlign: 'center', px: 2 }}>
                                            <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                                Conciliação
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }} style={{
                                                display: "flex",
                                                alignContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <RequestQuoteIcon style={{ marginRight: "5px" }} />
                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalextras)}
                                            </Typography>
                                        </Box>
                                        {viagem.adiantamento.status == EnumTipo.adiantamento.naosolicitado ? (
                                            null
                                        ) : (
                                            <Box sx={{ textAlign: 'center', px: 2 }}>
                                                <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                                    Adiantamento
                                                </Typography>

                                                <Typography variant="body2" sx={{ color: 'text.secondary' }} style={{
                                                    display: "flex",
                                                    alignContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <AddCardIcon style={{ marginRight: "5px" }} />
                                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial)}
                                                </Typography>
                                            </Box>
                                        )}
                                        {viagem.totalinicial !== 0 && (
                                            <Box sx={{ textAlign: 'center', px: 2 }}>
                                                <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                                    Restante
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: 'text.secondary' }} style={{
                                                    display: "flex",
                                                    alignContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <PaymentsIcon style={{ marginRight: "5px" }} />
                                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Math.max(viagem.totalinicial - viagem.total, 0))}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                    {despesas?.length > 0 && (
                                        <>
                                            {formatarParaAuditado(despesas) != 0 ? (
                                                <>
                                                    {/* <Typography variant="subtitle2" gutterBottom>
                                    <div style={{ textAlign: "left" }}>
                                        <Typography variant="h6" style={{ fontSize: "10px" }}>
                                            Auditado
                                        </Typography>
                                        <Typography variant="h6" style={{ fontWeight: "600" }} color="warning">
                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                                                .format(
                                                    formatarParaAuditado(viagem.despesas)
                                                )}
                                        </Typography>
                                    </div>
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>
                                    <div style={{ textAlign: "left" }}>
                                        <Typography variant="h6" style={{ fontSize: "10px" }}>
                                            Total
                                        </Typography>
                                        <Typography variant="h6" style={{ fontWeight: "600" }} color="warning">
                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                                                .format(
                                                    viagem.total
                                                )}
                                        </Typography>
                                    </div>
                                </Typography> */}
                                                    <Grid item xs={12} style={{ display: "flex", justifyContent: "left" }}>
                                                        <Box sx={{ textAlign: 'center', px: 2 }}>
                                                            <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                                                Total Auditado
                                                            </Typography>
                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }} style={{
                                                                display: "flex",
                                                                alignContent: "center",
                                                                alignItems: "center"
                                                            }}>
                                                                <PriceCheckIcon style={{ marginRight: "5px" }} />
                                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                                                                    .format(
                                                                        formatarParaAuditado(despesas)
                                                                    )}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ textAlign: 'center', px: 2 }}>
                                                            <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                                                Total Prestado
                                                            </Typography>
                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }} style={{
                                                                display: "flex",
                                                                alignContent: "center",
                                                                alignItems: "center"
                                                            }}>
                                                                <SavingsIcon style={{ marginRight: "5px" }} />
                                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                                                                    .format(
                                                                        viagem.total
                                                                    )}
                                                            </Typography>
                                                        </Box>
                                                        {/* <Box sx={{ textAlign: 'center', px: 2 }}>
                                                <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                                    Total Prestado
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: 'text.secondary' }} style={{
                                                    display: "flex",
                                                    alignContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <SavingsIcon style={{ marginRight: "5px" }} />
                                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                                                        .format(
                                                            viagem.total - formatarParaAuditado(despesas)
                                                        )}
                                                </Typography>
                                            </Box> */}
                                                    </Grid>
                                                </>
                                            ) : (null)}
                                        </>
                                    )}
                                </Grid>
                            </>
                        )}
                    </Grid >
                </Grow>
            )
            }
        </>
    )
}