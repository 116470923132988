import React from "react";
import { useDropzone } from "react-dropzone";
import { useStyles } from './styles';

const Dropzone = ({ onDrop, accept = ".xlsx, .xls" }) => {
    const classes = useStyles();
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept,
    });

    return (
        <div {...getRootProps()} className={classes.root}>
            <input className="dropzone-input" {...getInputProps()} />
            <div className="text-center">
                {isDragActive ? (
                    <p className="dropzone-content">Arraste e solte o arquivo aqui</p>
                ) : (
                    <p className="dropzone-content">
                        Arraste e solte o arquivo aqui, ou clique para escolher o arquivo
                    </p>
                )}
            </div>
        </div>
    );
};

export default Dropzone;