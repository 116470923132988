import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, TextField, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import prestacaoDeContasRepositorio from '../../../../../repositorios/prestacaodecontas';
import { useCustomSnackbar } from '../../../../../components/snackbar2/NewSnackBar';
import ULoaderVoo from '../../../../../components/uloadervoo';
import { prestacaoDeContasConfigRepositorio } from '../../../../../repositorios/prestacaodecontas/config';
import usuarioRepositorio from '../../../../../repositorios/usuario';

export const Categorias = () => {
    const [categorias, setCategorias] = useState([]);
    const [editTitle, setEditTitle] = useState("");
    const [editIndex, setEditIndex] = useState(null);
    const [editProductIndex, setEditProductIndex] = useState(null);
    const [editProductTitle, setEditProductTitle] = useState("");
    const [isNewCategories, setIsNewCategories] = useState(false);
    const [newCategory, setNewCategory] = useState({ nome: "" });
    const [newProducts, setNewProducts] = useState([{ descricao_subtipo: "Outros" }]);
    const [loading, setLoading] = useState(false)

    const [teste, setTeste] = useState(null);

    const { enqueueSnackbar } = useCustomSnackbar();

    const getUsuarios = async () => {
        try {

        } catch {

        } finally {

        }
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            const response = await usuarioRepositorio.getAll();
            setTeste(response.data);
            setLoading(false)
        })()
    }, [])

    const BuscarTodosGrupoConfiguracao = async () => {
        try {
            let response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas();
            setCategorias(response);
        } catch {
        } finally {
        }
    };

    const MoreSubTipos = () => {
        setNewProducts([...newProducts, { nome: "" }]);
    };

    const startEditing = (index) => {
        setEditIndex(index);
        setEditTitle(categorias[index].name);
    };

    const saveCategoryName = () => {
        if (editTitle.trim() === "") {
            alert("O nome da categoria não pode ser vazio.");
            return;
        }
        const updatedCategorias = [...categorias];
        updatedCategorias[editIndex].name = editTitle;
        setCategorias(updatedCategorias);
        setEditIndex(null);
        setEditTitle("");
    };

    const startProductEditing = (categoryIndex, productIndex) => {
        setEditIndex(categoryIndex);
        setEditProductIndex(productIndex);
        setEditProductTitle(categorias[categoryIndex].produtos[productIndex].name);
    };

    const saveProductName = () => {
        if (editProductTitle.trim() === "") {
            alert("O nome do produto não pode ser vazio.");
            return;
        }
        const updatedCategorias = [...categorias];
        updatedCategorias[editIndex].produtos[editProductIndex].name = editProductTitle;
        setCategorias(updatedCategorias);
        setEditIndex(null);
        setEditProductIndex(null);
        setEditProductTitle("");
    };

    const saveNewCategory = async () => {
        try {
            setLoading(true)
            let data = {
                descricao_tipo: newCategory.nome,
                subtipos: newProducts
            }
            await prestacaoDeContasConfigRepositorio.postNovaCategoria(data);
            enqueueSnackbar('Categoria criada com sucesso.', { variant: 'success' });
            setIsNewCategories(false);
            setNewCategory({ nome: "" });
            setNewProducts([{ descricao_subtipo: "Outros" }]);
            BuscarTodosGrupoConfiguracao()
        } catch {
            enqueueSnackbar('Erro ao criar categoria.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        BuscarTodosGrupoConfiguracao();
    }, []);

    return (
        <Container maxWidth="lg">
            <ULoaderVoo isLoading={loading} />
            <Grid container item xs={12}>
                {teste?.map((item) => (
                    <Grid item xs={12}>
                        {(item.ativo && item.privilegio === 6) && (
                            <>
                                {item.pessoa.nome} - {item.pessoa.sobrenome}
                            </>
                        )}
                    </Grid>
                ))}
            </Grid>
            <Grid item xs={12} spacing={2} style={{ marginTop: "-10px" }}>
                <Button variant="outlined" size="medium" sx={{ borderRadius: "16px" }} onClick={() => setIsNewCategories(true)}>Adicionar Categoria</Button>
                {isNewCategories && (
                    <Button variant="text" color="error" size="medium" sx={{ borderRadius: "16px", marginLeft: "10px" }} onClick={() => setIsNewCategories(false)}>Cancelar</Button>
                )}
            </Grid>
            <Grid container item xs={12} spacing={2} style={{ backgroundColor: "white", borderRadius: "16px", padding: "20px", marginTop: "10px" }}>
                <Grid item xs={12} style={{ padding: "0px", borderRadius: "16px", zIndex: 1000 }}>
                    <TableContainer component={Paper} sx={{ borderRadius: "16px", boxShadow: "0px 0px 2.2px 0px rgba(0,0,0,0.5)" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Categoria para despesas</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isNewCategories && (
                                    <TableRow fullWidth style={{ width: "100%" }}>
                                        <TableCell component="th" scope="row">
                                            <Accordion sx={{ marginBottom: 0, boxShadow: "none", borderRadius: "16px", width: "100%" }}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#FFFFFF', '&.Mui-expanded': { backgroundColor: '#FFFFFF' } }}>
                                                    <TextField
                                                        value={newCategory.nome}
                                                        onChange={(e) => setNewCategory({ ...newCategory, nome: e.target.value })}
                                                        onClick={(e) => e.stopPropagation()}
                                                        sx={{ marginRight: 2, width: "90%" }}
                                                    />
                                                    <IconButton edge="end" aria-label="save" onClick={saveNewCategory}>
                                                        <SaveIcon />
                                                    </IconButton>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer component={Paper} sx={{ borderRadius: "16px" }}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Tipo/Produto</TableCell>
                                                                    <TableCell align="right">
                                                                        <Button variant="outlined" size="medium" sx={{ borderRadius: "16px" }} onClick={() => MoreSubTipos()}>Mais Categoria</Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {newProducts.map((product, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell component="th" scope="row">
                                                                            <TextField
                                                                                fullWidth
                                                                                value={product.descricao_subtipo}
                                                                                disabled={(product.descricao_subtipo === "Outros")}
                                                                                onChange={(e) => {
                                                                                    const updatedProducts = [...newProducts];
                                                                                    updatedProducts[index].descricao_subtipo = e.target.value;
                                                                                    setNewProducts(updatedProducts);
                                                                                }}
                                                                                onClick={(e) => e.stopPropagation()}
                                                                                sx={{ marginRight: 2 }}
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {categorias?.filter(categoria =>
                                    categoria.name !== 'Hodometro' &&
                                    categoria.name !== 'Pendente' &&
                                    categoria.name !== 'SOLICITAÇÃO' &&
                                    categoria.name !== 'Outros' &&
                                    categoria.name !== 'AEREO' &&
                                    categoria.name !== 'HOTEL'
                                ).map((categoria, categoryIndex) => (
                                    <TableRow key={categoryIndex} fullWidth style={{ width: "100%" }}>
                                        <TableCell component="th" scope="row">
                                            <Accordion sx={{ marginBottom: 0, boxShadow: "none", borderRadius: "16px", width: "100%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`panel${categoryIndex}-content`}
                                                    id={`panel${categoryIndex}-header`}
                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                    {editIndex === categoryIndex && editProductIndex === null ? (
                                                        <TextField
                                                            fullWidth
                                                            value={editTitle}
                                                            onChange={(e) => setEditTitle(e.target.value)}
                                                            onClick={(e) => e.stopPropagation()}
                                                            sx={{ marginRight: 2 }}
                                                        />
                                                    ) : (
                                                        <Typography variant="body1" fontWeight="bold" style={{ alignItems: "center", display: "flex" }}>
                                                            {categoria.name}
                                                        </Typography>
                                                    )}
                                                    {editIndex === categoryIndex && editProductIndex === null ? (
                                                        <IconButton edge="end" aria-label="save" onClick={saveCategoryName}>
                                                            <SaveIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton style={{ marginLeft: "auto", marginRight: "8px" }} edge="end" aria-label="edit" onClick={(e) => { e.stopPropagation(); startEditing(categoryIndex); }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer component={Paper} sx={{ borderRadius: "16px" }}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Tipo/Produto</TableCell>
                                                                    <TableCell align="right"></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {categoria.produtos?.map((produto, productIndex) => (
                                                                    <TableRow key={productIndex}>
                                                                        <TableCell component="th" scope="row">
                                                                            {editIndex === categoryIndex && editProductIndex === productIndex ? (
                                                                                <TextField
                                                                                    fullWidth
                                                                                    value={editProductTitle}
                                                                                    onChange={(e) => setEditProductTitle(e.target.value)}
                                                                                    onClick={(e) => e.stopPropagation()}
                                                                                    sx={{ marginRight: 2 }}
                                                                                />
                                                                            ) : (
                                                                                <Typography variant="body1">
                                                                                    {produto.name}
                                                                                </Typography>
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {editIndex === categoryIndex && editProductIndex === productIndex ? (
                                                                                <IconButton edge="end" aria-label="save" onClick={saveProductName}>
                                                                                    <SaveIcon />
                                                                                </IconButton>
                                                                            ) : (
                                                                                <IconButton edge="end" aria-label="edit" onClick={(e) => { e.stopPropagation(); startProductEditing(categoryIndex, productIndex); }}>
                                                                                    <EditIcon />
                                                                                </IconButton>
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Container>
    );
};
