import { Box, Button, Card, CardContent, Chip, Divider, Grid, TextField, Typography } from "@mui/material"
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// import DialogComponent from "../../sections/dialogs-desktop";
import BuildIcon from '@mui/icons-material/Build';
import { useCallback, useEffect, useState } from "react";
import { MarkEmailRead } from "@mui/icons-material";
import { EnumTipo, formatarParaNomeStatusViagem } from "../../../../../enums/enumPrestacaoDeContas";
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AddModeratorIcon from '@mui/icons-material/AddModerator';

import AddCardIcon from '@mui/icons-material/AddCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

// import { getTipoDespesaForNumber } from "../../../../../mock/tiposdespesas";
// import { ImageComponent } from "../../../../../components/ImageComponent";
// import { CreationDate, IniciadoEm } from "../../sections";
// import { EditarDespesaComponent } from "../../minhasviagens/Viagem/EditarDespesa";
import { cancelarEnvioDeMensagem_CONTROLADORIA_Mock, enviarParaPessoa_CONTROLADOR_Mock } from "../../../../../mock/prestacaodecontas";
import { CreationDate } from "../../../Desktop/sections";
import DialogComponent from "../../../Desktop/sections/dialogs-desktop";
import { TabPanel } from "@material-ui/lab";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import PropTypes from 'prop-types';
import prestacaoDeContasRepositorio from "../../../../../repositorios/prestacaodecontas";
import { ViewHodometerExpansesComponent } from "../../../../../components/autalii_expanses/viewhodometer_expanses";
import ULoaderVoo from "../../../../../components/uloadervoo";


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


export const ControladoriaDespesasComponentM = ({ viagem, getViagensReload }) => {
    const [despesa, setDespesa] = useState({ total: 0 });
    const [openEdit, setOpenEdit] = useState(false);
    const [descricao, setDescricao] = useState("")
    const [openReturnDespesa, setOpenReturnDespesa] = useState(null)
    const [totalGasto, setTotalgasto] = useState(despesa?.total);
    const [openInfoHodometro, setOpenInfoHodometro] = useState(false);
    const [openComprovante, setOpenComprovante] = useState(false)
    const [despesas, setDespesas] = useState([])
    const [loading, setLoading] = useState(false)

    const { enqueueSnackbar } = useCustomSnackbar();

    const getDespesas = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(viagem.id);
            setDespesas(response.despesas);
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, setDespesas, viagem.id, enqueueSnackbar]); // Dependências

    const handleClickReturn = (despesa) => {
        setDespesa(despesa)
        setOpenReturnDespesa(true)
    }

    const handleOpenEdit = (value) => {
        setDespesa(value)
        setOpenEdit(true)
    }

    const handleClickPessoaDespesa = () => {
        enviarParaPessoa_CONTROLADOR_Mock(viagem.id, descricao, despesa.id)
        setOpenReturnDespesa(false)
    }

    const handleClickCancelarEnvioDeMensagem = (iddespesa) => {
        cancelarEnvioDeMensagem_CONTROLADORIA_Mock(viagem.id, iddespesa, EnumTipo.viagemstatus.viagemfinalizada);
        setOpenReturnDespesa(false)
    }

    const handleClickOpenComprovante = (despesa) => {
        setDespesa(despesa)
        setOpenComprovante(true)
    }


    const handleClickAuditar = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.putAuditarValor(viagem.id, despesa.iddespesa, totalGasto);
            getDespesas()
            getViagensReload()
            enqueueSnackbar('Valor auditado com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao auditar a despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickAprovar = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.postAprovarDespesas(viagem.id, [despesa.iddespesa])
            getDespesas()
            getViagensReload()
            enqueueSnackbar('Aprovada com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao tentar aprovar despesa(s).', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        getDespesas()
    }, [getDespesas])

    return (
        <>
            <ULoaderVoo isLoading={loading} />
            {despesa !== null && (
                <>
                    <DialogComponent
                        title={
                            <>
                                Retornar Despesa
                            </>
                        }
                        open={openReturnDespesa}
                        handleClose={() => setOpenReturnDespesa(false)}
                        body={
                            <Grid container item xs={12} style={{ width: "400px" }}>
                                <Typography style={{ marginTop: "15px", marginBottom: "15px" }}>Ao enviar para a pessoa ela poderá modificar a despesa.</Typography>
                                <TextField
                                    id="filled-multiline-flexible"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    defaultValue={despesa?.mensagemcontrolador != null ? despesa?.mensagemcontrolador : ""}
                                    onChange={(e) => setDescricao(e.target.value)}
                                    multiline
                                    maxRows={4}
                                    label="Justificativa/Descrição"
                                    placeholder="Tem algo de errado com..."
                                />
                            </Grid>
                        }
                        action={
                            <Grid container item xs={12} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                                {despesa?.mensagem !== "" ? (
                                    <Button color="error" onClick={() => handleClickCancelarEnvioDeMensagem(despesa?.id)}>Cancelar</Button>
                                ) : (
                                    null
                                )}
                                <Button color="primary" disabled={descricao !== "" ? false : true} onClick={() => handleClickPessoaDespesa(despesa?.id)}>Pessoa</Button>
                            </Grid>
                        }
                        width={"sm"}
                    />
                    <DialogComponent
                        title={
                            <>
                                Informações do Hodômetro
                            </>
                        }
                        open={openInfoHodometro}
                        handleClose={() => setOpenInfoHodometro(false)}
                        body={
                            <ViewHodometerExpansesComponent despesa={despesa} isMobile={true} />
                        }
                        action={
                            <></>
                        }
                        width={"sm"}
                    />
                    <DialogComponent
                        title={
                            <>
                                Comprovante
                            </>
                        }
                        fullScreen={true}
                        open={openComprovante}
                        handleClose={() => setOpenComprovante(false)}
                        body={
                            <Grid container item xs={12}>
                                <img style={{ width: "inherit" }} alt="Comprovante" src={despesa.comprovante}></img>
                            </Grid>
                        }
                        action={
                            <></>
                        }
                        width={"sm"}
                    />
                    <DialogComponent
                        title={
                            <>
                                Controladoria
                            </>
                        }
                        open={openEdit}
                        handleClose={() => setOpenEdit(false)}
                        body={
                            <>
                                <Grid item xs={12} style={{ padding: "5px" }}>
                                    <TextField
                                        fullWidth
                                        id="outlined-number"
                                        label="Total gasto"
                                        defaultValue={despesa.total}
                                        onChange={(e) => setTotalgasto(e.target.value)}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "right" }}>
                                    <Button variant="contained" color="primary" autoFocus onClick={() => handleClickAuditar()}>
                                        Auditar Total
                                    </Button>
                                </Grid>
                            </>
                        }
                        action={
                            <>
                            </>
                        }
                        width={"sm"}
                    />
                </>
            )}
            <Grid item xs={12} style={{ padding: "0px" }}>
                <Card sx={{ boxShadow: 0.2, borderRadius: 10, padding: 0.1 }}>
                    {/* <GlobalStyle /> */}
                    <Box sx={{ display: 'block', justifyContent: 'space-between', alignItems: 'center', mb: 2, marginTop: "5px" }}>
                        <Typography variant="h6">{viagem?.titulo}</Typography>
                        <Typography variant="h6">
                            {viagem ? formatarParaNomeStatusViagem(viagem.status) : null}
                        </Typography>
                    </Box>
                    <Typography variant="subtitle1" color="textSecondary">{viagem.pessoa.nome}</Typography>
                    {/* <Chip label="Em análise" color="info" sx={{ my: 1 }} /> */}
                    <Typography variant="body2" color="textSecondary">Data de criação</Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}><CreationDate data={viagem.datadecriacao} /></Typography>
                    <Grid container item xs={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                        <Grid item xs={12} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <Typography variant="subtitle2" gutterBottom>
                                Saldo Atual
                            </Typography>
                            <Typography variant="h3">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.total)}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "right", marginBottom: "10px" }}>
                            <Box sx={{ textAlign: 'center', px: 2 }}>
                                <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                    Adiantamento
                                </Typography>

                                <Typography variant="body2" sx={{ color: 'text.secondary' }} style={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center"
                                }}>
                                    <AddCardIcon style={{ marginRight: "5px" }} />
                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial)}
                                </Typography>
                            </Box>
                            <Box sx={{ textAlign: 'center', px: 2 }}>
                                <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                    Restante
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }} style={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center"
                                }}>
                                    <PaymentsIcon style={{ marginRight: "5px" }} />
                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial - viagem.total)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <CardContent sx={{ p: 0 }}>
                        {despesas?.map((item) => (
                            <>
                                {item.controladoria ? (
                                    <>
                                        {item.hodometro ? (
                                            <>
                                                <Divider style={{ marginTop: "5px" }} />
                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                                    <Box sx={{ flex: 1 }}>
                                                        <Typography variant="body1" style={{ fontWeight: "600" }}>Hodometro</Typography>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                            <Typography variant="body2" color="textSecondary">

                                                            </Typography>
                                                        </Box>
                                                        <Typography variant="body2" color="textSecondary"><CreationDate data={item.datacriacao} /></Typography>
                                                        <Typography variant="body2" sx={{ mt: 1 }}>{item.descricao}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                    <Typography variant="body2"></Typography>
                                                    <Typography variant="body2">Total em Km</Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                    <Typography variant="body2" style={{ marginTop: "auto" }}>{item.despesa_desc}</Typography>
                                                    <Typography variant="body2">
                                                        {item.hodometro.kmtotal} Km
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                    {item.mensagemcontrolador !== "" && item.mensagemcontrolador !== null ? (
                                                        <Button fullWidth color="warning" style={{ marginTop: "-5px", marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<MarkEmailRead />}>
                                                            Mensagem
                                                        </Button>
                                                    ) : (
                                                        <Button fullWidth color="warning" style={{ marginTop: "-5px", marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AssignmentReturnIcon />}>
                                                            Retornar
                                                        </Button>
                                                    )}
                                                    {/* <Button fullWidth color="secondary" style={{ marginTop: "-5px", marginRight: "3px", borderRadius: "20px" }} onClick={() => handleOpenEdit(item)} startIcon={<BuildIcon />}>
                                                        Corrigir
                                                    </Button> */}
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: "center", mt: 1 }}>
                                                    <Button
                                                        startIcon={<ReceiptLongIcon />}
                                                        onClick={() => handleClickOpenComprovante(item)}
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                    >Comprovante</Button>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: "center", mt: 1 }}>
                                                    <Button fullWidth variant="contained" color="secondary" style={{ marginTop: "0px", marginRight: "3px", borderRadius: "20px" }} onClick={() => handleClickAprovar(item)} startIcon={<AddModeratorIcon />}>
                                                        Aprovar
                                                    </Button>
                                                </Box>
                                            </>
                                        ) : (
                                            <>
                                                <Divider style={{ marginTop: "5px" }} />
                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                                    <Box sx={{ flex: 1 }}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                                                            <Typography variant="body1" style={{ fontWeight: "600" }}>Despesa</Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {item.controladoria ? (
                                                                    <>
                                                                        <Chip label="Controladoria" color="warning" style={{ marginTop: "-18px", marginLeft: "5px", color: "white" }} icon={<AdminPanelSettingsIcon />}>
                                                                        </Chip>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {item.aprovado ? (
                                                                            <Chip label="Aprovado" color="primary" />
                                                                        ) : (
                                                                            <Chip label="Pendente" color="secondary" />
                                                                        )}
                                                                    </>
                                                                )}
                                                                {item.valor_auditado !== null ? (
                                                                    <>
                                                                        <Chip label={"Auditado - " + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor_auditado))} color="warning" style={{ marginLeft: "5px", color: "white" }} icon={<CurrencyExchangeIcon />}>
                                                                        </Chip>
                                                                    </>) : (null)}
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant="body2" color="textSecondary"><CreationDate data={item.datacriacao} /></Typography>
                                                        <Typography variant="body2" sx={{ mt: 1 }}>{item.descricao}</Typography>
                                                        <Typography variant="body2" color="darkgrey" sx={{ mt: 1 }}>Mensagem do auditor</Typography>
                                                        <Typography variant="body2" sx={{ mt: 1 }}>{item.mensagem}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                    <Typography variant="body2">Para</Typography>
                                                    <Typography variant="body2">Total</Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                    <Typography variant="body2" style={{ marginTop: "auto" }}>{item.despesa_desc}</Typography>
                                                    <Typography variant="body2">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.total)}</Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                    {item.mensagemcontrolador !== "" && item.mensagemcontrolador !== null ? (
                                                        <Button fullWidth color="warning" style={{ marginTop: "-5px", marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<MarkEmailRead />}>
                                                            Mensagem
                                                        </Button>
                                                    ) : (
                                                        <Button fullWidth color="warning" style={{ marginTop: "-5px", marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AssignmentReturnIcon />}>
                                                            Retornar
                                                        </Button>
                                                    )}
                                                    <Button fullWidth color="secondary" style={{ marginTop: "-5px", marginRight: "3px", borderRadius: "20px" }} onClick={() => handleOpenEdit(item)} startIcon={<BuildIcon />}>
                                                        Corrigir
                                                    </Button>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: "center", mt: 1 }}>
                                                    <Button
                                                        startIcon={<ReceiptLongIcon />}
                                                        onClick={() => handleClickOpenComprovante(item)}
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                    >Comprovante</Button>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: "center", mt: 1 }}>
                                                    <Button fullWidth variant="contained" color="secondary" style={{ marginTop: "0px", marginRight: "3px", borderRadius: "20px" }} onClick={() => handleClickAprovar(item)} startIcon={<AddModeratorIcon />}>
                                                        Aprovar
                                                    </Button>
                                                </Box>
                                            </>
                                        )}
                                    </>
                                ) : (null)}
                            </>
                        ))}
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}