import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Backdrop, Grid, IconButton, InputLabel, List, Select, Slide, Toolbar, Typography, withStyles } from '@material-ui/core';
import { useStyles } from './style';
import LinesTextField from '../../../../components/LinesTextField';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'; 
import "../../../../fonts/fonts.css"

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledSelect = withStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c62828 !important',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c62828 !important',
        },
        "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c62828 !important',
        },
    },
})(Select);


export default function MenuSelectField(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [searching, setSearching] = useState();

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        if (props.setState != null) {
            props.setState(false)
        }
    };

    const setCrianca = (index, indexkid, value) => {

        props.setQuartos(quartosAnteriores => {
            // Criando uma cópia do array atual para manipulação
            const novosQuartos = [...quartosAnteriores];

            novosQuartos[index].crianças[indexkid].idade = value;

            return novosQuartos;
        });

        // setState({
        //     ...state,
        //     criancas: value.value
        // })
    }

    const setAdulto = (value) => {
        props.setStepperAdultos(value)
        // setState({
        //     ...state,
        //     adultos: value.value
        // })
    }
    const setRooms = (value) => {
        props.setStepperRooms(value)
        // setState({
        //     ...state,
        //     bebes: value.value
        // })
    }


    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    // const handleClose = (event) => {
    //     if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //         return;
    //     }

    //     setOpen(false);
    // };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const generatePlaceholder = () => {
        const parts = [];
        let totalAdultos = 0;
        let totalCriancas = 0;

        // Calcular o total de adultos e crianças
        props.quartos.forEach(quarto => {
            totalAdultos += quarto.adultos;
            totalCriancas += quarto.crianças.length;
        });

        props.setStepperCriancas(totalCriancas)
        props.setStepperAdultos(totalAdultos);

        // Adicionando a contagem de adultos e crianças às partes
        if (totalAdultos > 0) {
            parts.push(`${totalAdultos} ${totalAdultos === 1 ? 'Adulto' : 'Adultos'}`);
        }

        if (totalCriancas > 0) {
            parts.push(`${totalCriancas} ${totalCriancas === 1 ? 'Criança' : 'Crianças'}`);
        }

        // Adicionando a contagem de quartos
        if (props.quartos.length > 0) {
            parts.push(`${props.quartos.length} ${props.quartos.length === 1 ? 'Quarto' : 'Quartos'}`);
        }

        return parts.join(' • ');
    };

    const generatePlaceholderMobile = () => {
        const parts = [];
        let totalAdultos = 0;
        let totalCriancas = 0;

        // Calcular o total de adultos e crianças
        props.quartos.forEach(quarto => {
            totalAdultos += quarto.adultos;
            totalCriancas = quarto.crianças.length;
        });

        props.setStepperCriancas(totalCriancas)
        props.setStepperAdultos(totalAdultos);

        // Adicionando a contagem de adultos e crianças às partes
        if (totalAdultos > 0) {
            parts.push(`${totalAdultos} ${totalAdultos === 1 ? 'Adulto' : 'Adultos'}`);
        }

        if (totalCriancas > 0) {
            parts.push(`${totalCriancas} ${totalCriancas === 1 ? 'Criança' : 'Crianças'}`);
            props.setStepperCriancas(totalCriancas)
        }

        // Adicionando a contagem de quartos
        if (props.quartos.length > 0) {
            parts.push(`${props.quartos.length} ${props.quartos.length === 1 ? 'Quarto' : 'Quartos'}`);
        }

        return parts.join(' • ');
    }

    const handleClickRemoveRoom = (index) => {
        props.setQuartos(props.quartos.filter((_, i) => i !== index));
    }

    const handleClickAddRoom = () => {
        props.setQuartos(quartosAntigos => [
            ...quartosAntigos,
            { adultos: 1, crianças: 0 }
        ]);
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleAdultChange = (novoValor) => {
        props.setStepperAdultos(prevState => ({ ...prevState, value: novoValor }));
        props.setQuartos(quartosAntigos => quartosAntigos.map((quarto, index) => {
            if (index === 0) { // Supondo que estamos alterando o primeiro quarto
                return { ...quarto, adultos: novoValor };
            }
            return quarto;
        }));
    }

    const handleChildChange = (novoValor) => {
        props.setStepperCriancas(prevState => ({ ...prevState, value: novoValor }));
        props.setQuartos(quartosAntigos => quartosAntigos.map((quarto, index) => {
            if (index === 0) { // Supondo que estamos alterando o primeiro quarto
                return { ...quarto, crianças: novoValor };
            }
            return quarto;
        }));
    }

    const handleAdd = (index, type, operation) => {
        props.setQuartos(quartosAnteriores => {
            // Criando uma cópia do array atual para manipulação
            const novosQuartos = [...quartosAnteriores];

            // Verificando o tipo e realizando a operação correspondente
            if (type === "Adults") {
                if (operation === '+' && novosQuartos[index].adultos < 5) {
                    novosQuartos[index].adultos += 1;
                } else if (operation === '-' && novosQuartos[index].adultos > 1) {
                    novosQuartos[index].adultos -= 1;
                }
            } else if (type === "Children") {
                if (operation === '+' && novosQuartos[index].crianças.length < 5) {
                    novosQuartos[index].crianças.push({ value: novosQuartos[index].crianças.length, idade: 12, name: "" });
                } else if (operation === '-' && novosQuartos[index].crianças.length !== 0) {
                    novosQuartos[index].crianças = novosQuartos[index].crianças.filter((value, i) => value.value !== (novosQuartos[index].crianças.length - 1));
                }
            }


            return novosQuartos;
        });
    };

    return (
        <>
            {props.fullScreen != false ?
                <LinesTextField
                    placeholder={generatePlaceholder()}
                    variant="outlined"
                    margin="dense"
                    ref={anchorRef}
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    color="primary"
                    onClick={handleClick}
                    fullWidth
                />
                :
                <Grid item xs={12} onClick={handleClick} style={{ marginTop: "10px" }}>
                    <Backdrop className={classes.backdrop} open={Boolean(anchorEl)}>
                    </Backdrop>
                    <Typography style={{ alignSelf: "center", fontSize: "15px", display: "flex" }}><MeetingRoomIcon style={{ color: "#c62828", alignSelf: "center", marginRight: "5px" }} />Quarto & Hóspede</Typography>
                    <Typography style={{ alignSelf: "center", fontSize: "15px", display: "flex", marginLeft: "25px", fontFamily: "HindBold", marginLeft: "28px" }}>{generatePlaceholderMobile()}</Typography>
                </Grid>
            }
            {props.fullScreen != false ?
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <div>
                        <Grid className={classes.pacotinho} container style={{ marginLeft: "0px", width: "-webkit-fit-content", maxWidth: "378px", height: "auto" }}>
                            {props.quartos?.map((quarto, index) => (
                                <>
                                    <Grid item xs={12} style={{ padding: "20px", textAlign: "center" }}>
                                        <a style={{ fontWeight: "600", fontSize: "20px", color: "#c62828" }}>Quarto {index + 1}</a>
                                    </Grid>
                                    <Grid className={classes.passageiros} item xs={12} style={{ marginTop: "15px" }}>
                                        <div className={classes.root}>
                                            <InputLabel className={classes.label} align="left">Adulto</InputLabel>
                                            <div className={classes.linha}>
                                                <div className={classes.borda}>
                                                    <IconButton size="small" onClick={() => handleAdd(index, "Adults", '-')}>
                                                        <RemoveCircleOutline style={{ color: '#c62828' }} fontSize="inherit" />
                                                    </IconButton>
                                                </div>
                                                <LinesTextField disabled={true}
                                                    value={quarto.adultos}
                                                    className={classes.textfield}
                                                    inputProps={{
                                                        style: { textAlign: "center" }
                                                    }}
                                                />
                                                <div className={classes.borda}>
                                                    <IconButton size="small" onClick={() => handleAdd(index, "Adults", '+')}>
                                                        <AddCircleOutline style={{ color: '#c62828' }} fontSize="inherit" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.root} style={{ marginTop: "20px" }}>
                                            <InputLabel className={classes.label} align="left">Criança</InputLabel>
                                            <div className={classes.linha}>
                                                <div className={classes.borda}>
                                                    <IconButton size="small" onClick={() => handleAdd(index, "Children", '-')}>
                                                        <RemoveCircleOutline style={{ color: '#c62828' }} fontSize="inherit" />
                                                    </IconButton>
                                                </div>
                                                <LinesTextField disabled={true}
                                                    value={quarto.crianças.length}
                                                    className={classes.textfield}
                                                    inputProps={{
                                                        style: { textAlign: "center" }
                                                    }}
                                                />
                                                <div className={classes.borda}>
                                                    <IconButton size="small" onClick={() => handleAdd(index, "Children", '+')}>
                                                        <AddCircleOutline style={{ color: '#c62828' }} fontSize="inherit" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                        {props.quartos.map((room, index) => (
                                            <>
                                                <div >
                                                    <Grid container item xs={12}>
                                                        {
                                                            room.crianças?.map((kid, indexkid) => (
                                                                <>
                                                                    <Grid item xs={6} style={{ padding: "10px", marginBottom: "-30px", marginTop: "18px" }}>
                                                                        <Typography style={{ fontWeight: "600", fontSize: "18px", color: "#242424" }}>Idade da criança</Typography>
                                                                        <Typography style={{ fontWeight: "500", fontSize: "14px" }}>Até 17 anos</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{ padding: "10px", marginBottom: "-30px", marginTop: "15px" }}>
                                                                        <StyledSelect
                                                                            labelId="demo-simple-select-helper-label"
                                                                            id="demo-simple-select-helper"
                                                                            value={kid.idade}
                                                                            onChange={(e) => setCrianca(index, indexkid, e.target.value)}
                                                                            fullWidth
                                                                            variant={"outlined"}
                                                                        >
                                                                            {[...Array(18).keys()].map((value) => (
                                                                                <MenuItem value={value}>{value} anos</MenuItem>
                                                                            ))}
                                                                        </StyledSelect>
                                                                    </Grid>
                                                                </>
                                                            ))
                                                        }
                                                    </Grid>
                                                </div>
                                            </>
                                        ))}
                                    </Grid>
                                    {index == 0 ? (
                                        null
                                    ) : (
                                        <Grid item xs={12} style={{ margin: "20px 20px -10px 20px", textAlign: "center" }}>
                                            <button style={{
                                                fontWeight: "600",
                                                fontSize: "15px",
                                                color: "white",
                                                backgroundColor: "#d32f2f", // Uma cor vermelha mais escura para indicar uma ação de remover
                                                border: "none",
                                                cursor: "pointer",
                                                padding: "10px 20px",
                                                borderRadius: "5px",
                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                transition: "background-color 0.3s, transform 0.3s"
                                            }} onClick={() => handleClickRemoveRoom(index)}>
                                                Remover quarto
                                            </button>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} style={{ margin: "20px 20px -10px 20px", textAlign: "center" }}>

                                    </Grid>
                                </>
                            )
                            )}
                            <Grid item xs={12} style={{ marginTop: "18px", padding: "10px" }}>
                                <Button className={classes.button} onClick={handleClose} fullWidth>OK</Button>
                            </Grid>
                        </Grid>
                    </div>
                </StyledMenu>
                :
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <div>
                        <Grid className={classes.pacotinho} container style={{ marginLeft: "0px", width: "-webkit-fit-content" }}>
                            {props.quartos?.map((quarto, index) => (
                                <>
                                    <Grid item xs={12} style={{ padding: "20px", textAlign: "center" }}>
                                        <a style={{ fontWeight: "600", fontSize: "20px", color: "#c62828", fontFamily: "HindBold" }}>Quarto {index + 1}</a>
                                    </Grid>
                                    <Grid className={classes.passageiros} item xs={12} style={{ marginTop: "15px" }}>
                                        <div className={classes.root}>
                                            <InputLabel className={classes.label} style={{ fontFamily: "HindBold" }} align="left">Adulto</InputLabel>
                                            <div className={classes.linha}>
                                                <div className={classes.borda}>
                                                    <IconButton size="small" onClick={() => handleAdd(index, "Adults", '+')}>
                                                        <AddCircleOutline style={{ color: '#c62828' }} fontSize="inherit" />
                                                    </IconButton>
                                                </div>
                                                <LinesTextField disabled={true}
                                                    value={quarto.adultos}
                                                    className={classes.textfield}
                                                    inputProps={{
                                                        style: { textAlign: "center" }
                                                    }}
                                                />
                                                <div className={classes.borda}>
                                                    <IconButton size="small" onClick={() => handleAdd(index, "Adults", '-')}>
                                                        <RemoveCircleOutline style={{ color: '#c62828' }} fontSize="inherit" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.root} style={{ marginTop: "20px" }}>
                                            <InputLabel className={classes.label} style={{ fontFamily: "HindBold" }} align="left">Criança</InputLabel>
                                            <div className={classes.linha}>
                                                <div className={classes.borda}>
                                                    <IconButton size="small" onClick={() => handleAdd(index, "Children", '+')}>
                                                        <AddCircleOutline style={{ color: '#c62828' }} fontSize="inherit" />
                                                    </IconButton>
                                                </div>
                                                <LinesTextField disabled={true}
                                                    value={quarto.crianças.length}
                                                    className={classes.textfield}
                                                    inputProps={{
                                                        style: { textAlign: "center" }
                                                    }}
                                                />
                                                <div className={classes.borda}>
                                                    <IconButton size="small" onClick={() => handleAdd(index, "Children", '-')}>
                                                        <RemoveCircleOutline style={{ color: '#c62828' }} fontSize="inherit" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                        {props.quartos.map((room, index) => (
                                            <>
                                                <div >
                                                    <Grid container item xs={12}>
                                                        {
                                                            room.crianças?.map((kid, indexkid) => (
                                                                <>
                                                                    <Grid item xs={6} style={{ padding: "10px", marginBottom: "-30px", marginTop: "18px" }}>
                                                                        <Typography style={{ fontWeight: "600", fontSize: "18px", color: "#242424" }}>Idade da criança</Typography>
                                                                        <Typography style={{ fontWeight: "500", fontSize: "14px" }}>Até 17 anos</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{ padding: "10px", marginBottom: "-30px", marginTop: "15px" }}>
                                                                        <StyledSelect
                                                                            labelId="demo-simple-select-helper-label"
                                                                            id="demo-simple-select-helper"
                                                                            value={kid.idade}
                                                                            onChange={(e) => setCrianca(index, indexkid, e.target.value)}
                                                                            fullWidth
                                                                            variant={"outlined"}
                                                                        >
                                                                            {[...Array(18).keys()].map((value) => (
                                                                                <MenuItem value={value}>{value} anos</MenuItem>
                                                                            ))}
                                                                        </StyledSelect>
                                                                    </Grid>
                                                                </>
                                                            ))
                                                        }
                                                    </Grid>
                                                </div>
                                            </>
                                        ))}
                                    </Grid>
                                    {index == 0 ? (
                                        null
                                    ) : (
                                        <Grid item xs={12} style={{ margin: "20px 20px -10px 20px", textAlign: "center" }}>
                                            <button style={{
                                                fontWeight: "600",
                                                fontSize: "15px",
                                                color: "white",
                                                backgroundColor: "#d32f2f", // Uma cor vermelha mais escura para indicar uma ação de remover
                                                border: "none",
                                                cursor: "pointer",
                                                padding: "10px 20px",
                                                borderRadius: "5px",
                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                transition: "background-color 0.3s, transform 0.3s"
                                            }} onClick={() => handleClickRemoveRoom(index)}>
                                                Remover quarto
                                            </button>
                                        </Grid>
                                    )}
                                    {/* <Grid item xs={12} style={{ margin: "20px 20px -10px 20px", textAlign: "center" }}>
                                        <button style={{
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            color: "white",
                                            backgroundColor: "#c62828",
                                            border: "none",
                                            cursor: "pointer",
                                            padding: "10px 20px",
                                            borderRadius: "5px",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                            transition: "background-color 0.3s, transform 0.3s"
                                        }} onClick={() => handleClickAddRoom()}>
                                            Adicionar novo quarto
                                        </button>
                                    </Grid> */}

                                </>
                            )
                            )}
                            <Grid item xs={12} style={{ marginTop: "18px", padding: "10px" }}>
                                <Button className={classes.button} style={{ borderRadius: "30px" }} onClick={handleClose} fullWidth>OK</Button>
                            </Grid>
                        </Grid>
                    </div>
                </StyledMenu>
            }
        </>
    );
}
