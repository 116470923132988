import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close, TrendingDownOutlined } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import ULoaderVoo from "../../../../components/uloadervoo";
import menorTarifaRepositorio from "../../../../repositorios/relatorio/menorTarifa";
import Snackbar from "../../../../components/snackbar";
import { useStyles } from "./styles";

const DialogHistoricoReserva = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [responseHistorico, setResponseHistorico] = useState([]);
  const setOpenDialoHistorico = props.setOpenDialoHistorico;

  const handleClickClose = useCallback(() => {
    setOpenDialoHistorico(false); // Fecha o diálogo
  }, [setOpenDialoHistorico]);

  const pesquisaHistorico = useCallback(async () => {
    setIsLoading(true);

    if (props.dialogIdReserva <= 0) {
      setIsLoading(false);
      return;
    }

    try {
      let response =
        await menorTarifaRepositorio.getHistoricoRelatorioMenorTarifa(
          props.dialogIdReserva
        );
      setResponseHistorico(response);
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage("Erro ao buscar histórico de reduções do aéreo.");
      setSnackVisible({ visible: true });
      handleClickClose();
    } finally {
      setIsLoading(false);
    }
  }, [
    props.dialogIdReserva,
    setIsLoading,
    setResponseHistorico,
    setSnackVariant,
    setSnackMessage,
    setSnackVisible,
    handleClickClose,
  ]);

  useEffect(() => {
    pesquisaHistorico();
  }, [props.open, pesquisaHistorico]);

  return (
    <>
      <Snackbar
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />
      <ULoaderVoo isLoading={isLoading} />
      <Dialog
        open={props.open}
        maxWidth="xs"
        onClose={() => handleClickClose()}
        fullWidth
        fullScreen={responsive}
      >
        <AppBar position="relative" style={{ background: "none" }}>
          <Toolbar className={classes.appbar}>
            <div>
              <Typography>Histórico ({props.idreservatenant})</Typography>
            </div>
            <div>
              <IconButton
                className={classes.icon}
                onClick={() => handleClickClose()}
              >
                <Close />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container>
            {responseHistorico.map((item, index) => (
              <>
                <Grid item xs={6}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    fullWidth
                    justifyContent={"space-between"}
                  >
                    <Typography
                      style={{
                        fontWeight:
                          index === responseHistorico.length - 1
                            ? "bold"
                            : "normal",
                      }}
                    >
                      {item.data}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontWeight:
                        index === responseHistorico.length - 1
                          ? "bold"
                          : "normal",
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      fullWidth
                      justifyContent={"flex-end"}
                    >
                      {item.valor}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {index !== responseHistorico.length - 1 ? (
                    <TrendingDownOutlined style={{ color: "green" }} />
                  ) : null}
                </Grid>
              </>
            ))}

            <Grid
              container
              item
              xs={12}
              style={{ textAlign: "center", height: "100%" }}
            >
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "green",
                    textAlign: "center",
                    height: "auto",
                  }}
                >
                  Redução Total:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{ color: "green", textAlign: "center" }}
                >
                  {" "}
                  {props.reduction?.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogHistoricoReserva;
