import { Button, Card, Divider, ExpansionPanelDetails, InputBase, Paper, Table, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import api from '../../../../Api';
import UDatePicker from '../../../../components/udatepicker';
import USearchField from '../../../../components/usearchfield';
import Dashboard from '../../../dashboard';
import { useStyles } from './styles';
import Cookies from "js-cookie";

export const ListaExcedentes = props => {
    const classes = useStyles();
    const matches = useMediaQuery('(max-width:480px)');
    const matches2 = useMediaQuery('(max-width:900px) and (orientation: landscape)');
    const [centroCusto, setCentroCusto] = useState(null);
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [pessoas, setPessoas] = useState([]);
    const [pessoa, setPessoa] = useState(null);
    const [dataInicio, setDataInicio] = useState(new Date());
    const [dataFinal, setDataFinal] = useState(new Date());

    useEffect(() => {
        let listaPessoa = [];
        api.get('/pessoa', {
            headers: {
                Authorization: 'Bearer ' + Cookies.get('token'),
            }
        }).then(response => {
            response.data.forEach(item => {
                listaPessoa.push({
                    label: item.nome,
                    value: item.idpessoa,
                })
            })
            setPessoas(listaPessoa);
        })
    }, [])

    useEffect(() => {
        let listaCentroCusto = [];
        api.get('/centrocusto/GetAllPorTenant', {
            headers: {
                Authorization: 'Bearer ' + Cookies.get('token'),
                sessionId: Cookies.get('sessionId')
            } 
        }).then(response => {
            response.data.forEach(item => {
                listaCentroCusto.push({
                    label: item.nome,
                    value: item.idcentrocusto,
                })
            })
            setCentrosCusto(listaCentroCusto);
        })
    }, [])

    return (
        <div className={classes.expansion}>
            <Typography className={classes.titulo}>Excedentes</Typography>
            <div className={classes.lista2}>
                <Card elevation={5}>
                    {!matches && !matches2 ? (
                        <div className={classes.testevai}>
                            <Typography className={classes.heading}>Utilize filtros em sua pesquisa</Typography>
                            <Button className={classes.button}>Aplicar Filtros</Button>
                        </div>) : null}
                    <Divider />
                    <ExpansionPanelDetails className={classes.filtros}>
                        <div className={classes.search}>
                            <USearchField {...{ itens: centrosCusto, value: centroCusto, setValue: setCentroCusto, label: 'Centro de Custo' }} />
                        </div>

                        <div className={classes.search}>
                            <USearchField {...{ itens: pessoas, value: pessoa, setValue: setPessoa, label: 'Viajante' }} />
                        </div>
                        <div className={classes.udate}>
                            <div className={classes.data1}>
                                <UDatePicker {...{ date: dataInicio, setDate: setDataInicio, label: 'Data Inicio' }} />
                            </div>
                            <div>
                                <UDatePicker {...{ date: dataFinal, setDate: setDataFinal, label: 'Data Final' }} />
                            </div>
                        </div>
                        <div className={classes.search2}>
                            <USearchField {...{ itens: centrosCusto, value: centroCusto, setValue: setCentroCusto, label: 'Período' }} />
                        </div>
                        {matches || matches2 ? (
                            <div className={classes.iconzitobutton}>
                                <Button className={classes.button}>Pesquisar</Button>
                            </div>) : null}
                    </ExpansionPanelDetails>
                </Card>
            </div>
            <div className={classes.lista}>
                <Paper>
                    <div className={classes.separar}>
                        <div className={classes.botoes}>
                            <div className={classes.botao}>
                                <Button className={classes.botao0}>Imprimir</Button>
                                <Button className={classes.botao1}>Copiar</Button>
                                <Button className={classes.botao3}>Excel</Button>
                                <Button className={classes.botao4}>PDF</Button>
                            </div>
                        </div>
                        <div className={classes.search3}>
                            <div className={classes.searchIcon}>
                                <Search />
                            </div>
                            <InputBase
                                placeholder="Pesquise…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow >
                                {!matches && !matches2 ? (
                                    <div className={classes.dados}>
                                        <TableCell className={classes.heads} align="center"></TableCell>
                                        <TableCell className={classes.heads} align="center"></TableCell>
                                        <TableCell className={classes.heads} align="center">ID</TableCell>
                                        <TableCell className={classes.heads} align="center">Titulo</TableCell>
                                        <TableCell className={classes.heads} align="center">Produto</TableCell>
                                        <TableCell className={classes.heads} align="center">valor</TableCell>
                                        <TableCell className={classes.heads} align="center">Excedente</TableCell>
                                        <TableCell className={classes.heads} align="center">Solicitante</TableCell>
                                        <TableCell className={classes.heads} align="center">Passageiro</TableCell>
                                        <TableCell className={classes.heads} align="center"></TableCell>
                                    </div>) :
                                    <div>
                                        <div className={classes.cardtable}>
                                            <label className={classes.cardtab}>ID</label>
                                            <Card elevation={4} className={classes.cardtab}><TableCell className={classes.heads} align="center"></TableCell></Card>
                                            <label className={classes.cardtab}>Titulo</label>
                                            <Card elevation={4} className={classes.cardtab}><TableCell className={classes.heads} align="center"></TableCell></Card>
                                            <label className={classes.cardtab}>Produto</label>
                                            <Card elevation={4} className={classes.cardtab}><TableCell className={classes.heads} align="center"></TableCell></Card>
                                            <label className={classes.cardtab} style={{ color: 'Green' }}>Valor</label>
                                            <Card elevation={4} className={classes.cardtab}><TableCell className={classes.heads} align="center"></TableCell></Card>
                                            <label className={classes.cardtab} style={{ color: 'red' }}>Excedente</label>
                                            <Card elevation={4} className={classes.cardtab}><TableCell className={classes.heads} align="center"></TableCell></Card>
                                            <label className={classes.cardtab} >Solicitante</label>
                                            <Card elevation={4} className={classes.cardtab}><TableCell className={classes.heads} align="center"></TableCell></Card>
                                            <label className={classes.cardtab} >Passageiro</label>
                                            <Card elevation={4} className={classes.cardtab}><TableCell className={classes.heads} align="center"></TableCell></Card>
                                        </div>
                                    </div>
                                }
                            </TableRow>
                        </TableHead>
                    </Table>
                </Paper>
            </div>

        </div>
    )
};

export const Lista = props => {
    return (
        <div>
            <Dashboard {...props} component={ListaExcedentes} />
        </div>
    )
};
export default Lista
