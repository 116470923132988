const INITIAL_STATE = {
    permissaoDashboard: {
        usuario: false,
        pessoa: false,
        produto: false,
        fornecedor: false,
        empresa: false,
        centroCusto: false,
        permissao: false,
        politica: false,
        relatorio: false,
        relatorioPessoal: false,
        relatorioCentroCusto: false,
        permiteVeraereo: false,
        permiteVerCaixadeentrada: false,
        permiteVerSolicitacao: false,
        permiteverecogestao: false,
        permiteeditarecogestao: false,
        idtenant: 0
    },
    dadosSessao: {},
    politicaViagem: {}
}

const Permissoes = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'UPDATE_PERMISSAO':
            return { ...state, permissaoDashboard: action.permissao }
        case 'UPDATE_DADOS_SESSAO':
            return { ...state, dadosSessao: action.dadosSessao }
        case 'SET_POLITICA_VIAGEM':
            return { ...state, politicaViagem: action.politicaViagem }
        default:
            return state;
    }
}

export default Permissoes;
